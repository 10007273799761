import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SimpleSmoothScrollService } from 'ng2-simple-smooth-scroll';
import { User, Owner, Contact, UserForm, UserType } from '../core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '../interfaces';

import * as orderSelector from '../core/store/order/order.selectors';
import * as uiSelector from '../core/store/ui/ui.selectors';

import { Observable, Subscription, combineLatest } from 'rxjs';
import { AuthService } from '../core/services/auth.service';
import { MatStepper, MatCheckbox } from '@angular/material';

import PlaceResult = google.maps.places.PlaceResult;

import {
  SetAddress,
  SetOwner,
  SetContact,
  SetUserForm,
  SetDateButoir,
  SetNewUserType,
  SetGoGetKeys,
  SetContactChoice,
  SetInvoiceTo,
  SetVenteInfo
} from '../core/store/order';
import { Address } from '../core/models/address';
import { newUserTypes } from '../core/data/newUserTypes';
import { debounceTime } from 'rxjs/operators';
import { ContactTypes } from '../core/enums/contact.enum';
import { SetStep2Valid } from '../core/store/ui';
import { hasClientTypeChoice } from '../core/data';
import { filterNewUserTypes } from '../core/data/theme-config';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss']
})
export class Step2Component implements OnInit {
  assetsFolder = 'assets/images/';
  debouncer: any;

  step2FormGroup: FormGroup;
  minDate = new Date();
  user: User;
  userForm: UserForm;
  address: Address;
  owner: Owner;
  contact: Contact;
  newUserTypes: { id: UserType; txt: string; fileName: string }[];
  contactTypes = ContactTypes;
  userTypes = UserType;
  contactChoice: string;
  newUserType: UserType;
  userType: UserType;
  isValid: boolean;
  dateButoire: Date;

  hasClientTypeChoice = hasClientTypeChoice;
  filterNewUserTypes = filterNewUserTypes;

  $ownerSub: Subscription;
  $userSub: Subscription;
  $venteInfoSub: Subscription;
  $cadastreSub: Subscription;
  $goGetKeys: Observable<boolean>;

  @Input() stepper: MatStepper;

  $isLoggedIn: Observable<boolean>;
  $dateButoir: Observable<Date>;
  $newUserType: Observable<UserType>;
  $contact: Observable<Contact>;
  $contactChoice: Observable<string>;
  $owner: Observable<Owner>;
  $values: Observable<any>;
  $reason: Observable<number>;

  cadastreFG: FormGroup;
  venteFG: FormGroup;

  venteInfo = {
    street: '',
    num: '',
    city: '',
    zip: '',
    division: '',
    section: '',
    numCadastre: '',
    rcni: '',
    statusVentePub: '',
    venteDate: ''
  };

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private _formBuilder: FormBuilder,
    private smooth: SimpleSmoothScrollService,
    private cdRef: ChangeDetectorRef
  ) {
    this.$isLoggedIn = this.store.select(orderSelector.getIsLoggedIn);

    this.$contact = this.store.select(orderSelector.getContact);

    this.$contactChoice = this.store.select(orderSelector.getContactChoice);
    this.$contactChoice.subscribe(c => (this.contactChoice = c));
    this.$newUserType = this.store.select(orderSelector.getUserType);
    this.store.select(orderSelector.getUserInfo).subscribe(u => (this.user = u));
    this.store.select(orderSelector.getUserForm).subscribe(u => (this.userForm = u));
    this.$owner = this.store.select(orderSelector.getOwner);
    this.$newUserType.subscribe(ut => (this.newUserType = ut));
    this.store.select(orderSelector.getUserType).subscribe(ut => (this.userType = ut));
    this.$reason = this.store.select(orderSelector.getReason);

    this.$owner.subscribe(o => {
      this.owner = o;
    });

    this.venteFG = this._formBuilder.group({
      street: [{ value: this.venteInfo.street, disabled: false }],
      num: [{ value: this.venteInfo.num, disabled: false }],
      city: [{ value: this.venteInfo.city, disabled: false }],
      zip: [{ value: this.venteInfo.zip, disabled: false }]
    });

    this.cadastreFG = this._formBuilder.group({
      division: [{ value: this.venteInfo.division, disabled: false }],
      section: [{ value: this.venteInfo.section, disabled: false }],
      numCadastre: [{ value: this.venteInfo.numCadastre, disabled: false }],
      rcni: [{ value: this.venteInfo.rcni, disabled: false }]
    });

    this.$venteInfoSub = this.venteFG.valueChanges.pipe(debounceTime(1000)).subscribe(values => {
      this.venteInfo.street = values.street;
      this.venteInfo.num = values.num;
      this.venteInfo.city = values.city;
      this.venteInfo.zip = values.zip;
      /* this.venteInfo.statusVentePub = values.venteStatut;
      this.venteInfo.venteDate = values.venteDate; */

      this.store.dispatch(new SetVenteInfo(this.venteInfo));
    });

    this.$cadastreSub = this.cadastreFG.valueChanges.pipe(debounceTime(1000)).subscribe(values => {
      this.venteInfo.division = values.division;
      this.venteInfo.section = values.section;
      this.venteInfo.numCadastre = values.numCadastre;
      this.venteInfo.rcni = values.rcni;

      this.store.dispatch(new SetVenteInfo(this.venteInfo));
    });

    this.$contact.subscribe(c => (this.contact = c));
    this.store.select(orderSelector.getAddress).subscribe(a => (this.address = a));

    combineLatest(this.$isLoggedIn, this.$newUserType, this.$contactChoice, (bool, newUserType, contactChoice) => ({
      bool,
      newUserType,
      contactChoice
    })).subscribe(({ bool, newUserType, contactChoice }) => {
      const isConnectedPart = bool && this.userType === UserType.Particulier;
      const isNewUserPart = !bool && newUserType === UserType.Particulier;
      const isParticulier = isNewUserPart || isConnectedPart;
      const notLogAndNotPart = !bool && !isParticulier;
      // console.log('connected?', bool);
      // console.log('isParticulier', isParticulier);
      if (!bool && isParticulier) {
        this.step2FormGroup = this._formBuilder.group({
          user: this._formBuilder.group({
            firstName: [
              {
                value: this.userForm.firstname,
                disabled: false
              },
              Validators.required
            ],
            lastName: [
              {
                value: this.userForm.lastname,
                disabled: false
              },
              Validators.required
            ],
            phone: [
              {
                value: this.userForm.phone,
                disabled: false
              },
              Validators.required
            ],
            email: [
              {
                value: this.userForm.email,
                disabled: false
              },
              Validators.required,
              this.isEmailUnique.bind(this)
            ],
            companyname: [
              {
                value: this.userForm.companyname,
                disabled: false
              }
            ],
            usertva: [
              {
                value: this.userForm.tva,
                disabled: false
              }
            ]
          }),
          address: this._formBuilder.group({
            orderStreet: [this.address.street, Validators.required],
            orderNum: [this.address.number, Validators.required],
            orderZip: [this.address.zip, Validators.required],
            orderCity: [this.address.city, Validators.required]
          }),
          contact: this._formBuilder.group({
            firstName: [
              {
                value: this.contact.firstname,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ],
            lastName: [
              {
                value: this.contact.lastname,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ],
            phone: [
              {
                value: this.contact.phone,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ]
          }),
          date: this._formBuilder.group({
            dateButoir: ['']
          })
        });

        this.$userSub = this.step2FormGroup
          .get('user')
          .valueChanges.pipe(debounceTime(500))
          .subscribe(values => {
            const user = new UserForm();
            user.firstname = values.firstName;
            user.lastname = values.lastName;
            user.phone = values.phone;
            user.email = values.email;
            user.companyname = values.companyname;
            user.tva = values.usertva;
            this.store.dispatch(new SetUserForm(user));
          });
      } else if (notLogAndNotPart) {
        this.step2FormGroup = this._formBuilder.group({
          user: this._formBuilder.group({
            firstName: [
              {
                value: this.userForm.firstname,
                disabled: bool
              },
              Validators.required
            ],
            lastName: [
              {
                value: this.userForm.lastname,
                disabled: bool
              },
              Validators.required
            ],
            phone: [
              {
                value: this.userForm.phone,
                disabled: bool
              },
              Validators.required
            ],
            email: [
              {
                value: this.userForm.email,
                disabled: bool
              },
              Validators.required,
              this.isEmailUnique.bind(this)
            ],
            tva: [
              {
                value: this.userForm.tva,
                disabled: !notLogAndNotPart
              }
            ],
            userStreet: [
              {
                value: this.userForm.address.street,
                disabled: !notLogAndNotPart
              },
              Validators.required
            ],
            userNum: [
              {
                value: this.userForm.address.number,
                disabled: !notLogAndNotPart
              },
              Validators.required
            ],
            userZip: [
              {
                value: this.userForm.address.zip,
                disabled: !notLogAndNotPart
              },
              Validators.required
            ],
            userCity: [
              {
                value: this.userForm.address.city,
                disabled: !notLogAndNotPart
              },
              Validators.required
            ]
          }),
          owner: this._formBuilder.group({
            firstName: [
              {
                value: this.owner.firstname,
                disabled: isParticulier
              },
              Validators.required
            ],
            lastName: [
              {
                value: this.owner.lastname,
                disabled: isParticulier
              },
              Validators.required
            ],
            phone: [
              {
                value: this.owner.phone,
                disabled: isParticulier
              },
              Validators.required
            ],
            email: [
              {
                value: this.owner.email,
                disabled: isParticulier
              },
              Validators.email
            ]
          }),
          address: this._formBuilder.group({
            orderStreet: [this.address.street, Validators.required],
            orderNum: [this.address.number, Validators.required],
            orderZip: [this.address.zip, Validators.required],
            orderCity: [this.address.city, Validators.required]
          }),
          contact: this._formBuilder.group({
            firstName: [
              {
                value: this.contact.firstname,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ],
            lastName: [
              {
                value: this.contact.lastname,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ],
            phone: [
              {
                value: this.contact.phone,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ]
          }),
          date: this._formBuilder.group({
            dateButoir: ['']
          })
        });

        this.$ownerSub = this.step2FormGroup
          .get('owner')
          .valueChanges.pipe(debounceTime(1000))
          .subscribe(values => {
            const owner = new Owner();
            owner.firstname = values.firstName;
            owner.lastname = values.lastName;
            owner.phone = values.phone;
            owner.email = values.email;
            this.store.dispatch(new SetOwner(owner));
          });

        this.$userSub = this.step2FormGroup
          .get('user')
          .valueChanges.pipe(debounceTime(1000))
          .subscribe(values => {
            const user = new UserForm();
            user.firstname = values.firstName;
            user.lastname = values.lastName;
            user.phone = values.phone;
            user.email = values.email;
            user.tva = values.tva;
            user.address.street = values.userStreet;
            user.address.number = values.userNum;
            user.address.zip = values.userZip;
            user.address.city = values.userCity;
            this.store.dispatch(new SetUserForm(user));
          });
      } else if (bool && !isParticulier) {
        // console.log('this connected');
        this.step2FormGroup = this._formBuilder.group({
          owner: this._formBuilder.group({
            firstName: [
              {
                value: this.owner.firstname,
                disabled: isParticulier
              },
              Validators.required
            ],
            lastName: [
              {
                value: this.owner.lastname,
                disabled: isParticulier
              },
              Validators.required
            ],
            phone: [
              {
                value: this.owner.phone,
                disabled: isParticulier
              },
              Validators.required
            ],
            email: [
              {
                value: this.owner.email,
                disabled: isParticulier
              },
              Validators.email
            ]
          }),
          address: this._formBuilder.group({
            orderStreet: [this.address.street, Validators.required],
            orderNum: [this.address.number, Validators.required],
            orderZip: [this.address.zip, Validators.required],
            orderCity: [this.address.city, Validators.required]
          }),
          contact: this._formBuilder.group({
            firstName: [
              {
                value: this.contact.firstname,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ],
            lastName: [
              {
                value: this.contact.lastname,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ],
            phone: [
              {
                value: this.contact.phone,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ]
          }),
          date: this._formBuilder.group({
            dateButoir: ['']
          })
        });

        this.$ownerSub = this.step2FormGroup
          .get('owner')
          .valueChanges.pipe(debounceTime(1000))
          .subscribe(values => {
            const owner = new Owner();
            owner.firstname = values.firstName;
            owner.lastname = values.lastName;
            owner.phone = values.phone;
            owner.email = values.email;
            this.store.dispatch(new SetOwner(owner));
          });
      } else if (bool && isParticulier) {
        this.step2FormGroup = this._formBuilder.group({
          address: this._formBuilder.group({
            orderStreet: [this.address.street, Validators.required],
            orderNum: [this.address.number, Validators.required],
            orderZip: [this.address.zip, Validators.required],
            orderCity: [this.address.city, Validators.required]
          }),
          contact: this._formBuilder.group({
            firstName: [
              {
                value: this.contact.firstname,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ],
            lastName: [
              {
                value: this.contact.lastname,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ],
            phone: [
              {
                value: this.contact.phone,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ]
          }),
          date: this._formBuilder.group({
            dateButoir: ['']
          })
        });
      }
      // console.log(this.step2FormGroup);
      if (this.contactChoice === 'sameAsOwner') {
        this.$owner.subscribe(o => {
          const c = new Contact();
          c.firstname = o.firstname;
          c.lastname = o.lastname;
          c.phone = o.phone;
          this.store.dispatch(new SetContact(c as Contact));
        });
      }

      this.step2FormGroup
        .get('address')
        .valueChanges.pipe(debounceTime(1000))
        .subscribe(values => {
          // console.log('changes to address');
          const address = new Address();
          address.street = values.orderStreet;
          address.number = values.orderNum;
          address.zip = values.orderZip;
          address.city = values.orderCity;
          this.store.dispatch(new SetAddress(address));
        });

      this.step2FormGroup
        .get('contact')
        .valueChanges.pipe(debounceTime(1000))
        .subscribe(values => {
          // console.log('changes to contact');
          const contact = new Contact();
          contact.firstname = values.firstName;
          contact.lastname = values.lastName;
          contact.phone = values.phone;
          this.store.dispatch(new SetContact(contact));

          const date = values.dateButoir as Date;
          if (date) {
            date.setHours(date.getHours() + 1);
            this.store.dispatch(new SetDateButoir(date));
          }
        });

      this.step2FormGroup
        .get('date')
        .valueChanges.pipe(debounceTime(1000))
        .subscribe(values => {
          // console.log('changes to date');
          const date = values.dateButoir as Date;
          if (date) {
            date.setHours(date.getHours() + 1);
            this.store.dispatch(new SetDateButoir(date));
          }
        });
      this.step2FormGroup.statusChanges.subscribe(value => {
        this.store.dispatch(new SetStep2Valid(value === 'VALID'));
      });
    });

    this.minDate.setDate(this.minDate.getDate() + 1);
  }

  ngOnInit() {
    this.$isLoggedIn.subscribe(bool => {
      // console.log('connected?', bool);
      if (!bool) {
      }
    });

    this.$goGetKeys = this.store.select(orderSelector.getGoGeyKeys);
    this.newUserTypes = newUserTypes.filter(ut => this.filterNewUserTypes.every(fu => fu !== ut.id));
    this.$newUserType = this.store.select(orderSelector.getUserType);
    this.store.select(uiSelector.getStep2IsValid).subscribe(bool => (this.isValid = bool));
    this.$dateButoir = this.store.select(orderSelector.getDateButoir);
    this.$dateButoir.subscribe(d => (this.dateButoire = d));

    this.step2FormGroup.updateValueAndValidity();
  }

  newUserTypeImgUrl(selectedId, fileName) {
    let url = this.assetsFolder;
    url += fileName;
    url += this.newUserType === selectedId ? '-selected.svg' : '.svg';
    return url;
  }

  setNewUserType(id: UserType) {
    if ((id === UserType.Particulier || id === UserType.Installateur) && this.contactChoice === 'keyAtAgency') {
      this.setContactChoice('sameAsOwner');
    }
    this.store.dispatch(new SetNewUserType(id));
  }

  filterWeekend(d: Date): boolean {
    const day = d.getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  logOut() {
    const logged = this.authService.logout();
    if (!logged) {
      this.stepper.reset();
    }
  }

  copyContact() {
    // e.preventDefault();
    if (this.owner.firstname || this.owner.lastname || this.owner.phone) {
      // console.log('owner');
      this.step2FormGroup.get('contact').patchValue({
        firstName: this.owner.firstname,
        lastName: this.owner.lastname,
        phone: this.owner.phone
      });
    } else {
      // console.log(this.userForm.firstname);
      this.step2FormGroup.get('contact').patchValue({
        firstName: this.userForm.firstname,
        lastName: this.userForm.lastname,
        phone: this.userForm.phone
      });
    }
  }

  setContactChoice(id: string) {
    this.store.dispatch(new SetContactChoice(id));
    if (id === 'sameAsOwner') {
      this.copyContact();
      this.store.dispatch(new SetGoGetKeys(false));
    } else if (id === 'other') {
      this.store.dispatch(new SetContact(new Contact()));
      this.store.dispatch(new SetGoGetKeys(false));
      this.step2FormGroup.get('contact').patchValue({
        firstName: this.contact.firstname,
        lastName: this.contact.lastname,
        phone: this.contact.phone
      });
    } else if (id === 'keyAtAgency') {
      this.copyContact();
      this.store.dispatch(new SetGoGetKeys(true));
    }
    this.step2FormGroup.get('date').patchValue({
      dateButoir: this.dateButoire
    });
  }

  scrollTop() {
    setTimeout(() => this.smooth.smoothScrollToTop(), 500);
  }

  toStep3() {
    if (this.isValid) {
      this.store.dispatch(new SetInvoiceTo('toProprio'));
      this.scrollTop();
    }
  }

  onAutocompleteSelected(pResult: PlaceResult, type: string) {
    const address = new Address();

    const addrObj: any = this.getFormattedAddress(pResult);

    address.number = addrObj.num;
    address.street = addrObj.street;
    address.zip = addrObj.zip;
    address.city = addrObj.city;
    address.country = addrObj.country;
    address.lat = addrObj.lat;
    address.lng = addrObj.lng;

    if (type === 'owner') {
      this.step2FormGroup.get('address').patchValue({
        orderStreet: address.street,
        orderNum: address.number,
        orderZip: address.zip,
        orderCity: address.city
      });
      this.store.dispatch(new SetAddress(address));
    } else if (type === 'user') {
      const user = { ...this.userForm };
      user.address = address;
      this.step2FormGroup.get('user').patchValue({
        userStreet: address.street,
        userNum: address.number,
        userZip: address.zip,
        userCity: address.city
      });

      this.store.dispatch(new SetUserForm(user));
    }
    this.step2FormGroup.markAsDirty();
    this.step2FormGroup.updateValueAndValidity();
    this.cdRef.detectChanges();
  }

  getFormattedAddress(place) {
    const location_obj = {};
    for (const i in place.address_components) {
      if (place.address_components.hasOwnProperty(i)) {
        const item = place.address_components[i];

        location_obj['formatted_address'] = place.formatted_address;
        if (item['types'].indexOf('locality') > -1) {
          location_obj['city'] = item['long_name'];
        } else if (item['types'].indexOf('administrative_area_level_1') > -1) {
          location_obj['admin_area_l1'] = item['short_name'];
        } else if (item['types'].indexOf('street_number') > -1) {
          location_obj['num'] = item['short_name'];
        } else if (item['types'].indexOf('route') > -1) {
          location_obj['street'] = item['long_name'];
        } else if (item['types'].indexOf('country') > -1) {
          location_obj['country'] = item['long_name'];
        } else if (item['types'].indexOf('postal_code') > -1) {
          location_obj['zip'] = item['short_name'];
        }
      }
    }
    if (place && place.geometry && place.geometry.location.lat) {
      location_obj['lat'] = place.geometry.location.lat();
    }
    if (place && place.geometry && place.geometry.location.lng) {
      location_obj['lng'] = place.geometry.location.lng();
    }
    return location_obj;
  }

  isEmailUnique(control: FormControl) {
    clearTimeout(this.debouncer);

    return new Promise((resolve, reject) => {
      this.debouncer = setTimeout(() => {
        this.authService.isEmailAlreadyInUse(control.value).subscribe(data => {
          if (data.Data) {
            console.log('Email exists', data.Data);
            resolve({ isEmailAlreadyInUse: true });
          } else {
            console.log(`🚀`);
            resolve(null);
          }
        });
      }, 1000);
    });
  }

  toggleDisplayTVA(checkbox: MatCheckbox) {
    console.log(checkbox);
  }

  selectStatusVentePub(item) {
    this.venteInfo.statusVentePub = item;
    this.store.dispatch(new SetVenteInfo(this.venteInfo));
  }
}
