import { Address, AddressDB } from './address';

export class User {
  userid: string;
  firstname: string;
  lastname: string;
  email: string;
  companyname: string;
  phone: string;
  address: Address;
  sendByMail: boolean;
  userType: UserType;
  hasCommission: boolean;
  pricingPlan: number;
  roles: Role[];
  hasImmoAccess: boolean;
  tva = '';
  status?: number;
  customPrices: CustomPrice[];

  constructor() {
    this.address = new Address();
  }
}

export class CustomPrice {
  constructor(public price, public commission, public region, public estateType, public productTypeId) {}
}

export enum UserType {
  Particulier = 1688,
  Agence = 1689,
  Certificateur = 1690,
  Notaire = 8122,
  Installateur = 1691,
  Special = 1692
}

export enum ReasonType {
  Vente = 0,
  Location = 1,
  Renovation = 2,
  Controle = 3
}

export enum UserRole {
  ImmoAccess = 'c8909f2b-1e5d-4758-b920-a2b4f6114c90'
}

export class UserDB {
  UserID: string;
  FirstName: string;
  Name: string;
  Email: string;
  PhoneNumber: string;
  Address: AddressDB;
  Consumer: ConsumerDB;
  Roles: Role[];

  constructor() {
    this.Address = new AddressDB();
  }
}

export class Role {
  constructor(public RoleID: UserRole, public Name: string) {}
}

export class ConsumerDB {
  ConsumerType: number;
  Commission: boolean;
  Status: number;
  ReceiveByEmail: boolean;
  ConsumerPrices: ConsumerPriceDB[];

  constructor() {
    this.ConsumerType = 1688;
    this.Commission = false;
    this.Status = 0;
    this.ReceiveByEmail = true;
  }
}

class ConsumerPriceDB {
  constructor(
    public ConsumerAmount,
    public ConsumerCommission,
    public ConsumerRegion,
    public EstateType,
    public ProductTypeId
  ) {}
}

export class Contact {
  firstname: string;
  lastname: string;
  phone: string;

  constructor() {
    this.firstname = '';
    this.lastname = '';
    this.phone = '';
  }
}

export class Owner {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;

  constructor() {
    this.firstname = '';
    this.lastname = '';
    this.phone = '';
    this.email = '';
  }
}

export class UserForm extends Owner {
  tva?: string;
  address?: Address;
  companyname: string;
  constructor() {
    super();
    this.address = new Address();
  }
}
