import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Visit } from 'src/app/core/models/visit';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { AppState } from 'src/app/interfaces';
import { Store } from '@ngrx/store';
import { SetVisits, SetVisitContact } from 'src/app/core/store/order';
import { getVisits, getVisitContact } from 'src/app/core/store/order/order.selectors';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-visit-request-dialog',
  templateUrl: './visit-request-dialog.component.html',
  styleUrls: ['./visit-request-dialog.component.scss']
})
export class VisitRequestDialogComponent implements OnInit {
  visitEtudeExtended = false;
  visitFix = false;
  visitFree = false;
  visitAdValoris = false;
  showAddVisit = false;
  editing = false;

  currentVisit;
  selectedChoice = 0;
  reason = -1;

  visitList: Visit[] = [];

  options = [
    { hour: 0 },
    { hour: 9 },
    { hour: 10 },
    { hour: 11 },
    { hour: 12 },
    { hour: 13 },
    { hour: 14 },
    { hour: 15 },
    { hour: 16 },
    { hour: 17 }
  ];

  defaultVisit = {
    hour: this.options[0].hour,
    allDay: false,
    date: null
  };

  visitFreeFG: FormGroup;
  tomorrow = new Date();

  visitFreeInfo = {
    lastName: '',
    firstName: '',
    email: '',
    tel: ''
  };

  constructor(
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: { dialog: MatDialog; reason: number },
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.currentVisit = this.defaultVisit;
    this.store.select(getVisits).subscribe(v => (this.visitList = v));
    this.store.select(getVisitContact).subscribe(v => (this.visitFreeInfo = v));

    this.visitFreeFG = this._formBuilder.group({
      lastName: [{ value: this.visitFreeInfo.lastName, disabled: false }],
      firstName: [{ value: this.visitFreeInfo.firstName, disabled: false }],
      email: [{ value: this.visitFreeInfo.email, disabled: false }],
      tel: [{ value: this.visitFreeInfo.tel, disabled: false }]
    });

    this.visitFreeFG.valueChanges.pipe(debounceTime(1000)).subscribe(values => {
      this.visitFreeInfo.lastName = values.lastName;
      this.visitFreeInfo.firstName = values.firstName;
      this.visitFreeInfo.email = values.email;
      this.visitFreeInfo.tel = values.tel;
    });

    this.reason = this.data.reason;
  }

  setHour(index) {
    this.currentVisit.hour = this.options[index].hour;
  }

  displayAddVisit() {
    this.showAddVisit = true;
    this.currentVisit = { ...this.defaultVisit };
  }

  addVisit() {
    if (this.currentVisit.date) {
      this.visitList.push(this.currentVisit);
      this.showAddVisit = false;
    }
  }

  editVisit(index) {
    this.editing = true;
    this.showAddVisit = true;
    this.currentVisit = this.visitList[index];
    this.selectedChoice = this.options.indexOf(this.options.find(p => p.hour === this.currentVisit.hour));
  }

  saveEditVisit() {
    this.editing = false;
    this.showAddVisit = false;
  }

  deleteVisit(index) {
    this.visitList.splice(index, 1);
  }

  saveVisits() {
    this.store.dispatch(new SetVisits(this.visitList));
    this.close();
  }

  saveVisitFree() {
    if (
      this.visitFreeInfo.email !== '' &&
      this.visitFreeInfo.firstName !== '' &&
      this.visitFreeInfo.lastName !== '' &&
      this.visitFreeInfo.tel !== ''
    ) {
      this.visitFree = false;
      this.store.dispatch(new SetVisitContact(this.visitFreeInfo));
      this.close();
    }
  }

  close() {
    this.data.dialog.closeAll();
  }
}
