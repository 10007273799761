var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import * as orderSelector from '../core/store/order/order.selectors';
import * as uiSelector from '../core/store/ui/ui.selectors';
import { SetInvoiceTo, SetDocument } from '../core/store/order';
import { SimpleSmoothScrollService } from 'ng2-simple-smooth-scroll';
import { UserType } from '../core/models/user';
import { Address } from '../core/models/address';
import { FormBuilder, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { SetStep3Valid } from '../core/store/ui';
var Step3Component = /** @class */ (function () {
    function Step3Component(store, _formBuilder, smooth, cdRef) {
        var _this = this;
        this.store = store;
        this._formBuilder = _formBuilder;
        this.smooth = smooth;
        this.cdRef = cdRef;
        this.UserTypeEnum = UserType;
        this.store.select(orderSelector.getDocument).subscribe(function (d) { return (_this.document = d); });
        this.$isLoggedIn = this.store.select(orderSelector.getIsLoggedIn);
        this.otherDocFormGroup = this._formBuilder.group({
            firstName: [{ value: this.document.firstName, disabled: false }, Validators.required],
            lastName: [{ value: this.document.lastName, disabled: false }, Validators.required],
            street: [{ value: this.document.address.street, disabled: false }, Validators.required],
            number: [{ value: this.document.address.number, disabled: false }, Validators.required],
            zip: [{ value: this.document.address.zip, disabled: false }, Validators.required],
            city: [{ value: this.document.address.city, disabled: false }, Validators.required],
            tva: [{ value: this.document.tva, disabled: false }]
        });
    }
    Step3Component.prototype.ngOnInit = function () {
        var _this = this;
        this.$invoiceTo = this.store.select(orderSelector.getInvoiceTo);
        this.$isStep3Valid = this.store.select(uiSelector.getStep3IsValid);
        this.$invoiceTo.subscribe(function (i) {
            _this.invoiceTo = i;
            if (_this.invoiceTo !== 'toOther' && _this.invoiceTo !== '') {
                _this.store.dispatch(new SetStep3Valid(true));
            }
            var formGP = _this.otherDocFormGroup;
            formGP.statusChanges.pipe(debounceTime(500)).subscribe(function (statu) {
                if (_this.invoiceTo === 'toOther') {
                    var statusValid = statu === 'VALID';
                    var oldStatus_1;
                    _this.store.select(uiSelector.getStep3IsValid).subscribe(function (st) { return (oldStatus_1 = st); });
                    if (oldStatus_1 !== statusValid) {
                        _this.store.dispatch(new SetStep3Valid(statusValid));
                    }
                }
            });
        });
        this.store.select(orderSelector.getUserInfo).subscribe(function (u) { return (_this.user = u); });
        this.store.select(orderSelector.getUserForm).subscribe(function (u) { return (_this.userForm = u); });
        this.store.select(orderSelector.getOwner).subscribe(function (o) { return (_this.owner = o); });
        this.store.select(orderSelector.getAddress).subscribe(function (a) { return (_this.address = a); });
        this.store.select(orderSelector.getUserType).subscribe(function (t) { return (_this.userType = t); });
        this.otherDocFormGroup.valueChanges.pipe(debounceTime(500)).subscribe(function (values) {
            var doc = _this.document;
            doc.firstName = values.firstName;
            doc.lastName = values.lastName;
            doc.address.street = values.street;
            doc.address.number = values.number;
            doc.address.zip = values.zip;
            doc.address.city = values.city;
            doc.tva = values.tva;
            _this.store.dispatch(new SetDocument(doc));
        });
    };
    Step3Component.prototype.ngAfterViewInit = function () {
        this.otherDocFormGroup.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    };
    Step3Component.prototype.SelectInvoice = function (type) {
        if (type !== this.invoiceTo) {
            this.store.dispatch(new SetInvoiceTo(type));
            if (type === 'toOther') {
                // this.store.dispatch(new SetDocument(new Document()));
                this.otherDocFormGroup.updateValueAndValidity();
            }
        }
    };
    Step3Component.prototype.onAutocompleteSelected = function (pResult) {
        var address = new Address();
        var addrObj = this.getFormattedAddress(pResult);
        address.number = addrObj.num;
        address.street = addrObj.street;
        address.zip = addrObj.zip;
        address.city = addrObj.city;
        address.country = addrObj.country;
        address.lat = addrObj.lat;
        address.lng = addrObj.lng;
        this.otherDocFormGroup.patchValue({
            street: address.street,
            number: address.number,
            zip: address.zip,
            city: address.city
        });
        var doc = __assign({}, this.document);
        doc.address = address;
        this.store.dispatch(new SetDocument(doc));
        this.otherDocFormGroup.markAsDirty();
        this.otherDocFormGroup.updateValueAndValidity();
        this.cdRef.detectChanges();
    };
    Step3Component.prototype.getFormattedAddress = function (place) {
        var location_obj = {};
        for (var i in place.address_components) {
            if (place.address_components.hasOwnProperty(i)) {
                var item = place.address_components[i];
                location_obj['formatted_address'] = place.formatted_address;
                if (item['types'].indexOf('locality') > -1) {
                    location_obj['city'] = item['long_name'];
                }
                else if (item['types'].indexOf('administrative_area_level_1') > -1) {
                    location_obj['admin_area_l1'] = item['short_name'];
                }
                else if (item['types'].indexOf('street_number') > -1) {
                    location_obj['num'] = item['short_name'];
                }
                else if (item['types'].indexOf('route') > -1) {
                    location_obj['street'] = item['long_name'];
                }
                else if (item['types'].indexOf('country') > -1) {
                    location_obj['country'] = item['long_name'];
                }
                else if (item['types'].indexOf('postal_code') > -1) {
                    location_obj['zip'] = item['short_name'];
                }
            }
        }
        if (place && place.geometry && place.geometry.location.lat) {
            location_obj['lat'] = place.geometry.location.lat();
        }
        if (place && place.geometry && place.geometry.location.lng) {
            location_obj['lng'] = place.geometry.location.lng();
        }
        return location_obj;
    };
    Step3Component.prototype.scrollTop = function () {
        var _this = this;
        setTimeout(function () { return _this.smooth.smoothScrollToTop(); }, 500);
    };
    return Step3Component;
}());
export { Step3Component };
