import { NgModule } from '@angular/core';

import {
  MatButtonModule,
  MatCheckboxModule,
  MatCardModule,
  MatStepperModule,
  MatFormFieldModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MAT_DATE_LOCALE,
  MatRadioModule,
  MatSelectModule,
  MatDialogModule,
  MatExpansionModule,
  MatSliderModule,
  MatButtonToggleModule,
  MatTooltipModule,
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS
} from '@angular/material';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCalculator,
  faHome,
  faShippingFast,
  faClipboardCheck,
  faUser,
  faPhone,
  faAngleDown,
  faExternalLinkAlt,
  faPowerOff,
  faUserPlus,
  faInfoCircle,
  faQuestionCircle,
  faBriefcase,
  faCheckCircle,
  faThumbsUp,
  faExclamationCircle,
  faExclamationTriangle,
  faCheck,
  faFileInvoice,
  faCalendarAlt,
  faTimes,
  faCalendarCheck,
  faAngleLeft,
  faAngleRight,
  faArrowRight,
  faNewspaper,
  faTrash,
  faPlus,
  faPen,
  faStreetView
} from '@fortawesome/free-solid-svg-icons';

import { MatMenuModule } from '@angular/material/menu';

import { HtvaPipe } from './pipes/htva.pipe';
import { SpecificRequestDialogComponent } from '../dialogs/specific-request-dialog/specific-request-dialog.component';
import { SummaryConfirmDialogComponent } from '../dialogs/summary-confirm-dialog/summary-confirm-dialog.component';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { RouterModule } from '@angular/router';
import { SpinnerOverlayComponent } from '../core/spinner-overlay/spinner-overlay.component';
import { SortbyPipe } from './pipes/sortby.pipe';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    RouterModule,
    FontAwesomeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatTooltipModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    CommonModule,
    LayoutModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatDialogModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatSnackBarModule,
    HtvaPipe,
    SortbyPipe,
    HeaderComponent,
    FooterComponent,
    SpinnerOverlayComponent,
    TranslateModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 8000 } }
  ],
  declarations: [SpinnerOverlayComponent, HeaderComponent, FooterComponent, HtvaPipe, SortbyPipe]
})
export class SharedModule {
  constructor() {
    library.add(
      faCalculator,
      faHome,
      faShippingFast,
      faClipboardCheck,
      faUser,
      faHome,
      faPhone,
      faAngleDown,
      faExternalLinkAlt,
      faPowerOff,
      faUserPlus,
      faInfoCircle,
      faQuestionCircle,
      faBriefcase,
      faCheckCircle,
      faThumbsUp,
      faExclamationCircle,
      faExclamationTriangle,
      faCheck,
      faFileInvoice,
      faCalendarAlt,
      faTimes,
      faCalendarCheck,
      faAngleLeft,
      faAngleRight,
      faArrowRight,
      faNewspaper,
      faTrash,
      faPlus,
      faPen,
      faStreetView
    );
  }
}
