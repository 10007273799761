import { ActionTypes, Actions } from './order.actions';

import * as estates from '../../data/estates';
import { Product } from '../../models/product';

import * as PRODUCTS from '../../data/products';
import { Reduction } from '../../models/reduction';
import { Document } from '../../models/document';
import { Contact, Owner, UserForm, UserType, User, Role, UserRole } from '../../models/user';
import { Address } from '../../models/address';
import { Region } from '../../models/region';

import { defaultRegion, projectID } from '../../data/theme-config';
import { Print } from '../../models/print';
import { Visit } from '../../models/visit';
import { VisitContact } from '../../models/visitContact';

export interface IOrder {
  id: string;
  regionType: number;
  region: Region;
  estateType: number;
  estateStyle: number;
  estateName: string;
  nbOfAppartments: number;
  services: number[];
  immoServices: number[];
  products: Product[];
  hasPeb: boolean;
  hasPebPartiel: boolean;
  hasElec: boolean;
  hasCiterne: boolean;
  hasPlan: boolean;
  hasGaz: boolean;
  pebNb: string;
  nbOfElec: number;
  nbOfGaz: number;
  gazChoice: number;
  paymentType: string;
  acteType: string;
  sendByMail: boolean;
  remarks: string;
  invoiceTo: string;
  immoPack: number;
  reductions: Reduction[];
  reduction: number;
  reductionWithoutComm: number;
  price: number;
  onlinePayment: boolean;
  promoCode: string;
  urgence: boolean;
  address: Address;
  owner: Owner;
  contact: Contact;
  dateButoir: Date;
  document: Document;
  goGetKeys: boolean;
  commission: number;
  contactChoice: string;
  nn: string;
  nbOfIso: number;
  promoCodeID: string;
  packID: string;
  reason: number;
  clientType: number;
  venteType: number;
  venteInfo: {
    street: string;
    num: string;
    city: string;
    zip: string;
    division: string;
    section: string;
    numCadastre: string;
    rcni: string;
    venteStatut: {
      id: number;
      name: string;
    };
    venteDate: Date;
  };
  parutions: Print[];
  visits: Visit[];
  visitContact: VisitContact;

  newUser: UserForm;
  user: User;
}

const initialOrderState: IOrder = {
  id: '',
  regionType: defaultRegion,
  region: new Region(),
  estateType: -1,
  estateStyle: -1,
  estateName: '',
  nbOfAppartments: -1,
  services: [],
  immoServices: [],
  products: [],
  hasPeb: false,
  hasPebPartiel: false,
  hasElec: false,
  hasCiterne: false,
  hasPlan: false,
  hasGaz: false,
  pebNb: '',
  nbOfElec: 0,
  nbOfGaz: 0,
  gazChoice: 0,
  paymentType: 'classic',
  acteType: 'ILLPAY',
  sendByMail: false,
  remarks: '',
  invoiceTo: '',
  immoPack: -1,
  reductions: [],
  reduction: 0,
  reductionWithoutComm: 0,
  price: 0,
  onlinePayment: false,
  promoCode: '',
  urgence: false,
  address: new Address(),
  owner: new Owner(),
  contact: new Contact(),
  dateButoir: null,
  document: new Document(),
  goGetKeys: false,
  commission: 0,
  contactChoice: 'sameAsOwner',
  nn: '',
  nbOfIso: 0,
  promoCodeID: '',
  packID: '',
  reason: -1,
  clientType: -1,
  venteType: -1,
  venteInfo: {
    street: '',
    num: '',
    city: '',
    zip: '',
    division: '',
    section: '',
    numCadastre: '',
    rcni: '',
    venteStatut: {
      id: 1,
      name: 'Première séance'
    },
    venteDate: null
  },
  parutions: [],
  visits: [],
  visitContact: new VisitContact(),

  newUser: new UserForm(),
  user: {
    userid: '',
    firstname: '',
    lastname: '',
    email: '',
    companyname: '',
    phone: '',
    address: new Address(),
    sendByMail: false,
    hasImmoAccess: false,
    userType: UserType.Particulier,
    hasCommission: false,
    pricingPlan: 0,
    roles: [],
    tva: '',
    customPrices: []
  }
};

/*
  regionType: 0,
  estateType: 3,
  estateStyle: 0,
  estateName: 'MAISON_1',
*/

/* const initialOrderState: IOrder = {
  id: '',
  regionType: -1,
  estateType: -1,
  estateStyle: -1,
  estateName: '',
  nbOfAppartments: -1,
  services: [],
  products: [],
  hasPeb: false,
  hasElec: false,
  hasCiterne: false,
  hasPlan: false,
  hasGaz: false,
  nbOfElec: 0,
  paymentType: 'classic',
  acteType: 'ILLPAY',
  sendByMail: false,
  remarks: '',
  invoiceTo: 'toPro'
}; */

export function reducer(state: IOrder = initialOrderState, action: Actions): IOrder {
  switch (action.type) {
    case ActionTypes.SET_REGION:
      return { ...state, regionType: action.payload as number };

    case ActionTypes.SET_REGIONINFO:
      return { ...state, region: action.payload as Region };

    case ActionTypes.SET_ESTATETYPE:
      return {
        ...state,
        estateType: action.payload as number,
        estateName: getEstateName(action.payload as number),
        nbOfAppartments: action.payload === estates.IMMEUBLE_APPART ? 1 : -1,
        estateStyle: getEstateStyle(action.payload)
      };

    case ActionTypes.SET_ESTATESTYLE:
      return {
        ...state,
        estateStyle: action.payload as number,
        estateType: -1,
        nbOfAppartments: -1,
        reason: projectID === 'mdn' ? -1 : state.reason
      };

    case ActionTypes.SET_NUMBEROFAPPARTMENTS:
      return {
        ...state,
        nbOfAppartments: action.payload as number,
        estateType: estates.BUILDING,
        estateStyle: estates.BUILDING,
        estateName: 'IMMEUBLE_APPART'
      };

    case ActionTypes.SET_SERVICES:
      return {
        ...state,
        services: [...(action.payload as number[])]
      };

    case ActionTypes.SET_PRODUCTS:
      return {
        ...state,
        products: [...(action.payload as Product[])],
        hasPeb: (action.payload as Product[]).some(p => p.id === PRODUCTS.PEB),
        hasPebPartiel: (action.payload as Product[]).some(p => p.id === PRODUCTS.PEB_Partiel),
        hasElec: (action.payload as Product[]).some(p => p.id === PRODUCTS.ELEC),
        hasCiterne: (action.payload as Product[]).some(
          p => p.id === PRODUCTS.CITERNE_AERIENNE || p.id === PRODUCTS.CITERNE_ENTERREE
        ),
        hasPlan: (action.payload as Product[]).some(p => p.id === PRODUCTS.PLAN),
        hasGaz: (action.payload as Product[]).some(p => p.id === PRODUCTS.GAZ_CONTROLE),
        nbOfElec: (action.payload as Product[]).some(p => p.id === PRODUCTS.ELEC)
          ? state.nbOfElec > 0
            ? state.nbOfElec
            : 1
          : 0,
        nbOfGaz: (action.payload as Product[]).some(p => p.id === PRODUCTS.GAZ_CONTROLE)
          ? state.nbOfGaz > 0
            ? state.nbOfGaz
            : 1
          : 0,
        price: checkPriceWithoutReduction(action.payload as Product[])
      };

    case ActionTypes.SET_NBOFELEC:
      return {
        ...state,
        nbOfElec: action.payload as number
      };

    case ActionTypes.SET_NBOFGAZ:
      return {
        ...state,
        nbOfGaz: action.payload as number
      };

    case ActionTypes.SET_GAZCHOICE:
      return {
        ...state,
        gazChoice: action.payload as number
      };

    case ActionTypes.SET_PAYMENTTYPE:
      return {
        ...state,
        paymentType: action.payload as string,
        onlinePayment: action.payload === 'online' ? true : state.onlinePayment
      };

    case ActionTypes.SET_ACTETYPE:
      return {
        ...state,
        acteType: action.payload as string
      };

    case ActionTypes.TOGGLE_SENDBYMAIL:
      return {
        ...state,
        sendByMail: !state.sendByMail as boolean
      };

    case ActionTypes.SET_REMARKS:
      return {
        ...state,
        remarks: action.payload as string
      };

    case ActionTypes.SET_INVOICETO:
      return {
        ...state,
        invoiceTo: action.payload as string
      };

    case ActionTypes.SET_IMMOPACK:
      return {
        ...state,
        immoPack: action.payload as number
      };

    case ActionTypes.SET_REDUCTIONS:
      const redWithoutComm = checkReduction(action.payload);
      const maxComm = checkMaxCommission(checkReduction(action.payload) as number, state.commission) as number;
      const red = (redWithoutComm - maxComm) as number;
      return {
        ...state,
        reductions: action.payload as Reduction[],
        reduction: red,
        reductionWithoutComm: redWithoutComm,
        commission: maxComm
      };

    case ActionTypes.SET_COMMISSION:
      return {
        ...state,
        commission: action.payload as number,
        reduction: state.reductionWithoutComm - action.payload
      };

    case ActionTypes.TOGGLE_ONLINEPAYMENT:
      return {
        ...state,
        onlinePayment: !state.onlinePayment as boolean
      };

    case ActionTypes.SET_PROMOCODE:
      return {
        ...state,
        promoCode: action.payload as string
      };

    case ActionTypes.SET_PEBNB:
      return {
        ...state,
        pebNb: action.payload as string
      };

    case ActionTypes.TOGGLE_URGENCE:
      return {
        ...state,
        urgence: !state.urgence as boolean
      };

    case ActionTypes.SET_ADDRESS:
      return {
        ...state,
        address: { ...state.address, ...(action.payload as Address) }
      };

    case ActionTypes.SET_ZIPCODE:
      return {
        ...state,
        address: { ...state.address, zip: action.payload as string }
      };

    case ActionTypes.SET_OWNER:
      return {
        ...state,
        owner: action.payload as Owner
      };

    case ActionTypes.SET_USERFORM:
      return {
        ...state,
        newUser: action.payload as User,
        user: { ...state.user, ...(action.payload as User) }
      };

    case ActionTypes.SET_DATEBUTOIR:
      return {
        ...state,
        dateButoir: action.payload as Date
      };

    case ActionTypes.SET_DOCUMENT:
      return {
        ...state,
        document: action.payload as Document
      };

    case ActionTypes.RESET_ORDERSTATE:
      return {
        ...initialOrderState,
        services: [],
        reductions: [],
        products: [],
        immoServices: []
      };

    case ActionTypes.SET_NEWUSERTYPE:
      return {
        ...state,
        user: { ...state.user, userType: action.payload as UserType }
      };

    case ActionTypes.SET_CONTACTCHOICE:
      return {
        ...state,
        contactChoice: action.payload as string
      };

    case ActionTypes.SET_CONTACT:
      return {
        ...state,
        contact: action.payload as Contact
      };

    case ActionTypes.COPY_CONTACT:
      return {
        ...state,
        contact:
          state.owner.firstname || state.owner.lastname || state.owner.phone
            ? ({
                firstname: state.owner.firstname,
                lastname: state.owner.lastname,
                phone: state.owner.phone
              } as Contact)
            : ({
                firstname: state.user.firstname,
                lastname: state.user.lastname,
                phone: state.user.phone
              } as Contact)
      };

    case ActionTypes.SET_GOGETKEYS:
      return {
        ...state,
        goGetKeys: action.payload as boolean
      };

    case ActionTypes.SET_GUID:
      return { ...state, user: { ...state.user, userid: action.payload as string } };

    case ActionTypes.SET_USERINFO:
      return {
        ...state,
        user: {
          ...state.user,
          ...(action.payload as User),
          hasImmoAccess: checkHasImmoAccess((action.payload as User).roles)
        }
      };

    case ActionTypes.RESET_USERINFO:
      return {
        ...state,
        user: { ...initialOrderState.user }
      };

    case ActionTypes.SET_NN:
      return {
        ...state,
        nn: action.payload as string
      };

    case ActionTypes.SET_PROMOCODEID:
      return {
        ...state,
        promoCodeID: action.payload as string
      };

    case ActionTypes.SET_PACKID:
      return {
        ...state,
        packID: action.payload as string
      };

    case ActionTypes.SET_NBOFISO:
      return {
        ...state,
        nbOfIso: action.payload as number
      };

    case ActionTypes.SET_REASON:
      return {
        ...state,
        reason: action.payload as number
      };

    case ActionTypes.SET_CLIENTTYPE:
      return {
        ...state,
        clientType: action.payload as number
      };

    case ActionTypes.SET_VENTETYPE:
      return {
        ...state,
        venteType: action.payload as number
      };

    case ActionTypes.SET_VENTEINFO:
      return {
        ...state,
        venteInfo: action.payload as any
      };

    case ActionTypes.SET_PARUTIONS:
      return {
        ...state,
        parutions: action.payload as Print[]
      };

    case ActionTypes.SET_VISITS:
      return {
        ...state,
        visits: action.payload as Visit[]
      };

      case ActionTypes.SET_VISITCONTACT:
        return {
          ...state,
          visitContact: action.payload as VisitContact
        };
  
    default:
      return { ...state };
  }
}

/* function setServ(payload: number[]) {
  let services = [...payload];
  const indexCiterneE = services.indexOf(PRODUCTS.CITERNE_ENTERREE);
  const indexCiterneA = services.indexOf(PRODUCTS.CITERNE_AERIENNE);

  if (indexCiterneE)
} */

function checkHasImmoAccess(roles: Role[]): boolean {
  const hasImmoAccess = roles ? roles.some(r => r.RoleID === UserRole.ImmoAccess) : false;
  return hasImmoAccess;
}

function checkPriceWithoutReduction(products: Product[]) {
  let price = 0;

  products.forEach(product => {
    price += Number(product.price);
  });

  return price;
}

function checkReduction(reductions: Reduction[]) {
  let reduction = 0;
  if (reductions.length > 0) {
    reductions.forEach(reduc => {
      reduction += Number(reduc.price);
    });
  }
  return reduction;
}

function checkMaxCommission(reduc: number, comm: number) {
  let maxCom = 0;
  // console.log(`red: ${reduc} & comm ${comm}`);
  maxCom = comm > reduc ? reduc : comm;
  return maxCom;
}

function getEstateStyle(estateId): number {
  switch (estateId) {
    case estates.MAISON_1:
    case estates.MAISON_2:
    case estates.MAISON_3:
    case estates.MAISON_4:
    case estates.MAISON_5:
    case estates.VILLA:
      return estates.HOUSE;

    case estates.STUDIO:
    case estates.APPART_5075:
    case estates.APPART:
    case estates.DUPLEX:
    case estates.TRIPLEX:
      return estates.FLAT;

    case estates.IMMEUBLE_APPART:
      return estates.BUILDING;

    default:
      return -1;
  }
}

function getEstateName(id: number): string {
  // const key = Object.keys(estates).find(k => estates[k] === id);
  if (id === -1) {
    return '';
  }

  return estates.estatesName.find(e => e.id === id).name;
}
