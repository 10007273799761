/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./service-plans.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./service-plans.component";
import * as i4 from "../../core/services/auth.service";
import * as i5 from "@angular/router";
var styles_ServicePlansComponent = [i0.styles];
var RenderType_ServicePlansComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ServicePlansComponent, data: {} });
export { RenderType_ServicePlansComponent as RenderType_ServicePlansComponent };
export function View_ServicePlansComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "service-page"], ["id", "service-page-plans"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "header", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 1, "picture", [["class", "service-page-banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", ""], ["src", "../../../assets/images/service-page-plan.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 16, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 2, "a", [["class", "btn btn-big btn-accent"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toOrdering() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 2, "p", [["id", "service-plans-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, null, 4, "div", [["id", "plan-example-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "img", [["alt", ""], ["src", "../../../assets/images/service-page-plan-01.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "img", [["alt", ""], ["src", "../../../assets/images/service-page-plan-02.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "img", [["alt", ""], ["src", "../../../assets/images/service-page-plan-03.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 0, "img", [["alt", ""], ["src", "../../../assets/images/service-page-plan-04.jpg"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("service-page.plans.title")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("service-page.plans.intro")); _ck(_v, 9, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("service-page.plans.content")); _ck(_v, 11, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("service-page.plans.action")); _ck(_v, 14, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("service-page.plans.info")); _ck(_v, 17, 0, currVal_4); }); }
export function View_ServicePlansComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-service-plans", [], null, null, null, View_ServicePlansComponent_0, RenderType_ServicePlansComponent)), i1.ɵdid(1, 114688, null, 0, i3.ServicePlansComponent, [i4.AuthService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ServicePlansComponentNgFactory = i1.ɵccf("app-service-plans", i3.ServicePlansComponent, View_ServicePlansComponent_Host_0, {}, {}, []);
export { ServicePlansComponentNgFactory as ServicePlansComponentNgFactory };
