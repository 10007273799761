<div class="visit-request-container">
  <!-- NABIL: Afficher #visit-landing à l'ouverture -->
  <div *ngIf="!visitFix && !visitFree && !visitAdValoris && !showAddVisit" id="visit-landing">
    <h5>
      <img src="../../../assets/images/service-visite.svg" />
      Vos visites simplifiées
    </h5>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.
    </p>
    <div id="visit-choice">
      <!-- NABIL: Ajouter classe show-extend quand on clique sur #visit-etude -->
      <button
        (click)="visitEtudeExtended = !visitEtudeExtended"
        id="visit-etude"
        [class]="visitEtudeExtended ? 'btn main-choice show-extend' : 'btn main-choice'"
      >
        <img src="../../../assets/images/user-notaire.svg" alt="" />
        Via l’étude
      </button>
      <div *ngIf="visitEtudeExtended" id="visit-etude-extend">
        <button (click)="visitFix = true" class="btn">
          <fa-icon icon="calendar-check"></fa-icon>
          Visites fixes
        </button>
        <button (click)="visitFree = true" class="btn">
          <fa-icon icon="phone"></fa-icon>
          Visites libres
        </button>
      </div>
      <button (click)="visitAdValoris = true" class="btn main-choice" id="ad-valoris-trigger">
        <div class="title">
          <img src="../../../assets/images/add-valoris.svg" alt="" />
          Via Ad Valoris
        </div>
        <div class="prix">500€</div>
      </button>
    </div>
    <div class="action">
      <button (click)="close()" class="btn">Annuler</button>
      <button class="btn btn-primary">Valider</button>
    </div>
  </div>
  <!-- NABIL: Afficher #visit-fixes quand on choisit cette option -->
  <!-- NABIL: Ajouter la classe has-element quand on a au moins une date -->
  <div *ngIf="visitFix && !showAddVisit" id="visit-fixes" [class]="visitList.length > 0 ? 'has-element' : ''">
    <h5>
      <fa-icon icon="street-view"></fa-icon>
      Visites fixes
    </h5>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.
    </p>
    <button (click)="displayAddVisit()" id="first-visit-trigger" class="btn btn-accent">
      <fa-icon icon="plus"></fa-icon> Ajouter une visite
    </button>
    <ul *ngIf="visitList.length > 0" id="visit-list">
      <li *ngFor="let visit of visitList; let i = index">
        <div class="visit-date">
          {{ visit.date | date: 'dd/MM/yyyy' }} |
          {{ visit.hour === 0 ? 'Toute la journée (9h - 18h)' : visit.hour + 'h' }}
        </div>
        <button (click)="editVisit(i)" class="visit-edit"><fa-icon icon="pen"></fa-icon></button>
        <button (click)="deleteVisit(i)" class="visit-delete"><fa-icon icon="trash"></fa-icon></button>
      </li>
    </ul>
    <button (click)="displayAddVisit()" id="add-visit"><fa-icon icon="plus"></fa-icon> Ajouter une visite</button>
    <div class="action">
      <button (click)="visitFix = false" class="btn">Annuler</button>
      <button (click)="saveVisits()" class="btn btn-primary">Valider</button>
    </div>
  </div>
  <!-- NABIL: montrer cette section quand ajout de date -->
  <div *ngIf="showAddVisit" id="visit-fixe-creation">
    <h5>
      <fa-icon icon="plus"></fa-icon>
      Ajout de visite
    </h5>
    <form>
      <div class="form-element date-picker-container">
        <label>Jour de la visite</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            name="date-visit"
            [(ngModel)]="currentVisit.date"
            [min]="tomorrow"
            [matDatepicker]="picker"
            placeholder="DD/MM/YYYY"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="form-element" id="add-photo">
        <label>Horaire</label>
        <mat-form-field appearance="outline">
          <mat-select name="selected-visit" [(ngModel)]="selectedChoice">
            <mat-option *ngFor="let visit of options; let i = index" (click)="setHour(i)" [value]="i">
              {{ visit.hour === 0 ? 'Toute la journée (9h - 18h)' : visit.hour + 'h' }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <div class="action">
      <button (click)="showAddVisit = false" class="btn">Annuler</button>
      <button *ngIf="!editing" (click)="addVisit()" class="btn btn-primary">Ajouter</button>
      <button *ngIf="editing" (click)="saveEditVisit()" class="btn btn-primary">Modifier</button>
    </div>
  </div>
  <div *ngIf="visitFree" id="visit-libres">
    <h5>
      <fa-icon icon="street-view"></fa-icon>
      Visites libres
    </h5>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.
    </p>
    <form [formGroup]="visitFreeFG" action="">
      <mat-form-field appearance="outline">
        <mat-label>Prénom</mat-label>
        <input
          matInput
          placeholder="Jane"
          name="firstname"
          autocomplete="given-name"
          formControlName="firstName"
          required
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Nom</mat-label>
        <input matInput placeholder="Doe" name="name" autocomplete="given-name" formControlName="lastName" required />
      </mat-form-field>
      <mat-form-field appearance="outline" id="email">
        <mat-label>Email</mat-label>
        <input
          matInput
          placeholder="janedoe@exemple.com"
          name="email"
          autocomplete="given-email"
          formControlName="email"
          required
        />
      </mat-form-field>
      <mat-form-field appearance="outline" id="phone">
        <mat-label>Téléphone</mat-label>
        <input
          matInput
          placeholder="0123456789"
          name="telephone"
          autocomplete="given-telephone"
          formControlName="tel"
          required
        />
      </mat-form-field>
    </form>
    <div class="action">
      <button (click)="visitFree = false" class="btn">Annuler</button>
      <button (click)="saveVisitFree()" class="btn btn-primary">Valider</button>
    </div>
  </div>
  <div *ngIf="visitAdValoris" id="visit-ad-valoris">
    <h5>
      <fa-icon icon="street-view"></fa-icon>
      Visites Ad Valoris
    </h5>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.
    </p>
    <div *ngIf="reason === 1" id="summary">
      <span>Visite illimitée</span>
      <span>Max. 4 mois</span>
      <span>Min. 10 visites</span>
    </div>
    <div *ngIf="reason === 0 || reason === 2" id="summary">
      <span>4 semaines</span>
      <span>Max 2 sessions visites/semaines</span>
      <span>Heures fixes à déterminer</span>
    </div>
    <div id="options">
      <mat-checkbox>
        <div class="explanation">
          <p><strong>Option ouverture forcée - 199€</strong></p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.
          </p>
        </div>
      </mat-checkbox>
      <mat-checkbox>
        <div class="explanation">
          <p><strong>Option mise en valeur - sur devis</strong></p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.
          </p>
        </div>
      </mat-checkbox>
    </div>
    <div id="price">
      total : 500€
    </div>
    <div class="action">
      <button (click)="visitAdValoris = false" class="btn">Annuler</button>
      <button class="btn btn-primary">Valider</button>
    </div>
  </div>
</div>
