import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SendEmailService {
  apiURL = 'https://systems.certinergie.be/api/mailsending/sendMail';
  certiEmail = 'info@certinergie.be';

  constructor(private http: HttpClient) {}

  public sendDevis(value: {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    remarks: string;
    userCity: string;
    userNum: string;
    userStreet: string;
    userZip: string;
  }): Observable<any> {
    console.log('email data: ', value);
    const data = {
      user: {
        name: `${value.firstName} ${value.lastName}`,
        mail: value.email,
        phone: value.phone
      },
      estate: {
        street: value.userStreet,
        zip: value.userZip,
        numbox: value.userNum,
        city: value.userCity
      },
      message: {
        subject: 'Demande de Devis',
        message: value.remarks
      }
    };

    const subject = data.message.subject;
    let message =
      '<h1>' +
      data.message.subject +
      '</h1>' +
      'Nom: ' +
      data.user.name +
      ' <br/> ' +
      'Email: ' +
      data.user.mail +
      ' <br/> ' +
      'Tel: ' +
      data.user.phone +
      ' <br/> ' +
      'Adresse: ' +
      data.estate.street +
      ' ' +
      data.estate.numbox +
      ' ' +
      data.estate.zip +
      ' ' +
      data.estate.city +
      ' <br/> <br/> ' +
      data.message.message;

    message += '<br/><br/><br/><br/>';
    message += '<small>Une copie de cet email a été envoyée à ' + this.certiEmail + '</small>';
    this.sendMail(data.user.mail, 'Confirmation ' + subject, message).subscribe();

    return this.sendMail(this.certiEmail, subject, message);
  }

  private sendMail(email, subject, message) {
    console.log(email);
    console.log(subject);
    console.log(message);
    return this.http.post<any>(this.apiURL, {
      to: [email],
      subject: subject,
      body: message
    });
  }
}
