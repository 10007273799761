<div class="container">
  <header>
    <button (click)="closeModal()" class="btn-link btn-close-modal">
      <fa-icon icon="times"></fa-icon>
    </button>
    <img
      src="../../../assets/images/certiorder-success.svg"
      alt="{{ 'congrats-dialog.success-visual-alt' | translate }}"
    />
  </header>
  <div class="sub-header">
    <h5>{{ 'congrats-dialog.title' | translate }}</h5>
    <p>{{ 'congrats-dialog.subtitle' | translate }}</p>
  </div>
  <div class="content">
    <p>
      {{ 'congrats-dialog.content' | translate }}
    </p>
    <p>
      <strong>{{ 'congrats-dialog.emphasis' | translate }}</strong>
    </p>
  </div>
  <div class="action-container" *ngIf="agenda || onlinePayment">
    <button *ngIf="agenda" (click)="toAgenda()" class="btn btn-primary btn-agenda">
      <fa-icon icon="calendar-alt"></fa-icon>
      {{ 'congrats-dialog.fix' | translate }}
    </button>
    <button *ngIf="onlinePayment" (click)="toCheckout()" class="btn btn-accent">
      {{ 'congrats-dialog.pay-online' | translate }}
    </button>
  </div>
</div>
