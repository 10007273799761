import { Action } from '@ngrx/store';

export class ActionTypes {
  static SET_SHOWPACKS = '[UI] SET_SHOWPACKS';
  static SET_ISMOBILE = '[UI] SET_ISMOBILE';
  static SET_ISTABLETTE = '[UI] SET_ISTABLETTE';
  static SET_LANGUAGE = '[UI] SET_LANGUAGE';
  static SET_STEP1VALID = '[UI] SET_STEP1VALID';
  static SET_STEP2VALID = '[UI] SET_STEP2VALID';
  static SET_STEP3VALID = '[UI] SET_STEP3VALID';
  static SET_STEP4VALID = '[UI] SET_STEP4VALID';
  static SET_IAGREE = '[UI] SET_IAGREE';
  static SET_PACKSWITHPRICES = '[UI] SET_PACKSWITHPRICES';
  static SET_ONLINE = '[UI] SET_ONLINE';
  static StartOnlineOfflineCheck = '[UI] StartOnlineOfflineCheck';
  static SET_RESETSTEPPER = '[UI] SET_RESETSTEPPER';
  static RESET_UI = '[UI] RESET_UI';
  static SHOWMORESERVICES = '[UI] SHOWMORESERVICES';
}

export class SetShowPacks implements Action {
  public type = ActionTypes.SET_SHOWPACKS;
  constructor(public payload: boolean) {}
}
export class SetIsMobile implements Action {
  public type = ActionTypes.SET_ISMOBILE;
  constructor(public payload: boolean) {}
}
export class SetIsTablette implements Action {
  public type = ActionTypes.SET_ISTABLETTE;
  constructor(public payload: boolean) {}
}
export class SetLanguage implements Action {
  public type = ActionTypes.SET_LANGUAGE;
  constructor(public payload: 'fr' | 'nl' | 'de' | 'en') {}
}
export class SetStep1Valid implements Action {
  public type = ActionTypes.SET_STEP1VALID;
  constructor(public payload: boolean) {}
}
export class SetStep2Valid implements Action {
  public type = ActionTypes.SET_STEP2VALID;
  constructor(public payload: boolean) {}
}
export class SetStep3Valid implements Action {
  public type = ActionTypes.SET_STEP3VALID;
  constructor(public payload: boolean) {}
}
export class SetStep4Valid implements Action {
  public type = ActionTypes.SET_STEP4VALID;
  constructor(public payload: boolean) {}
}
export class SetIAgree implements Action {
  public type = ActionTypes.SET_IAGREE;
  constructor(public payload: boolean) {}
}
export class SetPacksWithPrices implements Action {
  public type = ActionTypes.SET_PACKSWITHPRICES;
  constructor(public payload: any) {}
}
export class StartOnlineOfflineCheck implements Action {
  readonly type = ActionTypes.StartOnlineOfflineCheck;
  constructor(public payload?: any) {}
}
export class SetOnline implements Action {
  public type = ActionTypes.SET_ONLINE;
  constructor(public payload: boolean) {}
}
export class SetResetStepper implements Action {
  public type = ActionTypes.SET_RESETSTEPPER;
  constructor(public payload: boolean) {}
}
export class ResetUI implements Action {
  public type = ActionTypes.RESET_UI;
  constructor(public payload: boolean) {}
}
export class ShowMoreServices implements Action {
  public type = ActionTypes.SHOWMORESERVICES;
  constructor(public payload: boolean) {}
}

export type Actions =
  | SetShowPacks
  | SetIsMobile
  | SetIsTablette
  | SetLanguage
  | SetStep1Valid
  | SetStep2Valid
  | SetStep3Valid
  | SetStep4Valid
  | SetIAgree
  | SetPacksWithPrices
  | StartOnlineOfflineCheck
  | SetOnline
  | SetResetStepper
  | ResetUI
  | ShowMoreServices;
