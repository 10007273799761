<div class="container">
  <img src="../../../assets/images/dialog-welcome-icon.svg" alt="" />
  <h5>{{ 'agency-welcome-dialog.title' | translate }}</h5>
  <div class="introduction" [innerHTML]="'agency-welcome-dialog.content' | translate">
    <!-- {{ 'agency-welcome-dialog.content' | translate }} -->
  </div>

  <section class="contact-section">
    <p>{{ 'agency-welcome-dialog.contact-title' | translate }}</p>
    <ul>
      <li>
        <b>{{ 'agency-welcome-dialog.contact-lg' | translate }}</b> Jean-François Quintin - 0488/19.33.58
      </li>
      <li>
        <b>{{ 'agency-welcome-dialog.contact-hn' | translate }}</b> Nicolas Boric - 0488/19.36.49
      </li>
      <li>
        <b>{{ 'agency-welcome-dialog.contact-bxl' | translate }}</b> Philippe Leloup - 0472/622.347
      </li>
    </ul>
  </section>
  <button (click)="closeModal()" class="btn btn-primary">{{ 'agency-welcome-dialog.action' | translate }}</button>
</div>
