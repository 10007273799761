import { ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/browser';
import * as i0 from "@angular/core";
var SentryErrorHandler = /** @class */ (function () {
    function SentryErrorHandler() {
    }
    SentryErrorHandler.prototype.handleError = function (error) {
        Sentry.captureException(error.originalError || error);
        throw error;
    };
    SentryErrorHandler.ngInjectableDef = i0.defineInjectable({ factory: function SentryErrorHandler_Factory() { return new SentryErrorHandler(); }, token: SentryErrorHandler, providedIn: "root" });
    return SentryErrorHandler;
}());
export { SentryErrorHandler };
