import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SendEmailService } from 'src/app/core/services/send-email.service';
import { getEstateName } from 'src/app/core/store/order/order.selectors';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-audit-request-dialog',
  templateUrl: './audit-request-dialog.component.html',
  styleUrls: ['./audit-request-dialog.component.scss']
})
export class AuditRequestDialogComponent implements OnInit {
  auditFG: FormGroup;
  auditInfo = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    street: '',
    num: '',
    zip: '',
    city: ''
  };
  estateName = '';
  sent = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { dialog: MatDialog },
    private store: Store<AppState>,
    private _formBuilder: FormBuilder,
    private sendEmailService: SendEmailService
  ) {
    this.auditFG = this._formBuilder.group({
      firstName: [{ value: this.auditInfo.firstName, disabled: false }, Validators.required],
      lastName: [{ value: this.auditInfo.lastName, disabled: false }, Validators.required],
      phone: [{ value: this.auditInfo.phone, disabled: false }, Validators.required],
      email: [{ value: this.auditInfo.email, disabled: false }, [Validators.required, Validators.email]],
      userStreet: [{ value: this.auditInfo.street, disabled: false }, Validators.required],
      userNum: [{ value: this.auditInfo.num, disabled: false }, Validators.required],
      userZip: [{ value: this.auditInfo.zip, disabled: false }, Validators.required],
      userCity: [{ value: this.auditInfo.city, disabled: false }, Validators.required]
    });
  }

  ngOnInit() {
    this.store.select(getEstateName).subscribe(name => {
      this.estateName = name;
    });
  }

  submit() {
    if (this.auditFG.invalid) {
      return;
    }
    const result = Object.assign(
      { remarks: 'Commande Audit logement <br/> Type de bien: ' + this.estateName },
      this.auditFG.value
    );

    this.sendEmailService.sendDevis(result).subscribe(
      val => {
        console.log(`🚀`);
        console.log('Audit envoyé avec succès', val);
        this.sent = true;
      },
      response => {
        console.log('😰 POST Audit error', response);
        this.sent = false;
      }
    );
  }

  closeDialog() {
    this.data.dialog.closeAll();
  }
}
