var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { estates, services, immoServices, regions, estateStyles, servicesSupp, displayedPacks } from '../data/';
import * as PRODUCTS from '../data/products';
import { HttpClient } from '@angular/common/http';
import { ProductDB } from '../models/productDB';
import { Store } from '@ngrx/store';
import * as orderSelector from '../store/order/order.selectors';
import { of, from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UserType } from '../models/user';
import { SetCommission } from '../store/order';
import { Products } from '../enums/product.enum';
import { PricingPlans } from '../enums/pricing-plan.enum';
import { getPricesID, projectID } from '../data/theme-config';
import { packsTheme } from '../data/packs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
var DataService = /** @class */ (function () {
    function DataService(http, store) {
        var _this = this;
        this.http = http;
        this.store = store;
        this.promoCodeURL = 'https://systems.certinergie.be/api/PackPromo/GetPromocode';
        this.packsURL = 'https://systems.certinergie.be/api/PackPromo/Getpack';
        // apiPricesURL = 'https://systems.certinergie.be/api/PackPromo/GetPrices';
        this.apiPricesURL = 'https://systems.certinergie.be/api/PackPromo/GetPrices?ordertype=' + getPricesID;
        this.apiGetOrderInfoURL = 'https://systems.certinergie.be/api/PackPromo/GetOrder?OrderID=';
        this.projectID = projectID;
        this.packsTheme = packsTheme;
        this.store.select(orderSelector.getRegion).subscribe(function (r) { return (_this.regionId = r); });
        this.store.select(orderSelector.getEstateID).subscribe(function (e) { return (_this.estateId = e); });
        this.store.select(orderSelector.getUserType).subscribe(function (u) { return (_this.userType = u); });
        this.store.select(orderSelector.getCustomPrices).subscribe(function (cp) { return (_this.customPrices = cp); });
        this.store.select(orderSelector.getNbOfAppartments).subscribe(function (n) { return (_this.nbOfApp = n); });
        this.store.select(orderSelector.getPricingPlan).subscribe(function (pp) { return (_this.userPricingPlan = pp); });
        this.store.select(orderSelector.getParutions).subscribe(function (p) { return (_this.parutions = p); });
        this.getPriceFromAPI().subscribe(function (pr) {
            _this.products = pr;
        });
        this.getPacksFromAPI().subscribe(function (p) {
            if (projectID === 'mdn') {
                _this.packsDB = [
                    {
                        estate: null,
                        id: null,
                        name: 'All in',
                        reduc: 0,
                        region: null,
                        services: [1, 4, 18, 21, 26],
                        show: true,
                        userRoles: [],
                        userTypes: [],
                        servicesPrice: 0,
                        servicesRed: 0,
                        totalWithReduc: 0,
                        description: '2001'
                    }
                ];
            }
            else {
                _this.packsDB = p;
            }
        });
    }
    DataService.prototype.isGreenfish = function () {
        return this.projectID === 'greenfish';
    };
    DataService.prototype.updatePricesOnResolve = function (pr) {
        this.products = pr;
    };
    DataService.prototype.getOrderInfo = function (orderid) {
        if (!this.isGuid(orderid)) {
            return of(false);
        }
        return this.http.get(this.apiGetOrderInfoURL + orderid);
    };
    DataService.prototype.getPriceFromAPI = function () {
        return this.http.get(this.apiPricesURL).pipe(map(function (data) { return data.Data; }));
    };
    DataService.prototype.getPacksFromAPI = function () {
        var res = from([this.packsTheme]);
        if (this.projectID === 'default') {
            res = this.http
                .get(this.packsURL)
                .pipe(map(function (data) {
                return displayedPacks.length > 0
                    ? data.Data.filter(function (p) { return displayedPacks.some(function (id) { return id === +p.description; }); })
                    : data.Data;
            }));
        }
        return res.pipe(tap());
    };
    DataService.prototype.getPromoCodesFromAPI = function () {
        return this.http.get(this.promoCodeURL).pipe(map(function (data) { return data.Data; }));
    };
    DataService.prototype.isPromoCodeValid = function (promoCode, productsID, region) {
        var _this = this;
        clearTimeout(this.debouncer);
        return new Promise(function (resolve, reject) {
            _this.debouncer = setTimeout(function () {
                if (promoCode && promoCode !== '') {
                    _this.getPromoCodesFromAPI().subscribe(function (data) {
                        if (data) {
                            var f = data.filter(function (p) { return p.KeyWord === promoCode && (p.PromoCode.region === region || !p.PromoCode.region); });
                            var found = f.filter(function (p) { return p.PromoCode.expertises.every(function (pr) { return productsID.indexOf(pr) >= 0; }); })[0];
                            // console.log('found code', found);
                            // console.log('codes', data);
                            resolve(found);
                        }
                        else {
                            console.log("\uD83D\uDE80");
                            resolve(null);
                        }
                    });
                }
                else {
                    resolve(null);
                }
            }, 200);
        });
    };
    /* async getPriceFromAPIForProductId(productTypeId: number): Promise<ProductDB[]> {
      return this.http
        .post<any>('http://localhost:64359/api/PackPromo/GetPrices', {
          Region: this.regionId,
          EstateType: this.estateId,
          ProductType: productTypeId
        })
        .pipe(map(data => data.Data))
        .toPromise();
    } */
    DataService.prototype.getProduct = function (productTypeId) {
        var prices = this.products;
        if (!prices) {
            return new ProductDB();
        }
        var tmp = prices;
        var filteredPrices = tmp.slice();
        var regionId = this.regionId;
        var estateTypeId = this.estateId;
        var filteredPrices1 = filteredPrices.find(function (p) { return p.Region === regionId && p.EstateType === estateTypeId && p.ProductType === productTypeId; });
        var filteredPrices2 = filteredPrices.find(function (p) {
            return (p.Region === regionId || p.Region === null) &&
                (p.EstateType === estateTypeId || p.EstateType === null) &&
                (p.ProductType === productTypeId || p.ProductType === null);
        });
        var dbPrice = !filteredPrices1
            ? !filteredPrices2
                ? { Price: 0, Reduction: 0, OrderPriceID: 0, OrderType: 1, ProductType: 27 }
                : filteredPrices2
            : filteredPrices1;
        return dbPrice;
    };
    DataService.prototype.getProductPrice = function (id) {
        if (id === 11111) {
            return { price: 100, reduc: 0 };
        }
        if (id === PRODUCTS.PRINT) {
            var paruPrice_1 = 0;
            this.parutions.forEach(function (paru) { return (paruPrice_1 += paru.price); });
            return { price: paruPrice_1, reduc: 0 };
        }
        var prod = this.getProduct(id);
        var pr = { price: prod.Price, reduc: prod.Reduction };
        if (this.userPricingPlan === 0 && this.userType !== UserType.Particulier && prod.ReductionPro) {
            console.log('⚡️ ReductionPro');
            prod.ReductionPro = this.getStatutReduc(prod.ProductType, prod.ReductionPro, prod);
            pr.reduc = prod.ReductionPro;
        }
        prod.Reduction = this.getStatutReduc(prod.ProductType, prod.Reduction, prod);
        var newDbPrice = this.checkCustomPrice(id, prod);
        prod = newDbPrice ? newDbPrice : prod;
        /* if (id === PRODUCTS.PEB && this.estateId === estateTypes.IMMEUBLE_APPART && this.nbOfApp > 0) {
          const newPrice = prod.Price + 100 * (this.nbOfApp - 1);
          return { price: newPrice, reduc: prod.Reduction };
        } */
        return { price: prod.Price, reduc: prod.Reduction };
    };
    DataService.prototype.checkCustomPrice = function (productTypeId, dbPrice) {
        var _this = this;
        var newDbPrice = null;
        var customP = this.customPrices
            ? this.customPrices.find(function (cp) { return cp.region === _this.regionId && cp.estateType === _this.estateId && cp.productTypeId === productTypeId; })
            : null;
        if (customP) {
            if (customP.commission > 0) {
                this.store.dispatch(new SetCommission(customP.commission));
            }
            newDbPrice = __assign({}, dbPrice);
            newDbPrice.Reduction = dbPrice.Price - customP.price;
        }
        return newDbPrice;
    };
    DataService.prototype.getEstateTypes = function () {
        return estates;
    };
    DataService.prototype.getEstateStyles = function () {
        return estateStyles;
    };
    DataService.prototype.getServiceTypes = function () {
        return services.concat(servicesSupp);
    };
    DataService.prototype.getImmoServiceTypes = function () {
        return immoServices;
    };
    DataService.prototype.getImmoPacks = function () {
        var _this = this;
        try {
            if (this.regionId === -1 || this.estateId === -1) {
                return null;
            }
            if (this.packsDB === undefined) {
                // If this happens, resolve data ? Or Subscribe to packs when we need to getImmoPacks()
                console.log('🔥🔥🔥🔥 packs undefined 🔥🔥🔥🔥');
                throw new Error('🔥🔥🔥🔥 packs undefined 🔥🔥🔥🔥');
                // return [];
            }
        }
        catch (e) { }
        var p1 = [];
        var p2 = [];
        var p3 = [];
        var p4 = [];
        if (!!this.packsDB) {
            this.packsDB = this.packsDB
                .filter(function (p) { return p.description !== null; })
                .sort(function (a, b) { return parseInt(a.description, 10) - parseInt(b.description, 10); });
            p1 = this.packsDB.filter(function (p) { return p.region === _this.regionId && p.estate === _this.estateId; });
            p2 = this.packsDB.filter(function (p) { return p.region === _this.regionId && (p.estate === -1 || p.estate === null); });
            p3 = this.packsDB.filter(function (p) { return p.region === null && p.estate === _this.estateId; });
            p4 = this.packsDB.filter(function (p) { return p.region === null && p.estate === null; });
        }
        var packs = p1.concat(p2, p3, p4);
        return packs;
    };
    DataService.prototype.getPacksToDisplay = function () {
        var _this = this;
        if (this.regionId === -1 || this.estateId === -1) {
            return null;
        }
        this.packsDB = this.packsDB.filter(function (p) { return p.description !== null; });
        var p1 = this.packsDB.filter(function (p) { return p.region === _this.regionId && p.estate === _this.estateId; });
        var p2 = this.packsDB.filter(function (p) { return p.region === _this.regionId && p.estate === -1; });
        var p3 = this.packsDB.filter(function (p) { return p.region === null && p.estate === _this.estateId; });
        var p4 = this.packsDB.filter(function (p) { return p.region === null && p.estate === null; });
        var packs = p1.concat(p2, p3, p4);
        return packs;
    };
    DataService.prototype.getPackPrice = function (packID) {
        var _this = this;
        if (this.regionId === -1 || this.estateId === -1) {
            return null;
        }
        var p1 = this.packsDB.filter(function (p) { return p.region === _this.regionId && p.estate === _this.estateId; });
        var p2 = this.packsDB.filter(function (p) { return p.region === _this.regionId && p.estate === -1; });
        var p3 = this.packsDB.filter(function (p) { return p.region === null && p.estate === _this.estateId; });
        var p4 = this.packsDB.filter(function (p) { return p.region === null && p.estate === null; });
        var packs = p1.concat(p2, p3, p4);
        var pack = packs.find(function (p) { return parseInt(p.id, 10) === packID; });
        return pack;
    };
    DataService.prototype.getProductsForPack = function (id) {
        var packs = this.getImmoPacks();
        var pack = packs.filter(function (p) { return p.description === id; });
        return pack[0].services;
    };
    DataService.prototype.getProductsAllIn = function () {
        return [
            PRODUCTS.PEB,
            PRODUCTS.ELEC,
            PRODUCTS.PHOTO,
            PRODUCTS.VISITE360,
            PRODUCTS.PLAN3D,
            PRODUCTS.DESCRIPTIF,
            PRODUCTS.PLAQUETTE
        ];
    };
    DataService.prototype.getRegionTypes = function () {
        return regions;
    };
    DataService.prototype.getRegionByID = function (id) {
        return this.getRegionTypes().find(function (r) { return r.id === id; });
    };
    DataService.prototype.getStatutReduc = function (id, reduc, prod) {
        var plandID = this.userPricingPlan;
        if (plandID === 0) {
            return reduc;
        }
        if (id === Products.PEB ||
            id === Products.ELEC ||
            id === Products.CITERNE_AERIENNE ||
            id === Products.CITERNE_ENTERREE ||
            id === Products.GAZ_CONTROLE) {
            switch (plandID) {
                case PricingPlans.BRONZE:
                    return prod.ReductionBronze;
                    break;
                case PricingPlans.SILVER:
                    return prod.ReductionSilver;
                    break;
                case PricingPlans.GOLD:
                    return prod.ReductionGold;
                    break;
                case PricingPlans.PLATINUM:
                    return prod.ReductionPlatinum;
                    break;
                case PricingPlans.BRONZE_FLANDRE:
                    return prod.ReductionBronze;
                    break;
                case PricingPlans.SILVER_FLANDRE:
                    return prod.ReductionSilver;
                    break;
                case PricingPlans.GOLD_FLANDRE:
                    return prod.ReductionGold;
                    break;
                case PricingPlans.PLATINUM:
                    return prod.ReductionPlatinum;
                    break;
                default:
                    break;
            }
        }
        else if (id === Products.PEB_ELEC && plandID !== 0) {
            switch (plandID) {
                case PricingPlans.BRONZE:
                    return 25;
                    break;
                case PricingPlans.SILVER:
                    return 25;
                    break;
                case PricingPlans.GOLD:
                    return 25;
                    break;
                case PricingPlans.PLATINUM:
                    return 25;
                    break;
                case PricingPlans.BRONZE_FLANDRE:
                    return 25;
                    break;
                case PricingPlans.SILVER_FLANDRE:
                    return 25;
                    break;
                case PricingPlans.GOLD_FLANDRE:
                    return 25;
                    break;
                case PricingPlans.PLATINUM_FLANDRE:
                    return 25;
                    break;
                default:
                    break;
            }
        }
        return reduc;
    };
    DataService.prototype.isGuid = function (stringToTest) {
        var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
        return regexGuid.test(stringToTest);
    };
    DataService.ngInjectableDef = i0.defineInjectable({ factory: function DataService_Factory() { return new DataService(i0.inject(i1.HttpClient), i0.inject(i2.Store)); }, token: DataService, providedIn: "root" });
    return DataService;
}());
export { DataService };
