/**
 * CONST for EstateType
 **/
export const DEVIS = 0;

export const MAISON_1 = 3;
export const MAISON_2 = 7;
export const MAISON_3 = 9;
export const MAISON_4 = 8;
export const MAISON_5 = 10;
export const VILLA = 5;

export const STUDIO = 4;
export const APPART_5075 = 11;
export const APPART = 1;
export const DUPLEX = 12;
export const TRIPLEX = 13;

export const IMMEUBLE_APPART = 2;

export const HOUSE = 0;
export const FLAT = 1;
export const BUILDING = 2;
export const LAND = 3;

export const estatesName = [
  { id: MAISON_1, name: 'Maison 1ch' },
  { id: MAISON_2, name: 'Maison 2ch' },
  { id: MAISON_3, name: 'Maison 3ch' },
  { id: MAISON_4, name: 'Maison 4ch' },
  { id: MAISON_5, name: 'Maison 5ch/-350m²' },
  { id: VILLA, name: 'Maison +350m²/Villa' },
  { id: STUDIO, name: 'Studio' },
  { id: APPART_5075, name: 'Appart 50-75m²' },
  { id: APPART, name: 'Appart 75-125m²' },
  { id: DUPLEX, name: 'Appart 125-200m²' },
  { id: TRIPLEX, name: 'Appart +200m²/Triplex' },
  { id: IMMEUBLE_APPART, name: 'Immeuble' }
];

export const estateStyles = [
  {
    id: -1,
    name: 'Sélectionnez un type de bien',
    type: '',
    fileName: 'type-estate'
  },
  {
    id: 0,
    name: 'Maison',
    type: 'house',
    fileName: 'type-maison'
  },
  {
    id: 1,
    name: 'Appartement',
    type: 'flat',
    fileName: 'type-appart'
  },
  {
    id: 2,
    name: 'Immeuble d’appartements',
    type: 'building',
    fileName: 'type-immeuble'
  },
  {
    id: 3,
    name: 'Terrain',
    type: 'land',
    fileName: 'type-terrain'
  }
];

export const estates = {
  m: [
    { buildingType: 'm', id: 1, text: '1 chambre', typeId: MAISON_1 },
    { buildingType: 'm', id: 2, text: '2 chambres', typeId: MAISON_2 },
    { buildingType: 'm', id: 3, text: '3 chambres', typeId: MAISON_3 },
    { buildingType: 'm', id: 4, text: '4 chambres', typeId: MAISON_4 },
    { buildingType: 'm', id: 5, text: '5 chambres & -350m2', typeId: MAISON_5 },
    { buildingType: 'm', id: 6, text: 'Entre 350m2 & 500m2', typeId: VILLA },
    { buildingType: 'm', id: 7, text: 'Plus de 500m²', typeId: DEVIS }
    /* {
      buildingType: 'm',
      id: 5,
      text: '+',
      typeId: MAISON_5,
      subOptions: [
        { buildingType: 'm', id: 6, text: '-350m²', typeId: MAISON_5 },
        { buildingType: 'm', id: 7, text: '+350m²', typeId: VILLA },
        { buildingType: 'm', id: 8, text: '+500m²', typeId: DEVIS }
      ]
    } */
  ],
  a: [
    { buildingType: 'a', id: 21, text: 'Studio (<50m²)', typeId: STUDIO },
    { buildingType: 'a', id: 22, text: '50-75m²', typeId: APPART_5075 },
    { buildingType: 'a', id: 23, text: '75-125m²', typeId: APPART },
    { buildingType: 'a', id: 24, text: '125-200m²', typeId: DUPLEX },
    { buildingType: 'a', id: 21, text: '+200m²/Triplex', typeId: TRIPLEX }
  ],
  i: [
    { id: 1, text: '1', typeId: IMMEUBLE_APPART },
    { id: 2, text: '2', typeId: IMMEUBLE_APPART },
    { id: 3, text: '3', typeId: IMMEUBLE_APPART },
    { id: 4, text: '4', typeId: IMMEUBLE_APPART },
    { id: 5, text: '5', typeId: IMMEUBLE_APPART },
    { id: 6, text: '6', typeId: IMMEUBLE_APPART }
  ]
};

export const estatesOld = {
  a: [
    { id: 0, name: 'bien-appart-Studio (<50m2)' },
    { id: 1, name: 'bien-appart-50-75m2' },
    { id: 2, name: 'bien-appart-75-125m2' },
    { id: 3, name: 'bien-appart-125-200m2' },
    { id: 4, name: 'bien-appart-200-300m2' },
    { id: 5, name: 'bien-appart->300m2' }
  ],
  m: [
    { id: 0, name: 'bien-maison-1 chambres' },
    { id: 1, name: 'bien-maison-2 chambres' },
    { id: 2, name: 'bien-maison-3 chambres' },
    { id: 3, name: 'bien-maison-4 chambres' },
    { id: 4, name: 'bien-maison-5 chambres' },
    { id: 5, name: 'bien-maison-6 chambres' },
    { id: 6, name: 'bien-maison-Villa' },
    { id: 7, name: 'bien-maison-GrandeVilla' },
    { id: 8, name: 'bien-maison-Villa' },
    { id: 9, name: 'bien-maison-GrandeVilla' },
    { id: 100, name: 'bien-maison-needtocall' }
  ],
  i: [
    { id: 1, name: 'immeuble' },
    { id: 2, name: 'immeuble' },
    { id: 3, name: 'immeuble' },
    { id: 4, name: 'immeuble' },
    { id: 5, name: 'immeuble' },
    { id: 6, name: 'immeuble' }
  ]
};

/**
 * TABLE for binding ui estate to estate type
 **/
const EstateTypes = [
  { buildingType: 'a', size: 0, typeId: STUDIO }, // studio <50m2
  { buildingType: 'a', size: 1, typeId: APPART_5075 }, // app 50-75m2
  { buildingType: 'a', size: 2, typeId: APPART }, // app 75-125m2
  { buildingType: 'a', size: 3, typeId: DUPLEX }, // app 125-200m2
  { buildingType: 'a', size: 4, typeId: TRIPLEX }, // app 200-300m2  duplex??
  { buildingType: 'a', size: 5, typeId: TRIPLEX }, // app +300m2  triplex??

  { buildingType: 'm', size: 0, typeId: MAISON_1 }, // maison 1 ch
  { buildingType: 'm', size: 1, typeId: MAISON_2 }, // maison 2 ch
  { buildingType: 'm', size: 2, typeId: MAISON_3 }, // maison 3 ch
  { buildingType: 'm', size: 3, typeId: MAISON_4 }, // maison 4 ch

  { buildingType: 'm', size: 6, typeId: MAISON_5 }, // maison 5 ch -350m2
  { buildingType: 'm', size: 7, typeId: VILLA }, // maison 5 ch +350m2
  { buildingType: 'm', size: 8, typeId: VILLA }, // maison + ch -350m2
  { buildingType: 'm', size: 9, typeId: VILLA }, // maison + ch +350m2

  { buildingType: 'i', count: 1, typeId: IMMEUBLE_APPART }, // immeuble
  { buildingType: 'i', count: 2, typeId: IMMEUBLE_APPART }, // immeuble
  { buildingType: 'i', count: 3, typeId: IMMEUBLE_APPART }, // immeuble
  { buildingType: 'i', count: 4, typeId: IMMEUBLE_APPART }, // immeuble
  { buildingType: 'i', count: 5, typeId: IMMEUBLE_APPART }, // immeuble
  { buildingType: 'i', count: 6, typeId: IMMEUBLE_APPART } // immeuble
];
