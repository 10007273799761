var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SimpleSmoothScrollService } from 'ng2-simple-smooth-scroll';
import { Owner, Contact, UserForm, UserType } from '../core/models/user';
import { Store } from '@ngrx/store';
import * as orderSelector from '../core/store/order/order.selectors';
import * as uiSelector from '../core/store/ui/ui.selectors';
import { combineLatest } from 'rxjs';
import { AuthService } from '../core/services/auth.service';
import { MatStepper } from '@angular/material';
import { SetAddress, SetOwner, SetContact, SetUserForm, SetDateButoir, SetNewUserType, SetGoGetKeys, SetContactChoice, SetInvoiceTo, SetVenteInfo } from '../core/store/order';
import { Address } from '../core/models/address';
import { newUserTypes } from '../core/data/newUserTypes';
import { debounceTime } from 'rxjs/operators';
import { ContactTypes } from '../core/enums/contact.enum';
import { SetStep2Valid } from '../core/store/ui';
import { hasClientTypeChoice } from '../core/data';
import { filterNewUserTypes } from '../core/data/theme-config';
var Step2Component = /** @class */ (function () {
    function Step2Component(store, authService, _formBuilder, smooth, cdRef) {
        var _this = this;
        this.store = store;
        this.authService = authService;
        this._formBuilder = _formBuilder;
        this.smooth = smooth;
        this.cdRef = cdRef;
        this.assetsFolder = 'assets/images/';
        this.minDate = new Date();
        this.contactTypes = ContactTypes;
        this.userTypes = UserType;
        this.hasClientTypeChoice = hasClientTypeChoice;
        this.filterNewUserTypes = filterNewUserTypes;
        this.venteInfo = {
            street: '',
            num: '',
            city: '',
            zip: '',
            division: '',
            section: '',
            numCadastre: '',
            rcni: '',
            statusVentePub: '',
            venteDate: ''
        };
        this.$isLoggedIn = this.store.select(orderSelector.getIsLoggedIn);
        this.$contact = this.store.select(orderSelector.getContact);
        this.$contactChoice = this.store.select(orderSelector.getContactChoice);
        this.$contactChoice.subscribe(function (c) { return (_this.contactChoice = c); });
        this.$newUserType = this.store.select(orderSelector.getUserType);
        this.store.select(orderSelector.getUserInfo).subscribe(function (u) { return (_this.user = u); });
        this.store.select(orderSelector.getUserForm).subscribe(function (u) { return (_this.userForm = u); });
        this.$owner = this.store.select(orderSelector.getOwner);
        this.$newUserType.subscribe(function (ut) { return (_this.newUserType = ut); });
        this.store.select(orderSelector.getUserType).subscribe(function (ut) { return (_this.userType = ut); });
        this.$reason = this.store.select(orderSelector.getReason);
        this.$owner.subscribe(function (o) {
            _this.owner = o;
        });
        this.venteFG = this._formBuilder.group({
            street: [{ value: this.venteInfo.street, disabled: false }],
            num: [{ value: this.venteInfo.num, disabled: false }],
            city: [{ value: this.venteInfo.city, disabled: false }],
            zip: [{ value: this.venteInfo.zip, disabled: false }]
        });
        this.cadastreFG = this._formBuilder.group({
            division: [{ value: this.venteInfo.division, disabled: false }],
            section: [{ value: this.venteInfo.section, disabled: false }],
            numCadastre: [{ value: this.venteInfo.numCadastre, disabled: false }],
            rcni: [{ value: this.venteInfo.rcni, disabled: false }]
        });
        this.$venteInfoSub = this.venteFG.valueChanges.pipe(debounceTime(1000)).subscribe(function (values) {
            _this.venteInfo.street = values.street;
            _this.venteInfo.num = values.num;
            _this.venteInfo.city = values.city;
            _this.venteInfo.zip = values.zip;
            /* this.venteInfo.statusVentePub = values.venteStatut;
            this.venteInfo.venteDate = values.venteDate; */
            _this.store.dispatch(new SetVenteInfo(_this.venteInfo));
        });
        this.$cadastreSub = this.cadastreFG.valueChanges.pipe(debounceTime(1000)).subscribe(function (values) {
            _this.venteInfo.division = values.division;
            _this.venteInfo.section = values.section;
            _this.venteInfo.numCadastre = values.numCadastre;
            _this.venteInfo.rcni = values.rcni;
            _this.store.dispatch(new SetVenteInfo(_this.venteInfo));
        });
        this.$contact.subscribe(function (c) { return (_this.contact = c); });
        this.store.select(orderSelector.getAddress).subscribe(function (a) { return (_this.address = a); });
        combineLatest(this.$isLoggedIn, this.$newUserType, this.$contactChoice, function (bool, newUserType, contactChoice) { return ({
            bool: bool,
            newUserType: newUserType,
            contactChoice: contactChoice
        }); }).subscribe(function (_a) {
            var bool = _a.bool, newUserType = _a.newUserType, contactChoice = _a.contactChoice;
            var isConnectedPart = bool && _this.userType === UserType.Particulier;
            var isNewUserPart = !bool && newUserType === UserType.Particulier;
            var isParticulier = isNewUserPart || isConnectedPart;
            var notLogAndNotPart = !bool && !isParticulier;
            // console.log('connected?', bool);
            // console.log('isParticulier', isParticulier);
            if (!bool && isParticulier) {
                _this.step2FormGroup = _this._formBuilder.group({
                    user: _this._formBuilder.group({
                        firstName: [
                            {
                                value: _this.userForm.firstname,
                                disabled: false
                            },
                            Validators.required
                        ],
                        lastName: [
                            {
                                value: _this.userForm.lastname,
                                disabled: false
                            },
                            Validators.required
                        ],
                        phone: [
                            {
                                value: _this.userForm.phone,
                                disabled: false
                            },
                            Validators.required
                        ],
                        email: [
                            {
                                value: _this.userForm.email,
                                disabled: false
                            },
                            Validators.required,
                            _this.isEmailUnique.bind(_this)
                        ],
                        companyname: [
                            {
                                value: _this.userForm.companyname,
                                disabled: false
                            }
                        ],
                        usertva: [
                            {
                                value: _this.userForm.tva,
                                disabled: false
                            }
                        ]
                    }),
                    address: _this._formBuilder.group({
                        orderStreet: [_this.address.street, Validators.required],
                        orderNum: [_this.address.number, Validators.required],
                        orderZip: [_this.address.zip, Validators.required],
                        orderCity: [_this.address.city, Validators.required]
                    }),
                    contact: _this._formBuilder.group({
                        firstName: [
                            {
                                value: _this.contact.firstname,
                                disabled: contactChoice !== 'other'
                            },
                            Validators.required
                        ],
                        lastName: [
                            {
                                value: _this.contact.lastname,
                                disabled: contactChoice !== 'other'
                            },
                            Validators.required
                        ],
                        phone: [
                            {
                                value: _this.contact.phone,
                                disabled: contactChoice !== 'other'
                            },
                            Validators.required
                        ]
                    }),
                    date: _this._formBuilder.group({
                        dateButoir: ['']
                    })
                });
                _this.$userSub = _this.step2FormGroup
                    .get('user')
                    .valueChanges.pipe(debounceTime(500))
                    .subscribe(function (values) {
                    var user = new UserForm();
                    user.firstname = values.firstName;
                    user.lastname = values.lastName;
                    user.phone = values.phone;
                    user.email = values.email;
                    user.companyname = values.companyname;
                    user.tva = values.usertva;
                    _this.store.dispatch(new SetUserForm(user));
                });
            }
            else if (notLogAndNotPart) {
                _this.step2FormGroup = _this._formBuilder.group({
                    user: _this._formBuilder.group({
                        firstName: [
                            {
                                value: _this.userForm.firstname,
                                disabled: bool
                            },
                            Validators.required
                        ],
                        lastName: [
                            {
                                value: _this.userForm.lastname,
                                disabled: bool
                            },
                            Validators.required
                        ],
                        phone: [
                            {
                                value: _this.userForm.phone,
                                disabled: bool
                            },
                            Validators.required
                        ],
                        email: [
                            {
                                value: _this.userForm.email,
                                disabled: bool
                            },
                            Validators.required,
                            _this.isEmailUnique.bind(_this)
                        ],
                        tva: [
                            {
                                value: _this.userForm.tva,
                                disabled: !notLogAndNotPart
                            }
                        ],
                        userStreet: [
                            {
                                value: _this.userForm.address.street,
                                disabled: !notLogAndNotPart
                            },
                            Validators.required
                        ],
                        userNum: [
                            {
                                value: _this.userForm.address.number,
                                disabled: !notLogAndNotPart
                            },
                            Validators.required
                        ],
                        userZip: [
                            {
                                value: _this.userForm.address.zip,
                                disabled: !notLogAndNotPart
                            },
                            Validators.required
                        ],
                        userCity: [
                            {
                                value: _this.userForm.address.city,
                                disabled: !notLogAndNotPart
                            },
                            Validators.required
                        ]
                    }),
                    owner: _this._formBuilder.group({
                        firstName: [
                            {
                                value: _this.owner.firstname,
                                disabled: isParticulier
                            },
                            Validators.required
                        ],
                        lastName: [
                            {
                                value: _this.owner.lastname,
                                disabled: isParticulier
                            },
                            Validators.required
                        ],
                        phone: [
                            {
                                value: _this.owner.phone,
                                disabled: isParticulier
                            },
                            Validators.required
                        ],
                        email: [
                            {
                                value: _this.owner.email,
                                disabled: isParticulier
                            },
                            Validators.email
                        ]
                    }),
                    address: _this._formBuilder.group({
                        orderStreet: [_this.address.street, Validators.required],
                        orderNum: [_this.address.number, Validators.required],
                        orderZip: [_this.address.zip, Validators.required],
                        orderCity: [_this.address.city, Validators.required]
                    }),
                    contact: _this._formBuilder.group({
                        firstName: [
                            {
                                value: _this.contact.firstname,
                                disabled: contactChoice !== 'other'
                            },
                            Validators.required
                        ],
                        lastName: [
                            {
                                value: _this.contact.lastname,
                                disabled: contactChoice !== 'other'
                            },
                            Validators.required
                        ],
                        phone: [
                            {
                                value: _this.contact.phone,
                                disabled: contactChoice !== 'other'
                            },
                            Validators.required
                        ]
                    }),
                    date: _this._formBuilder.group({
                        dateButoir: ['']
                    })
                });
                _this.$ownerSub = _this.step2FormGroup
                    .get('owner')
                    .valueChanges.pipe(debounceTime(1000))
                    .subscribe(function (values) {
                    var owner = new Owner();
                    owner.firstname = values.firstName;
                    owner.lastname = values.lastName;
                    owner.phone = values.phone;
                    owner.email = values.email;
                    _this.store.dispatch(new SetOwner(owner));
                });
                _this.$userSub = _this.step2FormGroup
                    .get('user')
                    .valueChanges.pipe(debounceTime(1000))
                    .subscribe(function (values) {
                    var user = new UserForm();
                    user.firstname = values.firstName;
                    user.lastname = values.lastName;
                    user.phone = values.phone;
                    user.email = values.email;
                    user.tva = values.tva;
                    user.address.street = values.userStreet;
                    user.address.number = values.userNum;
                    user.address.zip = values.userZip;
                    user.address.city = values.userCity;
                    _this.store.dispatch(new SetUserForm(user));
                });
            }
            else if (bool && !isParticulier) {
                // console.log('this connected');
                _this.step2FormGroup = _this._formBuilder.group({
                    owner: _this._formBuilder.group({
                        firstName: [
                            {
                                value: _this.owner.firstname,
                                disabled: isParticulier
                            },
                            Validators.required
                        ],
                        lastName: [
                            {
                                value: _this.owner.lastname,
                                disabled: isParticulier
                            },
                            Validators.required
                        ],
                        phone: [
                            {
                                value: _this.owner.phone,
                                disabled: isParticulier
                            },
                            Validators.required
                        ],
                        email: [
                            {
                                value: _this.owner.email,
                                disabled: isParticulier
                            },
                            Validators.email
                        ]
                    }),
                    address: _this._formBuilder.group({
                        orderStreet: [_this.address.street, Validators.required],
                        orderNum: [_this.address.number, Validators.required],
                        orderZip: [_this.address.zip, Validators.required],
                        orderCity: [_this.address.city, Validators.required]
                    }),
                    contact: _this._formBuilder.group({
                        firstName: [
                            {
                                value: _this.contact.firstname,
                                disabled: contactChoice !== 'other'
                            },
                            Validators.required
                        ],
                        lastName: [
                            {
                                value: _this.contact.lastname,
                                disabled: contactChoice !== 'other'
                            },
                            Validators.required
                        ],
                        phone: [
                            {
                                value: _this.contact.phone,
                                disabled: contactChoice !== 'other'
                            },
                            Validators.required
                        ]
                    }),
                    date: _this._formBuilder.group({
                        dateButoir: ['']
                    })
                });
                _this.$ownerSub = _this.step2FormGroup
                    .get('owner')
                    .valueChanges.pipe(debounceTime(1000))
                    .subscribe(function (values) {
                    var owner = new Owner();
                    owner.firstname = values.firstName;
                    owner.lastname = values.lastName;
                    owner.phone = values.phone;
                    owner.email = values.email;
                    _this.store.dispatch(new SetOwner(owner));
                });
            }
            else if (bool && isParticulier) {
                _this.step2FormGroup = _this._formBuilder.group({
                    address: _this._formBuilder.group({
                        orderStreet: [_this.address.street, Validators.required],
                        orderNum: [_this.address.number, Validators.required],
                        orderZip: [_this.address.zip, Validators.required],
                        orderCity: [_this.address.city, Validators.required]
                    }),
                    contact: _this._formBuilder.group({
                        firstName: [
                            {
                                value: _this.contact.firstname,
                                disabled: contactChoice !== 'other'
                            },
                            Validators.required
                        ],
                        lastName: [
                            {
                                value: _this.contact.lastname,
                                disabled: contactChoice !== 'other'
                            },
                            Validators.required
                        ],
                        phone: [
                            {
                                value: _this.contact.phone,
                                disabled: contactChoice !== 'other'
                            },
                            Validators.required
                        ]
                    }),
                    date: _this._formBuilder.group({
                        dateButoir: ['']
                    })
                });
            }
            // console.log(this.step2FormGroup);
            if (_this.contactChoice === 'sameAsOwner') {
                _this.$owner.subscribe(function (o) {
                    var c = new Contact();
                    c.firstname = o.firstname;
                    c.lastname = o.lastname;
                    c.phone = o.phone;
                    _this.store.dispatch(new SetContact(c));
                });
            }
            _this.step2FormGroup
                .get('address')
                .valueChanges.pipe(debounceTime(1000))
                .subscribe(function (values) {
                // console.log('changes to address');
                var address = new Address();
                address.street = values.orderStreet;
                address.number = values.orderNum;
                address.zip = values.orderZip;
                address.city = values.orderCity;
                _this.store.dispatch(new SetAddress(address));
            });
            _this.step2FormGroup
                .get('contact')
                .valueChanges.pipe(debounceTime(1000))
                .subscribe(function (values) {
                // console.log('changes to contact');
                var contact = new Contact();
                contact.firstname = values.firstName;
                contact.lastname = values.lastName;
                contact.phone = values.phone;
                _this.store.dispatch(new SetContact(contact));
                var date = values.dateButoir;
                if (date) {
                    date.setHours(date.getHours() + 1);
                    _this.store.dispatch(new SetDateButoir(date));
                }
            });
            _this.step2FormGroup
                .get('date')
                .valueChanges.pipe(debounceTime(1000))
                .subscribe(function (values) {
                // console.log('changes to date');
                var date = values.dateButoir;
                if (date) {
                    date.setHours(date.getHours() + 1);
                    _this.store.dispatch(new SetDateButoir(date));
                }
            });
            _this.step2FormGroup.statusChanges.subscribe(function (value) {
                _this.store.dispatch(new SetStep2Valid(value === 'VALID'));
            });
        });
        this.minDate.setDate(this.minDate.getDate() + 1);
    }
    Step2Component.prototype.ngOnInit = function () {
        var _this = this;
        this.$isLoggedIn.subscribe(function (bool) {
            // console.log('connected?', bool);
            if (!bool) {
            }
        });
        this.$goGetKeys = this.store.select(orderSelector.getGoGeyKeys);
        this.newUserTypes = newUserTypes.filter(function (ut) { return _this.filterNewUserTypes.every(function (fu) { return fu !== ut.id; }); });
        this.$newUserType = this.store.select(orderSelector.getUserType);
        this.store.select(uiSelector.getStep2IsValid).subscribe(function (bool) { return (_this.isValid = bool); });
        this.$dateButoir = this.store.select(orderSelector.getDateButoir);
        this.$dateButoir.subscribe(function (d) { return (_this.dateButoire = d); });
        this.step2FormGroup.updateValueAndValidity();
    };
    Step2Component.prototype.newUserTypeImgUrl = function (selectedId, fileName) {
        var url = this.assetsFolder;
        url += fileName;
        url += this.newUserType === selectedId ? '-selected.svg' : '.svg';
        return url;
    };
    Step2Component.prototype.setNewUserType = function (id) {
        if ((id === UserType.Particulier || id === UserType.Installateur) && this.contactChoice === 'keyAtAgency') {
            this.setContactChoice('sameAsOwner');
        }
        this.store.dispatch(new SetNewUserType(id));
    };
    Step2Component.prototype.filterWeekend = function (d) {
        var day = d.getDay();
        // Prevent Saturday and Sunday from being selected.
        return day !== 0 && day !== 6;
    };
    Step2Component.prototype.logOut = function () {
        var logged = this.authService.logout();
        if (!logged) {
            this.stepper.reset();
        }
    };
    Step2Component.prototype.copyContact = function () {
        // e.preventDefault();
        if (this.owner.firstname || this.owner.lastname || this.owner.phone) {
            // console.log('owner');
            this.step2FormGroup.get('contact').patchValue({
                firstName: this.owner.firstname,
                lastName: this.owner.lastname,
                phone: this.owner.phone
            });
        }
        else {
            // console.log(this.userForm.firstname);
            this.step2FormGroup.get('contact').patchValue({
                firstName: this.userForm.firstname,
                lastName: this.userForm.lastname,
                phone: this.userForm.phone
            });
        }
    };
    Step2Component.prototype.setContactChoice = function (id) {
        this.store.dispatch(new SetContactChoice(id));
        if (id === 'sameAsOwner') {
            this.copyContact();
            this.store.dispatch(new SetGoGetKeys(false));
        }
        else if (id === 'other') {
            this.store.dispatch(new SetContact(new Contact()));
            this.store.dispatch(new SetGoGetKeys(false));
            this.step2FormGroup.get('contact').patchValue({
                firstName: this.contact.firstname,
                lastName: this.contact.lastname,
                phone: this.contact.phone
            });
        }
        else if (id === 'keyAtAgency') {
            this.copyContact();
            this.store.dispatch(new SetGoGetKeys(true));
        }
        this.step2FormGroup.get('date').patchValue({
            dateButoir: this.dateButoire
        });
    };
    Step2Component.prototype.scrollTop = function () {
        var _this = this;
        setTimeout(function () { return _this.smooth.smoothScrollToTop(); }, 500);
    };
    Step2Component.prototype.toStep3 = function () {
        if (this.isValid) {
            this.store.dispatch(new SetInvoiceTo('toProprio'));
            this.scrollTop();
        }
    };
    Step2Component.prototype.onAutocompleteSelected = function (pResult, type) {
        var address = new Address();
        var addrObj = this.getFormattedAddress(pResult);
        address.number = addrObj.num;
        address.street = addrObj.street;
        address.zip = addrObj.zip;
        address.city = addrObj.city;
        address.country = addrObj.country;
        address.lat = addrObj.lat;
        address.lng = addrObj.lng;
        if (type === 'owner') {
            this.step2FormGroup.get('address').patchValue({
                orderStreet: address.street,
                orderNum: address.number,
                orderZip: address.zip,
                orderCity: address.city
            });
            this.store.dispatch(new SetAddress(address));
        }
        else if (type === 'user') {
            var user = __assign({}, this.userForm);
            user.address = address;
            this.step2FormGroup.get('user').patchValue({
                userStreet: address.street,
                userNum: address.number,
                userZip: address.zip,
                userCity: address.city
            });
            this.store.dispatch(new SetUserForm(user));
        }
        this.step2FormGroup.markAsDirty();
        this.step2FormGroup.updateValueAndValidity();
        this.cdRef.detectChanges();
    };
    Step2Component.prototype.getFormattedAddress = function (place) {
        var location_obj = {};
        for (var i in place.address_components) {
            if (place.address_components.hasOwnProperty(i)) {
                var item = place.address_components[i];
                location_obj['formatted_address'] = place.formatted_address;
                if (item['types'].indexOf('locality') > -1) {
                    location_obj['city'] = item['long_name'];
                }
                else if (item['types'].indexOf('administrative_area_level_1') > -1) {
                    location_obj['admin_area_l1'] = item['short_name'];
                }
                else if (item['types'].indexOf('street_number') > -1) {
                    location_obj['num'] = item['short_name'];
                }
                else if (item['types'].indexOf('route') > -1) {
                    location_obj['street'] = item['long_name'];
                }
                else if (item['types'].indexOf('country') > -1) {
                    location_obj['country'] = item['long_name'];
                }
                else if (item['types'].indexOf('postal_code') > -1) {
                    location_obj['zip'] = item['short_name'];
                }
            }
        }
        if (place && place.geometry && place.geometry.location.lat) {
            location_obj['lat'] = place.geometry.location.lat();
        }
        if (place && place.geometry && place.geometry.location.lng) {
            location_obj['lng'] = place.geometry.location.lng();
        }
        return location_obj;
    };
    Step2Component.prototype.isEmailUnique = function (control) {
        var _this = this;
        clearTimeout(this.debouncer);
        return new Promise(function (resolve, reject) {
            _this.debouncer = setTimeout(function () {
                _this.authService.isEmailAlreadyInUse(control.value).subscribe(function (data) {
                    if (data.Data) {
                        console.log('Email exists', data.Data);
                        resolve({ isEmailAlreadyInUse: true });
                    }
                    else {
                        console.log("\uD83D\uDE80");
                        resolve(null);
                    }
                });
            }, 1000);
        });
    };
    Step2Component.prototype.toggleDisplayTVA = function (checkbox) {
        console.log(checkbox);
    };
    Step2Component.prototype.selectStatusVentePub = function (item) {
        this.venteInfo.statusVentePub = item;
        this.store.dispatch(new SetVenteInfo(this.venteInfo));
    };
    return Step2Component;
}());
export { Step2Component };
