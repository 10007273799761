<div class="service-page">
  <header>
    <h1 id="service-citerne-title">
      {{ 'service-page.citerne.title' | translate }}
    </h1>
    <picture class="service-page-banner"> <img src="../../../assets/images/service-page-citerne.jpg" alt=""/></picture>
  </header>
  <div class="content">
    <h2>
      {{ 'service-page.citerne.intro' | translate }}
    </h2>
    <div [innerHTML]="'service-page.citerne.content' | translate"></div>
    <div id="service-citerne-subcontent">
      <h6>{{ 'service-page.citerne.wallonie.title' | translate }}</h6>
      <div [innerHTML]="'service-page.citerne.wallonie.content' | translate"></div>
      <p class="info">{{ 'service-page.citerne.wallonie.info' | translate }}</p>
      <h6>{{ 'service-page.citerne.bxl.title' | translate }}</h6>
      <div [innerHTML]="'service-page.citerne.bxl.content' | translate"></div>
      <h6>{{ 'service-page.citerne.flandre.title' | translate }}</h6>
      <div [innerHTML]="'service-page.citerne.flandre.content' | translate"></div>
      <div [innerHTML]="'service-page.citerne.contact' | translate"></div>
    </div>
    <a class="btn btn-big btn-accent" (click)="toOrdering()">
      {{ 'service-page.citerne.action' | translate }}
    </a>
  </div>
</div>
