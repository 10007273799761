<div class="service-page" id="service-page-elec">
  <header>
    <h1>
      {{ 'service-page.elec.title' | translate }}
    </h1>
    <picture class="service-page-banner"> <img src="../../../assets/images/service-page-elec.png" alt=""/></picture>
  </header>
  <div class="content">
    <h2>
      {{ 'service-page.elec.intro' | translate }}
    </h2>
    <div [innerHTML]="'service-page.elec.content' | translate"></div>

    <a class="btn btn-big btn-accent" (click)="toOrdering()">
      {{ 'service-page.elec.action' | translate }}
    </a>
  </div>
</div>
