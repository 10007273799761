var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionTypes } from './order.actions';
import * as estates from '../../data/estates';
import * as PRODUCTS from '../../data/products';
import { Document } from '../../models/document';
import { Contact, Owner, UserForm, UserType, UserRole } from '../../models/user';
import { Address } from '../../models/address';
import { Region } from '../../models/region';
import { defaultRegion, projectID } from '../../data/theme-config';
import { VisitContact } from '../../models/visitContact';
var initialOrderState = {
    id: '',
    regionType: defaultRegion,
    region: new Region(),
    estateType: -1,
    estateStyle: -1,
    estateName: '',
    nbOfAppartments: -1,
    services: [],
    immoServices: [],
    products: [],
    hasPeb: false,
    hasPebPartiel: false,
    hasElec: false,
    hasCiterne: false,
    hasPlan: false,
    hasGaz: false,
    pebNb: '',
    nbOfElec: 0,
    nbOfGaz: 0,
    gazChoice: 0,
    paymentType: 'classic',
    acteType: 'ILLPAY',
    sendByMail: false,
    remarks: '',
    invoiceTo: '',
    immoPack: -1,
    reductions: [],
    reduction: 0,
    reductionWithoutComm: 0,
    price: 0,
    onlinePayment: false,
    promoCode: '',
    urgence: false,
    address: new Address(),
    owner: new Owner(),
    contact: new Contact(),
    dateButoir: null,
    document: new Document(),
    goGetKeys: false,
    commission: 0,
    contactChoice: 'sameAsOwner',
    nn: '',
    nbOfIso: 0,
    promoCodeID: '',
    packID: '',
    reason: -1,
    clientType: -1,
    venteType: -1,
    venteInfo: {
        street: '',
        num: '',
        city: '',
        zip: '',
        division: '',
        section: '',
        numCadastre: '',
        rcni: '',
        venteStatut: {
            id: 1,
            name: 'Première séance'
        },
        venteDate: null
    },
    parutions: [],
    visits: [],
    visitContact: new VisitContact(),
    newUser: new UserForm(),
    user: {
        userid: '',
        firstname: '',
        lastname: '',
        email: '',
        companyname: '',
        phone: '',
        address: new Address(),
        sendByMail: false,
        hasImmoAccess: false,
        userType: UserType.Particulier,
        hasCommission: false,
        pricingPlan: 0,
        roles: [],
        tva: '',
        customPrices: []
    }
};
/*
  regionType: 0,
  estateType: 3,
  estateStyle: 0,
  estateName: 'MAISON_1',
*/
/* const initialOrderState: IOrder = {
  id: '',
  regionType: -1,
  estateType: -1,
  estateStyle: -1,
  estateName: '',
  nbOfAppartments: -1,
  services: [],
  products: [],
  hasPeb: false,
  hasElec: false,
  hasCiterne: false,
  hasPlan: false,
  hasGaz: false,
  nbOfElec: 0,
  paymentType: 'classic',
  acteType: 'ILLPAY',
  sendByMail: false,
  remarks: '',
  invoiceTo: 'toPro'
}; */
export function reducer(state, action) {
    if (state === void 0) { state = initialOrderState; }
    switch (action.type) {
        case ActionTypes.SET_REGION:
            return __assign({}, state, { regionType: action.payload });
        case ActionTypes.SET_REGIONINFO:
            return __assign({}, state, { region: action.payload });
        case ActionTypes.SET_ESTATETYPE:
            return __assign({}, state, { estateType: action.payload, estateName: getEstateName(action.payload), nbOfAppartments: action.payload === estates.IMMEUBLE_APPART ? 1 : -1, estateStyle: getEstateStyle(action.payload) });
        case ActionTypes.SET_ESTATESTYLE:
            return __assign({}, state, { estateStyle: action.payload, estateType: -1, nbOfAppartments: -1, reason: projectID === 'mdn' ? -1 : state.reason });
        case ActionTypes.SET_NUMBEROFAPPARTMENTS:
            return __assign({}, state, { nbOfAppartments: action.payload, estateType: estates.BUILDING, estateStyle: estates.BUILDING, estateName: 'IMMEUBLE_APPART' });
        case ActionTypes.SET_SERVICES:
            return __assign({}, state, { services: action.payload.slice() });
        case ActionTypes.SET_PRODUCTS:
            return __assign({}, state, { products: action.payload.slice(), hasPeb: action.payload.some(function (p) { return p.id === PRODUCTS.PEB; }), hasPebPartiel: action.payload.some(function (p) { return p.id === PRODUCTS.PEB_Partiel; }), hasElec: action.payload.some(function (p) { return p.id === PRODUCTS.ELEC; }), hasCiterne: action.payload.some(function (p) { return p.id === PRODUCTS.CITERNE_AERIENNE || p.id === PRODUCTS.CITERNE_ENTERREE; }), hasPlan: action.payload.some(function (p) { return p.id === PRODUCTS.PLAN; }), hasGaz: action.payload.some(function (p) { return p.id === PRODUCTS.GAZ_CONTROLE; }), nbOfElec: action.payload.some(function (p) { return p.id === PRODUCTS.ELEC; })
                    ? state.nbOfElec > 0
                        ? state.nbOfElec
                        : 1
                    : 0, nbOfGaz: action.payload.some(function (p) { return p.id === PRODUCTS.GAZ_CONTROLE; })
                    ? state.nbOfGaz > 0
                        ? state.nbOfGaz
                        : 1
                    : 0, price: checkPriceWithoutReduction(action.payload) });
        case ActionTypes.SET_NBOFELEC:
            return __assign({}, state, { nbOfElec: action.payload });
        case ActionTypes.SET_NBOFGAZ:
            return __assign({}, state, { nbOfGaz: action.payload });
        case ActionTypes.SET_GAZCHOICE:
            return __assign({}, state, { gazChoice: action.payload });
        case ActionTypes.SET_PAYMENTTYPE:
            return __assign({}, state, { paymentType: action.payload, onlinePayment: action.payload === 'online' ? true : state.onlinePayment });
        case ActionTypes.SET_ACTETYPE:
            return __assign({}, state, { acteType: action.payload });
        case ActionTypes.TOGGLE_SENDBYMAIL:
            return __assign({}, state, { sendByMail: !state.sendByMail });
        case ActionTypes.SET_REMARKS:
            return __assign({}, state, { remarks: action.payload });
        case ActionTypes.SET_INVOICETO:
            return __assign({}, state, { invoiceTo: action.payload });
        case ActionTypes.SET_IMMOPACK:
            return __assign({}, state, { immoPack: action.payload });
        case ActionTypes.SET_REDUCTIONS:
            var redWithoutComm = checkReduction(action.payload);
            var maxComm = checkMaxCommission(checkReduction(action.payload), state.commission);
            var red = (redWithoutComm - maxComm);
            return __assign({}, state, { reductions: action.payload, reduction: red, reductionWithoutComm: redWithoutComm, commission: maxComm });
        case ActionTypes.SET_COMMISSION:
            return __assign({}, state, { commission: action.payload, reduction: state.reductionWithoutComm - action.payload });
        case ActionTypes.TOGGLE_ONLINEPAYMENT:
            return __assign({}, state, { onlinePayment: !state.onlinePayment });
        case ActionTypes.SET_PROMOCODE:
            return __assign({}, state, { promoCode: action.payload });
        case ActionTypes.SET_PEBNB:
            return __assign({}, state, { pebNb: action.payload });
        case ActionTypes.TOGGLE_URGENCE:
            return __assign({}, state, { urgence: !state.urgence });
        case ActionTypes.SET_ADDRESS:
            return __assign({}, state, { address: __assign({}, state.address, action.payload) });
        case ActionTypes.SET_ZIPCODE:
            return __assign({}, state, { address: __assign({}, state.address, { zip: action.payload }) });
        case ActionTypes.SET_OWNER:
            return __assign({}, state, { owner: action.payload });
        case ActionTypes.SET_USERFORM:
            return __assign({}, state, { newUser: action.payload, user: __assign({}, state.user, action.payload) });
        case ActionTypes.SET_DATEBUTOIR:
            return __assign({}, state, { dateButoir: action.payload });
        case ActionTypes.SET_DOCUMENT:
            return __assign({}, state, { document: action.payload });
        case ActionTypes.RESET_ORDERSTATE:
            return __assign({}, initialOrderState, { services: [], reductions: [], products: [], immoServices: [] });
        case ActionTypes.SET_NEWUSERTYPE:
            return __assign({}, state, { user: __assign({}, state.user, { userType: action.payload }) });
        case ActionTypes.SET_CONTACTCHOICE:
            return __assign({}, state, { contactChoice: action.payload });
        case ActionTypes.SET_CONTACT:
            return __assign({}, state, { contact: action.payload });
        case ActionTypes.COPY_CONTACT:
            return __assign({}, state, { contact: state.owner.firstname || state.owner.lastname || state.owner.phone
                    ? {
                        firstname: state.owner.firstname,
                        lastname: state.owner.lastname,
                        phone: state.owner.phone
                    }
                    : {
                        firstname: state.user.firstname,
                        lastname: state.user.lastname,
                        phone: state.user.phone
                    } });
        case ActionTypes.SET_GOGETKEYS:
            return __assign({}, state, { goGetKeys: action.payload });
        case ActionTypes.SET_GUID:
            return __assign({}, state, { user: __assign({}, state.user, { userid: action.payload }) });
        case ActionTypes.SET_USERINFO:
            return __assign({}, state, { user: __assign({}, state.user, action.payload, { hasImmoAccess: checkHasImmoAccess(action.payload.roles) }) });
        case ActionTypes.RESET_USERINFO:
            return __assign({}, state, { user: __assign({}, initialOrderState.user) });
        case ActionTypes.SET_NN:
            return __assign({}, state, { nn: action.payload });
        case ActionTypes.SET_PROMOCODEID:
            return __assign({}, state, { promoCodeID: action.payload });
        case ActionTypes.SET_PACKID:
            return __assign({}, state, { packID: action.payload });
        case ActionTypes.SET_NBOFISO:
            return __assign({}, state, { nbOfIso: action.payload });
        case ActionTypes.SET_REASON:
            return __assign({}, state, { reason: action.payload });
        case ActionTypes.SET_CLIENTTYPE:
            return __assign({}, state, { clientType: action.payload });
        case ActionTypes.SET_VENTETYPE:
            return __assign({}, state, { venteType: action.payload });
        case ActionTypes.SET_VENTEINFO:
            return __assign({}, state, { venteInfo: action.payload });
        case ActionTypes.SET_PARUTIONS:
            return __assign({}, state, { parutions: action.payload });
        case ActionTypes.SET_VISITS:
            return __assign({}, state, { visits: action.payload });
        case ActionTypes.SET_VISITCONTACT:
            return __assign({}, state, { visitContact: action.payload });
        default:
            return __assign({}, state);
    }
}
/* function setServ(payload: number[]) {
  let services = [...payload];
  const indexCiterneE = services.indexOf(PRODUCTS.CITERNE_ENTERREE);
  const indexCiterneA = services.indexOf(PRODUCTS.CITERNE_AERIENNE);

  if (indexCiterneE)
} */
function checkHasImmoAccess(roles) {
    var hasImmoAccess = roles ? roles.some(function (r) { return r.RoleID === UserRole.ImmoAccess; }) : false;
    return hasImmoAccess;
}
function checkPriceWithoutReduction(products) {
    var price = 0;
    products.forEach(function (product) {
        price += Number(product.price);
    });
    return price;
}
function checkReduction(reductions) {
    var reduction = 0;
    if (reductions.length > 0) {
        reductions.forEach(function (reduc) {
            reduction += Number(reduc.price);
        });
    }
    return reduction;
}
function checkMaxCommission(reduc, comm) {
    var maxCom = 0;
    // console.log(`red: ${reduc} & comm ${comm}`);
    maxCom = comm > reduc ? reduc : comm;
    return maxCom;
}
function getEstateStyle(estateId) {
    switch (estateId) {
        case estates.MAISON_1:
        case estates.MAISON_2:
        case estates.MAISON_3:
        case estates.MAISON_4:
        case estates.MAISON_5:
        case estates.VILLA:
            return estates.HOUSE;
        case estates.STUDIO:
        case estates.APPART_5075:
        case estates.APPART:
        case estates.DUPLEX:
        case estates.TRIPLEX:
            return estates.FLAT;
        case estates.IMMEUBLE_APPART:
            return estates.BUILDING;
        default:
            return -1;
    }
}
function getEstateName(id) {
    // const key = Object.keys(estates).find(k => estates[k] === id);
    if (id === -1) {
        return '';
    }
    return estates.estatesName.find(function (e) { return e.id === id; }).name;
}
