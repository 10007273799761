<div class="service-page">
  <header>
    <h1 id="service-resp-peb-title">
      {{ 'service-page.resp-peb.title' | translate }}
    </h1>
    <picture class="service-page-banner"> <img src="../../../assets/images/service-page-resp-peb.jpg" alt=""/></picture>
  </header>
  <div class="content">
    <h2>
      {{ 'service-page.resp-peb.intro' | translate }}
    </h2>
    <div id="service-resp-peb-content" [innerHTML]="'service-page.resp-peb.content' | translate"></div>
  </div>
</div>
