/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./service-peb-chauffage.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./service-peb-chauffage.component";
var styles_ServicePebChauffageComponent = [i0.styles];
var RenderType_ServicePebChauffageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ServicePebChauffageComponent, data: {} });
export { RenderType_ServicePebChauffageComponent as RenderType_ServicePebChauffageComponent };
export function View_ServicePebChauffageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "service-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "header", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 1, "picture", [["class", "service-page-banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", ""], ["src", "../../../assets/images/service-page-peb-chauffage.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("service-page.peb-chauffage.title")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("service-page.peb-chauffage.intro")); _ck(_v, 9, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("service-page.peb-chauffage.content")); _ck(_v, 11, 0, currVal_2); }); }
export function View_ServicePebChauffageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-service-peb-chauffage", [], null, null, null, View_ServicePebChauffageComponent_0, RenderType_ServicePebChauffageComponent)), i1.ɵdid(1, 114688, null, 0, i3.ServicePebChauffageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ServicePebChauffageComponentNgFactory = i1.ɵccf("app-service-peb-chauffage", i3.ServicePebChauffageComponent, View_ServicePebChauffageComponent_Host_0, {}, {}, []);
export { ServicePebChauffageComponentNgFactory as ServicePebChauffageComponentNgFactory };
