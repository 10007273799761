<div class="print-request-container">
  <!-- Affichage de la liste vide ou avec support -->
  <!-- NABIL: Ajouter classe "has-element" quand on a ajouté un support -->
  <div *ngIf="!showAddParu" [class]="paruList.length > 0 ? 'has-element' : ''" id="print-landing">
    <h5>
      <img src="../../../assets/images/service-publication.svg" />
      Commandez vos parutions papier
    </h5>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.
    </p>
    <button (click)="displayAddParu()" id="first-support" class="btn btn-accent">
      <fa-icon icon="plus"></fa-icon> Ajouter une parution
    </button>
    <ul *ngIf="paruList.length > 0" id="print-list">
      <li *ngFor="let paru of paruList; let i = index">
        <div class="support-info">
          {{ paru.name }} - {{ paru.date | date: 'dd/MM/yyyy' }} - {{ paru.needPhoto ? 'Avec photo' : 'Sans photo' }}
        </div>
        <button (click)="editParu(i)" class="support-edit"><fa-icon icon="pen"></fa-icon></button>
        <button (click)="deleteParu(i)" class="support-delete"><fa-icon icon="trash"></fa-icon></button>
      </li>
    </ul>
    <button (click)="displayAddParu()" id="add-print"><fa-icon icon="plus"></fa-icon> Ajouter une parution</button>
    <div class="action">
      <button (click)="close()" class="btn">Annuler</button>
      <button (click)="saveParu()" class="btn btn-primary">Valider</button>
    </div>
  </div>
  <!-- Affichage des champs d'ajout de support -->
  <div *ngIf="showAddParu" id="print-creation">
    <h5>
      <fa-icon icon="plus"></fa-icon>
      Ajout de parution
    </h5>
    <form>
      <div class="form-element" id="support-choice">
        <label>Nom du support</label>
        <mat-form-field appearance="outline">
          <mat-select name="selected-paru" [(ngModel)]="selectedChoice">
            <mat-option *ngFor="let paru of options; let i = index" (click)="setSupportType(i)" [value]="i">
              {{ paru.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-element" id="add-photo">
        <label>Ajout de photo</label>
        <mat-form-field appearance="outline">
          <mat-select name="need-photo" [(ngModel)]="currentParu.needPhoto">
            <mat-option [value]="true">
              Avec photo
            </mat-option>
            <mat-option [value]="false">
              Sans photo
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-element date-picker-container">
        <label>Date de fin de parution</label>
        <mat-form-field appearance="outline" id="date-picker">
          <input
            [(ngModel)]="currentParu.date"
            name="date-parution"
            matInput
            [matDatepickerFilter]="filterOneDay.bind(this)"
            [matDatepicker]="picker"
            placeholder="DD/MM/YYYY"
            [min]="tomorrow"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </form>
    <div class="action">
      <button (click)="backToList()" class="btn">Annuler</button>
      <button *ngIf="!editing" (click)="addSupport()" class="btn btn-primary">Ajouter</button>
      <button *ngIf="editing" (click)="saveEditSupport()" class="btn btn-primary">Modifier</button>
    </div>
  </div>
</div>
