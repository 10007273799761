var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionTypes } from './ui.actions';
var initialUIState = {
    showpacks: false,
    isMobile: false,
    isTablette: false,
    language: 'fr',
    step1IsValid: false,
    step2IsValid: false,
    step3IsValid: false,
    step4IsValid: false,
    iAgree: false,
    packsWithPrices: {},
    isOnline: navigator.onLine,
    resetStepper: false,
    showMoreServices: false
};
export function reducer(state, action) {
    if (state === void 0) { state = initialUIState; }
    switch (action.type) {
        case ActionTypes.SET_SHOWPACKS:
            return __assign({}, state, { showpacks: action.payload });
        case ActionTypes.SET_ISMOBILE:
            return __assign({}, state, { isMobile: action.payload });
        case ActionTypes.SET_ISTABLETTE:
            return __assign({}, state, { isTablette: action.payload });
        case ActionTypes.SET_LANGUAGE:
            return __assign({}, state, { language: action.payload });
        case ActionTypes.SET_STEP1VALID:
            return __assign({}, state, { step1IsValid: action.payload });
        case ActionTypes.SET_STEP2VALID:
            return __assign({}, state, { step2IsValid: action.payload });
        case ActionTypes.SET_STEP3VALID:
            return __assign({}, state, { step3IsValid: action.payload });
        case ActionTypes.SET_STEP4VALID:
            return __assign({}, state, { step4IsValid: action.payload });
        case ActionTypes.SET_IAGREE:
            return __assign({}, state, { iAgree: !state.iAgree });
        case ActionTypes.SET_PACKSWITHPRICES:
            return __assign({}, state, { packsWithPrices: action.payload });
        case ActionTypes.SET_ONLINE:
            return handleIsOnline(state, action);
        case ActionTypes.SET_RESETSTEPPER:
            return __assign({}, state, { resetStepper: action.payload });
        case ActionTypes.RESET_UI:
            return __assign({}, state, { showpacks: false, iAgree: false, packsWithPrices: {} });
        case ActionTypes.SHOWMORESERVICES:
            return __assign({}, state, { showMoreServices: action.payload });
        default:
            return __assign({}, state);
    }
}
function handleIsOnline(state, action) {
    return __assign({}, state, { isOnline: action.payload });
}
