import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrderSteppersComponent } from './order-steppers/order-steppers.component';
import { Error404Component } from './error404/error404.component';
import { OrderResolver } from './order-steppers/order-resolver.service';
import { HomepageComponent } from './homepage/homepage.component';
import { ServicePebComponent } from './page-services/service-peb/service-peb.component';
import { ServiceElecComponent } from './page-services/service-elec/service-elec.component';
import { ServicePebElecComponent } from './page-services/service-peb-elec/service-peb-elec.component';
import { ServiceAuditComponent } from './page-services/service-audit/service-audit.component';
import { ServiceGazComponent } from './page-services/service-gaz/service-gaz.component';
import { ServiceResponsablePebComponent } from './page-services/service-responsable-peb/service-responsable-peb.component';
import { ServiceElecGazComponent } from './page-services/service-elec-gaz/service-elec-gaz.component';
import { ServiceCiterneComponent } from './page-services/service-citerne/service-citerne.component';
import { ServicePebChauffageComponent } from './page-services/service-peb-chauffage/service-peb-chauffage.component';
import { ServicePlansComponent } from './page-services/service-plans/service-plans.component';
import { ServiceReceptionChaudiereComponent } from './page-services/service-reception-chaudiere/service-reception-chaudiere.component';
import { ServiceDroneComponent } from './page-services/service-drone/service-drone.component';
import { ServiceConseillerPebComponent } from './page-services/service-conseiller-peb/service-conseiller-peb.component';
import { ServiceElecNonDomComponent } from './page-services/service-elec-non-dom/service-elec-non-dom.component';

const routes: Routes = [
  // { path: '', redirectTo: '/order', pathMatch: 'full' },
  { path: '', component: OrderSteppersComponent, resolve: { prices: OrderResolver } },
  { path: 'checkout', loadChildren: '../app/checkout/checkout.module#CheckoutModule' },
  { path: 'homepage', component: HomepageComponent },
  { path: 'error', component: Error404Component },
  { path: 'services/peb', component: ServicePebComponent },
  { path: 'services/controle-electrique', component: ServiceElecComponent },
  { path: 'services/peb-controle-electrique', component: ServicePebElecComponent },
  { path: 'services/controle-electrique-gaz', component: ServiceElecGazComponent },
  { path: 'services/audit', component: ServiceAuditComponent },
  { path: 'services/controle-gaz', component: ServiceGazComponent },
  { path: 'services/controle-citerne', component: ServiceCiterneComponent },
  { path: 'services/peb-chauffage', component: ServicePebChauffageComponent },
  { path: 'services/plans', component: ServicePlansComponent },
  { path: 'services/reception-chaudiere', component: ServiceReceptionChaudiereComponent },
  { path: 'services/drone', component: ServiceDroneComponent },
  { path: 'services/responsable-peb', component: ServiceResponsablePebComponent },
  { path: 'services/conseiller-peb', component: ServiceConseillerPebComponent },
  { path: 'services/controle-non-domestique', component: ServiceElecNonDomComponent },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [OrderResolver]
})
export class AppRoutingModule {}
