import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-agency-welcome',
  templateUrl: './agency-welcome.component.html',
  styleUrls: ['./agency-welcome.component.scss']
})
export class AgencyWelcomeComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { dialog: MatDialog }
  ) {}

  ngOnInit() {}

  closeModal() {
    this.data.dialog.closeAll();
  }
}
