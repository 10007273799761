<div class="service-page" id="service-page-elec-industry">
  <header>
    <h1>
      {{ 'service-page.elec-industry.title' | translate }}
    </h1>
    <picture class="service-page-banner">
      <img src="../../../assets/images/service-page-elec-non-dom.jpg" alt=""
    /></picture>
  </header>
  <div class="content">
    <h2>
      {{ 'service-page.elec-industry.intro' | translate }}
    </h2>
    <div [innerHTML]="'service-page.elec-industry.content' | translate"></div>
    <!-- <div [innerHTML]="'service-page.elec.content' | translate"></div>

    <a class="btn btn-big btn-accent" [routerLink]="['/']" [queryParams]="{ r: 1, e: 4, p: 4 }">
      {{ 'service-page.elec.action' | translate }}
    </a> -->
  </div>
</div>
