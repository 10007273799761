<div class="service-page" id="service-page-plans">
  <header>
    <h1>
      {{ 'service-page.plans.title' | translate }}
    </h1>
    <picture class="service-page-banner"><img src="../../../assets/images/service-page-plan.jpg" alt=""/></picture>
  </header>
  <div class="content">
    <h2>
      {{ 'service-page.plans.intro' | translate }}
    </h2>
    <div [innerHTML]="'service-page.plans.content' | translate"></div>
    <a class="btn btn-big btn-accent" (click)="toOrdering()">
      {{ 'service-page.plans.action' | translate }}
    </a>
    <p id="service-plans-info">{{ 'service-page.plans.info' | translate }}</p>
    <div id="plan-example-container">
      <img src="../../../assets/images/service-page-plan-01.jpg" alt="" />
      <img src="../../../assets/images/service-page-plan-02.jpg" alt="" />
      <img src="../../../assets/images/service-page-plan-03.jpg" alt="" />
      <img src="../../../assets/images/service-page-plan-04.jpg" alt="" />
    </div>
  </div>
</div>
