import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-service-elec',
  templateUrl: './service-elec.component.html',
  styleUrls: ['./service-elec.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServiceElecComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {}
  toOrdering() {
    this.router.navigate(['/']);
  }
}
