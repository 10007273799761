<div *ngIf="!this.sent" class="request-container">
  <header>
    <h5>
      <fa-icon icon="file-invoice"></fa-icon>
      {{ 'audit-request-dialog.title' | translate }}
    </h5>
    <p>
      {{ 'audit-request-dialog.intro' | translate }}
    </p>
  </header>
  <form (ngSubmit)="submit()" [formGroup]="auditFG">
    <div class="contact-section">
      <h6>
        {{ 'section-user.disco.your-info' | translate }}
      </h6>
      <mat-form-field class="devis-firstname" appearance="outline">
        <mat-label>{{ 'section-user.disco.firstname' | translate }}</mat-label>
        <input
          matInput
          placeholder="Jane"
          name="firstname"
          autocomplete="given-name"
          formControlName="firstName"
          required
        />
        <!-- <mat-error *ngIf="devisFormGroup.get('firstName')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error> -->
      </mat-form-field>
      <mat-form-field class="devis-lastname" appearance="outline">
        <mat-label>{{ 'section-user.disco.lastname' | translate }}</mat-label>
        <input
          matInput
          placeholder="Doe"
          name="lastname"
          autocomplete="family-name "
          formControlName="lastName"
          required
        />
        <!-- <mat-error *ngIf="devisFormGroup.get('lastName')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error> -->
      </mat-form-field>
      <mat-form-field class="devis-phone" appearance="outline">
        <mat-label>{{ 'section-user.disco.phone' | translate }}</mat-label>
        <input
          matInput
          placeholder="0123 45 67 89"
          type="tel"
          name="phone"
          autocomplete="tel"
          formControlName="phone"
          required
        />
        <!-- <mat-error *ngIf="devisFormGroup.get('phone')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error> -->
      </mat-form-field>
      <mat-form-field class="devis-mail" appearance="outline">
        <mat-label>{{ 'section-user.disco.email' | translate }}</mat-label>
        <input
          matInput
          placeholder="youremail@example.com"
          type="email"
          name="email"
          autocomplete="email"
          formControlName="email"
          required
        />
        <!-- <mat-error *ngIf="devisFormGroup.get('email')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error> -->
      </mat-form-field>
    </div>
    <div class="property-section">
      <h6>
        {{ 'section-propriete.title' | translate }}
      </h6>
      <mat-form-field class="devis-street" name="street" appearance="outline">
        <mat-label>{{ 'section-user.disco.street' | translate }}</mat-label>
        <input matInput placeholder="Rue de l'exemple" formControlName="userStreet" required />
        <!-- <mat-error *ngIf="devisFormGroup.get('userStreet')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error> -->
      </mat-form-field>
      <mat-form-field class="devis-num" appearance="outline">
        <mat-label>{{ 'section-user.disco.num' | translate }}</mat-label>
        <input matInput placeholder="1" autocomplete="off" formControlName="userNum" required />
        <!-- <mat-error *ngIf="devisFormGroup.get('userNum')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error> -->
      </mat-form-field>
      <mat-form-field class="devis-postalcode" appearance="outline">
        <mat-label>{{ 'section-user.disco.zip' | translate }}</mat-label>
        <input matInput placeholder="1234" formControlName="userZip" required />
        <!-- <mat-error *ngIf="devisFormGroup.get('userZip')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error> -->
      </mat-form-field>
      <mat-form-field class="devis-city" appearance="outline">
        <mat-label>{{ 'section-user.disco.city' | translate }}</mat-label>
        <input matInput placeholder="Exemple ville" formControlName="userCity" required />
        <!-- <mat-error *ngIf="devisFormGroup.get('userCity')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error> -->
      </mat-form-field>
    </div>
    <div class="type-section">
      <h6>
        {{ 'section-type.title' | translate }} : <span>{{ estateName }}</span>
      </h6>
    </div>
    <!-- <div class="product-section">
      <h6>Votre demande</h6>
      <div>
        <label>Spécifiez votre demande</label>
        <mat-form-field appearance="outline">
          <mat-select>
            <mat-option>
              audit logement
            </mat-option>
            <mat-option>
              mission complémentaire
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div> -->
    <button type="submit" class="btn btn-primary">
      {{ 'specific-request-dialog.send-request-btn' | translate }}
    </button>
  </form>
  <!-- <div class="content">
    <form (ngSubmit)="onSubmit()" [formGroup]="devisFormGroup">
      <mat-form-field class="devis-firstname" appearance="outline">
        <mat-label>{{ 'section-user.disco.firstname' | translate }}</mat-label>
        <input
          matInput
          placeholder="Jane"
          name="firstname"
          autocomplete="given-name"
          formControlName="firstName"
          required
        />
        <mat-error *ngIf="devisFormGroup.get('firstName')?.hasError('required')">
          {{ 'error-messages.required-blank' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="devis-lastname" appearance="outline">
        <mat-label>{{ 'section-user.disco.lastname' | translate }}</mat-label>
        <input
          matInput
          placeholder="Doe"
          name="lastname"
          autocomplete="family-name "
          formControlName="lastName"
          required
        />
        <mat-error *ngIf="devisFormGroup.get('lastName')?.hasError('required')">
          {{ 'error-messages.required-blank' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="devis-phone" appearance="outline">
        <mat-label>{{ 'section-user.disco.phone' | translate }}</mat-label>
        <input
          matInput
          placeholder="0123 45 67 89"
          type="tel"
          name="phone"
          autocomplete="tel"
          formControlName="phone"
          required
        />
        <mat-error *ngIf="devisFormGroup.get('phone')?.hasError('required')">
          {{ 'error-messages.required-blank' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="devis-mail" appearance="outline">
        <mat-label>{{ 'section-user.disco.email' | translate }}</mat-label>
        <input
          matInput
          placeholder="youremail@example.com"
          type="email"
          name="email"
          autocomplete="email"
          formControlName="email"
          required
        />
        <mat-error *ngIf="devisFormGroup.get('email')?.hasError('required')">
          {{ 'error-messages.required-blank' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="devis-street" name="street" appearance="outline">
        <mat-label>{{ 'section-user.disco.street' | translate }}</mat-label>
        <input matInput placeholder="Rue de l'exemple" formControlName="userStreet" required />
        <mat-error *ngIf="devisFormGroup.get('userStreet')?.hasError('required')">
          {{ 'error-messages.required-blank' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="devis-num" appearance="outline">
        <mat-label>{{ 'section-user.disco.num' | translate }}</mat-label>
        <input matInput placeholder="1" autocomplete="off" formControlName="userNum" required />
        <mat-error *ngIf="devisFormGroup.get('userNum')?.hasError('required')">
          {{ 'error-messages.required-blank' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="devis-postalcode" appearance="outline">
        <mat-label>{{ 'section-user.disco.zip' | translate }}</mat-label>
        <input matInput placeholder="1234" formControlName="userZip" required />
        <mat-error *ngIf="devisFormGroup.get('userZip')?.hasError('required')">
          {{ 'error-messages.required-blank' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="devis-city" appearance="outline">
        <mat-label>{{ 'section-user.disco.city' | translate }}</mat-label>
        <input matInput placeholder="Exemple ville" formControlName="userCity" required />
        <mat-error *ngIf="devisFormGroup.get('userCity')?.hasError('required')">
          {{ 'error-messages.required-blank' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="text-area">
        <textarea
          matInput
          formControlName="remarks"
          cdkTextareaAutosize
          cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="5"
          placeholder="{{ 'step4.section-comment.placeholder' | translate }}"
        ></textarea>
      </mat-form-field>
      <button type="submit" class="btn btn-primary">
        {{ 'specific-request-dialog.send-request-btn' | translate }}
      </button>
    </form>
  </div> -->
</div>
<div class="confirm-container" *ngIf="this.sent">
  <picture>
    <fa-icon icon="check"></fa-icon>
  </picture>
  <h6>{{ 'specific-request-dialog.success-title' | translate }}</h6>
  <p>
    {{ 'specific-request-dialog.success-content' | translate }}
  </p>
  <button (click)="closeDialog()" class="btn">{{ 'specific-request-dialog.success-action' | translate }}</button>
</div>
