<div class="service-page" id="service-page-cons-peb">
  <header>
    <h1>
      {{ 'service-page.cons-peb.title' | translate }}
    </h1>
    <picture class="service-page-banner"> <img src="../../../assets/images/service-page-cons-peb.jpg" alt=""/></picture>
  </header>
  <div class="content">
    <h2>
      {{ 'service-page.cons-peb.intro' | translate }}
    </h2>
    <div id="service-cons-peb-content" [innerHTML]="'service-page.cons-peb.content' | translate"></div>
  </div>
</div>
