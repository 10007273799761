import { PackDB } from './../models/PackDB';
import { Injectable } from '@angular/core';

import { estates, services, immoServices, regions, estateStyles, servicesSupp, displayedPacks } from '../data/';
import * as estateTypes from '../data/estates';
import * as PRODUCTS from '../data/products';

import { HttpClient } from '@angular/common/http';
import { ProductDB } from '../models/productDB';
import { Store } from '@ngrx/store';
import { AppState } from '../../interfaces';

import * as orderSelector from '../store/order/order.selectors';

import { Observable, of, from } from 'rxjs';
import { Region } from '../models/region';
import { map, tap } from 'rxjs/operators';
import { PromoCodeDB } from '../models/PromoCodeDB';
import { ServiceType } from '../models/serviceType';
import { CustomPrice, UserType } from '../models/user';
import { SetCommission } from '../store/order';
import { Products } from '../enums/product.enum';
import { PricingPlans } from '../enums/pricing-plan.enum';
import { OrderInfoDTO } from '../models/OrderInfoDTO';
import { getPricesID, projectID } from '../data/theme-config';
import { packsTheme } from '../data/packs';
import { Print } from '../models/print';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  promoCodeURL = 'https://systems.certinergie.be/api/PackPromo/GetPromocode';
  packsURL = 'https://systems.certinergie.be/api/PackPromo/Getpack';
  // apiPricesURL = 'https://systems.certinergie.be/api/PackPromo/GetPrices';
  apiPricesURL = 'https://systems.certinergie.be/api/PackPromo/GetPrices?ordertype=' + getPricesID;
  apiGetOrderInfoURL = 'https://systems.certinergie.be/api/PackPromo/GetOrder?OrderID=';

  regionId: number;
  estateId: number;
  products: ProductDB[];
  customPrices: CustomPrice[];
  packsDB: PackDB[];
  userPricingPlan: number;
  userType: number;
  nbOfApp: number;
  parutions: Print[];
  debouncer: any;

  projectID = projectID;

  packsTheme = packsTheme;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store.select(orderSelector.getRegion).subscribe(r => (this.regionId = r));
    this.store.select(orderSelector.getEstateID).subscribe(e => (this.estateId = e));
    this.store.select(orderSelector.getUserType).subscribe(u => (this.userType = u));
    this.store.select(orderSelector.getCustomPrices).subscribe(cp => (this.customPrices = cp));
    this.store.select(orderSelector.getNbOfAppartments).subscribe(n => (this.nbOfApp = n));
    this.store.select(orderSelector.getPricingPlan).subscribe(pp => (this.userPricingPlan = pp));
    this.store.select(orderSelector.getParutions).subscribe(p => (this.parutions = p));
    this.getPriceFromAPI().subscribe(pr => {
      this.products = pr;
    });
    this.getPacksFromAPI().subscribe(p => {
      if (projectID === 'mdn') {
        this.packsDB = [
          {
            estate: null,
            id: null,
            name: 'All in',
            reduc: 0,
            region: null,
            services: [1, 4, 18, 21, 26],
            show: true,
            userRoles: [],
            userTypes: [],
            servicesPrice: 0,
            servicesRed: 0,
            totalWithReduc: 0,
            description: '2001'
          }
        ];
      } else {
        this.packsDB = p;
      }
    });
  }

  isGreenfish() {
    return this.projectID === 'greenfish';
  }

  updatePricesOnResolve(pr: ProductDB[]) {
    this.products = pr;
  }

  getOrderInfo(orderid: string): Observable<any> {
    if (!this.isGuid(orderid)) {
      return of(false);
    }
    return this.http.get<any>(this.apiGetOrderInfoURL + orderid);
  }

  getPriceFromAPI(): Observable<any> {
    return this.http.get<any>(this.apiPricesURL).pipe(map(data => data.Data));
  }

  getPacksFromAPI(): Observable<PackDB[]> {
    let res = from([this.packsTheme]);
    if (this.projectID === 'default') {
      res = this.http
        .get<any>(this.packsURL)
        .pipe(
          map(data =>
            displayedPacks.length > 0
              ? data.Data.filter(p => displayedPacks.some(id => id === +p.description))
              : data.Data
          )
        );
    }
    return res.pipe(tap());
  }

  getPromoCodesFromAPI(): Observable<PromoCodeDB[]> {
    return this.http.get<any>(this.promoCodeURL).pipe(map(data => data.Data));
  }

  isPromoCodeValid(promoCode: string, productsID: number[], region: number): Promise<PromoCodeDB> {
    clearTimeout(this.debouncer);

    return new Promise((resolve, reject) => {
      this.debouncer = setTimeout(() => {
        if (promoCode && promoCode !== '') {
          this.getPromoCodesFromAPI().subscribe(data => {
            if (data) {
              const f = data.filter(
                p => p.KeyWord === promoCode && (p.PromoCode.region === region || !p.PromoCode.region)
              );
              const found = f.filter(p => p.PromoCode.expertises.every(pr => productsID.indexOf(pr) >= 0))[0];
              // console.log('found code', found);
              // console.log('codes', data);
              resolve(found);
            } else {
              console.log(`🚀`);
              resolve(null);
            }
          });
        } else {
          resolve(null);
        }
      }, 200);
    });
  }

  /* async getPriceFromAPIForProductId(productTypeId: number): Promise<ProductDB[]> {
    return this.http
      .post<any>('http://localhost:64359/api/PackPromo/GetPrices', {
        Region: this.regionId,
        EstateType: this.estateId,
        ProductType: productTypeId
      })
      .pipe(map(data => data.Data))
      .toPromise();
  } */

  getProduct(productTypeId: number): ProductDB {
    const prices = this.products;
    if (!prices) {
      return new ProductDB();
    }
    const tmp = prices;
    const filteredPrices = [...tmp];

    const regionId = this.regionId;
    const estateTypeId = this.estateId;

    const filteredPrices1 = filteredPrices.find(
      p => p.Region === regionId && p.EstateType === estateTypeId && p.ProductType === productTypeId
    );
    const filteredPrices2 = filteredPrices.find(
      p =>
        (p.Region === regionId || p.Region === null) &&
        (p.EstateType === estateTypeId || p.EstateType === null) &&
        (p.ProductType === productTypeId || p.ProductType === null)
    );

    const dbPrice = !filteredPrices1
      ? !filteredPrices2
        ? <ProductDB>{ Price: 0, Reduction: 0, OrderPriceID: 0, OrderType: 1, ProductType: 27 }
        : filteredPrices2
      : filteredPrices1;

    return dbPrice;
  }

  getProductPrice(id: number) {
    if (id === 11111) {
      return { price: 100, reduc: 0 };
    }
    if (id === PRODUCTS.PRINT) {
      let paruPrice = 0;
      this.parutions.forEach(paru => (paruPrice += paru.price));
      return { price: paruPrice, reduc: 0 };
    }
    let prod = this.getProduct(id);
    const pr = { price: prod.Price, reduc: prod.Reduction };

    if (this.userPricingPlan === 0 && this.userType !== UserType.Particulier && prod.ReductionPro) {
      console.log('⚡️ ReductionPro');
      prod.ReductionPro = this.getStatutReduc(prod.ProductType, prod.ReductionPro, prod);

      pr.reduc = prod.ReductionPro;
    }
    prod.Reduction = this.getStatutReduc(prod.ProductType, prod.Reduction, prod);

    const newDbPrice = this.checkCustomPrice(id, prod);

    prod = newDbPrice ? newDbPrice : prod;

    /* if (id === PRODUCTS.PEB && this.estateId === estateTypes.IMMEUBLE_APPART && this.nbOfApp > 0) {
      const newPrice = prod.Price + 100 * (this.nbOfApp - 1);
      return { price: newPrice, reduc: prod.Reduction };
    } */

    return { price: prod.Price, reduc: prod.Reduction };
  }

  checkCustomPrice(productTypeId, dbPrice) {
    let newDbPrice = null;

    const customP = this.customPrices
      ? this.customPrices.find(
          cp => cp.region === this.regionId && cp.estateType === this.estateId && cp.productTypeId === productTypeId
        )
      : null;

    if (customP) {
      if (customP.commission > 0) {
        this.store.dispatch(new SetCommission(customP.commission));
      }
      newDbPrice = { ...dbPrice };
      newDbPrice.Reduction = dbPrice.Price - customP.price;
    }

    return newDbPrice;
  }

  getEstateTypes() {
    return estates;
  }

  getEstateStyles() {
    return estateStyles;
  }

  getServiceTypes(): ServiceType[] {
    return [...services, ...servicesSupp];
  }

  getImmoServiceTypes(): ServiceType[] {
    return immoServices;
  }

  getImmoPacks() {
    try {
      if (this.regionId === -1 || this.estateId === -1) {
        return null;
      }
      if (this.packsDB === undefined) {
        // If this happens, resolve data ? Or Subscribe to packs when we need to getImmoPacks()
        console.log('🔥🔥🔥🔥 packs undefined 🔥🔥🔥🔥');
        throw new Error('🔥🔥🔥🔥 packs undefined 🔥🔥🔥🔥');
        // return [];
      }
    } catch (e) {}
    let p1: PackDB[] = [];
    let p2: PackDB[] = [];
    let p3: PackDB[] = [];
    let p4: PackDB[] = [];

    if (!!this.packsDB) {
      this.packsDB = this.packsDB
        .filter(p => p.description !== null)
        .sort((a, b) => parseInt(a.description, 10) - parseInt(b.description, 10));
      p1 = this.packsDB.filter(p => p.region === this.regionId && p.estate === this.estateId);
      p2 = this.packsDB.filter(p => p.region === this.regionId && (p.estate === -1 || p.estate === null));
      p3 = this.packsDB.filter(p => p.region === null && p.estate === this.estateId);
      p4 = this.packsDB.filter(p => p.region === null && p.estate === null);
    }

    const packs: PackDB[] = [...p1, ...p2, ...p3, ...p4];

    return packs;
  }

  getPacksToDisplay() {
    if (this.regionId === -1 || this.estateId === -1) {
      return null;
    }
    this.packsDB = this.packsDB.filter(p => p.description !== null);
    const p1 = this.packsDB.filter(p => p.region === this.regionId && p.estate === this.estateId);
    const p2 = this.packsDB.filter(p => p.region === this.regionId && p.estate === -1);
    const p3 = this.packsDB.filter(p => p.region === null && p.estate === this.estateId);
    const p4 = this.packsDB.filter(p => p.region === null && p.estate === null);

    const packs: PackDB[] = [...p1, ...p2, ...p3, ...p4];

    return packs;
  }

  getPackPrice(packID: number): PackDB {
    if (this.regionId === -1 || this.estateId === -1) {
      return null;
    }
    const p1 = this.packsDB.filter(p => p.region === this.regionId && p.estate === this.estateId);
    const p2 = this.packsDB.filter(p => p.region === this.regionId && p.estate === -1);
    const p3 = this.packsDB.filter(p => p.region === null && p.estate === this.estateId);
    const p4 = this.packsDB.filter(p => p.region === null && p.estate === null);

    const packs: PackDB[] = [...p1, ...p2, ...p3, ...p4];

    const pack = packs.find(p => parseInt(p.id, 10) === packID);

    return pack;
  }

  getProductsForPack(id: string) {
    const packs = this.getImmoPacks();
    const pack = packs.filter(p => p.description === id);
    return pack[0].services;
  }

  getProductsAllIn() {
    return [
      PRODUCTS.PEB,
      PRODUCTS.ELEC,
      PRODUCTS.PHOTO,
      PRODUCTS.VISITE360,
      PRODUCTS.PLAN3D,
      PRODUCTS.DESCRIPTIF,
      PRODUCTS.PLAQUETTE
    ];
  }

  getRegionTypes(): Region[] {
    return regions;
  }

  getRegionByID(id: number) {
    return this.getRegionTypes().find(r => r.id === id);
  }

  getStatutReduc(id: number, reduc: number, prod: ProductDB): number {
    const plandID = this.userPricingPlan;
    if (plandID === 0) {
      return reduc;
    }
    if (
      id === Products.PEB ||
      id === Products.ELEC ||
      id === Products.CITERNE_AERIENNE ||
      id === Products.CITERNE_ENTERREE ||
      id === Products.GAZ_CONTROLE
    ) {
      switch (plandID) {
        case PricingPlans.BRONZE:
          return prod.ReductionBronze;
          break;

        case PricingPlans.SILVER:
          return prod.ReductionSilver;
          break;

        case PricingPlans.GOLD:
          return prod.ReductionGold;
          break;

        case PricingPlans.PLATINUM:
          return prod.ReductionPlatinum;
          break;

        case PricingPlans.BRONZE_FLANDRE:
          return prod.ReductionBronze;
          break;

        case PricingPlans.SILVER_FLANDRE:
          return prod.ReductionSilver;
          break;

        case PricingPlans.GOLD_FLANDRE:
          return prod.ReductionGold;
          break;

        case PricingPlans.PLATINUM:
          return prod.ReductionPlatinum;
          break;

        default:
          break;
      }
    } else if (id === Products.PEB_ELEC && plandID !== 0) {
      switch (plandID) {
        case PricingPlans.BRONZE:
          return 25;
          break;

        case PricingPlans.SILVER:
          return 25;
          break;

        case PricingPlans.GOLD:
          return 25;
          break;

        case PricingPlans.PLATINUM:
          return 25;
          break;

        case PricingPlans.BRONZE_FLANDRE:
          return 25;
          break;

        case PricingPlans.SILVER_FLANDRE:
          return 25;
          break;

        case PricingPlans.GOLD_FLANDRE:
          return 25;
          break;

        case PricingPlans.PLATINUM_FLANDRE:
          return 25;
          break;

        default:
          break;
      }
    }
    return reduc;
  }

  public isGuid(stringToTest) {
    const regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(stringToTest);
  }
}
