export const reasons = [
  {
    id: -1,
    txt: 'Sélectionnez le type de vente',
    fileName: 'reason-type'
  },
  {
    id: 0,
    txt: 'Vente publique',
    fileName: 'reason-vente'
  },
  {
    id: 1,
    txt: 'Gré à gré',
    fileName: 'reason-gregre'
  },
  {
    id: 2,
    txt: 'Vente Biddit',
    fileName: 'reason-biddit'
  }
];
