import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-service-responsable-peb',
  templateUrl: './service-responsable-peb.component.html',
  styleUrls: ['./service-responsable-peb.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServiceResponsablePebComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
