var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Address, AddressDB } from './address';
var User = /** @class */ (function () {
    function User() {
        this.tva = '';
        this.address = new Address();
    }
    return User;
}());
export { User };
var CustomPrice = /** @class */ (function () {
    function CustomPrice(price, commission, region, estateType, productTypeId) {
        this.price = price;
        this.commission = commission;
        this.region = region;
        this.estateType = estateType;
        this.productTypeId = productTypeId;
    }
    return CustomPrice;
}());
export { CustomPrice };
export var UserType;
(function (UserType) {
    UserType[UserType["Particulier"] = 1688] = "Particulier";
    UserType[UserType["Agence"] = 1689] = "Agence";
    UserType[UserType["Certificateur"] = 1690] = "Certificateur";
    UserType[UserType["Notaire"] = 8122] = "Notaire";
    UserType[UserType["Installateur"] = 1691] = "Installateur";
    UserType[UserType["Special"] = 1692] = "Special";
})(UserType || (UserType = {}));
export var ReasonType;
(function (ReasonType) {
    ReasonType[ReasonType["Vente"] = 0] = "Vente";
    ReasonType[ReasonType["Location"] = 1] = "Location";
    ReasonType[ReasonType["Renovation"] = 2] = "Renovation";
    ReasonType[ReasonType["Controle"] = 3] = "Controle";
})(ReasonType || (ReasonType = {}));
export var UserRole;
(function (UserRole) {
    UserRole["ImmoAccess"] = "c8909f2b-1e5d-4758-b920-a2b4f6114c90";
})(UserRole || (UserRole = {}));
var UserDB = /** @class */ (function () {
    function UserDB() {
        this.Address = new AddressDB();
    }
    return UserDB;
}());
export { UserDB };
var Role = /** @class */ (function () {
    function Role(RoleID, Name) {
        this.RoleID = RoleID;
        this.Name = Name;
    }
    return Role;
}());
export { Role };
var ConsumerDB = /** @class */ (function () {
    function ConsumerDB() {
        this.ConsumerType = 1688;
        this.Commission = false;
        this.Status = 0;
        this.ReceiveByEmail = true;
    }
    return ConsumerDB;
}());
export { ConsumerDB };
var ConsumerPriceDB = /** @class */ (function () {
    function ConsumerPriceDB(ConsumerAmount, ConsumerCommission, ConsumerRegion, EstateType, ProductTypeId) {
        this.ConsumerAmount = ConsumerAmount;
        this.ConsumerCommission = ConsumerCommission;
        this.ConsumerRegion = ConsumerRegion;
        this.EstateType = EstateType;
        this.ProductTypeId = ProductTypeId;
    }
    return ConsumerPriceDB;
}());
var Contact = /** @class */ (function () {
    function Contact() {
        this.firstname = '';
        this.lastname = '';
        this.phone = '';
    }
    return Contact;
}());
export { Contact };
var Owner = /** @class */ (function () {
    function Owner() {
        this.firstname = '';
        this.lastname = '';
        this.phone = '';
        this.email = '';
    }
    return Owner;
}());
export { Owner };
var UserForm = /** @class */ (function (_super) {
    __extends(UserForm, _super);
    function UserForm() {
        var _this = _super.call(this) || this;
        _this.address = new Address();
        return _this;
    }
    return UserForm;
}(Owner));
export { UserForm };
