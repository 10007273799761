import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-service-elec-non-dom',
  templateUrl: './service-elec-non-dom.component.html',
  styleUrls: ['./service-elec-non-dom.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServiceElecNonDomComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
