import { Injectable } from '@angular/core';
import { AppState } from '../../interfaces';
import { Store } from '@ngrx/store';
import * as orderSelector from '../store/order/order.selectors';

import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, delay } from 'rxjs/operators';
import { throwError, Observable, Subject } from 'rxjs';
import { User, UserDB, Role, CustomPrice } from '../models/user';
import { ResetOrderState, SetUserInfo, ResetUserInfo } from '../store/order';
import { Router } from '@angular/router';
import { SetResetStepper, ResetUI } from '../store/ui';

const loginURL = `https://systems.certinergie.be/api/LoginWeb/LoginUser`;
const loginWithUserIDURL = `https://systems.certinergie.be/api/LoginWeb/LoginUserID`;
const emailCheckURL = `https://systems.certinergie.be/api/LoginWeb/CheckEmailAlreadyInUse`;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  immoAccess: boolean;
  userId: string;
  public loginStatus = new Subject<number>();

  constructor(private http: HttpClient, private store: Store<AppState>, private router: Router) {
    this.store.select(orderSelector.getUserID).subscribe(u => (this.userId = u));
  }

  private isLoggedIn(): Observable<boolean> {
    return this.store.select(orderSelector.getIsLoggedIn);
  }

  public login(email: string, password: string) {
    let logged = false;
    this.isLoggedIn().subscribe(bool => (logged = bool));

    if (logged) {
      return;
    }

    const httpParams = new HttpParams().append('login', email).append('password', password);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: httpParams
    };

    this.http
      .post<any>(loginURL, null, httpOptions)
      .pipe(catchError(this.handleError))
      .subscribe(data => {
        const userDB: UserDB = data.Data.User;
        // console.log(userDB);
        // 1 success - 2 pw invalid - 3 email doesn't exist
        const loginStatus = data.Data.LoginStatus;
        this.loginStatus.next(loginStatus);

        // this.store.dispatch(new SetIsLoggedIn(logged));

        if (loginStatus === 1) {
          const user = this.mapUserdbToUser(userDB);
          this.store.dispatch(new SetUserInfo(user));
        }
      });
  }

  public loginWithUserID(userid: string) {
    let logged = false;
    this.isLoggedIn().subscribe(bool => (logged = bool));

    if (this.userId === userid) {
      return;
    }

    const httpParams = new HttpParams().append('UserID', userid);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: httpParams
    };

    this.http
      .post<any>(loginWithUserIDURL, null, httpOptions)
      .pipe(
        delay(1100),
        catchError(this.handleError)
      )
      .subscribe(data => {
        const userDB: UserDB = data.Data.User;
        // console.log('userDB');
        // console.log(userDB);
        // 1 success - 2 pw invalid - 3 email doesn't exist
        const loginStatus = data.Data.LoginStatus;
        this.loginStatus.next(loginStatus);

        // this.store.dispatch(new SetIsLoggedIn(logged));

        if (loginStatus === 1) {
          const user = this.mapUserdbToUser(userDB);
          this.store.dispatch(new SetUserInfo(user));
        }
      });
  }

  orderSuccessLogin(userid: string) {
    this.logoutNoRedirect();
    this.loginWithUserID(userid);
  }

  public mapUserdbToUser(userDB: UserDB): User {
    const user = new User();

    user.userid = userDB.UserID;
    user.firstname = userDB.FirstName;
    user.lastname = userDB.Name;
    user.email = userDB.Email;
    user.tva = userDB.Address.VatNumber;
    user.phone = userDB.PhoneNumber;
    user.address.addressid = userDB.Address.AddressID;
    user.address.street = userDB.Address.Street;
    user.address.number = userDB.Address.Number;
    user.address.city = userDB.Address.City;
    user.address.zip = userDB.Address.PostalCode;

    user.sendByMail = !userDB.Consumer.ReceiveByEmail;
    user.userType = userDB.Consumer.ConsumerType;
    user.hasCommission = userDB.Consumer.Commission;
    user.pricingPlan = userDB.Consumer.Status;
    user.roles = userDB.Roles.map(r => new Role(r.RoleID, r.Name));

    user.customPrices = userDB.Consumer.ConsumerPrices.map(
      c =>
        <CustomPrice>{
          price: c.ConsumerAmount,
          commission: c.ConsumerCommission,
          estateType: c.EstateType,
          region: c.ConsumerRegion,
          productTypeId: c.ProductTypeId
        }
    );

    return user;
  }

  public logout() {
    this.router.navigate(['']);
    return this.logoutNoRedirect();
  }

  public logoutNoRedirect() {
    let logged;
    const sub = this.isLoggedIn().subscribe(bool => (logged = bool));

    if (true) {
      // this.store.dispatch(new SetIsLoggedIn(!logged));
      this.store.dispatch(new ResetUserInfo(null));
      this.store.dispatch(new ResetOrderState(null));
      this.store.dispatch(new SetResetStepper(true));
      this.store.dispatch(new ResetUI(true));
      this.router.navigate(['']);
    }
    sub.unsubscribe();
    return logged;
  }

  isEmailAlreadyInUse(email: string) {
    const httpParams = new HttpParams().append('email', email);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: httpParams
    };

    return this.http.post<any>(emailCheckURL, null, httpOptions).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
