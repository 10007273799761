<div class="carousel">
  <div class="carousel-element">
    <div class="carousel-element--content">
      <img src="../../assets/images/certinergie-homepage-icon.svg" alt="" />
      <h2>
        {{ 'homepage.carousel.title' | translate }}
      </h2>
      <h3>{{ 'homepage.carousel.subtitle' | translate }}</h3>
    </div>
  </div>
</div>
<section class="promo">
  <h5>{{ 'homepage.section-offres.title' | translate }}</h5>
  <div class="promo-container">
    <a [routerLink]="['/']" [queryParams]="{ r: 1, e: 4, p: 4 }" class="promo-block">
      <header>
        <picture>
          <img src="../../assets/images/homepage-elec.svg" alt="" />
        </picture>
        <h6>{{ 'homepage.section-offres.elec.title' | translate }}</h6>
      </header>
      <div class="promo-block-content">
        <p>{{ 'homepage.section-offres.elec.content' | translate }}</p>
        <div class="price-container">
          <span class="price">130</span>
          <div class="details-container">
            <span class="tvac">{{ 'homepage.section-offres.item.tvac' | translate }}</span>
            <span class="euro">€</span>
          </div>
        </div>
      </div>
      <button class="btn btn-primary">
        {{ 'homepage.section-offres.elec.action' | translate }}
      </button>
    </a>
    <a [routerLink]="['/']" [queryParams]="{ r: 1, e: 4, p: 1, c: 'ediscount' }" class="promo-block">
      <header>
        <picture>
          <img src="../../assets/images/homepage-peb.svg" alt="" />
        </picture>
        <h6>{{ 'homepage.section-offres.peb.title' | translate }}</h6>
      </header>
      <div class="promo-block-content">
        <p>{{ 'homepage.section-offres.peb.content' | translate }}</p>
        <div class="price-container">
          <span class="price">95</span>
          <div class="details-container">
            <span class="tvac">{{ 'homepage.section-offres.item.tvac' | translate }}</span>
            <span class="euro">€</span>
          </div>
        </div>
      </div>
      <button class="btn btn-primary">
        {{ 'homepage.section-offres.peb.action' | translate }}
      </button>
    </a>
    <a [routerLink]="['/']" [queryParams]="{ r: 1, e: 4, p: 15, c: 'ediscount' }" class="promo-block">
      <header>
        <picture>
          <img src="../../assets/images/homepage-gaz.svg" alt="" />
        </picture>
        <h6>{{ 'homepage.section-offres.gaz.title' | translate }}</h6>
      </header>
      <div class="promo-block-content">
        <p>{{ 'homepage.section-offres.gaz.content' | translate }}</p>
        <div class="price-container">
          <span class="price">115</span>
          <div class="details-container">
            <span class="tvac">{{ 'homepage.section-offres.item.tvac' | translate }}</span>
            <span class="euro">€</span>
          </div>
        </div>
      </div>
      <button class="btn btn-primary">
        {{ 'homepage.section-offres.gaz.action' | translate }}
      </button>
    </a>
    <a [routerLink]="['/']" [queryParams]="{ r: 1, e: 4, p: '1-4', c: 'ediscount' }" class="promo-block">
      <header>
        <picture>
          <img src="../../assets/images/homepage-peb-elec.svg" alt="" />
        </picture>
        <h6>{{ 'homepage.section-offres.combi.title' | translate }}</h6>
      </header>
      <div class="promo-block-content">
        <p>{{ 'homepage.section-offres.combi.content' | translate }}</p>
        <div class="price-container">
          <span class="price">85</span>
          <div class="details-container">
            <span class="tvac">{{ 'homepage.section-offres.item.tvac' | translate }}</span>
            <span class="euro">€</span>
          </div>
        </div>
      </div>
      <button class="btn btn-primary">
        {{ 'homepage.section-offres.combi.action' | translate }}
      </button>
    </a>
  </div>
  <div class="info-container">
    <div class="info-block-container">
      <article class="info-block">
        <header>
          <img src="../../assets/images/homepage-24h.svg" alt="" />
          <h6>{{ 'homepage.section-offres.24h.title' | translate }}</h6>
        </header>
        <p>{{ 'homepage.section-offres.24h.content' | translate }}</p>
      </article>
    </div>
    <div class="info-block-container">
      <article class="info-block">
        <header>
          <img src="../../assets/images/homepage-agenda.svg" alt="" />
          <h6>{{ 'homepage.section-offres.agenda.title' | translate }}</h6>
        </header>
        <p>{{ 'homepage.section-offres.agenda.content' | translate }}</p>
      </article>
    </div>
  </div>
</section>
<section class="services">
  <h5>{{ 'homepage.section-services.title' | translate }}</h5>
  <ul class="services-list" [ngClass]="showAll ? 'show-all' : ''">
    <li class="services-element">
      <a [routerLink]="['/services/peb']">
        <picture>
          <img src="../../assets/images/homepage-peb.svg" alt="" />
        </picture>
        <h6>{{ 'homepage.section-services.peb' | translate }}</h6>
      </a>
    </li>
    <li class="services-element">
      <a [routerLink]="['/services/controle-electrique']">
        <picture>
          <img src="../../assets/images/homepage-elec.svg" alt="" />
        </picture>
        <h6>{{ 'homepage.section-services.elec' | translate }}</h6>
      </a>
    </li>
    <li class="services-element combi-element">
      <a [routerLink]="['/services/peb-controle-electrique']">
        <picture>
          <img src="../../assets/images/homepage-peb-elec.svg" alt="" />
        </picture>
        <h6>{{ 'homepage.section-services.peb-elec' | translate }}</h6>
      </a>
    </li>
    <li class="services-element combi-element">
      <a [routerLink]="['/services/controle-electrique-gaz']">
        <picture>
          <img src="../../assets/images/homepage-elec-gaz.svg" alt="" />
        </picture>
        <h6>{{ 'homepage.section-services.elec-gaz' | translate }}</h6>
      </a>
    </li>
    <li class="services-element">
      <a [routerLink]="['/services/audit']">
        <picture>
          <img src="../../assets/images/homepage-audit.svg" alt="" />
        </picture>
        <h6>{{ 'homepage.section-services.audit' | translate }}</h6>
      </a>
    </li>
    <li class="services-element">
      <a [routerLink]="['/services/controle-gaz']">
        <picture>
          <img src="../../assets/images/homepage-gaz.svg" alt="" />
        </picture>
        <h6>{{ 'homepage.section-services.gaz' | translate }}</h6>
        <span class="new">{{ 'homepage.section-services.new' | translate }}</span>
      </a>
    </li>
    <li class="services-element">
      <a [routerLink]="['/services/controle-citerne']">
        <picture>
          <img src="../../assets/images/homepage-citerne.svg" alt="" />
        </picture>
        <h6>{{ 'homepage.section-services.citerne' | translate }}</h6>
      </a>
    </li>
    <li class="services-element">
      <a [routerLink]="['/services/peb-chauffage']">
        <picture>
          <img src="../../assets/images/homepage-peb-chauffage.svg" alt="" />
        </picture>
        <h6>{{ 'homepage.section-services.peb-chauff' | translate }}</h6>
        <span class="new">{{ 'homepage.section-services.new' | translate }}</span>
      </a>
    </li>
    <li class="services-element">
      <a [routerLink]="['/services/plans']">
        <picture>
          <img src="../../assets/images/homepage-plans.svg" alt="" />
        </picture>
        <h6>{{ 'homepage.section-services.plans' | translate }}</h6>
        <span class="new">{{ 'homepage.section-services.new' | translate }}</span>
      </a>
    </li>
    <li class="services-element">
      <a [routerLink]="['/services/peb-chauffage']">
        <picture>
          <img src="../../assets/images/homepage-chaudiere-reception.svg" alt="" />
        </picture>
        <h6>{{ 'homepage.section-services.recep-chaudiere' | translate }}</h6>
        <span class="new">{{ 'homepage.section-services.new' | translate }}</span>
      </a>
    </li>
    <li class="services-element">
      <a [routerLink]="['/services/drone']">
        <picture>
          <img src="../../assets/images/homepage-drone.svg" alt="" />
        </picture>
        <h6>{{ 'homepage.section-services.drone' | translate }}</h6>
      </a>
    </li>
    <li class="services-element">
      <a [routerLink]="['/services/responsable-peb']">
        <picture>
          <img src="../../assets/images/homepage-peb-responsabilite.svg" alt="" />
        </picture>
        <h6>{{ 'homepage.section-services.peb-resp' | translate }}</h6>
      </a>
    </li>
    <li class="services-element">
      <a [routerLink]="['/services/conseiller-peb']">
        <picture>
          <img src="../../assets/images/homepage-peb-conseiller.svg" alt="" />
        </picture>
        <h6>{{ 'homepage.section-services.peb-cons' | translate }}</h6>
      </a>
    </li>
    <li class="services-element">
      <a [routerLink]="['/services/controle-non-domestique']">
        <picture>
          <img src="../../assets/images/homepage-elec-industry.svg" alt="" />
        </picture>
        <h6>{{ 'homepage.section-services.elec-industry' | translate }}</h6>
      </a>
    </li>
  </ul>
  <button *ngIf="!showAll" class="btn btn-primary" (click)="showAll = !showAll">
    {{ 'homepage.section-services.show-more' | translate }}
    <fa-icon icon="angle-right"></fa-icon>
  </button>
  <button *ngIf="showAll" class="btn btn-primary" (click)="showAll = !showAll">
    {{ 'homepage.section-services.show-less' | translate }}
    <fa-icon icon="angle-right"></fa-icon>
  </button>
</section>
<section class="why-certinergie">
  <h5>{{ 'homepage.section-why.title' | translate }}</h5>
  <ul>
    <li>
      <img src="../../assets/images/homepage-agregation.svg" alt="" />
      <span>{{ 'homepage.section-why.agree' | translate }}</span>
    </li>
    <li class="combi">
      <img src="../../assets/images/homepage-combi.svg" alt="" />
      <span>{{ 'homepage.section-why.combi' | translate }}</span>
    </li>
    <li>
      <img src="../../assets/images/homepage-footer-agenda.svg" alt="" />
      <span>{{ 'homepage.section-why.agenda' | translate }}</span>
    </li>
    <li>
      <img src="../../assets/images/homepage-footer-24h.svg" alt="" />
      <span>{{ 'homepage.section-why.rapidite' | translate }}</span>
    </li>
    <li>
      <img src="../../assets/images/homepage-legislation.svg" alt="" />
      <span>{{ 'homepage.section-why.respect' | translate }}</span>
    </li>
  </ul>
</section>
