import { OnInit, ChangeDetectorRef, AfterViewChecked, AfterContentInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SimpleSmoothScrollService } from 'ng2-simple-smooth-scroll';
import { DataService } from '../core/services/data.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import * as orderSelector from '../core/store/order/order.selectors';
import * as uiSelector from '../core/store/ui/ui.selectors';
import { filterNewUserTypes } from '../core/data/theme-config';
import { SetEstateType, SetRegion, SetNumberOfAppartments, SetServices, SetNbOfElec, ToggleOnlinePayment, SetPromoCode, ToggleUrgence, SetCommission, SetEstateStyle, SetNbOfGaz, SetGazChoice, SetPebNb, SetNbOfIso, ResetOrderState, SetZipCode, SetReason, SetClientType, SetVenteInfo } from '../core/store/order';
import { combineLatest } from 'rxjs';
import * as estates from '../core/data/estates';
import * as PRODUCTS from '../core/data/products';
import { MatDialog, MatStepper } from '@angular/material';
import { LoginDialogComponent } from '../dialogs/login-dialog/login-dialog.component';
import { SpecificRequestDialogComponent } from '../dialogs/specific-request-dialog/specific-request-dialog.component';
import { SummaryConfirmDialogComponent } from '../dialogs/summary-confirm-dialog/summary-confirm-dialog.component';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { UserType } from '../core/models/user';
import { SetShowPacks, SetStep1Valid, SetPacksWithPrices, ShowMoreServices } from '../core/store/ui';
import { descriptifPacks, hasImmoAccessProject, hasAccessAllPack } from '../core/data';
import { DESCRIPTIF } from '../core/data/products';
import { ActivatedRoute } from '@angular/router';
import { map, debounceTime } from 'rxjs/operators';
import { GoogleAnalyticsService } from '../core/services/google-analytics.service';
import { displayRegionSelection, step1TitleImmo, step1SubTitle, headerShowPhone, showFinalOptions, projectID } from '../core/data/theme-config';
import { userTypePartAndPro } from '../core/data/newUserTypes';
import { reasons } from '../core/data/reasons';
import { imgagesFolder } from '../core/services/globals';
import { AuditRequestDialogComponent } from '../dialogs/audit-request-dialog/audit-request-dialog.component';
import { PrintRequestDialogComponent } from '../dialogs/print-request-dialog/print-request-dialog.component';
import { VisitRequestDialogComponent } from '../dialogs/visit-request-dialog/visit-request-dialog.component';
var Step1Component = /** @class */ (function () {
    function Step1Component(store, dataService, dialog, smooth, _formBuilder, breakpointObserver, cdRef, route, googleAnalyticsService) {
        var _this = this;
        this.store = store;
        this.dataService = dataService;
        this.dialog = dialog;
        this.smooth = smooth;
        this._formBuilder = _formBuilder;
        this.breakpointObserver = breakpointObserver;
        this.cdRef = cdRef;
        this.route = route;
        this.googleAnalyticsService = googleAnalyticsService;
        this.assetsFolder = 'assets/images/';
        this.pebNb = '';
        this.pebPartiel = false;
        this.wantsIso = false;
        this.hasPanel = false;
        this.hasPanelSupport = false;
        this.hasBidditPanel = false;
        this.hasBidditPanelSupport = false;
        this.showFinalOptions = showFinalOptions;
        this.minDate = new Date();
        this.ventePubChoices = [
            {
                id: 1,
                name: 'Première séance'
            },
            {
                id: 2,
                name: 'Séance ouverte'
            }
        ];
        this.venteInfo = {
            statusVentePub: '',
            venteDate: ''
        };
        this.displayRegionSelection = displayRegionSelection;
        this.step1TitleImmo = step1TitleImmo;
        this.projectID = projectID !== 'default' ? '-' + projectID : '';
        this.hasImmoAccessProject = hasImmoAccessProject;
        this.hasAccessAllPack = hasAccessAllPack;
        this.succintStep1 = false;
        this.step1SubTitle = step1SubTitle;
        this.headerShowPhone = headerShowPhone;
        this.houseControl = new FormControl();
        this.appartControl = new FormControl();
        this.buildingControl = new FormControl();
        this.situationControl = new FormControl();
        this.reasons = reasons;
        this.UserTypes = UserType;
        this.filterNewUserTypes = filterNewUserTypes;
        this.estates = ['m', 'a', 'i'];
        this.store.select(orderSelector.getServices).subscribe(function (data) {
            _this.products = data;
            _this.hasPanelSupport = data.some(function (p) { return p === PRODUCTS.PANEL_SUPPORT; });
            _this.hasPanel = data.some(function (p) { return p === PRODUCTS.PANEL || p === PRODUCTS.PANEL_SUPPORT; });
            _this.hasBidditPanelSupport = data.some(function (p) { return p === PRODUCTS.PANELBIDDIT_SUPPORT; });
            _this.hasBidditPanel = data.some(function (p) { return p === PRODUCTS.PANELBIDDIT || p === PRODUCTS.PANELBIDDIT_SUPPORT; });
            _this.step1FormGroup = _this._formBuilder.group({
                validStep: [_this.products.length, Validators.min(1)]
            });
            _this.store.dispatch(new SetStep1Valid(_this.step1FormGroup.get('validStep').valid));
        });
        this.ventePubFG = this._formBuilder.group({
            venteStatut: [this.ventePubChoices[0]],
            venteDate: ['']
        });
        this.$ventePubSub = this.ventePubFG.valueChanges.pipe(debounceTime(1000)).subscribe(function (values) {
            _this.venteInfo.statusVentePub = values.venteStatut;
            _this.venteInfo.venteDate = values.venteDate;
            _this.store.dispatch(new SetVenteInfo(_this.venteInfo));
        });
        this.descriptifPacks = descriptifPacks;
        this.checkQueryParamas();
    }
    Step1Component.prototype.ngOnInit = function () {
        var _this = this;
        this.$immoPacks = this.dataService.getPacksFromAPI();
        this.$selectedRegion = this.store.select(orderSelector.getRegion);
        this.$selectedEstateType = this.store.select(orderSelector.getEstateID);
        this.$selectedEstateStyle = this.store.select(orderSelector.getEstateStyle);
        this.$nbOfAppartments = this.store.select(orderSelector.getNbOfAppartments);
        this.$selectedImmoPack = this.store.select(orderSelector.getImmoPack);
        this.$filteredServices = this.store.select(orderSelector.getFilteredServices);
        this.$filteredImmoServices = this.store.select(orderSelector.getFilteredImmoServices);
        this.$filteredPanneauxServices = this.store.select(orderSelector.getFilteredPanneauxServices);
        this.$products = this.store.select(orderSelector.getProducts);
        this.$reductions = this.store.select(orderSelector.getReductions);
        this.$onlinePayment = this.store.select(orderSelector.getOnlinePayment);
        this.$urgence = this.store.select(orderSelector.getUrgence);
        this.$commission = this.store.select(orderSelector.getCommission);
        this.$reductionWithoutComm = this.store.select(orderSelector.getReductionWithoutComm);
        this.$reduction = this.store.select(orderSelector.getReduction);
        this.$nbElec = this.store.select(orderSelector.nbOfElec);
        this.$nbOfIso = this.store.select(orderSelector.getNbOfIso);
        this.$nbGaz = this.store.select(orderSelector.nbOfGaz);
        this.$hasElec = this.store.select(orderSelector.hasElec);
        this.$hasPeb = this.store.select(orderSelector.hasPeb);
        this.$gazChoice = this.store.select(orderSelector.gazChoice);
        this.$hasImmoAccess = this.store.select(orderSelector.getHasImmoAccess);
        this.$hasCommission = this.store.select(orderSelector.getHasCommission);
        this.$userRoles = this.store.select(orderSelector.getUserRoles);
        this.$showPacks = this.store.select(uiSelector.getShowPacks);
        this.$isMobile = this.store.select(uiSelector.getIsMobile);
        this.$immoPacksWithPrices = this.store.select(uiSelector.getPacksWithPrices);
        this.$services = this.store.select(orderSelector.getServices);
        this.$userType = this.store.select(orderSelector.getUserType);
        this.$reason = this.store.select(orderSelector.getReason);
        this.$clientType = this.store.select(orderSelector.getClientType);
        this.$reduction.subscribe(function (reduc) { return (_this.reduction = reduc); });
        this.$promocodeID = this.store.select(orderSelector.getPromoCodeID);
        this.$region = this.store.select(orderSelector.getRegionInfo);
        this.$hasImmoAccess.subscribe(function (i) { return (_this.hasImmoAccess = i); });
        this.$showMoreServices = this.store.select(uiSelector.getShowMoreServices);
        this.store.select(orderSelector.pebNb).subscribe(function (nb) {
            _this.pebNb = nb;
        });
        this.store.select(orderSelector.getVisitContact).subscribe(function (v) { return (_this.visitContact = v); });
        this.$reason.subscribe(function (r) { return (_this.reason = r); });
        this.$showMoreServices.subscribe(function (bool) { return (_this.showMoreServices = bool); });
        this.clientTypes = userTypePartAndPro;
        this.onZipChanges();
        this.$nbOfAppartments.subscribe(function (nb) { return (_this.nbOfApp = nb); });
        combineLatest(this.$nbOfAppartments, this.$hasElec, this.$hasPeb, this.$selectedEstateStyle)
            .pipe(map(function (results) { return ({ nbOfApp: results[0], hasElec: results[1], hasPeb: results[2] }); }))
            .subscribe(function (v) {
            if (v.nbOfApp > 0 && _this.estateType === 2) {
                if (v.hasElec) {
                    _this.setElecSupp(v.nbOfApp);
                }
                if (v.hasPeb) {
                    _this.setPebSupp(v.nbOfApp);
                }
            }
        });
        this.store.select(orderSelector.hasPeb).subscribe(function (bool) { return (_this.hasPeb = bool); });
        this.store.select(orderSelector.hasPebPartiel).subscribe(function (bool) {
            _this.hasPebPartiel = bool;
            if (bool) {
                _this.pebPartiel = true;
            }
        });
        this.store.select(orderSelector.hasElec).subscribe(function (bool) { return (_this.hasElec = bool); });
        this.store.select(orderSelector.hasCiterne).subscribe(function (bool) { return (_this.hasCiterne = bool); });
        this.store.select(orderSelector.hasPlan).subscribe(function (bool) { return (_this.hasPlan = bool); });
        this.store.select(orderSelector.hasGaz).subscribe(function (bool) { return (_this.hasGaz = bool); });
        this.store.select(orderSelector.getPriceWithoutReduction).subscribe(function (price) {
            _this.priceWithoutReduction = price;
        });
        this.store.select(orderSelector.getPromoCode).subscribe(function (data) { return (_this.promoCode = data); });
        this.store.select(orderSelector.getEstateStyle).subscribe(function (data) { return (_this.currentEstateStyle = data); });
        this.store.select(orderSelector.getParutionsLength).subscribe(function (data) { return (_this.printLength = data); });
        this.store.select(orderSelector.getVisitsLength).subscribe(function (data) { return (_this.visitLength = data); });
        this.$filteredServices.subscribe(function (fs) { return (_this.filteredServices = fs); });
        this.$filteredImmoServices.subscribe(function (fi) { return (_this.filteredImmoServices = fi); });
        this.$isLoggedIn = this.store.select(orderSelector.getIsLoggedIn);
        this.regions = this.dataService.getRegionTypes();
        this.estateTypes = this.dataService.getEstateTypes();
        this.estateStyles = this.dataService.getEstateStyles();
        this.$products.subscribe(function (p) { return (_this.productsDB = p); });
        this.$selectedRegion.subscribe(function (id) { return (_this.currentRegionId = id); });
        this.$isLoggedIn.subscribe(function (i) { return (_this.isLoggedIn = i); });
        this.$reductions.subscribe(function (red) {
            var r = '';
            var promodRed = red.find(function (rr) { return rr.id === 1002; });
            if (promodRed) {
                r = promodRed.price;
            }
            _this.reductionPromoCode = r;
        });
        this.houseOptions = this.estateTypes['m'];
        this.maison5 = this.houseOptions.filter(function (h) { return h.typeId === estates.MAISON_5; })[0];
        this.maison6 = this.houseOptions.filter(function (h) { return h.typeId === estates.VILLA; })[0];
        this.appartOptions = this.estateTypes['a'];
        this.immeubleOptions = this.estateTypes['i'];
        this.elecOptions = [1, 2, 3, 4, 5, 6];
        this.gazOptions = [1, 2, 3, 4, 5, 6];
        this.noPlaceHolderEstateStyles = this.estateStyles.slice(1);
        combineLatest(this.$userRoles, this.$selectedEstateType, this.$userType, this.$selectedRegion, this.$immoPacks, function (userRoles, estateType, userType, selectedRegion, immoPacks) { return ({
            userRoles: userRoles,
            estateType: estateType,
            userType: userType,
            selectedRegion: selectedRegion,
            immoPacks: immoPacks
        }); }).subscribe(function (_a) {
            var userRoles = _a.userRoles, estateType = _a.estateType, userType = _a.userType, selectedRegion = _a.selectedRegion, immoPacks = _a.immoPacks;
            console.log('🐛 ImmoPacks');
            var immoPacksWithPrices = _this.dataService.getImmoPacks();
            _this.estateType = estateType;
            // let immoPacksWithPrices = this.packs;
            if (selectedRegion !== -1 && estateType !== -1) {
                if (!_this.hasImmoAccess) {
                    immoPacksWithPrices = immoPacksWithPrices
                        .filter(function (p) { return p.userRoles.length === 0 || p.userRoles.some(function (ur) { return userRoles.some(function (r) { return r.RoleID === ur; }); }); })
                        .filter(function (p) { return p.userTypes.length === 0 || p.userTypes.some(function (ut) { return ut === userType; }) || _this.hasAccessAllPack; });
                }
                immoPacksWithPrices = _this.getPackPrice(immoPacksWithPrices);
            }
            _this.store.dispatch(new SetPacksWithPrices(immoPacksWithPrices));
        });
        this.$services.subscribe(function (services) {
            _this.controlLength = _this.filteredServices.length;
            _this.immoLength = _this.filteredImmoServices.length;
            // improve by comparing with getServiceTypes
            _this.howManyControlSelected = services.filter(function (s) { return s === 1 || s === 4 || s === 6 || s === 9 || s === 15 || s === 28; }).length;
            _this.howManyImmoSelected = services.length - _this.howManyControlSelected;
        });
        combineLatest(this.$selectedEstateType, this.$selectedEstateStyle)
            .pipe(map(function (results) { return ({ estateType: results[0], estateStyle: results[1] }); }))
            .subscribe(function (v) {
            if (v.estateStyle === 0) {
                _this.houseControl.setValidators(_this.selectNotEmpty);
                _this.houseControl.setValue(v.estateType);
            }
            else if (v.estateStyle === 1) {
                _this.appartControl.setValidators(_this.selectNotEmpty);
                _this.appartControl.setValue(v.estateType);
            }
            else if (v.estateStyle === 2) {
                _this.buildingControl.setValidators(_this.selectNotEmpty);
                _this.buildingControl.setValue(_this.nbOfApp);
            }
        });
        /* setTimeout(() => {
            this.dialog.open(VisitRequestDialogComponent, {
              panelClass: ['specific-request-dialog'],
              data: {
                dialog: this.dialog,
                reason: this.reason
              },
              disableClose: true
            });
          }, 1000
          ); */
    };
    Step1Component.prototype.checkQueryParamas = function () {
        var _this = this;
        // /?r=0&e=11&p=1-4&c=ouioui
        this.route.queryParamMap.subscribe(function (params) {
            var code = params.get('c') ? params.get('c') : null;
            var region = params.get('r') ? +params.get('r') : null;
            var estate = params.get('e') ? +params.get('e') : null;
            var pr = params.get('p') && params.get('p').split('-');
            _this.succintStep1 = false;
            if (!!code || !!region || !!estate) {
                _this.store.dispatch(new ResetOrderState(null));
            }
            if (code) {
                _this.setPromoCode(code);
            }
            if (!isNaN(region) && [0, 1, 2].some(function (r) { return r === region; })) {
                console.log('🎨 set region');
                _this.setRegion(region);
                if (!isNaN(estate) && [1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13].some(function (e) { return e === estate; })) {
                    console.log('🎨 set esateType');
                    _this.setEstateType(estate);
                    if (pr && pr.length > 0 && pr[0]) {
                        var hasGazInUrl = pr.some(function (p) { return +p === 15; });
                        if (hasGazInUrl) {
                            console.log('hasGazInUrl', hasGazInUrl);
                            _this.setGazChoice(0);
                        }
                        _this.succintStep1 = true;
                        if (!_this.dataService.isGreenfish()) {
                            _this.onlinePayment();
                        }
                        _this.store.dispatch(new SetServices([]));
                        pr.forEach(function (element) {
                            var prod = +element;
                            if (!isNaN(prod) && [1, 4, 6, 9, 14, 15].some(function (e) { return e === prod; })) {
                                console.log('🎨 set products');
                                _this.setService(prod);
                            }
                        });
                    }
                }
            }
        });
    };
    Step1Component.prototype.ngAfterContentInit = function () { };
    Step1Component.prototype.ngAfterViewChecked = function () {
        this.cdRef.detectChanges();
    };
    Step1Component.prototype.setRegion = function (id) {
        if (id === -1) {
            this.setEstateStyle(-1);
        }
        this.store.dispatch(new SetRegion(id));
    };
    Step1Component.prototype.setEstateStyle = function (style) {
        this.store.dispatch(new SetEstateStyle(style));
    };
    Step1Component.prototype.getDefaultEstateType = function (estateStyle) {
        switch (estateStyle) {
            case estates.HOUSE:
                return estates.MAISON_1;
            case estates.FLAT:
                return estates.STUDIO;
            case estates.BUILDING:
                return estates.IMMEUBLE_APPART;
            default:
                break;
        }
    };
    Step1Component.prototype.setEstateType = function (id) {
        if (id === 0) {
            this.dialog.open(SpecificRequestDialogComponent, {
                panelClass: ['specific-request-dialog']
            });
            return;
        }
        else if (id === -1) {
            this.store.dispatch(new SetServices([]));
        }
        this.store.dispatch(new SetEstateType(id));
    };
    Step1Component.prototype.setNbOfAppartments = function (id) {
        if (this.hasElec) {
            this.setElecSupp(id);
        }
        if (this.hasGaz) {
            this.setGazSupp(id);
        }
        this.store.dispatch(new SetNumberOfAppartments(id));
    };
    // add this to a service with logic when adding/removing products
    Step1Component.prototype.setService = function (id) {
        if (id === PRODUCTS.PLAQUETTE || id === PRODUCTS.DESCRIPTIF) {
            return;
        }
        if (id === -1) {
            this.dialog.open(SpecificRequestDialogComponent, {
                panelClass: ['specific-request-dialog']
            });
            return;
        }
        if (id === -3) {
            this.dialog.open(AuditRequestDialogComponent, {
                panelClass: ['audit-request-dialog'],
                data: {
                    dialog: this.dialog
                }
            });
            return;
        }
        if (id === -4) {
            this.dialog.open(PrintRequestDialogComponent, {
                panelClass: ['print-request-dialog', 'full-screen-dialog'],
                data: {
                    dialog: this.dialog
                },
                disableClose: true
            });
            return;
        }
        if (id === -5) {
            this.dialog.open(VisitRequestDialogComponent, {
                panelClass: ['visit-request-dialog', 'full-screen-dialog'],
                data: {
                    dialog: this.dialog,
                    reason: this.reason
                },
                disableClose: true
            });
            return;
        }
        var index = this.products.indexOf(id);
        var indexCiterneE = this.products.indexOf(PRODUCTS.CITERNE_ENTERREE);
        var indexCiterneA = this.products.indexOf(PRODUCTS.CITERNE_AERIENNE);
        var indexPlan2D = this.products.indexOf(PRODUCTS.PLAN);
        var indexPlan3D = this.products.indexOf(PRODUCTS.PLAN3D);
        var indexVisite360 = this.products.indexOf(PRODUCTS.VISITE360);
        var indexPanel = this.products.indexOf(PRODUCTS.PANEL) > -1 ||
            this.products.indexOf(PRODUCTS.PANEL_SUPPORT) > -1 ||
            this.products.indexOf(PRODUCTS.PANELBIDDIT) > -1 ||
            this.products.indexOf(PRODUCTS.PANELBIDDIT_SUPPORT) > -1;
        if (indexCiterneE > -1 && id === PRODUCTS.CITERNE_AERIENNE) {
            this.products.splice(indexCiterneE, 1);
        }
        else if (indexCiterneA > -1 && id === PRODUCTS.CITERNE_ENTERREE) {
            this.products.splice(indexCiterneA, 1);
        }
        if (id === 1) {
            // this.products = this.products.filter(pr => pr !== 14);
        }
        if (id === PRODUCTS.PANEL ||
            id === PRODUCTS.PANEL_SUPPORT ||
            id === PRODUCTS.PANELBIDDIT ||
            id === PRODUCTS.PANELBIDDIT_SUPPORT) {
            this.products = this.products.filter(function (pr) {
                return pr !== PRODUCTS.SMS &&
                    pr !== PRODUCTS.PANEL &&
                    pr !== PRODUCTS.PANEL_SUPPORT &&
                    pr !== PRODUCTS.PANELBIDDIT &&
                    pr !== PRODUCTS.PANELBIDDIT_SUPPORT;
            });
        }
        if (id === PRODUCTS.PEB) {
            this.products = this.products.filter(function (pr) { return pr !== PRODUCTS.PEB_SUPP; });
            this.products = this.products.filter(function (pr) { return pr !== PRODUCTS.PEB_Partiel; });
        }
        if (id === PRODUCTS.ELEC) {
            this.products = this.products.filter(function (pr) { return pr !== PRODUCTS.ELEC_SUPP; });
        }
        if (id === PRODUCTS.GAZ_CONTROLE) {
            this.products = this.products.filter(function (pr) { return pr !== PRODUCTS.GAZ_SUPP; });
        }
        if (indexPlan2D < 0 && id === PRODUCTS.PLAN3D) {
            this.setService(PRODUCTS.PLAN);
        }
        if (indexPlan2D < 0 && id === PRODUCTS.VISITE360) {
            this.setService(PRODUCTS.PLAN);
        }
        if (indexPlan2D > -1 && indexPlan3D > -1 && id === PRODUCTS.PLAN) {
            this.products = this.products.filter(function (pr) { return pr !== PRODUCTS.PLAN3D; });
        }
        if (indexPlan2D > -1 && indexVisite360 > -1 && id === PRODUCTS.PLAN) {
            this.products = this.products.filter(function (pr) { return pr !== PRODUCTS.VISITE360; });
        }
        if (index > -1) {
            // this.products.splice(index, 1);
            this.products = this.products.filter(function (pr) { return pr !== id; });
        }
        else {
            this.products.push(id);
            if (id === PRODUCTS.GAZ_CONTROLE) {
                this.setGazSupp(this.nbOfApp);
            }
            if (id === PRODUCTS.ELEC) {
                this.setElecSupp(this.nbOfApp);
            }
        }
        this.store.dispatch(new SetServices(this.products));
    };
    Step1Component.prototype.setServiceNoRemove = function (id) {
        var index = this.products.indexOf(id);
        if (id === PRODUCTS.PANEL ||
            id === PRODUCTS.PANEL_SUPPORT ||
            id === PRODUCTS.PANELBIDDIT ||
            id === PRODUCTS.PANELBIDDIT_SUPPORT) {
            this.products = this.products.filter(function (pr) {
                return pr !== PRODUCTS.SMS &&
                    pr !== PRODUCTS.PANEL &&
                    pr !== PRODUCTS.PANEL_SUPPORT &&
                    pr !== PRODUCTS.PANELBIDDIT &&
                    pr !== PRODUCTS.PANELBIDDIT_SUPPORT;
            });
        }
        this.products.push(id);
        this.store.dispatch(new SetServices(this.products));
    };
    Step1Component.prototype.setPebSupp = function (nb) {
        this.products = this.products.filter(function (pr) { return pr !== PRODUCTS.PEB_SUPP; });
        if (nb > 1) {
            var pebSuppArray = Array(nb - 1).fill(PRODUCTS.PEB_SUPP).slice();
            this.products = this.products.concat(pebSuppArray);
        }
        this.store.dispatch(new SetServices(this.products));
    };
    Step1Component.prototype.setElecSupp = function (nb) {
        this.products = this.products.filter(function (pr) { return pr !== PRODUCTS.ELEC_SUPP; });
        if (nb > 1) {
            var elecSuppArray = Array(nb - 1).fill(PRODUCTS.ELEC_SUPP).slice();
            this.products = this.products.concat(elecSuppArray);
        }
        this.store.dispatch(new SetServices(this.products));
        this.store.dispatch(new SetNbOfElec(nb));
    };
    Step1Component.prototype.setGazSupp = function (nb) {
        this.products = this.products.filter(function (pr) { return pr !== PRODUCTS.GAZ_SUPP; });
        if (nb > 1) {
            var gazSuppArray = Array(nb - 1).fill(PRODUCTS.GAZ_SUPP).slice();
            this.products = this.products.concat(gazSuppArray);
        }
        this.store.dispatch(new SetServices(this.products));
        this.store.dispatch(new SetNbOfGaz(nb));
    };
    Step1Component.prototype.setGazChoice = function (choice) {
        this.store.dispatch(new SetGazChoice(choice));
    };
    Step1Component.prototype.isCiterneSelected = function (id) {
        var index = this.products.indexOf(id);
        if (id === PRODUCTS.CITERNE_AERIENNE) {
            return index > -1 ? 'accent' : 'primary';
        }
        else if (id === PRODUCTS.CITERNE_ENTERREE) {
            return index > -1 ? 'accent' : 'primary';
        }
    };
    Object.defineProperty(Step1Component.prototype, "citerneType", {
        get: function () {
            var under = this.products.indexOf(9) > -1;
            var aerial = this.products.indexOf(6) > -1;
            return under ? 9 : aerial ? 6 : 6;
        },
        enumerable: true,
        configurable: true
    });
    Step1Component.prototype.regionClass = function (selectedId) {
        var cls = this.currentRegionId === selectedId ? 'region selected' : 'region';
        return cls;
    };
    Step1Component.prototype.regionImgUrl = function (selectedId, fileName) {
        var url = this.assetsFolder;
        url += fileName + '.svg';
        return url;
    };
    Step1Component.prototype.estateClass = function (selectedEstate) {
        var cls = this.currentEstateStyle === selectedEstate ? 'estate selected' : 'estate';
        return cls;
    };
    Step1Component.prototype.estateImgUrl = function (fileName) {
        var url = this.assetsFolder;
        url += fileName;
        return url;
    };
    Step1Component.prototype.serviceClass = function (id) {
        if (id === 9) {
            return this.isSelected(6) || this.isSelected(9) ? 'service selected' : 'service';
        }
        if (id === PRODUCTS.PRINT) {
            return this.printLength > 0 ? 'service selected' : 'service';
        }
        if (id === PRODUCTS.VISIT) {
            return this.visitLength > 0 || this.visitContact.email !== '' ? 'service selected' : 'service';
        }
        if (id === PRODUCTS.PANEL) {
            return this.isSelected(PRODUCTS.PANEL_SUPPORT) || this.isSelected(PRODUCTS.PANEL)
                ? 'service selected'
                : 'service';
        }
        else if (id === PRODUCTS.PANELBIDDIT) {
            return this.isSelected(PRODUCTS.PANELBIDDIT) || this.isSelected(PRODUCTS.PANELBIDDIT_SUPPORT)
                ? 'service selected'
                : 'service';
        }
        return this.isSelected(id) ? 'service selected' : 'service';
    };
    Step1Component.prototype.isSelected = function (id) {
        var bool = this.products.indexOf(id) > -1;
        return bool;
    };
    Step1Component.prototype.serviceImgUrl = function (fileName, id) {
        var url = this.assetsFolder;
        url += fileName;
        if (id === PRODUCTS.PANEL) {
            url += this.isSelected(PRODUCTS.PANEL_SUPPORT) || this.isSelected(PRODUCTS.PANEL) ? '-selected.svg' : '.svg';
        }
        else if (id === PRODUCTS.PANELBIDDIT) {
            url +=
                this.isSelected(PRODUCTS.PANELBIDDIT) || this.isSelected(PRODUCTS.PANELBIDDIT_SUPPORT)
                    ? '-selected.svg'
                    : '.svg';
        }
        else if (id === PRODUCTS.VISIT) {
            url += this.visitLength > 0 || this.visitContact.email !== '' ? '-selected.svg' : '.svg';
        }
        else {
            url += this.isSelected(id) ? '-selected.svg' : '.svg';
        }
        return url;
    };
    Step1Component.prototype.setImmoPack = function (id) {
        var services = this.dataService.getProductsForPack(id);
        this.store.dispatch(new SetServices(services));
        this.setShowPacks(false);
    };
    Step1Component.prototype.setPackAllIn = function () {
        var services = this.dataService.getProductsAllIn();
        this.store.dispatch(new SetServices(services));
    };
    Step1Component.prototype.setPebPartiel = function (bool) {
        if (!bool && this.hasPebPartiel) {
            this.setService(PRODUCTS.PEB_Partiel);
        }
        this.pebPartiel = bool;
    };
    Step1Component.prototype.addPebPartiel = function () {
        this.setService(PRODUCTS.PEB_Partiel);
    };
    Step1Component.prototype.addPebNb = function (pebNb) {
        this.store.dispatch(new SetPebNb(pebNb));
    };
    Step1Component.prototype.setWantsIso = function (bool) {
        this.wantsIso = bool;
    };
    Step1Component.prototype.setPlanIso = function (nb) {
        this.products = this.products.filter(function (pr) { return pr !== PRODUCTS.ISO; });
        if (nb > 0) {
            var isoArray = Array(nb).fill(PRODUCTS.ISO).slice();
            this.products = this.products.concat(isoArray);
        }
        this.store.dispatch(new SetServices(this.products));
        this.store.dispatch(new SetNbOfIso(nb));
    };
    Step1Component.prototype.scrollTop = function () {
        var _this = this;
        if (this.step1FormGroup.valid) {
            setTimeout(function () { return _this.smooth.smoothScrollToTop(); }, 500);
        }
    };
    Step1Component.prototype.nextStep = function () {
        var _this = this;
        this.googleAnalyticsService.emitEvent('bouton', 'clic', 'étape 1 continuer');
        this.houseControl.markAsTouched();
        if (this.succintStep1) {
            var ref = this.dialog.open(SummaryConfirmDialogComponent, {
                panelClass: 'summary-confirm-dialog',
                data: {
                    regionId: this.currentRegionId,
                    estateTypeId: this.estateType,
                    dialog: this.dialog,
                    products: this.products,
                    stepper: this.stepper,
                    price: this.priceWithoutReduction,
                    reduction: this.reduction
                }
            });
            ref.afterClosed().subscribe(function (result) {
                // console.log(result);
                _this.succintStep1 = result;
            });
        }
        this.scrollTop();
    };
    Step1Component.prototype.onlinePayment = function () {
        this.store.dispatch(new ToggleOnlinePayment(true));
    };
    Step1Component.prototype.urgence = function () {
        this.store.dispatch(new ToggleUrgence(true));
    };
    Step1Component.prototype.setPromoCode = function (code) {
        if (code !== '') {
            this.store.dispatch(new SetPromoCode(code.toLowerCase()));
        }
    };
    Step1Component.prototype.setCommission = function (value) {
        this.store.dispatch(new SetCommission(value));
    };
    Step1Component.prototype.setShowPacks = function (value) {
        this.store.dispatch(new SetShowPacks(value));
    };
    Step1Component.prototype.openRegisterDialog = function () {
        var dialogRef = this.dialog.open(LoginDialogComponent, {
            panelClass: ['no-padding-dialog', 'full-screen-dialog'],
            data: { register: false }
        });
    };
    Step1Component.prototype.getPackPrice = function (immoPack) {
        var _this = this;
        var tmp = JSON.parse(JSON.stringify(immoPack));
        tmp.forEach(function (pack) {
            pack.servicesInfo = [];
            pack.servicesPrice = pack.servicesRed = pack.totalWithReduc = 0;
            // Add descriptif for details
            if (_this.descriptifPacks.some(function (id) { return id === pack.id; })) {
                pack.services.push(DESCRIPTIF);
                pack.services.push(PRODUCTS.PLAQUETTE);
            }
            var services = pack.services;
            services.forEach(function (id) {
                var servPrice = _this.dataService.getProductPrice(id);
                var serv = _this.dataService.getServiceTypes().find(function (s) { return s.id === id; });
                if (!serv) {
                    serv = _this.dataService.getImmoServiceTypes().find(function (s) { return s.id === id; });
                }
                servPrice.reduc = _this.dataService.getStatutReduc(parseInt(id, 10), servPrice.reduc, _this.dataService.getProduct(+id));
                var thisPack = tmp.filter(function (p) { return p.id === pack.id; })[0];
                /* thisPack.servicesPrice = parseInt(servPrice.price, 10);
                thisPack.servicesRed = parseInt(servPrice.reduc, 10); */
                pack.servicesInfo.push(serv);
                if (isNaN(thisPack.servicesPrice)) {
                    thisPack.servicesPrice = 0;
                }
                if (isNaN(thisPack.servicesRed)) {
                    thisPack.servicesRed = 0;
                }
                thisPack.servicesPrice =
                    thisPack.servicesPrice || thisPack.servicesPrice === 0
                        ? isNaN(servPrice.price)
                            ? thisPack.servicesPrice
                            : thisPack.servicesPrice + servPrice.price
                        : servPrice.price;
                thisPack.servicesRed =
                    thisPack.servicesRed || thisPack.servicesRed === 0
                        ? isNaN(servPrice.reduc)
                            ? thisPack.servicesRed
                            : thisPack.servicesRed + servPrice.reduc
                        : servPrice.reduc;
            });
            pack.reduc = _this.dataService.getStatutReduc(parseInt(pack.description, 10), pack.reduc, _this.dataService.getProduct(+pack.description));
            pack.totalWithReduc = pack.servicesPrice - pack.servicesRed - parseInt(pack.reduc, 10);
        });
        return tmp;
    };
    Step1Component.prototype.selectNotEmpty = function (control) {
        var valid = control.value !== -1;
        return valid ? null : { isEmpty: { valid: false, value: control.value } };
    };
    Step1Component.prototype.setClientType = function (id) {
        if (+id === 1) {
            this.openRegisterDialog();
            return;
        }
        this.store.dispatch(new SetClientType(id));
    };
    Step1Component.prototype.setReasonType = function (id) {
        this.store.dispatch(new SetReason(id));
    };
    Step1Component.prototype.inputZipCode = function (control) {
        var pattern = new RegExp(/^(?:(?:[1-9])(?:\d{3}))$/);
        var code = control.value;
        console.log(control);
        var valid = code ? code.match(pattern) : false;
        console.log(valid);
        var region = -1;
        if (valid) {
            if (code >= 1000 && code <= 1299) {
                console.log('Bxl');
                region = 1;
            }
            else if ((code >= 1500 && code <= 3999) || (code >= 8000 && code <= 9999)) {
                console.log('Fl');
                region = 2;
            }
            else if ((code >= 1300 && code <= 1499) || (code >= 4000 && code <= 7999)) {
                console.log('Wal');
                region = 0;
            }
            console.log(region);
        }
        this.setRegion(region);
        return valid ? null : { isZipCode: { valid: false, value: code } };
    };
    Step1Component.prototype.imgUrlRegion = function (id) {
        var fileName = this.regions.find(function (r) { return r.id === id; }).fileName;
        var url = imgagesFolder;
        url += fileName;
        url += '-selected.svg';
        return url;
    };
    Step1Component.prototype.onZipChanges = function () {
        var _this = this;
        this.situationControl.setValidators(this.inputZipCode.bind(this));
        this.situationControl.valueChanges.pipe(debounceTime(1000)).subscribe(function (val) {
            _this.store.dispatch(new SetZipCode(val));
        });
    };
    Step1Component.prototype.toggleShowMoreServices = function (bool) {
        this.store.dispatch(new ShowMoreServices(bool));
        console.log('showMore: ', bool);
    };
    return Step1Component;
}());
export { Step1Component };
