/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header/header.component.ngfactory";
import * as i3 from "./header/header.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "./core/services/auth.service";
import * as i6 from "@ngrx/store";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@angular/router";
import * as i9 from "./footer/footer.component.ngfactory";
import * as i10 from "./footer/footer.component";
import * as i11 from "./core/spinner-overlay/spinner-overlay.component.ngfactory";
import * as i12 from "./core/spinner-overlay/spinner-overlay.component";
import * as i13 from "./core/spinner-overlay/spinner-overlay.service";
import * as i14 from "./app.component";
import * as i15 from "@angular/cdk/layout";
import * as i16 from "./core/services/update-sw.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { OrderSteppersComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderComponent, [i4.MatDialog, i5.AuthService, i6.Store, i7.TranslateService, i8.Router], { stepper: [0, "stepper"] }, null), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-footer", [], null, null, null, i9.View_FooterComponent_0, i9.RenderType_FooterComponent)), i1.ɵdid(6, 114688, null, 0, i10.FooterComponent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-loader", [], null, null, null, i11.View_SpinnerOverlayComponent_0, i11.RenderType_SpinnerOverlayComponent)), i1.ɵdid(8, 245760, null, 0, i12.SpinnerOverlayComponent, [i13.SpinnerOverlayService], null, null)], function (_ck, _v) { var currVal_0 = null; _ck(_v, 2, 0, currVal_0); _ck(_v, 4, 0); _ck(_v, 6, 0); _ck(_v, 8, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 4308992, null, 0, i14.AppComponent, [i6.Store, i15.BreakpointObserver, i7.TranslateService, i16.UpdateSwService, i5.AuthService, i8.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i14.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
