import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { AppState } from '../../interfaces';
import { BreakpointObserver } from '@angular/cdk/layout';

import * as userSelector from '../../core/store/order/order.selectors';
import * as uiSelector from '../../core/store/ui/ui.selectors';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {
  $isLoggedIn: Observable<boolean>;
  $isTablette: Observable<boolean>;

  emailSent = false;
  emailAsked = false;
  emailError = false;
  emailNotInDB = false;
  language = 'fr';

  userFG: FormGroup;

  loginStatus: Subject<number>;

  userTypeSelected = 'notary';

  userInfo = {
    lastName: '',
    firstName: '',
    phone: '',
    email: '',
    company: '',
    tva: ''
  };

  constructor(
    private authService: AuthService,
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    private store: Store<AppState>,
    private http: HttpClient,
    private _formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
    public data: { register: boolean }
  ) {
    this.userFG = this._formBuilder.group({
      firstName: [{ value: this.userInfo.firstName, disabled: false }, Validators.required],
      lastName: [{ value: this.userInfo.lastName, disabled: false }, Validators.required],
      phone: [{ value: this.userInfo.phone, disabled: false }, Validators.required],
      email: [{ value: this.userInfo.email, disabled: false }, Validators.required],
      company: [{ value: this.userInfo.company, disabled: false }, Validators.required],
      tva: [{ value: this.userInfo.tva, disabled: false }, Validators.required]
    });
  }

  ngOnInit() {
    this.$isLoggedIn = this.store.select(userSelector.getIsLoggedIn);
    this.$isTablette = this.store.select(uiSelector.getIsTablette);

    /* this.$isLoggedIn.subscribe(bool => {
      if (bool) {
        this.closeDialog();
      }
    }); */

    this.loginStatus = this.authService.loginStatus;

    this.store.select(uiSelector.getLanguage).subscribe(ln => (this.language = ln));
  }

  signIn(email, pw) {
    if (email !== '' && pw !== '') {
      this.authService.login(email, pw);

      this.$isLoggedIn.subscribe(logged => {
        console.log('logged', logged);
        if (logged) {
          this.closeDialog();
        }
      });
    }
  }

  forgotPassword(email: string) {
    this.emailAsked = true;
    if (email === '') {
      this.emailError = true;
      return;
    }
    this.authService.isEmailAlreadyInUse(email).subscribe(data => {
      if (data.Data) {
        // console.log('Email exists', data.Data);
        this.http
          .post<any>('https://systems.certinergie.be/api/Emailwithtemplate/sendmail', {
            EmailType: 94,
            Recipient: email
          })
          .subscribe(
            val => {
              console.log(`🚀`);
              console.log('POST call successful value returned in body', val);
              this.snackBar.open('🚀 Email envoyé avec succès 🚀');
              this.emailSent = true;
            },
            response => {
              console.log('POST call in error', response);
              this.snackBar.open('Une erreur a eu lieu 😰');
              this.emailError = true;
            }
          );
      } else {
        console.log(`Email not in DB`);
        this.emailNotInDB = true;
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  selectUserType(type: string) {
    console.log(type);
    this.userTypeSelected = type;
  }

  askForProAccount() {
    if (this.userFG.invalid) {
      return;
    }
    this.http
      .post<any>('https://systems.certinergie.be/api/CreateNewUser/SaveOrderClient', {
        ConsumerType: 1688,
        Users: {
          Name: this.userInfo.lastName,
          Email: this.userInfo.email,
          FirstName: this.userInfo.firstName,
          PhoneNumber: this.userInfo.phone,
          Language:
            this.language === 'nl'
              ? 'nl-BE'
              : this.language === 'en'
              ? 'en-BE'
              : this.language === 'de'
              ? 'de-BE'
              : 'fr-BE',
          Addresses: {
            Company: this.userInfo.company,
            VatNumber: this.userInfo.tva
          }
        },
        Comment: this.userTypeSelected
      })
      .subscribe(
        val => {
          console.log(`🚀`);
          console.log('POST call successful value returned in body', val);
          this.snackBar.open('🚀 Email envoyé avec succès 🚀');
          this.emailSent = true;
          this.authService.loginWithUserID(val.UserID);
          this.closeDialog();
        },
        response => {
          console.log('POST call in error', response);
          this.snackBar.open('Une erreur a eu lieu 😰');
          this.emailError = true;
        }
      );
  }
}
