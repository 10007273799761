var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { map, switchMap } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ActionTypes } from './../store/order/';
import * as fromOrder from '../store/order/order.actions';
import * as estates from './../data/estates';
import { Product } from '../models/product';
import { DataService } from '../services/data.service';
import * as PRODUCTS from '../data/products';
import { getServices } from '../store/order/order.selectors';
import { Reduction } from '../models/reduction';
import { descriptifPacks, ONLINE_REDUC } from '../data';
import * as orderSelector from '../store/order/order.selectors';
import * as uiSelector from '../store/ui/ui.selectors';
import { Document } from '../models/document';
import { Owner, UserType } from '../models/user';
import { MatSnackBar } from '@angular/material';
import { PackSelectedComponent } from 'src/app/snackbars/pack-selected/pack-selected.component';
import { DESCRIPTIF } from '../data/products';
import { projectID } from '../data/theme-config';
var OrderEffects = /** @class */ (function () {
    function OrderEffects(actions$, dataService, store, snackBar) {
        var _this = this;
        this.actions$ = actions$;
        this.dataService = dataService;
        this.store = store;
        this.snackBar = snackBar;
        this.onlineReduc = ONLINE_REDUC;
        /* @Effect()
        init$ = this.actions$.pipe(
          ofType(ROOT_EFFECTS_INIT),
          map((action: any) => new fromOrder.SetProducts(this.getProducts(this.services)))
        ); */
        this.actionReason$ = this.actions$.pipe(ofType(ActionTypes.SET_REASON), map(function (action) {
            if (projectID === 'mdn') {
                return new fromOrder.SetServices([]);
            }
            return new fromOrder.SetEstateType(-1);
        }));
        this.actionRegion$ = this.actions$.pipe(ofType(ActionTypes.SET_REGION), switchMap(function (action) { return [
            new fromOrder.SetRegionInfo(_this.getRegion(action.payload)),
            new fromOrder.SetProducts(_this.getProducts(_this.services))
        ]; }));
        this.actionX$ = this.actions$.pipe(ofType(ActionTypes.SET_ESTATETYPE), map(function (action) { return new fromOrder.SetProducts(_this.getProducts(_this.services)); }));
        this.actionEstateStyles$ = this.actions$.pipe(ofType(ActionTypes.SET_ESTATESTYLE), map(function (action) { return _this.checkStyles(_this.services, action.payload); }));
        this.actionY$ = this.actions$.pipe(ofType(ActionTypes.SET_SERVICES), map(function (action) { return new fromOrder.SetProducts(_this.getProducts(action.payload)); }));
        this.actionNbOfApp$ = this.actions$.pipe(ofType(ActionTypes.SET_NUMBEROFAPPARTMENTS), map(function (action) { return new fromOrder.SetProducts(_this.getProducts(_this.services)); }));
        this.actionOnlinePayment$ = this.actions$.pipe(ofType(ActionTypes.TOGGLE_ONLINEPAYMENT), map(function (action) { return new fromOrder.SetReductions(_this.checkReductionOnline()); }));
        this.actionPromoCode$ = this.actions$.pipe(ofType(ActionTypes.SET_PROMOCODE), map(function (action) { return new fromOrder.SetProducts(_this.getProducts(_this.services)); }));
        this.actionGazChoice$ = this.actions$.pipe(ofType(ActionTypes.SET_GAZCHOICE), map(function (action) { return new fromOrder.SetProducts(_this.getProducts(_this.services)); }));
        this.actionInvoiceTo$ = this.actions$.pipe(ofType(ActionTypes.SET_INVOICETO), map(function (action) { return _this.fillDocumentInfo(action.payload); }));
        this.actionSetUserForm$ = this.actions$.pipe(ofType(ActionTypes.SET_USERFORM), map(function (action) { return new fromOrder.SetOwner(_this.getOwner(action.payload)); }));
        this.actionSetUserInfo$ = this.actions$.pipe(ofType(ActionTypes.SET_USERINFO), switchMap(function (action) { return [
            new fromOrder.SetProducts(_this.getProducts(_this.services)),
            new fromOrder.SetOwner(_this.getOwner(action.payload))
        ]; }));
        this.actionUrgent$ = this.actions$.pipe(ofType(ActionTypes.TOGGLE_URGENCE), map(function (action) { return new fromOrder.ToggleOnlinePayment(true); }));
        this.actionParutions$ = this.actions$.pipe(ofType(ActionTypes.SET_PARUTIONS), map(function (action) { return _this.checkParutions(action.payload); }));
        this.store.select(getServices).subscribe(function (serv) { return (_this.services = serv); });
        this.store.select(orderSelector.getUserType).subscribe(function (ut) { return (_this.newUserType = ut); });
        this.store.select(orderSelector.getOwner).subscribe(function (o) { return (_this.owner = o); });
        this.store.select(orderSelector.getImmoPack).subscribe(function (i) { return (_this.immoPackSelected = i); });
        this.store.select(uiSelector.getShowPacks).subscribe(function (s) { return (_this.showpacks = s); });
        this.store.select(orderSelector.getPricingPlan).subscribe(function (pp) { return (_this.userPricingPlan = pp); });
        this.store.select(orderSelector.getRegion).subscribe(function (r) { return (_this.region = r); });
        this.store.select(orderSelector.getEstateID).subscribe(function (e) { return (_this.esateType = e); });
        this.store.select(orderSelector.gazChoice).subscribe(function (g) { return (_this.gazChoice = g); });
        this.descriptifPacks = descriptifPacks;
    }
    /* @Effect()
    actionReduction$ = this.actions$.pipe(
      ofType(ActionTypes.SET_REDUCTIONS),
      map((action: any) => new fromOrder.SetCommission(this.checkMaxCommission()))
    ); */
    /* @Effect() actionZ$ = this.actions$
      .ofType(ActionTypes.SET_ELECSUPP).pipe(
        map((action: any) => new fromOrder.SetServices(this.addElecSupp(action.payload)))
      );
  
    private addElecSupp(nb: number): number[] {
      return [...Array(nb).fill(PRODUCTS.ELEC_SUPP)];
    } */
    /* private checkMaxCommission() {
      const $reduction = this.store.select(orderSelector.getReduction);
      const $commission = this.store.select(orderSelector.getCommission);
      let maxCom = 0;
      combineLatest($reduction, $commission, (reduc, comm) => ({ reduc, comm })).subscribe(pair => {
        const { reduc, comm } = pair;
        console.log(`red: ${reduc} & comm ${comm}`);
        maxCom = comm > reduc ? reduc : comm;
      });
      return maxCom;
    } */
    OrderEffects.prototype.getRegion = function (id) {
        return this.dataService.getRegionTypes().find(function (r) { return r.id === id; });
    };
    OrderEffects.prototype.getOwner = function (user) {
        if (this.newUserType === UserType.Particulier) {
            var owner = new Owner();
            owner.email = user.email;
            owner.firstname = user.firstname;
            owner.lastname = user.lastname;
            owner.phone = user.phone;
            return owner;
        }
        else {
            return this.owner;
        }
    };
    OrderEffects.prototype.checkStyles = function (services, styleID) {
        /* if (styleID === -1) {
          return new fromOrder.SetServices([]);
        } else {
          return new fromOrder.SetProducts(this.getProducts(services));
        } */
        return new fromOrder.SetServices([]);
    };
    OrderEffects.prototype.checkParutions = function (p) {
        var serv = this.services.slice();
        var hasParu = this.services.find(function (s) { return s === PRODUCTS.PRINT; });
        if (!hasParu && p.length > 0) {
            serv = this.services.concat([PRODUCTS.PRINT]);
        }
        else if (hasParu && p.length === 0) {
            serv = this.services.slice().filter(function (id) { return id !== PRODUCTS.PRINT; });
        }
        return new fromOrder.SetServices(serv);
    };
    OrderEffects.prototype.fillDocumentInfo = function (type) {
        var doc = new Document();
        var owner;
        var userForm;
        var user;
        var address;
        this.store.select(orderSelector.getOwner).subscribe(function (o) { return (owner = o); });
        this.store.select(orderSelector.getUserForm).subscribe(function (u) { return (userForm = u); });
        this.store.select(orderSelector.getAddress).subscribe(function (a) { return (address = a); });
        this.store.select(orderSelector.getUserInfo).subscribe(function (u) { return (user = u); });
        if (type === 'toProprio') {
            doc.firstName = owner.firstname !== null ? owner.firstname : userForm.firstname;
            doc.lastName = owner.lastname !== null ? owner.lastname : userForm.lastname;
            doc.address.street = address.street;
            doc.address.number = address.number;
            doc.address.zip = address.zip;
            doc.address.city = address.city;
        }
        else if (type === 'toProprioAndPro') {
            doc.firstName = owner.firstname;
            doc.lastName = owner.lastname + ' C/o ' + user.lastname;
            doc.address.street = user.address.street;
            doc.address.number = user.address.number;
            doc.address.zip = user.address.zip;
            doc.address.city = user.address.city;
        }
        else if (type === 'toPro') {
            doc.firstName = user.firstname;
            doc.lastName = user.lastname;
            doc.address.street = user.address.street;
            doc.address.number = user.address.number;
            doc.address.zip = user.address.zip;
            doc.address.city = user.address.city;
        }
        return new fromOrder.SetDocument(doc);
    };
    OrderEffects.prototype.checkReductionOnline = function () {
        var onlinePayment;
        var reductions;
        this.store.select(orderSelector.getOnlinePayment).subscribe(function (o) { return (onlinePayment = o); });
        this.store.select(orderSelector.getReductions).subscribe(function (r) { return (reductions = r); });
        if (onlinePayment) {
            var reduction = new Reduction(1001, this.onlineReduc, 'Réduction paiement en ligne');
            reductions.push(reduction);
        }
        else {
            reductions = reductions.filter(function (r) { return r.id !== 1001; });
        }
        return reductions;
    };
    /* private checkReductionPromoCode() {
      let promoCode;
      let reductions;
      let products;
      let region;
  
      this.store.select(orderSelector.getPromoCode).subscribe(p => (promoCode = p));
      this.store.select(orderSelector.getReductions).subscribe(r => (reductions = r));
      this.store.select(orderSelector.getProducts).subscribe(p => (products = p));
      this.store.select(orderSelector.getRegion).subscribe(r => (region = r));
  
      const f = promoCodes.filter(p => p.code === promoCode && p.regions.some(r => r === region));
      // console.log('f', f);
      const productsID: number[] = [];
      products.map(p => productsID.push(p.id));
      // console.log('productsID', productsID);
  
      const found = f.filter(p => p.products.every(pr => productsID.indexOf(pr) >= 0))[0];
      // console.log('found', found);
      if (found) {
        const reduction = new Reduction(1002, found.reduc, `${found.code}`);
        reductions.push(reduction);
      } else {
        reductions = reductions.filter(r => r.id !== 1002);
      }
  
      return reductions;
    } */
    OrderEffects.prototype.checkReductionPromoCode = function () {
        var promoCode;
        var products;
        var region;
        this.store.select(orderSelector.getPromoCode).subscribe(function (p) { return (promoCode = p); });
        this.store.select(orderSelector.getServices).subscribe(function (p) { return (products = p); });
        this.store.select(orderSelector.getRegion).subscribe(function (r) { return (region = r); });
        // const f = promoCodes.filter(p => p.code === promoCode && p.regions.some(r => r === region));
        // console.log('f', f);
        /* const productsID: number[] = [];
        products.map(p => productsID.push(p.id)); */
        // console.log('productsID', productsID);
        // const found = f.filter(p => p.products.every(pr => productsID.indexOf(pr) >= 0))[0];
        var found = this.dataService.isPromoCodeValid(promoCode, products, region);
        // console.log('found', found);
        return found;
    };
    OrderEffects.prototype.getProducts = function (ids) {
        var _this = this;
        if (ids.length === 0) {
            return [];
        }
        var reductions = [];
        // console.log('calculating price');
        var products = ids.map(function (id) {
            var prod = _this.dataService.getProductPrice(id);
            return new Product(id, prod.price, prod.reduc);
        });
        products.forEach(function (prod) {
            var reduction;
            var reduc = Number(prod.reduction);
            // Check if prod.id is in pricingPlansDB.services for region and estatetype and add special reduction
            if (_this.userPricingPlan !== 0 && _this.esateType !== estates.IMMEUBLE_APPART) {
                reduc = _this.dataService.getStatutReduc(prod.id, reduc, _this.dataService.getProduct(prod.id));
            }
            if (reduc && reduc !== 0) {
                reduction = new Reduction(prod.id, reduc, 'Réduction ' + prod.name);
                reductions.push(reduction);
            }
        });
        if (this.gazChoice === 0) {
            var gaz = reductions.filter(function (r) { return r.id === 15; }).length > 0 ? reductions.filter(function (r) { return r.id === 15; })[0] : null;
            if (gaz) {
                gaz.price = +gaz.price + 15 + '';
            }
        }
        var packReducs = this.checkForPacks(products);
        // Check if there is a packReducs.id in pricingPlansDB.services for region and estatetype and add special reduction
        var redOnline = !this.getReductionOnline() ? [] : this.getReductionOnline();
        this.checkReductionPromoCode().then(function (promoCode) {
            var reducCode = [];
            if (promoCode) {
                var code = '';
                if (typeof promoCode !== 'undefined') {
                    reducCode = [new Reduction(1002, promoCode.PromoCode.reduc, "" + promoCode.KeyWord)];
                    code = promoCode.id;
                    console.log(reducCode);
                }
                _this.store.dispatch(new fromOrder.SetPromoCodeID(code));
            }
            _this.store.dispatch(new fromOrder.SetReductions(reductions
                .concat(packReducs ? packReducs : [])
                .concat(redOnline)
                .concat(reducCode)));
        });
        return products;
    };
    OrderEffects.prototype.getReductionOnline = function () {
        var onlinePayment;
        var reduction;
        this.store.select(orderSelector.getOnlinePayment).subscribe(function (o) { return (onlinePayment = o); });
        if (onlinePayment) {
            reduction = new Reduction(1001, this.onlineReduc, 'RÉDUCTION PAIEMENT EN LIGNE');
        }
        return reduction;
    };
    OrderEffects.prototype.checkForPacks = function (products) {
        var reduction;
        var immoPacks = JSON.parse(JSON.stringify(this.dataService.getImmoPacks()));
        if (products.length !== 0 && !!immoPacks) {
            var productsID_1 = [];
            var biggestReductionPack_1 = null;
            products.map(function (p) { return productsID_1.push(p.id); });
            console.log('productsID', productsID_1);
            // saving only pack with biggest reduction
            immoPacks.forEach(function (pack) {
                console.log('pack', pack.services);
                var found = pack.services.every(function (s) { return productsID_1.indexOf(s) >= 0; });
                console.log(found);
                if (found) {
                    var packReduc = immoPacks.filter(function (p) { return p.id === pack.id; })[0];
                    /* const pPlan = pricingPlansDB.find(
                      pp =>
                        pp.id === this.userPricingPlan &&
                        pp.regions.some(r => r === this.region) &&
                        pp.services.some(p => p === pack.id)
                    );
          
                    if (pPlan) {
                      // packReduc.reduc = pPlan.reduction + '';
                      const pricingPlanReduction = new Reduction(
                        200 + this.userPricingPlan,
                        pPlan.reduction - packReduc.reduc,
                        this.userPricingPlan + ' - ' + packReduc.name
                      );
                      console.log('pricingPlanReduction', pricingPlanReduction);
          
                      reductions.push(pricingPlanReduction);
                    } */
                    // console.log('packReduc', packReduc);
                    // initiate first pack if found
                    if (!biggestReductionPack_1) {
                        biggestReductionPack_1 = packReduc;
                    }
                    biggestReductionPack_1 = packReduc.reduc > biggestReductionPack_1.reduc ? packReduc : biggestReductionPack_1;
                }
            });
            if (biggestReductionPack_1) {
                this.store.dispatch(new fromOrder.SetPackID(biggestReductionPack_1.id));
                var reduc = Number(biggestReductionPack_1.reduc);
                var packID_1 = parseInt(biggestReductionPack_1.description, 10);
                console.log('packID', packID_1);
                if (this.userPricingPlan !== 0 && this.esateType !== estates.IMMEUBLE_APPART) {
                    reduc = this.dataService.getStatutReduc(packID_1, reduc, this.dataService.getProduct(packID_1));
                    biggestReductionPack_1.reduc = reduc;
                }
                // console.log(reduc);
                if (reduc && reduc !== 0) {
                    reduction = new Reduction(packID_1, reduc, 'Réduction ' + biggestReductionPack_1.name);
                }
                if (!this.showpacks && this.immoPackSelected !== packID_1) {
                    this.snackBar.openFromComponent(PackSelectedComponent, {
                        panelClass: 'snackbar-success',
                        data: biggestReductionPack_1
                    });
                    // console.log(biggestReductionPack);
                }
                /* console.log('biggestReductionPack');
                console.log(biggestReductionPack); */
                this.store.dispatch(new fromOrder.SetImmoPack(packID_1));
                var found = this.services.indexOf(DESCRIPTIF) > -1;
                var descriptifPack = this.descriptifPacks.some(function (id) { return id === packID_1; });
                console.log('found?', found);
                console.log('descriptifPack?', descriptifPack);
                var tempProducts = this.services.slice();
                if (!found && descriptifPack) {
                    console.log('add descriptif');
                    tempProducts.push(DESCRIPTIF);
                    tempProducts.push(PRODUCTS.PLAQUETTE);
                    this.store.dispatch(new fromOrder.SetServices(tempProducts));
                }
                else if (found && !descriptifPack) {
                    // this.products.splice(index, 1);
                    tempProducts = tempProducts.filter(function (pr) { return pr !== DESCRIPTIF; });
                    tempProducts = tempProducts.filter(function (pr) { return pr !== PRODUCTS.PLAQUETTE; });
                    this.store.dispatch(new fromOrder.SetServices(tempProducts));
                }
            }
            else {
                this.store.dispatch(new fromOrder.SetPackID(''));
                var found = this.services.indexOf(DESCRIPTIF) > -1;
                if (found) {
                    var tempProducts = this.services.slice();
                    tempProducts = tempProducts.filter(function (pr) { return pr !== DESCRIPTIF; });
                    tempProducts = tempProducts.filter(function (pr) { return pr !== PRODUCTS.PLAQUETTE; });
                    this.store.dispatch(new fromOrder.SetServices(tempProducts));
                }
                this.store.dispatch(new fromOrder.SetImmoPack(-1));
            }
        }
        return reduction;
    };
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], OrderEffects.prototype, "actionReason$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], OrderEffects.prototype, "actionRegion$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], OrderEffects.prototype, "actionX$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], OrderEffects.prototype, "actionEstateStyles$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], OrderEffects.prototype, "actionY$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], OrderEffects.prototype, "actionNbOfApp$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], OrderEffects.prototype, "actionOnlinePayment$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], OrderEffects.prototype, "actionPromoCode$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], OrderEffects.prototype, "actionGazChoice$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], OrderEffects.prototype, "actionInvoiceTo$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], OrderEffects.prototype, "actionSetUserForm$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], OrderEffects.prototype, "actionSetUserInfo$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], OrderEffects.prototype, "actionUrgent$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], OrderEffects.prototype, "actionParutions$", void 0);
    return OrderEffects;
}());
export { OrderEffects };
