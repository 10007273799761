import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Print } from 'src/app/core/models/print';
import { AppState } from 'src/app/interfaces';
import { Store } from '@ngrx/store';
import { SetParutions } from 'src/app/core/store/order';
import { getParutions } from 'src/app/core/store/order/order.selectors';

@Component({
  selector: 'app-print-request-dialog',
  templateUrl: './print-request-dialog.component.html',
  styleUrls: ['./print-request-dialog.component.scss']
})
export class PrintRequestDialogComponent implements OnInit {
  tomorrow = new Date();

  selectedChoice = 0;

  currentParu;

  showAddParu = false;
  editing = false;

  options = [
    { name: 'Vland (lundi)', day: 1, price: 255 },
    { name: 'Le Soir (jeudi)', day: 4, price: 240 },
    { name: 'Niewsblad/standaard (samedi)', day: 6, price: 195 },
    { name: 'Het Laatste Nieuws (samedi)', day: 6, price: 300 },
    { name: 'La Libre Immo + La dernière Heure (jeudi)', day: 4, price: 370 }
  ];

  paruList: Print[] = [];

  defaultParu = {
    name: this.options[0].name,
    needPhoto: true,
    date: null,
    day: this.options[0].day,
    price: this.options[0].price
  };

  testData = [
    {
      name: 'Vland (lundi)',
      needPhoto: true,
      date: new Date(),
      day: 1,
      price: 255
    },
    {
      name: 'Le Soir (jeudi)',
      needPhoto: false,
      date: new Date(),
      day: 4,
      price: 240
    },
    {
      name: 'Niewsblad/standaard (samedi)',
      needPhoto: true,
      date: new Date(),
      day: 6,
      price: 195
    }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { dialog: MatDialog },
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.currentParu = this.defaultParu;
    this.store.select(getParutions).subscribe(p => (this.paruList = p));
  }

  setSupportType(index) {
    this.currentParu.name = this.options[index].name;
    this.currentParu.day = this.options[index].day;
    this.currentParu.price = this.options[index].price;
  }

  filterWeekend(d: Date) {
    const day = d.getDay();
    return day === 1 || day === 2 || day === 3 || day === 4 || day === 5;
  }

  filterOneDay(d: Date) {
    const day = d.getDay();
    return day === this.currentParu.day;
  }

  addSupport() {
    if (this.currentParu.day) {
      this.paruList.push(this.currentParu);
      this.showAddParu = false;
    }
  }

  displayAddParu() {
    this.showAddParu = true;
    this.currentParu = { ...this.defaultParu };
  }

  deleteParu(index) {
    this.paruList.splice(index, 1);
  }

  editParu(index) {
    this.editing = true;
    this.showAddParu = true;
    this.currentParu = this.paruList[index];
    this.selectedChoice = this.options.indexOf(this.options.find(p => p.name === this.currentParu.name));
  }

  saveEditSupport() {
    this.editing = false;
    this.showAddParu = false;
  }

  close() {
    this.data.dialog.closeAll();
  }

  saveParu() {
    this.store.dispatch(new SetParutions(this.paruList));
    this.close();
  }

  backToList() {
    this.showAddParu = false;
  }
}
