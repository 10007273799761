import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var SpinnerOverlayService = /** @class */ (function () {
    function SpinnerOverlayService() {
        this.loaderSubject = new Subject();
        this.loaderState = this.loaderSubject.asObservable();
    }
    SpinnerOverlayService.prototype.show = function () {
        this.loaderSubject.next({ show: true });
    };
    SpinnerOverlayService.prototype.hide = function () {
        this.loaderSubject.next({ show: false });
    };
    SpinnerOverlayService.ngInjectableDef = i0.defineInjectable({ factory: function SpinnerOverlayService_Factory() { return new SpinnerOverlayService(); }, token: SpinnerOverlayService, providedIn: "root" });
    return SpinnerOverlayService;
}());
export { SpinnerOverlayService };
