import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
var UpdateSwService = /** @class */ (function () {
    function UpdateSwService(updates) {
        this.updates = updates;
        if (updates.isEnabled) {
            interval(1000 * 60).subscribe(function () {
                return updates.checkForUpdate().then(function () { return console.log('✅✅✅ checking for updates'); });
            });
        }
    }
    UpdateSwService.prototype.checkForUpdates = function () {
        var _this = this;
        this.updates.available.subscribe(function (event) { return _this.promptUser(); });
    };
    UpdateSwService.prototype.promptUser = function () {
        console.log('🎉🎉🎉 updating to new version 🎉🎉🎉');
        localStorage.clear();
        this.updates.activateUpdate().then(function () { return document.location.reload(); });
    };
    UpdateSwService.ngInjectableDef = i0.defineInjectable({ factory: function UpdateSwService_Factory() { return new UpdateSwService(i0.inject(i1.SwUpdate)); }, token: UpdateSwService, providedIn: "root" });
    return UpdateSwService;
}());
export { UpdateSwService };
