import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DataService } from '../core/services/data.service';
import { Store } from '@ngrx/store';
import { AppState } from '../interfaces';
import { getOnline } from '../core/store/ui/ui.selectors';

@Injectable()
export class OrderResolver implements Resolve<any> {
  online: boolean;
  constructor(private dataService: DataService, private store: Store<AppState>) {
    this.store.select(getOnline).subscribe(o => {
      this.online = o;
    });
  }

  resolve(): Observable<any> {
    console.log(this.online);
    if (this.online) {
      console.log('resolve online');
      return this.dataService.getPriceFromAPI().pipe(map(dataFromApi => dataFromApi));
    } else {
      console.log('resolve offline');
      return null;
    }
  }
}
