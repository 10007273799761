/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./spinner-overlay.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./spinner-overlay.component";
import * as i4 from "./spinner-overlay.service";
var styles_SpinnerOverlayComponent = [i0.styles];
var RenderType_SpinnerOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SpinnerOverlayComponent, data: {} });
export { RenderType_SpinnerOverlayComponent as RenderType_SpinnerOverlayComponent };
function View_SpinnerOverlayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "loader-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "loader__bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "loader__bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "loader__bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "loader__bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "loader__bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "loader__ball"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading\u2026"]))], null, null); }
export function View_SpinnerOverlayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "wrapper"]], [[2, "hidden", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpinnerOverlayComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.show; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.show; _ck(_v, 0, 0, currVal_0); }); }
export function View_SpinnerOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, View_SpinnerOverlayComponent_0, RenderType_SpinnerOverlayComponent)), i1.ɵdid(1, 245760, null, 0, i3.SpinnerOverlayComponent, [i4.SpinnerOverlayService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SpinnerOverlayComponentNgFactory = i1.ɵccf("app-loader", i3.SpinnerOverlayComponent, View_SpinnerOverlayComponent_Host_0, {}, {}, []);
export { SpinnerOverlayComponentNgFactory as SpinnerOverlayComponentNgFactory };
