var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { SetParutions } from 'src/app/core/store/order';
import { getParutions } from 'src/app/core/store/order/order.selectors';
var PrintRequestDialogComponent = /** @class */ (function () {
    function PrintRequestDialogComponent(data, store) {
        this.data = data;
        this.store = store;
        this.tomorrow = new Date();
        this.selectedChoice = 0;
        this.showAddParu = false;
        this.editing = false;
        this.options = [
            { name: 'Vland (lundi)', day: 1, price: 255 },
            { name: 'Le Soir (jeudi)', day: 4, price: 240 },
            { name: 'Niewsblad/standaard (samedi)', day: 6, price: 195 },
            { name: 'Het Laatste Nieuws (samedi)', day: 6, price: 300 },
            { name: 'La Libre Immo + La dernière Heure (jeudi)', day: 4, price: 370 }
        ];
        this.paruList = [];
        this.defaultParu = {
            name: this.options[0].name,
            needPhoto: true,
            date: null,
            day: this.options[0].day,
            price: this.options[0].price
        };
        this.testData = [
            {
                name: 'Vland (lundi)',
                needPhoto: true,
                date: new Date(),
                day: 1,
                price: 255
            },
            {
                name: 'Le Soir (jeudi)',
                needPhoto: false,
                date: new Date(),
                day: 4,
                price: 240
            },
            {
                name: 'Niewsblad/standaard (samedi)',
                needPhoto: true,
                date: new Date(),
                day: 6,
                price: 195
            }
        ];
    }
    PrintRequestDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentParu = this.defaultParu;
        this.store.select(getParutions).subscribe(function (p) { return (_this.paruList = p); });
    };
    PrintRequestDialogComponent.prototype.setSupportType = function (index) {
        this.currentParu.name = this.options[index].name;
        this.currentParu.day = this.options[index].day;
        this.currentParu.price = this.options[index].price;
    };
    PrintRequestDialogComponent.prototype.filterWeekend = function (d) {
        var day = d.getDay();
        return day === 1 || day === 2 || day === 3 || day === 4 || day === 5;
    };
    PrintRequestDialogComponent.prototype.filterOneDay = function (d) {
        var day = d.getDay();
        return day === this.currentParu.day;
    };
    PrintRequestDialogComponent.prototype.addSupport = function () {
        if (this.currentParu.day) {
            this.paruList.push(this.currentParu);
            this.showAddParu = false;
        }
    };
    PrintRequestDialogComponent.prototype.displayAddParu = function () {
        this.showAddParu = true;
        this.currentParu = __assign({}, this.defaultParu);
    };
    PrintRequestDialogComponent.prototype.deleteParu = function (index) {
        this.paruList.splice(index, 1);
    };
    PrintRequestDialogComponent.prototype.editParu = function (index) {
        var _this = this;
        this.editing = true;
        this.showAddParu = true;
        this.currentParu = this.paruList[index];
        this.selectedChoice = this.options.indexOf(this.options.find(function (p) { return p.name === _this.currentParu.name; }));
    };
    PrintRequestDialogComponent.prototype.saveEditSupport = function () {
        this.editing = false;
        this.showAddParu = false;
    };
    PrintRequestDialogComponent.prototype.close = function () {
        this.data.dialog.closeAll();
    };
    PrintRequestDialogComponent.prototype.saveParu = function () {
        this.store.dispatch(new SetParutions(this.paruList));
        this.close();
    };
    PrintRequestDialogComponent.prototype.backToList = function () {
        this.showAddParu = false;
    };
    return PrintRequestDialogComponent;
}());
export { PrintRequestDialogComponent };
