import { Store } from '@ngrx/store';
import * as orderSelector from '../store/order/order.selectors';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, delay } from 'rxjs/operators';
import { throwError, Subject } from 'rxjs';
import { User, Role } from '../models/user';
import { ResetOrderState, SetUserInfo, ResetUserInfo } from '../store/order';
import { Router } from '@angular/router';
import { SetResetStepper, ResetUI } from '../store/ui';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
import * as i3 from "@angular/router";
var loginURL = "https://systems.certinergie.be/api/LoginWeb/LoginUser";
var loginWithUserIDURL = "https://systems.certinergie.be/api/LoginWeb/LoginUserID";
var emailCheckURL = "https://systems.certinergie.be/api/LoginWeb/CheckEmailAlreadyInUse";
var AuthService = /** @class */ (function () {
    function AuthService(http, store, router) {
        var _this = this;
        this.http = http;
        this.store = store;
        this.router = router;
        this.loginStatus = new Subject();
        this.store.select(orderSelector.getUserID).subscribe(function (u) { return (_this.userId = u); });
    }
    AuthService.prototype.isLoggedIn = function () {
        return this.store.select(orderSelector.getIsLoggedIn);
    };
    AuthService.prototype.login = function (email, password) {
        var _this = this;
        var logged = false;
        this.isLoggedIn().subscribe(function (bool) { return (logged = bool); });
        if (logged) {
            return;
        }
        var httpParams = new HttpParams().append('login', email).append('password', password);
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            params: httpParams
        };
        this.http
            .post(loginURL, null, httpOptions)
            .pipe(catchError(this.handleError))
            .subscribe(function (data) {
            var userDB = data.Data.User;
            // console.log(userDB);
            // 1 success - 2 pw invalid - 3 email doesn't exist
            var loginStatus = data.Data.LoginStatus;
            _this.loginStatus.next(loginStatus);
            // this.store.dispatch(new SetIsLoggedIn(logged));
            if (loginStatus === 1) {
                var user = _this.mapUserdbToUser(userDB);
                _this.store.dispatch(new SetUserInfo(user));
            }
        });
    };
    AuthService.prototype.loginWithUserID = function (userid) {
        var _this = this;
        var logged = false;
        this.isLoggedIn().subscribe(function (bool) { return (logged = bool); });
        if (this.userId === userid) {
            return;
        }
        var httpParams = new HttpParams().append('UserID', userid);
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            params: httpParams
        };
        this.http
            .post(loginWithUserIDURL, null, httpOptions)
            .pipe(delay(1100), catchError(this.handleError))
            .subscribe(function (data) {
            var userDB = data.Data.User;
            // console.log('userDB');
            // console.log(userDB);
            // 1 success - 2 pw invalid - 3 email doesn't exist
            var loginStatus = data.Data.LoginStatus;
            _this.loginStatus.next(loginStatus);
            // this.store.dispatch(new SetIsLoggedIn(logged));
            if (loginStatus === 1) {
                var user = _this.mapUserdbToUser(userDB);
                _this.store.dispatch(new SetUserInfo(user));
            }
        });
    };
    AuthService.prototype.orderSuccessLogin = function (userid) {
        this.logoutNoRedirect();
        this.loginWithUserID(userid);
    };
    AuthService.prototype.mapUserdbToUser = function (userDB) {
        var user = new User();
        user.userid = userDB.UserID;
        user.firstname = userDB.FirstName;
        user.lastname = userDB.Name;
        user.email = userDB.Email;
        user.tva = userDB.Address.VatNumber;
        user.phone = userDB.PhoneNumber;
        user.address.addressid = userDB.Address.AddressID;
        user.address.street = userDB.Address.Street;
        user.address.number = userDB.Address.Number;
        user.address.city = userDB.Address.City;
        user.address.zip = userDB.Address.PostalCode;
        user.sendByMail = !userDB.Consumer.ReceiveByEmail;
        user.userType = userDB.Consumer.ConsumerType;
        user.hasCommission = userDB.Consumer.Commission;
        user.pricingPlan = userDB.Consumer.Status;
        user.roles = userDB.Roles.map(function (r) { return new Role(r.RoleID, r.Name); });
        user.customPrices = userDB.Consumer.ConsumerPrices.map(function (c) {
            return ({
                price: c.ConsumerAmount,
                commission: c.ConsumerCommission,
                estateType: c.EstateType,
                region: c.ConsumerRegion,
                productTypeId: c.ProductTypeId
            });
        });
        return user;
    };
    AuthService.prototype.logout = function () {
        this.router.navigate(['']);
        return this.logoutNoRedirect();
    };
    AuthService.prototype.logoutNoRedirect = function () {
        var logged;
        var sub = this.isLoggedIn().subscribe(function (bool) { return (logged = bool); });
        if (true) {
            // this.store.dispatch(new SetIsLoggedIn(!logged));
            this.store.dispatch(new ResetUserInfo(null));
            this.store.dispatch(new ResetOrderState(null));
            this.store.dispatch(new SetResetStepper(true));
            this.store.dispatch(new ResetUI(true));
            this.router.navigate(['']);
        }
        sub.unsubscribe();
        return logged;
    };
    AuthService.prototype.isEmailAlreadyInUse = function (email) {
        var httpParams = new HttpParams().append('email', email);
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            params: httpParams
        };
        return this.http.post(emailCheckURL, null, httpOptions).pipe(catchError(this.handleError));
    };
    AuthService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " + ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient), i0.inject(i2.Store), i0.inject(i3.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
