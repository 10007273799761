export const projectID: 'default' | 'greenfish' | 'mdn' = 'mdn';

export const hasImmoAccessProject = false;
export const hasClientTypeChoice = true;
export const hasAccessAllPack = false;

export const displayHeaderMenu = false;
export const displayHelpSection = false;

export const step1TitleImmo = true;
export const step1SubTitle = true;
export const headerShowPhone = false;
export const showBigFooter = false;
export const showFinalOptions = false;
export const step4ShowByMail = true;

export const displayRegionSelection = false;
export const defaultRegion = 0;

export const filterNewUserTypes = [1689, 1691];

export const getPricesID = -1;
