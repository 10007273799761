<div class="service-page">
  <header>
    <h1>
      {{ 'service-page.recep-chaudiere.title' | translate }}
    </h1>
    <picture class="service-page-banner">
      <img src="../../../assets/images/service-page-recep-chaudiere.jpg" alt=""
    /></picture>
  </header>
  <div class="content">
    <h2>
      {{ 'service-page.recep-chaudiere.intro' | translate }}
    </h2>
    <div [innerHTML]="'service-page.recep-chaudiere.content' | translate"></div>
  </div>
</div>
