<div class="service-page" id="service-page-elec-gaz">
  <header>
    <h1>
      {{ 'service-page.elec-gaz.title' | translate }}
    </h1>
    <picture class="service-page-banner"> <img src="../../../assets/images/service-page-elec-gaz.jpg" alt=""/></picture>
  </header>
  <div class="content">
    <h2>
      {{ 'service-page.elec-gaz.intro' | translate }}
    </h2>
    <div [innerHTML]="'service-page.elec-gaz.content' | translate"></div>
    <div class="promo-container">
      <div [innerHTML]="'service-page.elec-gaz.promo' | translate"></div>
    </div>
    <a class="btn btn-big btn-accent" (click)="toOrdering()">
      {{ 'service-page.elec-gaz.action' | translate }}
    </a>
    <div [innerHTML]="'service-page.elec-gaz.option' | translate"></div>
  </div>
</div>
