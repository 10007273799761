import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppComponent } from './app.component';
import { BigSelectComponent } from './step1/big-select/big-select.component';
import { EffectsModule } from '../../node_modules/@ngrx/effects';
import { environment } from '../environments/environment';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OrderEffects } from './core/effects/order.effects';
import { reducers, metaReducers } from './app.reducers';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedModule } from './shared/shared.module';
import { SimpleSmoothScrollModule } from 'ng2-simple-smooth-scroll';
import { Step1Component } from './step1/step1.component';
import { Step2Component } from './step2/step2.component';
import { Step3Component } from './step3/step3.component';
import { Step4Component } from './step4/step4.component';
import { StoreModule } from '@ngrx/store';
import { PackSelectedComponent } from './snackbars/pack-selected/pack-selected.component';

import { SentryErrorHandler } from './core/services/sentry-error-handler.service';

import * as Sentry from '@sentry/browser';
import { CongratsDialogComponent } from './dialogs/congrats-dialog/congrats-dialog.component';
import { AppRoutingModule } from './app-routing.module';
import { OrderSteppersComponent } from './order-steppers/order-steppers.component';
import { Error404Component } from './error404/error404.component';
import { UIEffects } from './core/effects/ui.effects';
import { TooltipComponent } from './tooltip/tooltip.component';

import { SpecificRequestDialogComponent } from './dialogs/specific-request-dialog/specific-request-dialog.component';
import { LoaderInterceptorService } from './core/spinner-overlay/loader-interceptor.service';

import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { AgencyWelcomeComponent } from './dialogs/agency-welcome/agency-welcome.component';
import { SummaryConfirmDialogComponent } from './dialogs/summary-confirm-dialog/summary-confirm-dialog.component';
import { DefaultDialogComponent } from './dialogs/default-dialog/default-dialog.component';
import { HomepageComponent } from './homepage/homepage.component';
import { ServicePebComponent } from './page-services/service-peb/service-peb.component';
import { ServiceElecComponent } from './page-services/service-elec/service-elec.component';
import { ServicePebElecComponent } from './page-services/service-peb-elec/service-peb-elec.component';
import { ServiceAuditComponent } from './page-services/service-audit/service-audit.component';
import { ServiceGazComponent } from './page-services/service-gaz/service-gaz.component';
import { ServiceResponsablePebComponent } from './page-services/service-responsable-peb/service-responsable-peb.component';
import { ServiceElecGazComponent } from './page-services/service-elec-gaz/service-elec-gaz.component';
import { ServiceCiterneComponent } from './page-services/service-citerne/service-citerne.component';
import { ServicePebChauffageComponent } from './page-services/service-peb-chauffage/service-peb-chauffage.component';
import { ServicePlansComponent } from './page-services/service-plans/service-plans.component';
import { ServiceReceptionChaudiereComponent } from './page-services/service-reception-chaudiere/service-reception-chaudiere.component';
import { ServiceDroneComponent } from './page-services/service-drone/service-drone.component';
import { ServiceConseillerPebComponent } from './page-services/service-conseiller-peb/service-conseiller-peb.component';
import { ServiceElecNonDomComponent } from './page-services/service-elec-non-dom/service-elec-non-dom.component';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { LoginDialogComponent } from './dialogs/login-dialog/login-dialog.component';
import { AuditRequestDialogComponent } from './dialogs/audit-request-dialog/audit-request-dialog.component';
import { PrintRequestDialogComponent } from './dialogs/print-request-dialog/print-request-dialog.component';
import { VisitRequestDialogComponent } from './dialogs/visit-request-dialog/visit-request-dialog.component';
registerLocaleData(localeFr);

if (environment.production) {
  Sentry.init({
    dsn: 'https://279ed45c208b4e7c87d0dd83279b6c53@sentry.io/1391261',
    release: environment.release
  });
}

@NgModule({
  declarations: [
    AppComponent,
    OrderSteppersComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
    BigSelectComponent,
    PackSelectedComponent,
    CongratsDialogComponent,
    LoginDialogComponent,
    Error404Component,
    TooltipComponent,
    SpecificRequestDialogComponent,
    AgencyWelcomeComponent,
    SummaryConfirmDialogComponent,
    DefaultDialogComponent,
    HomepageComponent,
    ServicePebComponent,
    ServiceElecComponent,
    ServicePebElecComponent,
    ServiceAuditComponent,
    ServiceGazComponent,
    ServiceResponsablePebComponent,
    ServiceElecGazComponent,
    ServiceCiterneComponent,
    ServicePebChauffageComponent,
    ServicePlansComponent,
    ServiceReceptionChaudiereComponent,
    ServiceDroneComponent,
    ServiceConseillerPebComponent,
    ServiceElecNonDomComponent,
    AuditRequestDialogComponent,
    PrintRequestDialogComponent,
    VisitRequestDialogComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    EffectsModule.forRoot([OrderEffects, UIEffects]),
    HttpClientModule,
    SharedModule,
    SimpleSmoothScrollModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({}),
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBXzjjPS9asB2iK_wl-ZcMhRc7RLBEUS18',
      libraries: ['places']
    }),
    MatGoogleMapsAutocompleteModule.forRoot()
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    },
    { provide: ErrorHandler, useClass: environment.production ? SentryErrorHandler : ErrorHandler }
  ],
  entryComponents: [
    CongratsDialogComponent,
    DefaultDialogComponent,
    PackSelectedComponent,
    SpecificRequestDialogComponent,
    AgencyWelcomeComponent,
    SummaryConfirmDialogComponent,
    LoginDialogComponent,
    AuditRequestDialogComponent,
    PrintRequestDialogComponent,
    VisitRequestDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
