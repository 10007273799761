<div class="service-page">
  <header>
    <h1>
      {{ 'service-page.audit.title' | translate }}
    </h1>
    <picture class="service-page-banner"> <img src="../../../assets/images/service-page-audit.jpg" alt=""/></picture>
  </header>
  <div class="content">
    <h2>
      {{ 'service-page.audit.intro' | translate }}
    </h2>
    <div [innerHTML]="'service-page.audit.content' | translate"></div>
  </div>
</div>
