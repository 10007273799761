import { OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as orderSelector from '../core/store/order/order.selectors';
import * as uiSelector from '../core/store/ui/ui.selectors';
import { Step2Component } from '../step2/step2.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Step3Component } from '../step3/step3.component';
import { Step1Component } from '../step1/step1.component';
import { SetIsMobile, SetIsTablette, SetResetStepper } from '../core/store/ui';
import { TranslateService } from '@ngx-translate/core';
import { MatStepper } from '@angular/material';
import { DataService } from '../core/services/data.service';
import { delay } from 'rxjs/operators';
var OrderSteppersComponent = /** @class */ (function () {
    function OrderSteppersComponent(store, breakpointObserver, translate, dataService) {
        this.store = store;
        this.breakpointObserver = breakpointObserver;
        this.dataService = dataService;
        translate.setDefaultLang('fr');
        this.store.select(uiSelector.getLanguage).subscribe(function (ln) { return translate.use(ln); });
    }
    OrderSteppersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataService.getPacksFromAPI().subscribe(function (packs) {
            // console.log(packs);
            _this.packs = packs;
        });
        /* this.store.select(orderSelector.getProducts).subscribe(pr => (this.products = pr)); */
        // this.dataService.updatePricesOnResolve(this.route.snapshot.data['prices']);
        /* this.store.select(orderSelector.getServices).subscribe(serv => {
          if (this.products && !this.products[0].price) {
            this.store.dispatch(new SetServices(serv));
          }
        }); */
        this.$products = this.store.select(orderSelector.getProducts);
        this.store.select(orderSelector.getInvoiceTo).subscribe(function (i) { return (_this.invoiceTo = i); });
        this.breakpointObserver.observe(['(min-width: 576px)']).subscribe(function (state) {
            if (state.matches) {
                _this.store.dispatch(new SetIsMobile(false));
            }
            else {
                _this.store.dispatch(new SetIsMobile(true));
            }
        });
        this.breakpointObserver.observe(['(min-width: 768px)']).subscribe(function (state) {
            if (state.matches) {
                _this.store.dispatch(new SetIsTablette(false));
            }
            else {
                _this.store.dispatch(new SetIsTablette(true));
            }
        });
        this.$hasImmoAccess = this.store.select(orderSelector.getHasImmoAccess);
        this.store
            .select(uiSelector.getResetStepper)
            .pipe(delay(500))
            .subscribe(function (bool) {
            if (bool && _this.stepper && _this.stepper !== undefined) {
                _this.stepper.reset();
                _this.store.dispatch(new SetResetStepper(false));
            }
        });
    };
    Object.defineProperty(OrderSteppersComponent.prototype, "frmStep1", {
        get: function () {
            var st = this.Step1Component ? this.Step1Component.step1FormGroup : null;
            if (st) {
                // console.log('st.valid', st.valid);
            }
            return st;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderSteppersComponent.prototype, "frmStep2", {
        get: function () {
            return this.Step2Component ? this.Step2Component.step2FormGroup : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderSteppersComponent.prototype, "isStep3Valid", {
        get: function () {
            var formGP = this.Step3Component ? this.Step3Component.otherDocFormGroup : null;
            var otherValid = this.invoiceTo === 'toOther' && formGP && formGP.valid;
            var valid = this.invoiceTo && this.invoiceTo !== '' && this.invoiceTo !== 'toOther';
            return valid || otherValid;
        },
        enumerable: true,
        configurable: true
    });
    return OrderSteppersComponent;
}());
export { OrderSteppersComponent };
