import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatStepper } from '@angular/material';
import { LoginDialogComponent } from '../dialogs/login-dialog/login-dialog.component';
import { AuthService } from '../core/services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '../interfaces';
import { Observable } from 'rxjs';
import * as userSelector from '../core/store/order/order.selectors';
import { TranslateService } from '@ngx-translate/core';
import { SetLanguage, SetResetStepper } from '../core/store/ui';
import { headerMenu, contactLinks } from '../core/data/header-menu';
import { Router } from '@angular/router';
import { displayHeaderMenu, headerShowPhone } from '../core/data/theme-config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() stepper: MatStepper;
  $isLoggedIn: Observable<boolean>;
  $userEmail: Observable<string>;
  $userID: Observable<string>;

  open = false;
  currentLang: string;
  languageTypes: 'fr' | 'nl' | 'de' | 'en';
  filteredHeaderMenu: { text: string; link: string }[];
  userID: string;

  displayHeaderMenu = displayHeaderMenu;
  headerShowPhone = headerShowPhone;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private store: Store<AppState>,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit() {
    this.$isLoggedIn = this.store.select(userSelector.getIsLoggedIn);
    this.$userEmail = this.store.select(userSelector.getUserEmail);
    this.$userID = this.store.select(userSelector.getUserID);

    this.$userID.subscribe(u => (this.userID = u));

    this.translate.onLangChange.subscribe(data => {
      this.currentLang = data.lang;
      this.filteredHeaderMenu = headerMenu[data.lang];
    });
  }

  openLoginDialog() {
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      panelClass: ['no-padding-dialog', 'full-screen-dialog'],
      data: { name: 'testName', animal: 'testAnimal' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  logOut() {
    this.authService.logout();
  }

  switchLanguage(language: 'fr' | 'nl' | 'de' | 'en') {
    this.store.dispatch(new SetLanguage(language));
  }

  toContact() {
    window.location.href = contactLinks[this.currentLang];
  }

  toOrdering() {
    this.authService.orderSuccessLogin(this.userID);
  }

  toHomePage() {
    this.router.navigate(['/homepage']);
  }
}
