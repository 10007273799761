import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { tap, retryWhen, concatMap, delay } from 'rxjs/operators';
import { SpinnerOverlayService } from './spinner-overlay.service';
@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptorService implements HttpInterceptor {
  constructor(private loaderService: SpinnerOverlayService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.showLoader();
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.onEnd();
        }
      }),
      retryWhen(errors =>
        errors.pipe(
          concatMap((error, count) => {
            if (
              count < 5 &&
              (error.status === 503 || error.status === 504 || error.status === 400 || error.status === 0)
            ) {
              console.log('🚑🚑🚑 retrying ' + count);
              return of(error.status);
            }
            this.onEnd();
            return throwError(error);
          }),
          delay(3000)
        )
      )
    );
  }
  private onEnd(): void {
    this.hideLoader();
  }
  private showLoader(): void {
    // console.log('show loader');
    this.loaderService.show();
  }
  private hideLoader(): void {
    // console.log('hide loader');
    this.loaderService.hide();
  }
}
