var _a, _b;
export var headerMenu = (_a = {},
    _a['fr'] = [
        {
            text: 'Certificat PEB',
            link: '/services/peb'
        },
        {
            text: 'Contrôle électrique',
            link: '/services/controle-electrique'
        },
        {
            text: 'Contrôle mazout',
            link: '/services/controle-citerne'
        },
        {
            text: 'Contrôle Gaz',
            link: '/services/controle-gaz'
        },
        {
            text: 'Audit',
            link: '/services/audit'
        }
    ],
    _a['nl'] = [
        {
            text: 'EPC certificaat',
            link: 'https://www.certinergie.be/nl/epc-certificaat/'
        },
        {
            text: 'Elektrische keuring',
            link: 'https://www.certinergie.be/nl/elektrische-keuring/'
        },
        {
            text: 'Combi - keuring',
            link: 'https://www.certinergie.be/nl/combi-keuring/'
        },
        {
            text: 'Stookolietank keuring',
            link: 'https://www.certinergie.be/nl/stookoliekeuring/'
        },
        {
            text: 'FAQ',
            link: 'https://www.certinergie.be/nl/veelgestelde-vragen/epc/'
        }
    ],
    _a['en'] = [
        {
            text: 'Energy Performance Certificate',
            link: 'https://www.certinergie.be/en/energy-performance-certificate/'
        },
        {
            text: 'Electricity inspection',
            link: 'https://www.certinergie.be/en/electricity-inspection/'
        },
        {
            text: 'Fuel oil tank check',
            link: 'https://www.certinergie.be/en/fuel-oil-tank-check/'
        }
    ],
    _a['de'] = [
        {
            text: 'Energiepass',
            link: 'https://www.certinergie.be/de/energiepass/'
        },
        {
            text: 'Prüfung Elektoinstallation',
            link: 'https://www.certinergie.be/de/prufung-elektoinstallation/'
        }
    ],
    _a);
export var contactLinks = (_b = {},
    _b['fr'] = 'https://www.certinergie.be/fr/contact/',
    _b['nl'] = 'https://www.certinergie.be/nl/contact-en-afspraak/',
    _b['en'] = 'https://www.certinergie.be/en/contact-and-appointment/',
    _b['de'] = 'https://www.certinergie.be/en/contact-and-appointment/',
    _b);
