<form [formGroup]="step2FormGroup">
  <div class="step-content">
    <section *ngIf="($isLoggedIn | async)" class="section-user-connected">
      <h6>{{ 'section-user.disco.your-info' | translate }}</h6>
      <div>
        <p>
          <span>{{ 'section-user.disco.lastname' | translate }}:</span> {{ user.firstname }} {{ user.lastname }}
        </p>
        <p *ngIf="user.address.street">
          <span>{{ 'section-user.connected.address' | translate }}:</span> {{ user.address.street }}
          {{ user.address.number }}, {{ user.address.zip }}
          {{ user.address.city }}
        </p>
        <p>
          <span>{{ 'section-user.disco.email' | translate }}:</span> {{ user.email }}
        </p>
      </div>
    </section>
    <section *ngIf="!($isLoggedIn | async)" formGroupName="user" class="section-user-disconnected">
      <div *ngIf="($newUserType | async) as newUserType" class="section-user-disconnected--subsection">
        <h6>{{ 'section-user.disco.your-info' | translate }}</h6>
        <div class="section-user-disconnected--info">
          <mat-form-field class="user-firstname" appearance="outline">
            <mat-label>{{ 'section-user.disco.firstname' | translate }}</mat-label>
            <input
              matInput
              placeholder="Jane"
              name="firstname"
              autocomplete="given-name"
              formControlName="firstName"
              required
            />
            <mat-error *ngIf="step2FormGroup.get('user.firstName')?.hasError('required')">
              {{ 'error-messages.required-blank' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="user-lastname" appearance="outline">
            <mat-label>{{ 'section-user.disco.lastname' | translate }}</mat-label>
            <input
              matInput
              placeholder="Doe"
              name="lastname"
              autocomplete="family-name "
              formControlName="lastName"
              required
            />
            <mat-error *ngIf="step2FormGroup.get('user.lastName')?.hasError('required')">
              {{ 'error-messages.required-blank' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="user-phone" appearance="outline">
            <mat-label>{{ 'section-user.disco.phone' | translate }}</mat-label>
            <input
              matInput
              placeholder="0123 45 67 89"
              type="tel"
              name="phone"
              autocomplete="tel"
              formControlName="phone"
              required
            />
            <mat-error *ngIf="step2FormGroup.get('user.phone')?.hasError('required')">
              {{ 'error-messages.required-blank' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="user-mail" appearance="outline">
            <mat-label>{{ 'section-user.disco.email' | translate }}</mat-label>
            <input
              matInput
              placeholder="youremail@example.com"
              type="email"
              name="email"
              autocomplete="email"
              formControlName="email"
              required
            />
            <mat-error *ngIf="step2FormGroup.get('user.email')?.hasError('required')">
              {{ 'error-messages.required-blank' | translate }}
            </mat-error>
            <mat-error *ngIf="step2FormGroup.get('user.email').hasError('isEmailAlreadyInUse')">
              {{ 'section-user.disco.email-error-usedemail' | translate }}
            </mat-error>
          </mat-form-field>
          <div *ngIf="newUserType === userTypes.Particulier" class="section-proprietaire--societe">
            <mat-checkbox #tvacheckbox>
              <span class="checkbox-label">{{ 'section-user.disco.societe-label' | translate }}</span>
            </mat-checkbox>
            <div *ngIf="tvacheckbox.checked" class="section-proprietaire--societe-plus">
              <mat-form-field class="user-societe-name" appearance="outline">
                <mat-label>{{ 'section-user.disco.societe-name' | translate }}</mat-label>
                <input
                  matInput
                  placeholder="Jane Doe corporation"
                  name="companyname"
                  autocomplete="companyname"
                  formControlName="companyname"
                />
                <mat-error *ngIf="step2FormGroup.get('user.firstName')?.hasError('required')">
                  {{ 'error-messages.required-blank' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="user-societe-tva" appearance="outline">
                <mat-label>{{ 'section-user.disco.societe-tva' | translate }}</mat-label>
                <input
                  matInput
                  placeholder="BE 0831.937.722"
                  name="usertva"
                  autocomplete="tva"
                  formControlName="usertva"
                />
                <mat-error *ngIf="step2FormGroup.get('user.lastName')?.hasError('required')">
                  {{ 'error-messages.required-blank' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div *ngIf="newUserType !== userTypes.Particulier" class="propriete-info">
          <mat-form-field appearance="outline" class="search-adress">
            <mat-label>{{ 'inputs.google-map-search' | translate }}</mat-label>
            <input
              matGoogleMapsAutocomplete
              [country]="['be']"
              [types]="['address']"
              (onAutocompleteSelected)="onAutocompleteSelected($event, 'user')"
              matInput
              type="text"
              placeholder="{{ 'inputs.google-map-search' | translate }}..."
            />
          </mat-form-field>
          <div class="propriete-info-form">
            <mat-form-field class="propriete-street" name="street" appearance="outline">
              <mat-label>{{ 'section-user.disco.street' | translate }}</mat-label>
              <input matInput placeholder="Rue de l'exemple" formControlName="userStreet" required />
              <mat-error *ngIf="step2FormGroup.get('user.userStreet')?.hasError('required')">
                {{ 'error-messages.required-blank' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="propriete-num" appearance="outline">
              <mat-label>{{ 'section-user.disco.num' | translate }}</mat-label>
              <input matInput placeholder="1" autocomplete="off" formControlName="userNum" required />
              <mat-error *ngIf="step2FormGroup.get('user.userNum')?.hasError('required')">
                {{ 'error-messages.required-blank' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="propriete-postalcode" appearance="outline">
              <mat-label>{{ 'section-user.disco.zip' | translate }}</mat-label>
              <input matInput placeholder="1234" formControlName="userZip" required />
              <mat-error *ngIf="step2FormGroup.get('user.userZip')?.hasError('required')">
                {{ 'error-messages.required-blank' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="propriete-city" appearance="outline">
              <mat-label>{{ 'section-user.disco.city' | translate }}</mat-label>
              <input matInput placeholder="Exemple ville" formControlName="userCity" required />
              <mat-error *ngIf="step2FormGroup.get('user.userCity')?.hasError('required')">
                {{ 'error-messages.required-blank' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="propriete-tva" appearance="outline">
              <mat-label>{{ 'section-user.disco.tva' | translate }}</mat-label>
              <input matInput placeholder="Exemple tva" formControlName="tva" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </section>
    <section
      *ngIf="
        (!($isLoggedIn | async) && ($newUserType | async) !== userTypes.Particulier) ||
        (($isLoggedIn | async) && user.userType !== userTypes.Particulier)
      "
      class="section-proprietaire"
      formGroupName="owner"
    >
      <h6>{{ 'section-user.disco.owner-info.title' | translate }}</h6>
      <div class="section-proprietaire--info">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'section-user.disco.firstname' | translate }}</mat-label>
          <input
            matInput
            placeholder="Jane"
            name="firstname"
            autocomplete="given-name"
            formControlName="firstName"
            required
          />
          <mat-error *ngIf="step2FormGroup.get('owner.firstName')?.hasError('required')">
            {{ 'error-messages.required-blank' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'section-user.disco.lastname' | translate }}</mat-label>
          <input
            matInput
            placeholder="Doe"
            name="lastname"
            autocomplete="family-name "
            formControlName="lastName"
            required
          />
          <mat-error *ngIf="step2FormGroup.get('owner.lastName')?.hasError('required')">
            {{ 'error-messages.required-blank' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'section-user.disco.phone' | translate }}</mat-label>
          <input matInput placeholder="0123 45 67 89" formControlName="phone" required />
          <mat-error *ngIf="step2FormGroup.get('owner.phone')?.hasError('required')">
            {{ 'error-messages.required-blank' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'section-user.disco.email' | translate }}</mat-label>
          <input matInput placeholder="youremail@example.com" formControlName="email" />
          <div *ngIf="owner.email !== null" class="email-danger">
            <fa-icon icon="exclamation-triangle"></fa-icon>
            <p class="caption">
              {{ 'section-user.disco.email-danger' | translate }}
            </p>
          </div>
          <mat-error *ngIf="step2FormGroup.get('owner.email')?.hasError('required')">
            {{ 'error-messages.required-blank' | translate }}
          </mat-error>
          <mat-error *ngIf="step2FormGroup.get('user.email')?.hasError('isEmailAlreadyInUse')">
            Email déjà utilisé
          </mat-error>
        </mat-form-field>
      </div>
    </section>
    <section class="section-propriete" formGroupName="address">
      <h6>{{ 'section-propriete.title' | translate }}</h6>
      <div class="propriete-info">
        <mat-form-field appearance="outline" class="search-adress">
          <mat-label>Recherche avec Google Maps</mat-label>
          <input
            matGoogleMapsAutocomplete
            [country]="['be']"
            [types]="['address']"
            (onAutocompleteSelected)="onAutocompleteSelected($event, 'owner')"
            matInput
            type="text"
            placeholder="Recherche avec Google Maps..."
          />
        </mat-form-field>
        <div class="propriete-info-form">
          <mat-form-field class="propriete-street" appearance="outline">
            <mat-label>{{ 'section-user.disco.street' | translate }}</mat-label>
            <input
              matInput
              placeholder="Rue de l'exemple"
              name="address"
              autocomplete="street-address"
              formControlName="orderStreet"
              required
            />
            <mat-error *ngIf="step2FormGroup.get('address.orderStreet')?.hasError('required')">
              {{ 'error-messages.required-blank' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="propriete-num" appearance="outline">
            <mat-label>{{ 'section-user.disco.num' | translate }}</mat-label>
            <input
              matInput
              placeholder="1"
              name="number"
              autocomplete="street-number"
              formControlName="orderNum"
              required
            />
            <mat-error *ngIf="step2FormGroup.get('address.orderNum')?.hasError('required')">
              {{ 'error-messages.required-blank' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="propriete-postalcode" appearance="outline">
            <mat-label>{{ 'section-user.disco.zip' | translate }}</mat-label>
            <input
              matInput
              placeholder="1234"
              name="zip"
              autocomplete="postal-code"
              formControlName="orderZip"
              required
            />
            <mat-error *ngIf="step2FormGroup.get('address.orderZip')?.hasError('required')">
              {{ 'error-messages.required-blank' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="propriete-city" appearance="outline">
            <mat-label>{{ 'section-user.disco.city' | translate }}</mat-label>
            <input matInput placeholder="Exemple ville" name="city" formControlName="orderCity" required />
            <mat-error *ngIf="step2FormGroup.get('address.orderCity')?.hasError('required')">
              {{ 'error-messages.required-blank' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </section>
    <section class="section-cadastre">
      <h6>Cadastre</h6>
      <form [formGroup]="cadastreFG">
        <mat-form-field class="user-division" appearance="outline">
          <mat-label>Division</mat-label>
          <input
            matInput
            placeholder="rue de campine"
            name="division"
            autocomplete="division"
            formControlName="division"
          />
          <mat-error *ngIf="venteFG.get('division')?.hasError('required')">
            {{ 'error-messages.required-blank' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="user-section" appearance="outline">
          <mat-label>Section</mat-label>
          <input matInput placeholder="3" name="section" autocomplete="section" formControlName="section" />
          <mat-error *ngIf="venteFG.get('section')?.hasError('required')">
            {{ 'error-messages.required-blank' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="user-numCadastre" appearance="outline">
          <mat-label>N° cadastre</mat-label>
          <input
            matInput
            placeholder="11"
            name="numCadastre"
            autocomplete="numCadastre"
            formControlName="numCadastre"
          />
          <mat-error *ngIf="venteFG.get('numCadastre')?.hasError('required')">
            {{ 'error-messages.required-blank' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="user-rcni" appearance="outline">
          <mat-label>RCNI</mat-label>
          <input matInput placeholder="11" name="rcni" autocomplete="rcni" formControlName="rcni" />
          <mat-error *ngIf="venteFG.get('rcni')?.hasError('required')">
            {{ 'error-messages.required-blank' | translate }}
          </mat-error>
        </mat-form-field>
      </form>
    </section>
    <section class="section-vente-publique-lieu" *ngIf="($reason | async) !== 1">
      <h6>Lieu de la vente</h6>
      <form [formGroup]="venteFG">
        <mat-form-field class="user-street" appearance="outline">
          <mat-label>{{ 'section-user.disco.street' | translate }}</mat-label>
          <input matInput placeholder="rue de campine" name="street" autocomplete="street" formControlName="street" />
          <mat-error *ngIf="venteFG.get('street')?.hasError('required')">
            {{ 'error-messages.required-blank' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="user-num" appearance="outline">
          <mat-label>{{ 'section-user.disco.num' | translate }}</mat-label>
          <input matInput placeholder="11" name="num" autocomplete="num" formControlName="num" />
          <mat-error *ngIf="venteFG.get('num')?.hasError('required')">
            {{ 'error-messages.required-blank' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="user-city" appearance="outline">
          <mat-label>{{ 'section-user.disco.city' | translate }}</mat-label>
          <input matInput placeholder="11" name="city" autocomplete="city" formControlName="city" />
          <mat-error *ngIf="venteFG.get('city')?.hasError('required')">
            {{ 'error-messages.required-blank' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="user-zip" appearance="outline">
          <mat-label>{{ 'section-user.disco.zip' | translate }}</mat-label>
          <input matInput placeholder="11" name="zip" autocomplete="zip" formControlName="zip" />
          <mat-error *ngIf="venteFG.get('zip')?.hasError('required')">
            {{ 'error-messages.required-blank' | translate }}
          </mat-error>
        </mat-form-field>
      </form>
    </section>
    <section class="section-accueil" formGroupName="contact">
      <h6>
        {{ 'section-accueil.title' | translate }}
        <app-tooltip [tooltipPosition]="'top'" [content]="'section-accueil.tooltip' | translate"></app-tooltip>
      </h6>
      <mat-radio-group [value]="$contactChoice | async">
        <ul>
          <li>
            <mat-radio-button value="sameAsOwner" (change)="setContactChoice('sameAsOwner')">
              {{ 'section-accueil.same-as-owner' | translate }}.
            </mat-radio-button>
          </li>
          <li
            *ngIf="
              ($newUserType | async) === userTypes.Agence ||
              ($newUserType | async) === userTypes.Notaire ||
              user.userType === userTypes.Agence ||
              user.userType === userTypes.Notaire
            "
          >
            <mat-radio-button value="keyAtAgency" (change)="setContactChoice('keyAtAgency')">
              {{ 'section-accueil.key' | translate }}
              {{
                user.userType === userTypes.Agence || ($newUserType | async) === userTypes.Agence
                  ? ('section-accueil.at-agency.title' | translate)
                  : ('section-accueil.at-notary' | translate)
              }}
            </mat-radio-button>
            <div *ngIf="($contactChoice | async) === 'keyAtAgency'" class="info-box">
              <fa-icon icon="info-circle"></fa-icon>
              <div>
                <p>{{ 'section-accueil.at-agency.info' | translate }}</p>
              </div>
            </div>
          </li>

          <li>
            <mat-radio-button value="other" (change)="setContactChoice('other')">
              {{ 'section-accueil.other-person' | translate }}
            </mat-radio-button>
          </li>
        </ul>
      </mat-radio-group>
      <div *ngIf="($contactChoice | async) === 'other'" class="different-person">
        <mat-form-field class="accueil-firstname" appearance="outline">
          <mat-label>{{ 'section-user.disco.firstname' | translate }}</mat-label>
          <input
            matInput
            placeholder="Jane"
            [value]="($contact | async).firstname"
            formControlName="firstName"
            required
          />
          <mat-error *ngIf="step2FormGroup.get('contact.firstName')?.hasError('required')">
            {{ 'error-messages.required-blank' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="accueil-lastname" appearance="outline">
          <mat-label>{{ 'section-user.disco.lastname' | translate }}</mat-label>
          <input matInput placeholder="Doe" [value]="($contact | async).lastname" formControlName="lastName" required />
          <mat-error *ngIf="step2FormGroup.get('contact.lastname')?.hasError('required')">
            {{ 'error-messages.required-blank' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="accueil-phone" appearance="outline">
          <mat-label>{{ 'section-user.disco.phone' | translate }}</mat-label>
          <input
            matInput
            placeholder="0123 45 67 89"
            [value]="($contact | async).phone"
            formControlName="phone"
            required
          />
          <mat-error *ngIf="step2FormGroup.get('contact.phone')?.hasError('required')">
            {{ 'error-messages.required-blank' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </section>
    <section formGroupName="date" class="section-date">
      <h6>
        {{ 'section-accueil.date-butoir.title' | translate }}
        <app-tooltip
          [tooltipPosition]="'top'"
          [content]="'section-accueil.date-butoir.tooltip' | translate"
        ></app-tooltip>
      </h6>
      <mat-form-field appearance="outline" id="date-picker">
        <input
          matInput
          [value]="dateButoire"
          [min]="minDate"
          [matDatepickerFilter]="filterWeekend"
          [matDatepicker]="picker"
          placeholder="DD/MM/YYYY"
          formControlName="dateButoir"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </section>
  </div>
  <div class="step-actions">
    <button class="btn btn-previous" (click)="scrollTop()" matStepperPrevious>
      {{ 'navigation.previous' | translate }}
    </button>
    <button class="btn btn-primary btn-next" (click)="toStep3()" matStepperNext>
      {{ 'navigation.next' | translate }}
    </button>
  </div>
</form>
<!-- {{ step2FormGroup.valid }} -->
