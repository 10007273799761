<div class="service-page">
  <header>
    <h1 id="service-gaz-title">
      {{ 'service-page.gaz.title' | translate }}
    </h1>
    <picture class="service-page-banner"> <img src="../../../assets/images/service-page-gaz.png" alt=""/></picture>
  </header>
  <div class="content">
    <h2>
      {{ 'service-page.gaz.intro' | translate }}
    </h2>
    <div [innerHTML]="'service-page.gaz.content' | translate"></div>
    <a class="btn btn-big btn-accent" (click)="toOrdering()">
      {{ 'service-page.gaz.action' | translate }}
    </a>
    <div [innerHTML]="'service-page.gaz.legal' | translate" id="service-gaz-legal"></div>
  </div>
</div>
