var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { SetVisits, SetVisitContact } from 'src/app/core/store/order';
import { getVisits, getVisitContact } from 'src/app/core/store/order/order.selectors';
import { debounceTime } from 'rxjs/operators';
var VisitRequestDialogComponent = /** @class */ (function () {
    function VisitRequestDialogComponent(_formBuilder, data, store) {
        this._formBuilder = _formBuilder;
        this.data = data;
        this.store = store;
        this.visitEtudeExtended = false;
        this.visitFix = false;
        this.visitFree = false;
        this.visitAdValoris = false;
        this.showAddVisit = false;
        this.editing = false;
        this.selectedChoice = 0;
        this.reason = -1;
        this.visitList = [];
        this.options = [
            { hour: 0 },
            { hour: 9 },
            { hour: 10 },
            { hour: 11 },
            { hour: 12 },
            { hour: 13 },
            { hour: 14 },
            { hour: 15 },
            { hour: 16 },
            { hour: 17 }
        ];
        this.defaultVisit = {
            hour: this.options[0].hour,
            allDay: false,
            date: null
        };
        this.tomorrow = new Date();
        this.visitFreeInfo = {
            lastName: '',
            firstName: '',
            email: '',
            tel: ''
        };
    }
    VisitRequestDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentVisit = this.defaultVisit;
        this.store.select(getVisits).subscribe(function (v) { return (_this.visitList = v); });
        this.store.select(getVisitContact).subscribe(function (v) { return (_this.visitFreeInfo = v); });
        this.visitFreeFG = this._formBuilder.group({
            lastName: [{ value: this.visitFreeInfo.lastName, disabled: false }],
            firstName: [{ value: this.visitFreeInfo.firstName, disabled: false }],
            email: [{ value: this.visitFreeInfo.email, disabled: false }],
            tel: [{ value: this.visitFreeInfo.tel, disabled: false }]
        });
        this.visitFreeFG.valueChanges.pipe(debounceTime(1000)).subscribe(function (values) {
            _this.visitFreeInfo.lastName = values.lastName;
            _this.visitFreeInfo.firstName = values.firstName;
            _this.visitFreeInfo.email = values.email;
            _this.visitFreeInfo.tel = values.tel;
        });
        this.reason = this.data.reason;
    };
    VisitRequestDialogComponent.prototype.setHour = function (index) {
        this.currentVisit.hour = this.options[index].hour;
    };
    VisitRequestDialogComponent.prototype.displayAddVisit = function () {
        this.showAddVisit = true;
        this.currentVisit = __assign({}, this.defaultVisit);
    };
    VisitRequestDialogComponent.prototype.addVisit = function () {
        if (this.currentVisit.date) {
            this.visitList.push(this.currentVisit);
            this.showAddVisit = false;
        }
    };
    VisitRequestDialogComponent.prototype.editVisit = function (index) {
        var _this = this;
        this.editing = true;
        this.showAddVisit = true;
        this.currentVisit = this.visitList[index];
        this.selectedChoice = this.options.indexOf(this.options.find(function (p) { return p.hour === _this.currentVisit.hour; }));
    };
    VisitRequestDialogComponent.prototype.saveEditVisit = function () {
        this.editing = false;
        this.showAddVisit = false;
    };
    VisitRequestDialogComponent.prototype.deleteVisit = function (index) {
        this.visitList.splice(index, 1);
    };
    VisitRequestDialogComponent.prototype.saveVisits = function () {
        this.store.dispatch(new SetVisits(this.visitList));
        this.close();
    };
    VisitRequestDialogComponent.prototype.saveVisitFree = function () {
        if (this.visitFreeInfo.email !== '' &&
            this.visitFreeInfo.firstName !== '' &&
            this.visitFreeInfo.lastName !== '' &&
            this.visitFreeInfo.tel !== '') {
            this.visitFree = false;
            this.store.dispatch(new SetVisitContact(this.visitFreeInfo));
            this.close();
        }
    };
    VisitRequestDialogComponent.prototype.close = function () {
        this.data.dialog.closeAll();
    };
    return VisitRequestDialogComponent;
}());
export { VisitRequestDialogComponent };
