import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SendEmailService = /** @class */ (function () {
    function SendEmailService(http) {
        this.http = http;
        this.apiURL = 'https://systems.certinergie.be/api/mailsending/sendMail';
        this.certiEmail = 'info@certinergie.be';
    }
    SendEmailService.prototype.sendDevis = function (value) {
        console.log('email data: ', value);
        var data = {
            user: {
                name: value.firstName + " " + value.lastName,
                mail: value.email,
                phone: value.phone
            },
            estate: {
                street: value.userStreet,
                zip: value.userZip,
                numbox: value.userNum,
                city: value.userCity
            },
            message: {
                subject: 'Demande de Devis',
                message: value.remarks
            }
        };
        var subject = data.message.subject;
        var message = '<h1>' +
            data.message.subject +
            '</h1>' +
            'Nom: ' +
            data.user.name +
            ' <br/> ' +
            'Email: ' +
            data.user.mail +
            ' <br/> ' +
            'Tel: ' +
            data.user.phone +
            ' <br/> ' +
            'Adresse: ' +
            data.estate.street +
            ' ' +
            data.estate.numbox +
            ' ' +
            data.estate.zip +
            ' ' +
            data.estate.city +
            ' <br/> <br/> ' +
            data.message.message;
        message += '<br/><br/><br/><br/>';
        message += '<small>Une copie de cet email a été envoyée à ' + this.certiEmail + '</small>';
        this.sendMail(data.user.mail, 'Confirmation ' + subject, message).subscribe();
        return this.sendMail(this.certiEmail, subject, message);
    };
    SendEmailService.prototype.sendMail = function (email, subject, message) {
        console.log(email);
        console.log(subject);
        console.log(message);
        return this.http.post(this.apiURL, {
            to: [email],
            subject: subject,
            body: message
        });
    };
    SendEmailService.ngInjectableDef = i0.defineInjectable({ factory: function SendEmailService_Factory() { return new SendEmailService(i0.inject(i1.HttpClient)); }, token: SendEmailService, providedIn: "root" });
    return SendEmailService;
}());
export { SendEmailService };
