import { createSelector } from '@ngrx/store';

import { IOrder } from './order.reducer';
import { AppState } from '../../../interfaces';

import { services, immoServices, panneauxServices } from '../../data/services';
import { Product } from '../../models/product';
import { Reduction } from '../../models/reduction';
import { User } from '../../models/user';
import { IUI } from '../ui';

export const getOrder = (state: AppState): IOrder => state.order;
export const getUser = (state: AppState): User => state.order.user;
export const getState = (state: AppState): AppState => state;

// *** PUBLIC API's ***
export const getRegion = createSelector(
  getOrder,
  (order: IOrder) => order.regionType
);
export const getRegionInfo = createSelector(
  getOrder,
  (order: IOrder) => order.region
);
export const getEstateID = createSelector(
  getOrder,
  (order: IOrder) => order.estateType
);
export const getEstateStyle = createSelector(
  getOrder,
  (order: IOrder) => order.estateStyle
);
export const getEstateName = createSelector(
  getOrder,
  (order: IOrder) => order.estateName
);
export const getNbOfAppartments = createSelector(
  getOrder,
  (order: IOrder) => order.nbOfAppartments
);
export const getFilteredServices = createSelector(
  getState,
  (state: AppState) => filterServices(state.order, state.order.user, state.ui)
);
export const getFilteredImmoServices = createSelector(
  getOrder,
  (order: IOrder) => filterImmoServices(order)
);
export const getServices = createSelector(
  getOrder,
  (order: IOrder) => order.services
);
export const getProducts = createSelector(
  getOrder,
  (order: IOrder) => order.products
);
export const getImmoPack = createSelector(
  getOrder,
  (order: IOrder) => order.immoPack
);
export const getReductions = createSelector(
  getOrder,
  (order: IOrder) => order.reductions
);
export const getOnlinePayment = createSelector(
  getOrder,
  (order: IOrder) => order.onlinePayment
);
export const getPromoCode = createSelector(
  getOrder,
  (order: IOrder) => order.promoCode
);
export const getPromoCodeID = createSelector(
  getOrder,
  (order: IOrder) => order.promoCodeID
);
export const getPriceWithoutReduction = createSelector(
  getOrder,
  (order: IOrder) => order.price
);
export const getReduction = createSelector(
  getOrder,
  (order: IOrder) => order.reduction
);
export const getReductionWithoutComm = createSelector(
  getOrder,
  (order: IOrder) => order.reductionWithoutComm
);
export const getUrgence = createSelector(
  getOrder,
  (order: IOrder) => order.urgence
);
export const hasPeb = createSelector(
  getOrder,
  (order: IOrder) => order.hasPeb
);
export const hasPebPartiel = createSelector(
  getOrder,
  (order: IOrder) => order.hasPebPartiel
);
export const hasElec = createSelector(
  getOrder,
  (order: IOrder) => order.hasElec
);
export const hasCiterne = createSelector(
  getOrder,
  (order: IOrder) => order.hasCiterne
);
export const hasPlan = createSelector(
  getOrder,
  (order: IOrder) => order.hasPlan
);
export const hasGaz = createSelector(
  getOrder,
  (order: IOrder) => order.hasGaz
);
export const nbOfElec = createSelector(
  getOrder,
  (order: IOrder) => order.nbOfElec
);
export const nbOfGaz = createSelector(
  getOrder,
  (order: IOrder) => order.nbOfGaz
);
export const gazChoice = createSelector(
  getOrder,
  (order: IOrder) => order.gazChoice
);
export const getUserForm = createSelector(
  getOrder,
  (order: IOrder) => order.newUser
);
export const getOwner = createSelector(
  getOrder,
  (order: IOrder) => order.owner
);
export const getContact = createSelector(
  getOrder,
  (order: IOrder) => order.contact
);
export const getAddress = createSelector(
  getOrder,
  (order: IOrder) => order.address
);
export const getPaymentType = createSelector(
  getOrder,
  (order: IOrder) => order.paymentType
);
export const getActeType = createSelector(
  getOrder,
  (order: IOrder) => order.acteType
);
export const getSendByMail = createSelector(
  getOrder,
  (order: IOrder) => order.sendByMail
);
export const getRemarks = createSelector(
  getOrder,
  (order: IOrder) => order.remarks
);
export const getInvoiceTo = createSelector(
  getOrder,
  (order: IOrder) => order.invoiceTo
);
export const getDocument = createSelector(
  getOrder,
  (order: IOrder) => order.document
);
/* export const getNewUserType = createSelector(
  getOrder,
  (order: IOrder) => order.newUserType
); */
export const getGoGeyKeys = createSelector(
  getOrder,
  (order: IOrder) => order.goGetKeys
);
export const getCommission = createSelector(
  getOrder,
  (order: IOrder) => order.commission
);
export const getContactChoice = createSelector(
  getOrder,
  (order: IOrder) => order.contactChoice
);
export const getDateButoir = createSelector(
  getOrder,
  (order: IOrder) => order.dateButoir
);
export const getCustomPrices = createSelector(
  getUser,
  (user: User) => user.customPrices
);
export const getPricingPlan = createSelector(
  getUser,
  (user: User) => user.pricingPlan
);
export const getFilteredPanneauxServices = createSelector(
  getOrder,
  (order: IOrder) => filterPanneauxServices(order)
);

// *** PUBLIC USER API's ***
export const getUserInfo = createSelector(
  getUser,
  (user: User) => {
    const u = new User();
    u.firstname = user.firstname;
    u.lastname = user.lastname;
    u.phone = user.phone;
    u.userType = user.userType;
    u.sendByMail = user.sendByMail;
    u.email = user.email;
    u.address = user.address;
    u.userid = user.userid;

    return u;
  }
);
export const getUserID = createSelector(
  getUser,
  (user: User) => user.userid
);
export const getHasImmoAccess = createSelector(
  getUser,
  (user: User) => user.hasImmoAccess
);
export const getIsLoggedIn = createSelector(
  getUser,
  (user: User) => user.userid && user.userid !== ''
);
export const getUserType = createSelector(
  getUser,
  (user: User) => user.userType
);
export const getUserRoles = createSelector(
  getUser,
  (user: User) => user.roles
);
export const getHasCommission = createSelector(
  getUser,
  (user: User) => user.hasCommission
);
export const getUserEmail = createSelector(
  getUser,
  (user: User) => user.email
);
export const getNN = createSelector(
  getOrder,
  (order: IOrder) => order.nn
);
export const pebNb = createSelector(
  getOrder,
  (order: IOrder) => order.pebNb
);

export const packID = createSelector(
  getOrder,
  (order: IOrder) => order.packID
);

export const getNbOfIso = createSelector(
  getOrder,
  (order: IOrder) => order.nbOfIso
);

export const getReason = createSelector(
  getOrder,
  (order: IOrder) => order.reason
);

export const getClientType = createSelector(
  getOrder,
  (order: IOrder) => order.clientType
);

export const getVenteType = createSelector(
  getOrder,
  (order: IOrder) => order.venteType
);

export const getVenteStatut = createSelector(
  getOrder,
  (order: IOrder) => order.venteInfo.venteStatut
);

export const getParutions = createSelector(
  getOrder,
  (order: IOrder) => order.parutions
);

export const getVisits = createSelector(
  getOrder,
  (order: IOrder) => order.visits
);

export const getParutionsLength = createSelector(
  getOrder,
  (order: IOrder) => order.parutions && order.parutions.length
);

export const getVisitsLength = createSelector(
  getOrder,
  (order: IOrder) => order.visits && order.visits.length
);

export const getVisitContact = createSelector(
  getOrder,
  (order: IOrder) => order.visitContact
);

function filterServices(order: IOrder, user: User, ui: IUI) {
  let serv = services;
  if (order.regionType > -1) {
    serv = serv.filter(service => service.regions.indexOf(order.regionType) > -1);
  }
  if (order.estateStyle !== -1) {
    serv = serv.filter(service => service.biens.indexOf(order.estateStyle) > -1);
  }
  if (!ui.showMoreServices && order.reason > -1) {
    serv = serv.filter(service => service.reasons.indexOf(order.reason) > -1);
  }

  /* if (user.hasImmoAccess) {
    serv = serv.filter(service => service.id !== 14);
  } */

  return serv;
}

function filterImmoServices(order: IOrder) {
  let serv = immoServices;
  if (order.regionType > -1) {
    serv = serv.filter(service => service.regions.indexOf(order.regionType) > -1);
  }
  if (order.estateStyle !== -1) {
    serv = serv.filter(service => service.biens.indexOf(order.estateStyle) > -1);
  }
  if (order.reason > -1) {
    serv = serv.filter(service => service.reasons.indexOf(order.reason) > -1);
  }
  return serv;
}

function filterPanneauxServices(order: IOrder) {
  let serv = panneauxServices;
  if (order.regionType > -1) {
    serv = serv.filter(service => service.regions.indexOf(order.regionType) > -1);
  }
  if (order.estateStyle !== -1) {
    serv = serv.filter(service => service.biens.indexOf(order.estateStyle) > -1);
  }

  return serv;
}
