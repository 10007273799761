import { Component, OnInit, ChangeDetectorRef, AfterViewChecked, Input, AfterContentInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { SimpleSmoothScrollService } from 'ng2-simple-smooth-scroll';
import { SimpleSmoothScrollOption } from 'ng2-simple-smooth-scroll';

import { DataService } from '../core/services/data.service';
import { BreakpointObserver } from '@angular/cdk/layout';

import { AppState } from '../interfaces';
import * as orderSelector from '../core/store/order/order.selectors';
import * as uiSelector from '../core/store/ui/ui.selectors';

import { filterNewUserTypes } from '../core/data/theme-config';

import {
  SetEstateType,
  SetRegion,
  SetNumberOfAppartments,
  SetServices,
  SetNbOfElec,
  ToggleOnlinePayment,
  SetPromoCode,
  ToggleUrgence,
  SetCommission,
  SetEstateStyle,
  SetNbOfGaz,
  SetGazChoice,
  SetPebNb,
  SetNbOfIso,
  ResetOrderState,
  SetContactChoice,
  SetNewUserType,
  SetZipCode,
  SetReason,
  SetClientType,
  SetVenteInfo
} from '../core/store/order';
import { Observable, combineLatest, Subscription } from 'rxjs';

import * as estates from '../core/data/estates';
import { Product } from '../core/models/product';

import * as PRODUCTS from '../core/data/products';
import { Reduction } from '../core/models/reduction';
import { MatDialog, MatStepper } from '@angular/material';
import { LoginDialogComponent } from '../dialogs/login-dialog/login-dialog.component';
import { SpecificRequestDialogComponent } from '../dialogs/specific-request-dialog/specific-request-dialog.component';
import { SummaryConfirmDialogComponent } from '../dialogs/summary-confirm-dialog/summary-confirm-dialog.component';
import { FormGroup, Validators, FormBuilder, FormControl, AbstractControl } from '@angular/forms';
import { Role, UserType } from '../core/models/user';
import { SetShowPacks, SetStep1Valid, SetPacksWithPrices, ShowMoreServices } from '../core/store/ui';
import { PackDB } from '../core/models/PackDB';
import { descriptifPacks, hasImmoAccessProject, hasAccessAllPack } from '../core/data';
import { DESCRIPTIF } from '../core/data/products';
import { ActivatedRoute } from '@angular/router';
import { Region } from '../core/models/region';
import { map, delay, debounceTime } from 'rxjs/operators';
import { AgencyWelcomeComponent } from '../dialogs/agency-welcome/agency-welcome.component';
import { GoogleAnalyticsService } from '../core/services/google-analytics.service';
import { DefaultDialogComponent } from '../dialogs/default-dialog/default-dialog.component';
import {
  displayRegionSelection,
  step1TitleImmo,
  step1SubTitle,
  headerShowPhone,
  showFinalOptions,
  projectID
} from '../core/data/theme-config';
import { userTypePartAndPro } from '../core/data/newUserTypes';
import { reasons } from '../core/data/reasons';
import { imgagesFolder } from '../core/services/globals';
import { AuditRequestDialogComponent } from '../dialogs/audit-request-dialog/audit-request-dialog.component';
import { PrintRequestDialogComponent } from '../dialogs/print-request-dialog/print-request-dialog.component';
import { VisitRequestDialogComponent } from '../dialogs/visit-request-dialog/visit-request-dialog.component';
import { VisitContact } from '../core/models/visitContact';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss']
})
export class Step1Component implements OnInit, AfterViewChecked, AfterContentInit {
  assetsFolder = 'assets/images/';
  descriptifPacks: number[];

  @Input() packs: PackDB[];
  @Input() stepper: MatStepper;
  regions;
  estateTypes;
  estateStyles;
  noPlaceHolderEstateStyles;
  immoPacks;
  houseOptions: HouseOption[];
  appartOptions;
  immeubleOptions;
  elecOptions;
  gazOptions;
  promoCode: string;
  pebNb = '';
  priceWithoutReduction: number;
  reduction: number;
  howManyControlSelected: number;
  howManyImmoSelected: number;
  reductionPromoCode: string;
  estateType: number;
  pebPartiel = false;
  wantsIso = false;
  hasImmoAccess: boolean;
  hasPanel = false;
  hasPanelSupport = false;
  hasBidditPanel = false;
  hasBidditPanelSupport = false;
  showFinalOptions = showFinalOptions;
  showMoreServices: boolean;
  minDate = new Date();
  printLength;
  visitLength;
  reason: number;

  ventePubChoices = [
    {
      id: 1,
      name: 'Première séance'
    },
    {
      id: 2,
      name: 'Séance ouverte'
    }
  ];

  venteInfo = {
    statusVentePub: '',
    venteDate: ''
  };

  displayRegionSelection = displayRegionSelection;
  step1TitleImmo = step1TitleImmo;
  projectID = projectID !== 'default' ? '-' + projectID : '';

  hasImmoAccessProject = hasImmoAccessProject;
  hasAccessAllPack = hasAccessAllPack;

  succintStep1 = false;
  step1SubTitle = step1SubTitle;
  headerShowPhone = headerShowPhone;

  houseControl = new FormControl();
  appartControl = new FormControl();
  buildingControl = new FormControl();
  situationControl = new FormControl();

  reasons = reasons;
  UserTypes = UserType;
  filterNewUserTypes = filterNewUserTypes;
  clientTypes: { id: number | string; txt: string; fileName: string }[];
  clientTypeIndex: number;
  reasonIndex: number;

  step1FormGroup: FormGroup;
  ventePubFG: FormGroup;

  products: number[];
  productsDB: Product[];

  $selectedRegion: Observable<number>;
  $selectedEstateType: Observable<number>;
  $selectedEstateStyle: Observable<number>;
  $selectedImmoPack: Observable<number>;
  $selectedUserType: Observable<number>;
  $nbOfAppartments: Observable<number>;
  $filteredServices: Observable<any>;
  $filteredImmoServices: Observable<any>;
  $filteredPanneauxServices: Observable<any>;
  $products: Observable<Product[]>;
  $services: Observable<number[]>;
  $reductions: Observable<Reduction[]>;
  $onlinePayment: Observable<boolean>;
  $urgence: Observable<boolean>;
  $isLoggedIn: Observable<boolean>;
  $hasImmoAccess: Observable<boolean>;
  $reduction: Observable<number>;
  $reductionWithoutComm: Observable<number>;
  $commission: Observable<number>;
  $nbElec: Observable<number>;
  $nbGaz: Observable<number>;
  $gazChoice: Observable<number>;
  $hasCommission: Observable<boolean>;
  $userRoles: Observable<Role[]>;
  $showPacks: Observable<boolean>;
  $isMobile: Observable<boolean>;
  $immoPacksWithPrices: any;
  $userType: Observable<UserType>;
  $promocodeID: Observable<string>;
  $immoPacks: Observable<PackDB[]>;
  $region: Observable<Region>;
  $hasElec: Observable<boolean>;
  $hasPeb: Observable<boolean>;
  $nbOfIso: Observable<number>;
  $reason: Observable<number>;
  $clientType: Observable<number>;
  $showMoreServices: Observable<boolean>;
  $venteStatus: Observable<{ id: number; name: string }>;

  $ventePubSub: Subscription;

  currentRegionId: number;
  currentEstateStyle: number;

  maison5: HouseOption;
  maison6: HouseOption;

  hasPeb: boolean;
  hasPebPartiel: boolean;
  hasElec: boolean;
  hasCiterne: boolean;
  hasPlan: boolean;
  hasGaz: boolean;

  nbOfApp: number;

  isMobile: boolean;
  isLoggedIn: boolean;

  estates = ['m', 'a', 'i'];

  filteredServices: any;
  filteredImmoServices: any;
  controlLength: number;
  immoLength: number;

  visitContact: VisitContact;

  constructor(
    private store: Store<AppState>,
    private dataService: DataService,
    public dialog: MatDialog,
    private smooth: SimpleSmoothScrollService,
    private _formBuilder: FormBuilder,
    public breakpointObserver: BreakpointObserver,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.store.select(orderSelector.getServices).subscribe(data => {
      this.products = data;
      this.hasPanelSupport = data.some(p => p === PRODUCTS.PANEL_SUPPORT);
      this.hasPanel = data.some(p => p === PRODUCTS.PANEL || p === PRODUCTS.PANEL_SUPPORT);
      this.hasBidditPanelSupport = data.some(p => p === PRODUCTS.PANELBIDDIT_SUPPORT);
      this.hasBidditPanel = data.some(p => p === PRODUCTS.PANELBIDDIT || p === PRODUCTS.PANELBIDDIT_SUPPORT);
      this.step1FormGroup = this._formBuilder.group({
        validStep: [this.products.length, Validators.min(1)]
      });

      this.store.dispatch(new SetStep1Valid(this.step1FormGroup.get('validStep').valid));
    });

    this.ventePubFG = this._formBuilder.group({
      venteStatut: [this.ventePubChoices[0]],
      venteDate: ['']
    });

    this.$ventePubSub = this.ventePubFG.valueChanges.pipe(debounceTime(1000)).subscribe(values => {
      this.venteInfo.statusVentePub = values.venteStatut;
      this.venteInfo.venteDate = values.venteDate;

      this.store.dispatch(new SetVenteInfo(this.venteInfo));
    });

    this.descriptifPacks = descriptifPacks;
    this.checkQueryParamas();
  }

  ngOnInit(): void {
    this.$immoPacks = this.dataService.getPacksFromAPI();

    this.$selectedRegion = this.store.select(orderSelector.getRegion);
    this.$selectedEstateType = this.store.select(orderSelector.getEstateID);
    this.$selectedEstateStyle = this.store.select(orderSelector.getEstateStyle);
    this.$nbOfAppartments = this.store.select(orderSelector.getNbOfAppartments);
    this.$selectedImmoPack = this.store.select(orderSelector.getImmoPack);
    this.$filteredServices = this.store.select(orderSelector.getFilteredServices);
    this.$filteredImmoServices = this.store.select(orderSelector.getFilteredImmoServices);
    this.$filteredPanneauxServices = this.store.select(orderSelector.getFilteredPanneauxServices);
    this.$products = this.store.select(orderSelector.getProducts);
    this.$reductions = this.store.select(orderSelector.getReductions);
    this.$onlinePayment = this.store.select(orderSelector.getOnlinePayment);
    this.$urgence = this.store.select(orderSelector.getUrgence);
    this.$commission = this.store.select(orderSelector.getCommission);
    this.$reductionWithoutComm = this.store.select(orderSelector.getReductionWithoutComm);
    this.$reduction = this.store.select(orderSelector.getReduction);
    this.$nbElec = this.store.select(orderSelector.nbOfElec);
    this.$nbOfIso = this.store.select(orderSelector.getNbOfIso);
    this.$nbGaz = this.store.select(orderSelector.nbOfGaz);
    this.$hasElec = this.store.select(orderSelector.hasElec);
    this.$hasPeb = this.store.select(orderSelector.hasPeb);
    this.$gazChoice = this.store.select(orderSelector.gazChoice);
    this.$hasImmoAccess = this.store.select(orderSelector.getHasImmoAccess);
    this.$hasCommission = this.store.select(orderSelector.getHasCommission);
    this.$userRoles = this.store.select(orderSelector.getUserRoles);
    this.$showPacks = this.store.select(uiSelector.getShowPacks);
    this.$isMobile = this.store.select(uiSelector.getIsMobile);
    this.$immoPacksWithPrices = this.store.select(uiSelector.getPacksWithPrices);
    this.$services = this.store.select(orderSelector.getServices);
    this.$userType = this.store.select(orderSelector.getUserType);
    this.$reason = this.store.select(orderSelector.getReason);
    this.$clientType = this.store.select(orderSelector.getClientType);
    this.$reduction.subscribe(reduc => (this.reduction = reduc));
    this.$promocodeID = this.store.select(orderSelector.getPromoCodeID);
    this.$region = this.store.select(orderSelector.getRegionInfo);
    this.$hasImmoAccess.subscribe(i => (this.hasImmoAccess = i));
    this.$showMoreServices = this.store.select(uiSelector.getShowMoreServices);
    this.store.select(orderSelector.pebNb).subscribe(nb => {
      this.pebNb = nb;
    });
    this.store.select(orderSelector.getVisitContact).subscribe(v => (this.visitContact = v));
    this.$reason.subscribe(r => (this.reason = r));

    this.$showMoreServices.subscribe(bool => (this.showMoreServices = bool));

    this.clientTypes = userTypePartAndPro;

    this.onZipChanges();

    this.$nbOfAppartments.subscribe(nb => (this.nbOfApp = nb));

    combineLatest(this.$nbOfAppartments, this.$hasElec, this.$hasPeb, this.$selectedEstateStyle)
      .pipe(map(results => ({ nbOfApp: results[0], hasElec: results[1], hasPeb: results[2] })))
      .subscribe(v => {
        if (v.nbOfApp > 0 && this.estateType === 2) {
          if (v.hasElec) {
            this.setElecSupp(v.nbOfApp);
          }
          if (v.hasPeb) {
            this.setPebSupp(v.nbOfApp);
          }
        }
      });

    this.store.select(orderSelector.hasPeb).subscribe(bool => (this.hasPeb = bool));
    this.store.select(orderSelector.hasPebPartiel).subscribe(bool => {
      this.hasPebPartiel = bool;
      if (bool) {
        this.pebPartiel = true;
      }
    });
    this.store.select(orderSelector.hasElec).subscribe(bool => (this.hasElec = bool));
    this.store.select(orderSelector.hasCiterne).subscribe(bool => (this.hasCiterne = bool));
    this.store.select(orderSelector.hasPlan).subscribe(bool => (this.hasPlan = bool));
    this.store.select(orderSelector.hasGaz).subscribe(bool => (this.hasGaz = bool));
    this.store.select(orderSelector.getPriceWithoutReduction).subscribe(price => {
      this.priceWithoutReduction = price;
    });
    this.store.select(orderSelector.getPromoCode).subscribe(data => (this.promoCode = data));
    this.store.select(orderSelector.getEstateStyle).subscribe(data => (this.currentEstateStyle = data));
    this.store.select(orderSelector.getParutionsLength).subscribe(data => (this.printLength = data));
    this.store.select(orderSelector.getVisitsLength).subscribe(data => (this.visitLength = data));

    this.$filteredServices.subscribe(fs => (this.filteredServices = fs));
    this.$filteredImmoServices.subscribe(fi => (this.filteredImmoServices = fi));

    this.$isLoggedIn = this.store.select(orderSelector.getIsLoggedIn);

    this.regions = this.dataService.getRegionTypes();
    this.estateTypes = this.dataService.getEstateTypes();
    this.estateStyles = this.dataService.getEstateStyles();

    this.$products.subscribe(p => (this.productsDB = p));

    this.$selectedRegion.subscribe(id => (this.currentRegionId = id));
    this.$isLoggedIn.subscribe(i => (this.isLoggedIn = i));

    this.$reductions.subscribe(red => {
      let r = '';
      const promodRed = red.find(rr => rr.id === 1002);
      if (promodRed) {
        r = promodRed.price;
      }
      this.reductionPromoCode = r;
    });

    this.houseOptions = this.estateTypes['m'];
    this.maison5 = this.houseOptions.filter(h => h.typeId === estates.MAISON_5)[0];
    this.maison6 = this.houseOptions.filter(h => h.typeId === estates.VILLA)[0];

    this.appartOptions = this.estateTypes['a'];
    this.immeubleOptions = this.estateTypes['i'];
    this.elecOptions = [1, 2, 3, 4, 5, 6];
    this.gazOptions = [1, 2, 3, 4, 5, 6];

    this.noPlaceHolderEstateStyles = this.estateStyles.slice(1);

    combineLatest(
      this.$userRoles,
      this.$selectedEstateType,
      this.$userType,
      this.$selectedRegion,
      this.$immoPacks,
      (userRoles, estateType, userType, selectedRegion, immoPacks) => ({
        userRoles,
        estateType,
        userType,
        selectedRegion,
        immoPacks
      })
    ).subscribe(({ userRoles, estateType, userType, selectedRegion, immoPacks }) => {
      console.log('🐛 ImmoPacks');
      let immoPacksWithPrices = this.dataService.getImmoPacks();
      this.estateType = estateType;

      // let immoPacksWithPrices = this.packs;
      if (selectedRegion !== -1 && estateType !== -1) {
        if (!this.hasImmoAccess) {
          immoPacksWithPrices = immoPacksWithPrices
            .filter(p => p.userRoles.length === 0 || p.userRoles.some(ur => userRoles.some(r => r.RoleID === ur)))
            .filter(p => p.userTypes.length === 0 || p.userTypes.some(ut => ut === userType) || this.hasAccessAllPack);
        }

        immoPacksWithPrices = this.getPackPrice(immoPacksWithPrices as any) as any;
      }
      this.store.dispatch(new SetPacksWithPrices(immoPacksWithPrices));
    });

    this.$services.subscribe(services => {
      this.controlLength = this.filteredServices.length;
      this.immoLength = this.filteredImmoServices.length;
      // improve by comparing with getServiceTypes
      this.howManyControlSelected = services.filter(
        s => s === 1 || s === 4 || s === 6 || s === 9 || s === 15 || s === 28
      ).length;
      this.howManyImmoSelected = services.length - this.howManyControlSelected;
    });

    combineLatest(this.$selectedEstateType, this.$selectedEstateStyle)
      .pipe(map(results => ({ estateType: results[0], estateStyle: results[1] })))
      .subscribe(v => {
        if (v.estateStyle === 0) {
          this.houseControl.setValidators(this.selectNotEmpty);
          this.houseControl.setValue(v.estateType);
        } else if (v.estateStyle === 1) {
          this.appartControl.setValidators(this.selectNotEmpty);
          this.appartControl.setValue(v.estateType);
        } else if (v.estateStyle === 2) {
          this.buildingControl.setValidators(this.selectNotEmpty);
          this.buildingControl.setValue(this.nbOfApp);
        }
      });

    /* setTimeout(() => {
        this.dialog.open(VisitRequestDialogComponent, {
          panelClass: ['specific-request-dialog'],
          data: {
            dialog: this.dialog,
            reason: this.reason
          },
          disableClose: true
        });
      }, 1000
      ); */
  }

  checkQueryParamas() {
    // /?r=0&e=11&p=1-4&c=ouioui
    this.route.queryParamMap.subscribe(params => {
      const code = params.get('c') ? params.get('c') : null;
      const region = params.get('r') ? +params.get('r') : null;
      const estate = params.get('e') ? +params.get('e') : null;
      const pr = params.get('p') && params.get('p').split('-');
      this.succintStep1 = false;

      if (!!code || !!region || !!estate) {
        this.store.dispatch(new ResetOrderState(null));
      }

      if (code) {
        this.setPromoCode(code);
      }

      if (!isNaN(region) && [0, 1, 2].some(r => r === region)) {
        console.log('🎨 set region');
        this.setRegion(region);

        if (!isNaN(estate) && [1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13].some(e => e === estate)) {
          console.log('🎨 set esateType');
          this.setEstateType(estate);
          if (pr && pr.length > 0 && pr[0]) {
            const hasGazInUrl = pr.some(p => +p === 15);
            if (hasGazInUrl) {
              console.log('hasGazInUrl', hasGazInUrl);
              this.setGazChoice(0);
            }
            this.succintStep1 = true;
            if (!this.dataService.isGreenfish()) {
              this.onlinePayment();
            }
            this.store.dispatch(new SetServices([]));
            pr.forEach(element => {
              const prod = +element;
              if (!isNaN(prod) && [1, 4, 6, 9, 14, 15].some(e => e === prod)) {
                console.log('🎨 set products');
                this.setService(prod);
              }
            });
          }
        }
      }
    });
  }

  ngAfterContentInit(): void {}

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  setRegion(id: number) {
    if (id === -1) {
      this.setEstateStyle(-1);
    }
    this.store.dispatch(new SetRegion(id));
  }

  setEstateStyle(style: number) {
    this.store.dispatch(new SetEstateStyle(style));
  }

  private getDefaultEstateType(estateStyle): number {
    switch (estateStyle) {
      case estates.HOUSE:
        return estates.MAISON_1;
      case estates.FLAT:
        return estates.STUDIO;
      case estates.BUILDING:
        return estates.IMMEUBLE_APPART;
      default:
        break;
    }
  }

  setEstateType(id: number) {
    if (id === 0) {
      this.dialog.open(SpecificRequestDialogComponent, {
        panelClass: ['specific-request-dialog']
      });
      return;
    } else if (id === -1) {
      this.store.dispatch(new SetServices([]));
    }
    this.store.dispatch(new SetEstateType(id));
  }

  setNbOfAppartments(id: number) {
    if (this.hasElec) {
      this.setElecSupp(id);
    }
    if (this.hasGaz) {
      this.setGazSupp(id);
    }

    this.store.dispatch(new SetNumberOfAppartments(id));
  }

  // add this to a service with logic when adding/removing products
  setService(id) {
    if (id === PRODUCTS.PLAQUETTE || id === PRODUCTS.DESCRIPTIF) {
      return;
    }
    if (id === -1) {
      this.dialog.open(SpecificRequestDialogComponent, {
        panelClass: ['specific-request-dialog']
      });
      return;
    }
    if (id === -3) {
      this.dialog.open(AuditRequestDialogComponent, {
        panelClass: ['audit-request-dialog'],
        data: {
          dialog: this.dialog
        }
      });
      return;
    }
    if (id === -4) {
      this.dialog.open(PrintRequestDialogComponent, {
        panelClass: ['print-request-dialog', 'full-screen-dialog'],
        data: {
          dialog: this.dialog
        },
        disableClose: true
      });
      return;
    }
    if (id === -5) {
      this.dialog.open(VisitRequestDialogComponent, {
        panelClass: ['visit-request-dialog', 'full-screen-dialog'],
        data: {
          dialog: this.dialog,
          reason: this.reason
        },
        disableClose: true
      });
      return;
    }

    const index = this.products.indexOf(id);

    const indexCiterneE = this.products.indexOf(PRODUCTS.CITERNE_ENTERREE);
    const indexCiterneA = this.products.indexOf(PRODUCTS.CITERNE_AERIENNE);

    const indexPlan2D = this.products.indexOf(PRODUCTS.PLAN);
    const indexPlan3D = this.products.indexOf(PRODUCTS.PLAN3D);
    const indexVisite360 = this.products.indexOf(PRODUCTS.VISITE360);

    const indexPanel =
      this.products.indexOf(PRODUCTS.PANEL) > -1 ||
      this.products.indexOf(PRODUCTS.PANEL_SUPPORT) > -1 ||
      this.products.indexOf(PRODUCTS.PANELBIDDIT) > -1 ||
      this.products.indexOf(PRODUCTS.PANELBIDDIT_SUPPORT) > -1;

    if (indexCiterneE > -1 && id === PRODUCTS.CITERNE_AERIENNE) {
      this.products.splice(indexCiterneE, 1);
    } else if (indexCiterneA > -1 && id === PRODUCTS.CITERNE_ENTERREE) {
      this.products.splice(indexCiterneA, 1);
    }

    if (id === 1) {
      // this.products = this.products.filter(pr => pr !== 14);
    }

    if (
      id === PRODUCTS.PANEL ||
      id === PRODUCTS.PANEL_SUPPORT ||
      id === PRODUCTS.PANELBIDDIT ||
      id === PRODUCTS.PANELBIDDIT_SUPPORT
    ) {
      this.products = this.products.filter(
        pr =>
          pr !== PRODUCTS.SMS &&
          pr !== PRODUCTS.PANEL &&
          pr !== PRODUCTS.PANEL_SUPPORT &&
          pr !== PRODUCTS.PANELBIDDIT &&
          pr !== PRODUCTS.PANELBIDDIT_SUPPORT
      );
    }

    if (id === PRODUCTS.PEB) {
      this.products = this.products.filter(pr => pr !== PRODUCTS.PEB_SUPP);
      this.products = this.products.filter(pr => pr !== PRODUCTS.PEB_Partiel);
    }

    if (id === PRODUCTS.ELEC) {
      this.products = this.products.filter(pr => pr !== PRODUCTS.ELEC_SUPP);
    }

    if (id === PRODUCTS.GAZ_CONTROLE) {
      this.products = this.products.filter(pr => pr !== PRODUCTS.GAZ_SUPP);
    }

    if (indexPlan2D < 0 && id === PRODUCTS.PLAN3D) {
      this.setService(PRODUCTS.PLAN);
    }

    if (indexPlan2D < 0 && id === PRODUCTS.VISITE360) {
      this.setService(PRODUCTS.PLAN);
    }

    if (indexPlan2D > -1 && indexPlan3D > -1 && id === PRODUCTS.PLAN) {
      this.products = this.products.filter(pr => pr !== PRODUCTS.PLAN3D);
    }

    if (indexPlan2D > -1 && indexVisite360 > -1 && id === PRODUCTS.PLAN) {
      this.products = this.products.filter(pr => pr !== PRODUCTS.VISITE360);
    }

    if (index > -1) {
      // this.products.splice(index, 1);
      this.products = this.products.filter(pr => pr !== id);
    } else {
      this.products.push(id);

      if (id === PRODUCTS.GAZ_CONTROLE) {
        this.setGazSupp(this.nbOfApp);
      }
      if (id === PRODUCTS.ELEC) {
        this.setElecSupp(this.nbOfApp);
      }
    }

    this.store.dispatch(new SetServices(this.products));
  }

  setServiceNoRemove(id) {
    const index = this.products.indexOf(id);

    if (
      id === PRODUCTS.PANEL ||
      id === PRODUCTS.PANEL_SUPPORT ||
      id === PRODUCTS.PANELBIDDIT ||
      id === PRODUCTS.PANELBIDDIT_SUPPORT
    ) {
      this.products = this.products.filter(
        pr =>
          pr !== PRODUCTS.SMS &&
          pr !== PRODUCTS.PANEL &&
          pr !== PRODUCTS.PANEL_SUPPORT &&
          pr !== PRODUCTS.PANELBIDDIT &&
          pr !== PRODUCTS.PANELBIDDIT_SUPPORT
      );
    }

    this.products.push(id);

    this.store.dispatch(new SetServices(this.products));
  }

  setPebSupp(nb) {
    this.products = this.products.filter(pr => pr !== PRODUCTS.PEB_SUPP);

    if (nb > 1) {
      const pebSuppArray = [...Array(nb - 1).fill(PRODUCTS.PEB_SUPP)];
      this.products = this.products.concat(pebSuppArray);
    }

    this.store.dispatch(new SetServices(this.products));
  }

  setElecSupp(nb) {
    this.products = this.products.filter(pr => pr !== PRODUCTS.ELEC_SUPP);

    if (nb > 1) {
      const elecSuppArray = [...Array(nb - 1).fill(PRODUCTS.ELEC_SUPP)];
      this.products = this.products.concat(elecSuppArray);
    }

    this.store.dispatch(new SetServices(this.products));
    this.store.dispatch(new SetNbOfElec(nb));
  }

  setGazSupp(nb) {
    this.products = this.products.filter(pr => pr !== PRODUCTS.GAZ_SUPP);

    if (nb > 1) {
      const gazSuppArray = [...Array(nb - 1).fill(PRODUCTS.GAZ_SUPP)];
      this.products = this.products.concat(gazSuppArray);
    }

    this.store.dispatch(new SetServices(this.products));
    this.store.dispatch(new SetNbOfGaz(nb));
  }

  setGazChoice(choice: number) {
    this.store.dispatch(new SetGazChoice(choice));
  }

  isCiterneSelected(id: number) {
    const index = this.products.indexOf(id);

    if (id === PRODUCTS.CITERNE_AERIENNE) {
      return index > -1 ? 'accent' : 'primary';
    } else if (id === PRODUCTS.CITERNE_ENTERREE) {
      return index > -1 ? 'accent' : 'primary';
    }
  }

  get citerneType(): number {
    const under = this.products.indexOf(9) > -1;
    const aerial = this.products.indexOf(6) > -1;

    return under ? 9 : aerial ? 6 : 6;
  }

  regionClass(selectedId) {
    const cls = this.currentRegionId === selectedId ? 'region selected' : 'region';
    return cls;
  }

  regionImgUrl(selectedId, fileName) {
    let url = this.assetsFolder;
    url += fileName + '.svg';
    return url;
  }

  estateClass(selectedEstate) {
    const cls = this.currentEstateStyle === selectedEstate ? 'estate selected' : 'estate';
    return cls;
  }

  estateImgUrl(fileName) {
    let url = this.assetsFolder;
    url += fileName;
    return url;
  }

  serviceClass(id: number) {
    if (id === 9) {
      return this.isSelected(6) || this.isSelected(9) ? 'service selected' : 'service';
    }
    if (id === PRODUCTS.PRINT) {
      return this.printLength > 0 ? 'service selected' : 'service';
    }
    if (id === PRODUCTS.VISIT) {
      return this.visitLength > 0 || this.visitContact.email !== '' ? 'service selected' : 'service';
    }
    if (id === PRODUCTS.PANEL) {
      return this.isSelected(PRODUCTS.PANEL_SUPPORT) || this.isSelected(PRODUCTS.PANEL)
        ? 'service selected'
        : 'service';
    } else if (id === PRODUCTS.PANELBIDDIT) {
      return this.isSelected(PRODUCTS.PANELBIDDIT) || this.isSelected(PRODUCTS.PANELBIDDIT_SUPPORT)
        ? 'service selected'
        : 'service';
    }
    return this.isSelected(id) ? 'service selected' : 'service';
  }

  isSelected(id: number): boolean {
    const bool = this.products.indexOf(id) > -1;
    return bool;
  }

  serviceImgUrl(fileName, id) {
    let url = this.assetsFolder;

    url += fileName;

    if (id === PRODUCTS.PANEL) {
      url += this.isSelected(PRODUCTS.PANEL_SUPPORT) || this.isSelected(PRODUCTS.PANEL) ? '-selected.svg' : '.svg';
    } else if (id === PRODUCTS.PANELBIDDIT) {
      url +=
        this.isSelected(PRODUCTS.PANELBIDDIT) || this.isSelected(PRODUCTS.PANELBIDDIT_SUPPORT)
          ? '-selected.svg'
          : '.svg';
    } else if (id === PRODUCTS.VISIT) {
      url += this.visitLength > 0 || this.visitContact.email !== '' ? '-selected.svg' : '.svg';
    } else {
      url += this.isSelected(id) ? '-selected.svg' : '.svg';
    }

    return url;
  }

  setImmoPack(id: string) {
    const services = this.dataService.getProductsForPack(id);
    this.store.dispatch(new SetServices(services));
    this.setShowPacks(false);
  }

  setPackAllIn() {
    const services = this.dataService.getProductsAllIn();
    this.store.dispatch(new SetServices(services));
  }

  setPebPartiel(bool: boolean) {
    if (!bool && this.hasPebPartiel) {
      this.setService(PRODUCTS.PEB_Partiel);
    }
    this.pebPartiel = bool;
  }

  addPebPartiel() {
    this.setService(PRODUCTS.PEB_Partiel);
  }

  addPebNb(pebNb: string) {
    this.store.dispatch(new SetPebNb(pebNb));
  }

  setWantsIso(bool: boolean) {
    this.wantsIso = bool;
  }

  setPlanIso(nb: number) {
    this.products = this.products.filter(pr => pr !== PRODUCTS.ISO);

    if (nb > 0) {
      const isoArray = [...Array(nb).fill(PRODUCTS.ISO)];
      this.products = this.products.concat(isoArray);
    }

    this.store.dispatch(new SetServices(this.products));
    this.store.dispatch(new SetNbOfIso(nb));
  }

  scrollTop() {
    if (this.step1FormGroup.valid) {
      setTimeout(() => this.smooth.smoothScrollToTop(), 500);
    }
  }

  nextStep() {
    this.googleAnalyticsService.emitEvent('bouton', 'clic', 'étape 1 continuer');
    this.houseControl.markAsTouched();

    if (this.succintStep1) {
      const ref = this.dialog.open(SummaryConfirmDialogComponent, {
        panelClass: 'summary-confirm-dialog',
        data: {
          regionId: this.currentRegionId,
          estateTypeId: this.estateType,
          dialog: this.dialog,
          products: this.products,
          stepper: this.stepper,
          price: this.priceWithoutReduction,
          reduction: this.reduction
        }
      });

      ref.afterClosed().subscribe(result => {
        // console.log(result);
        this.succintStep1 = result;
      });
    }

    this.scrollTop();
  }

  onlinePayment() {
    this.store.dispatch(new ToggleOnlinePayment(true));
  }

  urgence() {
    this.store.dispatch(new ToggleUrgence(true));
  }

  setPromoCode(code: string) {
    if (code !== '') {
      this.store.dispatch(new SetPromoCode(code.toLowerCase()));
    }
  }

  setCommission(value: number) {
    this.store.dispatch(new SetCommission(value));
  }

  setShowPacks(value: boolean) {
    this.store.dispatch(new SetShowPacks(value));
  }

  openRegisterDialog(): void {
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      panelClass: ['no-padding-dialog', 'full-screen-dialog'],
      data: { register: false }
    });
  }

  getPackPrice(
    immoPack: {
      id: number;
      reduc: string;
      services: number[];
      servicesPrice: number;
      servicesRed: number;
      totalWithReduc: number;
      servicesInfo: { id: number; name: string }[];
    }[]
  ) {
    const tmp = JSON.parse(JSON.stringify(immoPack));
    tmp.forEach(pack => {
      pack.servicesInfo = [];
      pack.servicesPrice = pack.servicesRed = pack.totalWithReduc = 0;

      // Add descriptif for details
      if (this.descriptifPacks.some(id => id === pack.id)) {
        pack.services.push(DESCRIPTIF);
        pack.services.push(PRODUCTS.PLAQUETTE);
      }

      const services = pack.services;

      services.forEach(id => {
        const servPrice = this.dataService.getProductPrice(id);
        let serv = this.dataService.getServiceTypes().find(s => s.id === id);
        if (!serv) {
          serv = this.dataService.getImmoServiceTypes().find(s => s.id === id);
        }
        servPrice.reduc = this.dataService.getStatutReduc(
          parseInt(id, 10),
          servPrice.reduc,
          this.dataService.getProduct(+id)
        );
        const thisPack = tmp.filter(p => p.id === pack.id)[0];
        /* thisPack.servicesPrice = parseInt(servPrice.price, 10);
        thisPack.servicesRed = parseInt(servPrice.reduc, 10); */
        pack.servicesInfo.push(serv);
        if (isNaN(thisPack.servicesPrice)) {
          thisPack.servicesPrice = 0;
        }
        if (isNaN(thisPack.servicesRed)) {
          thisPack.servicesRed = 0;
        }
        thisPack.servicesPrice =
          thisPack.servicesPrice || thisPack.servicesPrice === 0
            ? isNaN(servPrice.price)
              ? thisPack.servicesPrice
              : thisPack.servicesPrice + servPrice.price
            : servPrice.price;
        thisPack.servicesRed =
          thisPack.servicesRed || thisPack.servicesRed === 0
            ? isNaN(servPrice.reduc)
              ? thisPack.servicesRed
              : thisPack.servicesRed + servPrice.reduc
            : servPrice.reduc;
      });
      pack.reduc = this.dataService.getStatutReduc(
        parseInt(pack.description, 10),
        pack.reduc,
        this.dataService.getProduct(+pack.description)
      );
      pack.totalWithReduc = pack.servicesPrice - pack.servicesRed - parseInt(pack.reduc, 10);
    });
    return tmp;
  }

  selectNotEmpty(control: AbstractControl): { [key: string]: any } | null {
    const valid = control.value !== -1;
    return valid ? null : { isEmpty: { valid: false, value: control.value } };
  }

  setClientType(id: string) {
    if (+id === 1) {
      this.openRegisterDialog();
      return;
    }
    this.store.dispatch(new SetClientType(id));
  }

  setReasonType(id: string) {
    this.store.dispatch(new SetReason(id));
  }

  inputZipCode(control: AbstractControl): { [key: string]: any } | null {
    const pattern = new RegExp(/^(?:(?:[1-9])(?:\d{3}))$/);
    const code = control.value;
    console.log(control);
    const valid = code ? code.match(pattern) : false;
    console.log(valid);
    let region = -1;
    if (valid) {
      if (code >= 1000 && code <= 1299) {
        console.log('Bxl');
        region = 1;
      } else if ((code >= 1500 && code <= 3999) || (code >= 8000 && code <= 9999)) {
        console.log('Fl');
        region = 2;
      } else if ((code >= 1300 && code <= 1499) || (code >= 4000 && code <= 7999)) {
        console.log('Wal');
        region = 0;
      }
      console.log(region);
    }
    this.setRegion(region);
    return valid ? null : { isZipCode: { valid: false, value: code } };
  }

  imgUrlRegion(id: number) {
    const fileName = this.regions.find(r => r.id === id).fileName;
    let url = imgagesFolder;
    url += fileName;
    url += '-selected.svg';
    return url;
  }

  onZipChanges(): void {
    this.situationControl.setValidators(this.inputZipCode.bind(this));
    this.situationControl.valueChanges.pipe(debounceTime(1000)).subscribe(val => {
      this.store.dispatch(new SetZipCode(val));
    });
  }

  toggleShowMoreServices(bool: boolean) {
    this.store.dispatch(new ShowMoreServices(bool));
    console.log('showMore: ', bool);
  }
}

interface HouseOption {
  buildingType: string;
  id: number;
  text: string;
  typeId: number;
  subOptions?: HouseOption[];
}
