import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './interfaces';
import { combineLatest } from 'rxjs';

import * as orderSelector from './core/store/order/order.selectors';
import * as uiSelector from './core/store/ui/ui.selectors';
import { BreakpointObserver } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { StartOnlineOfflineCheck, SetLanguage } from './core/store/ui';
import { OrderSteppersComponent } from './order-steppers/order-steppers.component';

import * as Sentry from '@sentry/browser';
import { UpdateSwService } from './core/services/update-sw.service';
import { AuthService } from './core/services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild(OrderSteppersComponent) OrderSteppersComponent: OrderSteppersComponent;

  constructor(
    private store: Store<AppState>,
    public breakpointObserver: BreakpointObserver,
    translate: TranslateService,
    private sw: UpdateSwService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    translate.setDefaultLang('fr');
    this.store.select(uiSelector.getLanguage).subscribe(ln => translate.use(ln));
  }

  ngOnInit() {
    this.store.dispatch(new StartOnlineOfflineCheck());
    // check the service worker for updates
    this.sw.checkForUpdates();

    combineLatest(
      this.store.select(orderSelector.getUserID),
      this.store.select(orderSelector.getUserEmail),
      (uid, email) => ({
        uid,
        email
      })
    ).subscribe(({ uid, email }) => {
      Sentry.configureScope(scope => {
        scope.setUser({ id: uid, email: email });
      });
    });
  }

  ngAfterViewInit(): void {
    this.route.queryParamMap.subscribe(params => {
      const userid = params.get('u') ? params.get('u') : null;
      const lan = params.get('ln') ? params.get('ln') : null;

      if (userid) {
        // console.log('login :)');
        this.authService.loginWithUserID(userid);
      }
      if (lan === 'fr' || lan === 'nl' || lan === 'de' || lan === 'en') {
        this.store.dispatch(new SetLanguage(lan as 'fr' | 'nl' | 'de' | 'en'));
      }
    });
  }

  connectUserId(userid: string) {
    this.authService.loginWithUserID(userid);
  }
}
