export class Product {
  id: number;
  name: string;
  price: string;
  reduction: string;

  constructor(id, price, reduction) {
    this.id = id;
    this.price = price;
    this.reduction = reduction;
    this.name = this.SetName(id);
  }

  private SetName(id: number): string {
    switch (id) {
      case 1:
        return 'Certificat PEB';
      case 4:
        return 'Contrôle électrique';
      case 8:
        return 'Contrôle électrique supplémentaire';
      case 5:
        return 'Réduction';
      case 9:
        return 'Contrôle citerne - enterrée';
      case 6:
        return 'Contrôle citerne - aérienne';
      case 11:
        return 'PEB Partiel';
      case 14:
        return 'Plan 2D';
      case 15:
        return 'Contrôle gaz';
      case 16:
        return 'Contrôle gaz supplémentaire';
      case 17:
        return 'Plan isométrique';
      case 18:
        return 'Photo [HDR]';
      case 19:
        return 'Photos aériennes';
      case 20:
        return 'Vidéo';
      case 21:
        return 'Visite 360°';
      case 26:
        return 'Plan 3D';
      case 27:
        return 'Descriptif';
      case 29:
        return 'Panneau Pack Biddit';
      case 30:
        return 'Pack Biddit avec support';
      case 32:
        return 'Placement panneau';
      case 33:
        return 'Placement panneau avec support';
      case 34:
        return 'Code SMS';
      case 49:
        return 'Diagnostique chaudière';
      case 50:
        return 'Entretien chaudière';
      case 11111:
        return 'Certificat PEB';

      default:
        return 'wrong id';
    }
  }
}
