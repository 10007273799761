import { HttpResponse } from '@angular/common/http';
import { throwError, of } from 'rxjs';
import { tap, retryWhen, concatMap, delay } from 'rxjs/operators';
import { SpinnerOverlayService } from './spinner-overlay.service';
import * as i0 from "@angular/core";
import * as i1 from "./spinner-overlay.service";
var LoaderInterceptorService = /** @class */ (function () {
    function LoaderInterceptorService(loaderService) {
        this.loaderService = loaderService;
    }
    LoaderInterceptorService.prototype.intercept = function (req, next) {
        var _this = this;
        this.showLoader();
        return next.handle(req).pipe(tap(function (event) {
            if (event instanceof HttpResponse) {
                _this.onEnd();
            }
        }), retryWhen(function (errors) {
            return errors.pipe(concatMap(function (error, count) {
                if (count < 5 &&
                    (error.status === 503 || error.status === 504 || error.status === 400 || error.status === 0)) {
                    console.log('🚑🚑🚑 retrying ' + count);
                    return of(error.status);
                }
                _this.onEnd();
                return throwError(error);
            }), delay(3000));
        }));
    };
    LoaderInterceptorService.prototype.onEnd = function () {
        this.hideLoader();
    };
    LoaderInterceptorService.prototype.showLoader = function () {
        // console.log('show loader');
        this.loaderService.show();
    };
    LoaderInterceptorService.prototype.hideLoader = function () {
        // console.log('hide loader');
        this.loaderService.hide();
    };
    LoaderInterceptorService.ngInjectableDef = i0.defineInjectable({ factory: function LoaderInterceptorService_Factory() { return new LoaderInterceptorService(i0.inject(i1.SpinnerOverlayService)); }, token: LoaderInterceptorService, providedIn: "root" });
    return LoaderInterceptorService;
}());
export { LoaderInterceptorService };
