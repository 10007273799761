import * as estates from './estates';
import { UserType, UserRole } from '../models/user';
import { Reasons } from '../enums/reason.enum';

export const descriptifPacks = [52, 53, 54, 2001];
export const displayedPacks = [];

export const ONLINE_REDUC = 10;

export const hasImmoAccessProject = true;
export const hasClientTypeChoice = false;
export const hasAccessAllPack = true;

export const orderMeansType = 1;

export const services = [
  {
    id: 1,
    name: 'Certificat PEB',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-peb',
    reasons: [Reasons.VENTE_PUB, Reasons.GRE_A_GRE, Reasons.BIDDIT]
  },
  {
    id: 4,
    name: 'Contrôle électrique',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-elec',
    reasons: [Reasons.VENTE_PUB, Reasons.GRE_A_GRE, Reasons.BIDDIT]
  },
  {
    id: 9,
    name: 'Contrôle citerne',
    regions: [0, 1, 2],
    biens: [estates.HOUSE],
    fileName: 'service-citerne',
    reasons: [Reasons.VENTE_PUB, Reasons.GRE_A_GRE, Reasons.BIDDIT]
  },
  {
    id: 15,
    name: 'Contrôle gaz',
    regions: [0, 1],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-gaz',
    reasons: [Reasons.VENTE_PUB]
  },
  {
    id: 28,
    name: 'PEB chauffage',
    regions: [1],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-peb-chauffage',
    reasons: []
  },
  {
    id: 49,
    name: 'Diagnostique chaudière',
    regions: [1],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-chaudiere-diagnostique',
    reasons: []
  },
  {
    id: 50,
    name: 'Entretien chaudière',
    regions: [1],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-chaudiere-entretien',
    reasons: []
  },
  {
    id: -3,
    name: 'Audit',
    regions: [0],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-audit',
    reasons: []
  }
];

export const servicesSupp = [
  {
    id: 6,
    name: 'Contrôle citerne aérienne',
    regions: [0, 1, 2],
    biens: [estates.HOUSE],
    fileName: 'service-citerne',
    reasons: []
  }
];

export const immoServices = [
  {
    id: 14,
    name: 'Plan 2D',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-plan',
    reasons: []
  },
  {
    id: 26,
    name: 'Plan 3D',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-3d',
    reasons: []
  },
  {
    id: 21,
    name: 'Visite 360°',
    regions: [0, 1, 2],
    biens: [estates.HOUSE],
    fileName: 'service-360',
    reasons: []
  },
  {
    id: 18,
    name: 'Photo [HDR]',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-photo',
    reasons: []
  },
  {
    id: 19,
    name: 'Photos aériennes',
    regions: [0, 1],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING, estates.LAND],
    fileName: 'service-drone',
    reasons: [Reasons.VENTE_PUB, Reasons.GRE_A_GRE, Reasons.BIDDIT]
  },
  {
    id: 20,
    name: 'Video',
    regions: [0, 1],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING, estates.LAND],
    fileName: 'service-video',
    reasons: [Reasons.VENTE_PUB, Reasons.GRE_A_GRE, Reasons.BIDDIT]
  },
  {
    id: 27,
    name: 'Descriptif',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-descriptif',
    free: true,
    reasons: []
  },
  {
    id: -2,
    name: 'Web vitrine',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-plaquette',
    free: true,
    reasons: []
  },
  {
    id: -4,
    name: 'Publication',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-publication',
    reasons: [Reasons.VENTE_PUB, Reasons.GRE_A_GRE, Reasons.BIDDIT]
  },
  {
    id: -5,
    name: 'Visite',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-visite',
    reasons: [Reasons.VENTE_PUB, Reasons.GRE_A_GRE, Reasons.BIDDIT]
  }
];

export const panneauxServices = [
  {
    id: 32,
    name: 'Panneau',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'panneau-basic'
  },
  {
    id: -1,
    name: 'Panneau XL',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'panneau-xl'
  },
  {
    id: 34,
    name: 'SMS',
    regions: [0, 1, 2],
    biens: [estates.HOUSE],
    fileName: 'panneau-sms'
  }
];

export const immoPacks = [
  {
    id: 51,
    name: 'Contrôle combi peb elec',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    services: [1, 4],
    fileName: 'controle-combi.png',
    reduc: 10,
    userTypes: [
      UserType.Particulier,
      UserType.Agence,
      UserType.Certificateur,
      UserType.Notaire,
      UserType.Installateur,
      UserType.Special
    ],
    userRoles: [],
    show: true
  },
  {
    id: 55,
    name: 'Contrôle combi gaz élec',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    services: [4, 15],
    fileName: 'controle-combi.png',
    reduc: 35,
    userTypes: [
      UserType.Particulier,
      UserType.Agence,
      UserType.Certificateur,
      UserType.Notaire,
      UserType.Installateur,
      UserType.Special
    ],
    userRoles: [],
    show: true
  },
  {
    id: 52,
    name: 'Média combi',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    services: [14, 18, 21],
    fileName: 'media-combi.png',
    reduc: 181,
    userTypes: [
      UserType.Particulier,
      UserType.Agence,
      UserType.Certificateur,
      UserType.Notaire,
      UserType.Installateur,
      UserType.Special
    ],
    userRoles: [UserRole.ImmoAccess],
    show: true
  },
  {
    id: 53,
    name: 'Immo light combi',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    services: [1, 4, 18],
    fileName: 'immo-light-combi.png',
    reduc: 40,
    userTypes: [
      UserType.Particulier,
      UserType.Agence,
      UserType.Certificateur,
      UserType.Notaire,
      UserType.Installateur,
      UserType.Special
    ],
    userRoles: [UserRole.ImmoAccess],
    show: true
  },
  {
    id: 54,
    name: 'Immo combi',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    services: [1, 4, 14, 18, 21],
    fileName: 'immo-combi.png',
    reduc: 309,
    userTypes: [
      UserType.Particulier,
      UserType.Agence,
      UserType.Certificateur,
      UserType.Notaire,
      UserType.Installateur,
      UserType.Special
    ],
    userRoles: [UserRole.ImmoAccess],
    show: true
  }
];
