<div class="service-page" id="service-page-drone">
  <header>
    <h1>
      {{ 'service-page.drone.title' | translate }}
    </h1>
    <picture class="service-page-banner"> <img src="../../../assets/images/service-page-drone.jpg" alt=""/></picture>
  </header>
  <div class="content">
    <h2>
      {{ 'service-page.drone.intro' | translate }}
    </h2>
    <div [innerHTML]="'service-page.drone.content' | translate"></div>
    <a class="btn btn-big btn-accent" (click)="toOrdering()">
      {{ 'service-page.drone.action' | translate }}
    </a>
    <div id="drone-example-container">
      <img src="../../../assets/images/service-page-drone-01.jpg" alt="" />
      <img src="../../../assets/images/service-page-drone-02.jpg" alt="" />
      <img src="../../../assets/images/service-page-drone-03.jpg" alt="" />
      <img src="../../../assets/images/service-page-drone-04.jpg" alt="" />
      <img src="../../../assets/images/service-page-drone-05.jpg" alt="" />
      <img src="../../../assets/images/service-page-drone-06.jpg" alt="" />
    </div>
  </div>
</div>
