export const PEB = 1;
export const PEB_Partiel = 11;
export const ELEC = 4;
export const ELEC_SUPP = 8;
export const CITERNE_ENTERREE = 9;
export const CITERNE_AERIENNE = 6;
export const PLAN = 14;
export const GAZ_CONTROLE = 15;
export const GAZ_SUPP = 16;

export const ISO = 17;

export const DESCRIPTIF = 27;
export const PLAN3D = 26;
export const VISITE360 = 21;
export const PHOTO = 18;
export const PHOTODRONE = 19;
export const PEBCHAUFFAGE = 28;

export const PANELBIDDIT = 29;
export const PANELBIDDIT_SUPPORT = 30;
export const PANEL = 32;
export const PANEL_SUPPORT = 33;
export const SMS = 34;

export const IMMOCOMBI = 50;
export const VISIOCOMBI = 51;
export const CONTROLECOMBI = 52;

export const PEB_SUPP = 11111;

export const PLAQUETTE = -2;
export const PRINT = -4;
export const VISIT = -5;
