import { OnInit, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import * as orderSelector from './core/store/order/order.selectors';
import * as uiSelector from './core/store/ui/ui.selectors';
import { BreakpointObserver } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { StartOnlineOfflineCheck, SetLanguage } from './core/store/ui';
import { OrderSteppersComponent } from './order-steppers/order-steppers.component';
import * as Sentry from '@sentry/browser';
import { UpdateSwService } from './core/services/update-sw.service';
import { AuthService } from './core/services/auth.service';
import { ActivatedRoute } from '@angular/router';
var AppComponent = /** @class */ (function () {
    function AppComponent(store, breakpointObserver, translate, sw, authService, route) {
        this.store = store;
        this.breakpointObserver = breakpointObserver;
        this.sw = sw;
        this.authService = authService;
        this.route = route;
        translate.setDefaultLang('fr');
        this.store.select(uiSelector.getLanguage).subscribe(function (ln) { return translate.use(ln); });
    }
    AppComponent.prototype.ngOnInit = function () {
        this.store.dispatch(new StartOnlineOfflineCheck());
        // check the service worker for updates
        this.sw.checkForUpdates();
        combineLatest(this.store.select(orderSelector.getUserID), this.store.select(orderSelector.getUserEmail), function (uid, email) { return ({
            uid: uid,
            email: email
        }); }).subscribe(function (_a) {
            var uid = _a.uid, email = _a.email;
            Sentry.configureScope(function (scope) {
                scope.setUser({ id: uid, email: email });
            });
        });
    };
    AppComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.route.queryParamMap.subscribe(function (params) {
            var userid = params.get('u') ? params.get('u') : null;
            var lan = params.get('ln') ? params.get('ln') : null;
            if (userid) {
                // console.log('login :)');
                _this.authService.loginWithUserID(userid);
            }
            if (lan === 'fr' || lan === 'nl' || lan === 'de' || lan === 'en') {
                _this.store.dispatch(new SetLanguage(lan));
            }
        });
    };
    AppComponent.prototype.connectUserId = function (userid) {
        this.authService.loginWithUserID(userid);
    };
    return AppComponent;
}());
export { AppComponent };
