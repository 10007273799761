import { map, switchMap, tap, concatMap, catchError } from 'rxjs/operators';
import { Observable, of, forkJoin, combineLatest } from 'rxjs';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { ActionTypes } from './../store/order/';
import * as fromOrder from '../store/order/order.actions';
import { AppState } from '../../interfaces';

import * as estates from './../data/estates';
import { Product } from '../models/product';
import { DataService } from '../services/data.service';
import * as PRODUCTS from '../data/products';
import { getServices } from '../store/order/order.selectors';
import { ProductDB } from '../models/productDB';
import { Reduction } from '../models/reduction';
import { descriptifPacks, ONLINE_REDUC } from '../data';
import * as orderSelector from '../store/order/order.selectors';
import * as uiSelector from '../store/ui/ui.selectors';
import { Document } from '../models/document';
import { Owner, UserForm, User, UserType } from '../models/user';
import { Address } from '../models/address';
import { Region } from '../models/region';
import { MatSnackBar } from '@angular/material';
import { PackSelectedComponent } from 'src/app/snackbars/pack-selected/pack-selected.component';
import { PromoCodeDB } from '../models/PromoCodeDB';
import { DESCRIPTIF } from '../data/products';

import { projectID } from '../data/theme-config';
import { Print } from '../models/print';

@Injectable()
export class OrderEffects {
  descriptifPacks: number[];

  services: number[];
  newUserType;
  owner;
  showpacks: boolean;
  immoPackSelected: number;
  userPricingPlan: number;
  region: number;
  statut: number;
  esateType: number;

  gazChoice: number;

  onlineReduc = ONLINE_REDUC;

  constructor(
    private actions$: Actions,
    private dataService: DataService,
    private store: Store<AppState>,
    private snackBar: MatSnackBar
  ) {
    this.store.select(getServices).subscribe(serv => (this.services = serv));
    this.store.select(orderSelector.getUserType).subscribe(ut => (this.newUserType = ut));
    this.store.select(orderSelector.getOwner).subscribe(o => (this.owner = o));
    this.store.select(orderSelector.getImmoPack).subscribe(i => (this.immoPackSelected = i));
    this.store.select(uiSelector.getShowPacks).subscribe(s => (this.showpacks = s));
    this.store.select(orderSelector.getPricingPlan).subscribe(pp => (this.userPricingPlan = pp));
    this.store.select(orderSelector.getRegion).subscribe(r => (this.region = r));
    this.store.select(orderSelector.getEstateID).subscribe(e => (this.esateType = e));
    this.store.select(orderSelector.gazChoice).subscribe(g => (this.gazChoice = g));

    this.descriptifPacks = descriptifPacks;
  }

  /* @Effect()
  init$ = this.actions$.pipe(
    ofType(ROOT_EFFECTS_INIT),
    map((action: any) => new fromOrder.SetProducts(this.getProducts(this.services)))
  ); */

  @Effect()
  actionReason$ = this.actions$.pipe(
    ofType(ActionTypes.SET_REASON),
    map((action: any) => {
      if (projectID === 'mdn') {
        return new fromOrder.SetServices([]);
      }
      return new fromOrder.SetEstateType(-1);
    })
  );

  @Effect()
  actionRegion$ = this.actions$.pipe(
    ofType(ActionTypes.SET_REGION),
    switchMap((action: any) => [
      new fromOrder.SetRegionInfo(this.getRegion(action.payload)),
      new fromOrder.SetProducts(this.getProducts(this.services))
    ])
  );

  @Effect()
  actionX$ = this.actions$.pipe(
    ofType(ActionTypes.SET_ESTATETYPE),
    map((action: any) => new fromOrder.SetProducts(this.getProducts(this.services)))
  );

  @Effect()
  actionEstateStyles$ = this.actions$.pipe(
    ofType(ActionTypes.SET_ESTATESTYLE),
    map((action: any) => this.checkStyles(this.services, action.payload))
  );

  @Effect()
  actionY$ = this.actions$.pipe(
    ofType(ActionTypes.SET_SERVICES),
    map((action: any) => new fromOrder.SetProducts(this.getProducts(action.payload)))
  );

  @Effect()
  actionNbOfApp$ = this.actions$.pipe(
    ofType(ActionTypes.SET_NUMBEROFAPPARTMENTS),
    map((action: any) => new fromOrder.SetProducts(this.getProducts(this.services)))
  );

  @Effect()
  actionOnlinePayment$ = this.actions$.pipe(
    ofType(ActionTypes.TOGGLE_ONLINEPAYMENT),
    map((action: any) => new fromOrder.SetReductions(this.checkReductionOnline()))
  );

  @Effect()
  actionPromoCode$ = this.actions$.pipe(
    ofType(ActionTypes.SET_PROMOCODE),
    map((action: any) => new fromOrder.SetProducts(this.getProducts(this.services)))
  );

  @Effect()
  actionGazChoice$ = this.actions$.pipe(
    ofType(ActionTypes.SET_GAZCHOICE),
    map((action: any) => new fromOrder.SetProducts(this.getProducts(this.services)))
  );

  @Effect()
  actionInvoiceTo$ = this.actions$.pipe(
    ofType(ActionTypes.SET_INVOICETO),
    map((action: any) => this.fillDocumentInfo(action.payload))
  );

  @Effect()
  actionSetUserForm$ = this.actions$.pipe(
    ofType(ActionTypes.SET_USERFORM),
    map((action: any) => new fromOrder.SetOwner(this.getOwner(action.payload)))
  );

  @Effect()
  actionSetUserInfo$ = this.actions$.pipe(
    ofType(ActionTypes.SET_USERINFO),
    switchMap((action: any) => [
      new fromOrder.SetProducts(this.getProducts(this.services)),
      new fromOrder.SetOwner(this.getOwner(action.payload))
    ])
  );

  @Effect()
  actionUrgent$ = this.actions$.pipe(
    ofType(ActionTypes.TOGGLE_URGENCE),
    map((action: any) => new fromOrder.ToggleOnlinePayment(true))
  );

  @Effect()
  actionParutions$ = this.actions$.pipe(
    ofType(ActionTypes.SET_PARUTIONS),
    map((action: any) => this.checkParutions(action.payload))
  );

  /* @Effect()
  actionReduction$ = this.actions$.pipe(
    ofType(ActionTypes.SET_REDUCTIONS),
    map((action: any) => new fromOrder.SetCommission(this.checkMaxCommission()))
  ); */
  /* @Effect() actionZ$ = this.actions$
    .ofType(ActionTypes.SET_ELECSUPP).pipe(
      map((action: any) => new fromOrder.SetServices(this.addElecSupp(action.payload)))
    );

  private addElecSupp(nb: number): number[] {
    return [...Array(nb).fill(PRODUCTS.ELEC_SUPP)];
  } */

  /* private checkMaxCommission() {
    const $reduction = this.store.select(orderSelector.getReduction);
    const $commission = this.store.select(orderSelector.getCommission);
    let maxCom = 0;
    combineLatest($reduction, $commission, (reduc, comm) => ({ reduc, comm })).subscribe(pair => {
      const { reduc, comm } = pair;
      console.log(`red: ${reduc} & comm ${comm}`);
      maxCom = comm > reduc ? reduc : comm;
    });
    return maxCom;
  } */

  private getRegion(id: number): Region {
    return this.dataService.getRegionTypes().find(r => r.id === id);
  }

  private getOwner(user: UserForm) {
    if (this.newUserType === UserType.Particulier) {
      const owner = new Owner();
      owner.email = user.email;
      owner.firstname = user.firstname;
      owner.lastname = user.lastname;
      owner.phone = user.phone;
      return owner;
    } else {
      return this.owner;
    }
  }

  private checkStyles(services, styleID: number) {
    /* if (styleID === -1) {
      return new fromOrder.SetServices([]);
    } else {
      return new fromOrder.SetProducts(this.getProducts(services));
    } */
    return new fromOrder.SetServices([]);
  }

  private checkParutions(p) {
    let serv = [...this.services];
    const hasParu = this.services.find(s => s === PRODUCTS.PRINT);
    if (!hasParu && p.length > 0) {
      serv = [...this.services, PRODUCTS.PRINT];
    } else if (hasParu && p.length === 0) {
      serv = [...this.services].filter(id => id !== PRODUCTS.PRINT);
    }
    return new fromOrder.SetServices(serv);
  }

  private fillDocumentInfo(type: string) {
    const doc = new Document();
    let owner: Owner;
    let userForm: UserForm;
    let user: User;
    let address: Address;
    this.store.select(orderSelector.getOwner).subscribe(o => (owner = o));
    this.store.select(orderSelector.getUserForm).subscribe(u => (userForm = u));
    this.store.select(orderSelector.getAddress).subscribe(a => (address = a));
    this.store.select(orderSelector.getUserInfo).subscribe(u => (user = u));
    if (type === 'toProprio') {
      doc.firstName = owner.firstname !== null ? owner.firstname : userForm.firstname;
      doc.lastName = owner.lastname !== null ? owner.lastname : userForm.lastname;
      doc.address.street = address.street;
      doc.address.number = address.number;
      doc.address.zip = address.zip;
      doc.address.city = address.city;
    } else if (type === 'toProprioAndPro') {
      doc.firstName = owner.firstname;
      doc.lastName = owner.lastname + ' C/o ' + user.lastname;
      doc.address.street = user.address.street;
      doc.address.number = user.address.number;
      doc.address.zip = user.address.zip;
      doc.address.city = user.address.city;
    } else if (type === 'toPro') {
      doc.firstName = user.firstname;
      doc.lastName = user.lastname;
      doc.address.street = user.address.street;
      doc.address.number = user.address.number;
      doc.address.zip = user.address.zip;
      doc.address.city = user.address.city;
    }

    return new fromOrder.SetDocument(doc);
  }

  private checkReductionOnline() {
    let onlinePayment;
    let reductions;
    this.store.select(orderSelector.getOnlinePayment).subscribe(o => (onlinePayment = o));
    this.store.select(orderSelector.getReductions).subscribe(r => (reductions = r));
    if (onlinePayment) {
      const reduction = new Reduction(1001, this.onlineReduc, 'Réduction paiement en ligne');
      reductions.push(reduction);
    } else {
      reductions = reductions.filter(r => r.id !== 1001);
    }

    return reductions;
  }

  /* private checkReductionPromoCode() {
    let promoCode;
    let reductions;
    let products;
    let region;

    this.store.select(orderSelector.getPromoCode).subscribe(p => (promoCode = p));
    this.store.select(orderSelector.getReductions).subscribe(r => (reductions = r));
    this.store.select(orderSelector.getProducts).subscribe(p => (products = p));
    this.store.select(orderSelector.getRegion).subscribe(r => (region = r));

    const f = promoCodes.filter(p => p.code === promoCode && p.regions.some(r => r === region));
    // console.log('f', f);
    const productsID: number[] = [];
    products.map(p => productsID.push(p.id));
    // console.log('productsID', productsID);

    const found = f.filter(p => p.products.every(pr => productsID.indexOf(pr) >= 0))[0];
    // console.log('found', found);
    if (found) {
      const reduction = new Reduction(1002, found.reduc, `${found.code}`);
      reductions.push(reduction);
    } else {
      reductions = reductions.filter(r => r.id !== 1002);
    }

    return reductions;
  } */

  private checkReductionPromoCode(): Promise<PromoCodeDB> {
    let promoCode;
    let products;
    let region;

    this.store.select(orderSelector.getPromoCode).subscribe(p => (promoCode = p));
    this.store.select(orderSelector.getServices).subscribe(p => (products = p));
    this.store.select(orderSelector.getRegion).subscribe(r => (region = r));

    // const f = promoCodes.filter(p => p.code === promoCode && p.regions.some(r => r === region));

    // console.log('f', f);
    /* const productsID: number[] = [];
    products.map(p => productsID.push(p.id)); */
    // console.log('productsID', productsID);

    // const found = f.filter(p => p.products.every(pr => productsID.indexOf(pr) >= 0))[0];

    const found = this.dataService.isPromoCodeValid(promoCode, products, region);

    // console.log('found', found);

    return found;
  }

  private getProducts(ids: number[]): Product[] {
    if (ids.length === 0) {
      return [];
    }
    const reductions: Reduction[] = [];
    // console.log('calculating price');
    const products = ids.map(id => {
      const prod = this.dataService.getProductPrice(id);
      return new Product(id, prod.price, prod.reduc);
    });

    products.forEach(prod => {
      let reduction: Reduction;
      let reduc = Number(prod.reduction);
      // Check if prod.id is in pricingPlansDB.services for region and estatetype and add special reduction
      if (this.userPricingPlan !== 0 && this.esateType !== estates.IMMEUBLE_APPART) {
        reduc = this.dataService.getStatutReduc(prod.id, reduc, this.dataService.getProduct(prod.id));
      }
      if (reduc && reduc !== 0) {
        reduction = new Reduction(prod.id, reduc, 'Réduction ' + prod.name);
        reductions.push(reduction);
      }
    });

    if (this.gazChoice === 0) {
      const gaz = reductions.filter(r => r.id === 15).length > 0 ? reductions.filter(r => r.id === 15)[0] : null;
      if (gaz) {
        gaz.price = +gaz.price + 15 + '';
      }
    }

    const packReducs = this.checkForPacks(products);

    // Check if there is a packReducs.id in pricingPlansDB.services for region and estatetype and add special reduction

    const redOnline = !this.getReductionOnline() ? [] : this.getReductionOnline();

    this.checkReductionPromoCode().then(promoCode => {
      let reducCode = [];
      if (promoCode) {
        let code = '';
        if (typeof promoCode !== 'undefined') {
          reducCode = [new Reduction(1002, promoCode.PromoCode.reduc, `${promoCode.KeyWord}`)];
          code = promoCode.id;
          console.log(reducCode);
        }
        this.store.dispatch(new fromOrder.SetPromoCodeID(code));
      }
      this.store.dispatch(
        new fromOrder.SetReductions(
          reductions
            .concat(packReducs ? packReducs : [])
            .concat(redOnline)
            .concat(reducCode)
        )
      );
    });

    return products;
  }

  private getReductionOnline() {
    let onlinePayment;
    let reduction;
    this.store.select(orderSelector.getOnlinePayment).subscribe(o => (onlinePayment = o));
    if (onlinePayment) {
      reduction = new Reduction(1001, this.onlineReduc, 'RÉDUCTION PAIEMENT EN LIGNE');
    }

    return reduction;
  }

  checkForPacks(products: Product[]): Reduction {
    let reduction: Reduction;
    const immoPacks = JSON.parse(JSON.stringify(this.dataService.getImmoPacks()));
    if (products.length !== 0 && !!immoPacks) {
      const productsID: number[] = [];
      let biggestReductionPack = null;
      products.map(p => productsID.push(p.id));
      console.log('productsID', productsID);
      // saving only pack with biggest reduction
      immoPacks.forEach(pack => {
        console.log('pack', pack.services);
        const found = pack.services.every(s => productsID.indexOf(s) >= 0);
        console.log(found);
        if (found) {
          const packReduc = immoPacks.filter(p => p.id === pack.id)[0];
          /* const pPlan = pricingPlansDB.find(
            pp =>
              pp.id === this.userPricingPlan &&
              pp.regions.some(r => r === this.region) &&
              pp.services.some(p => p === pack.id)
          );

          if (pPlan) {
            // packReduc.reduc = pPlan.reduction + '';
            const pricingPlanReduction = new Reduction(
              200 + this.userPricingPlan,
              pPlan.reduction - packReduc.reduc,
              this.userPricingPlan + ' - ' + packReduc.name
            );
            console.log('pricingPlanReduction', pricingPlanReduction);

            reductions.push(pricingPlanReduction);
          } */

          // console.log('packReduc', packReduc);
          // initiate first pack if found
          if (!biggestReductionPack) {
            biggestReductionPack = packReduc;
          }
          biggestReductionPack = packReduc.reduc > biggestReductionPack.reduc ? packReduc : biggestReductionPack;
        }
      });
      if (biggestReductionPack) {
        this.store.dispatch(new fromOrder.SetPackID(biggestReductionPack.id));
        let reduc = Number(biggestReductionPack.reduc);
        const packID = parseInt(biggestReductionPack.description, 10);
        console.log('packID', packID);
        if (this.userPricingPlan !== 0 && this.esateType !== estates.IMMEUBLE_APPART) {
          reduc = this.dataService.getStatutReduc(packID, reduc, this.dataService.getProduct(packID));
          biggestReductionPack.reduc = reduc;
        }
        // console.log(reduc);
        if (reduc && reduc !== 0) {
          reduction = new Reduction(packID, reduc, 'Réduction ' + biggestReductionPack.name);
        }
        if (!this.showpacks && this.immoPackSelected !== packID) {
          this.snackBar.openFromComponent(PackSelectedComponent, {
            panelClass: 'snackbar-success',
            data: biggestReductionPack
          });
          // console.log(biggestReductionPack);
        }

        /* console.log('biggestReductionPack');
        console.log(biggestReductionPack); */
        this.store.dispatch(new fromOrder.SetImmoPack(packID));

        const found = this.services.indexOf(DESCRIPTIF) > -1;
        const descriptifPack = this.descriptifPacks.some(id => id === packID);
        console.log('found?', found);
        console.log('descriptifPack?', descriptifPack);

        let tempProducts = [...this.services];
        if (!found && descriptifPack) {
          console.log('add descriptif');
          tempProducts.push(DESCRIPTIF);
          tempProducts.push(PRODUCTS.PLAQUETTE);
          this.store.dispatch(new fromOrder.SetServices(tempProducts));
        } else if (found && !descriptifPack) {
          // this.products.splice(index, 1);
          tempProducts = tempProducts.filter(pr => pr !== DESCRIPTIF);
          tempProducts = tempProducts.filter(pr => pr !== PRODUCTS.PLAQUETTE);
          this.store.dispatch(new fromOrder.SetServices(tempProducts));
        }
      } else {
        this.store.dispatch(new fromOrder.SetPackID(''));
        const found = this.services.indexOf(DESCRIPTIF) > -1;
        if (found) {
          let tempProducts = [...this.services];
          tempProducts = tempProducts.filter(pr => pr !== DESCRIPTIF);
          tempProducts = tempProducts.filter(pr => pr !== PRODUCTS.PLAQUETTE);
          this.store.dispatch(new fromOrder.SetServices(tempProducts));
        }
        this.store.dispatch(new fromOrder.SetImmoPack(-1));
      }
    }
    return reduction;
  }
}
