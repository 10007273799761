import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-service-citerne',
  templateUrl: './service-citerne.component.html',
  styleUrls: ['./service-citerne.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServiceCiterneComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {}
  toOrdering() {
    this.router.navigate(['/']);
  }
}
