var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { map, switchMap, mapTo } from 'rxjs/operators';
import { Observable, of, merge, fromEvent } from 'rxjs';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { ActionTypes, SetOnline } from './../store/ui/';
var UIEffects = /** @class */ (function () {
    function UIEffects(actions$) {
        this.actions$ = actions$;
        this.startOnlineOfflineCheck$ = this.actions$.pipe(ofType(ActionTypes.StartOnlineOfflineCheck), switchMap(function () {
            return merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
        }), map(function (isOnline) {
            return new SetOnline(isOnline);
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UIEffects.prototype, "startOnlineOfflineCheck$", void 0);
    return UIEffects;
}());
export { UIEffects };
