import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalculator, faHome, faShippingFast, faClipboardCheck, faUser, faPhone, faAngleDown, faExternalLinkAlt, faPowerOff, faUserPlus, faInfoCircle, faQuestionCircle, faBriefcase, faCheckCircle, faThumbsUp, faExclamationCircle, faExclamationTriangle, faCheck, faFileInvoice, faCalendarAlt, faTimes, faCalendarCheck, faAngleLeft, faAngleRight, faArrowRight, faNewspaper, faTrash, faPlus, faPen, faStreetView } from '@fortawesome/free-solid-svg-icons';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
var ɵ0 = { duration: 8000 };
var SharedModule = /** @class */ (function () {
    function SharedModule() {
        library.add(faCalculator, faHome, faShippingFast, faClipboardCheck, faUser, faHome, faPhone, faAngleDown, faExternalLinkAlt, faPowerOff, faUserPlus, faInfoCircle, faQuestionCircle, faBriefcase, faCheckCircle, faThumbsUp, faExclamationCircle, faExclamationTriangle, faCheck, faFileInvoice, faCalendarAlt, faTimes, faCalendarCheck, faAngleLeft, faAngleRight, faArrowRight, faNewspaper, faTrash, faPlus, faPen, faStreetView);
    }
    return SharedModule;
}());
export { SharedModule };
export { ɵ0 };
