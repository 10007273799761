import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pack-selected',
  templateUrl: './pack-selected.component.html',
  styleUrls: ['./pack-selected.component.scss']
})
export class PackSelectedComponent implements OnInit {
  packName: string;
  param;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      id: number;
      services: number[];
      fileName: string;
      reduc: string;
      servicesInfo: { id: number; fileName: string }[];
      totalWithReduc: number;
      servicesRed: number;
      servicesPrice: number;
      description: string;
    },
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.translate
      .get('section-money.reductions.' + parseInt(this.data.description, 10))
      .subscribe(result => (this.packName = result));
    this.param = {
      packName: this.packName,
      reduction: this.data.reduc
    };
  }
}
