<div class="service-page">
  <header>
    <h1>
      {{ 'service-page.peb-elec.title' | translate }}
    </h1>
    <picture class="service-page-banner">
      <img src="../../../assets/images/service-page-combi-peb-elec.jpg" alt=""
    /></picture>
  </header>
  <div class="content">
    <h2>
      {{ 'service-page.peb-elec.intro' | translate }}
    </h2>
    <div [innerHTML]="'service-page.peb-elec.content' | translate"></div>
    <a class="btn btn-big btn-accent" (click)="toOrdering()">
      {{ 'service-page.peb-elec.action' | translate }}
    </a>
  </div>
</div>
