import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SendEmailService } from 'src/app/core/services/send-email.service';

@Component({
  selector: 'app-specific-request-dialog',
  templateUrl: './specific-request-dialog.component.html',
  styleUrls: ['./specific-request-dialog.component.scss']
})
export class SpecificRequestDialogComponent implements OnInit {
  devisFormGroup: FormGroup;
  sent: boolean;

  constructor(private _formBuilder: FormBuilder, private sendEmailService: SendEmailService) {}

  ngOnInit() {
    this.sent = false;

    this.devisFormGroup = this._formBuilder.group({
      firstName: [
        {
          value: '',
          disabled: false
        },
        Validators.required
      ],
      lastName: [
        {
          value: '',
          disabled: false
        },
        Validators.required
      ],
      phone: [
        {
          value: '',
          disabled: false
        },
        Validators.required
      ],
      email: [
        {
          value: '',
          disabled: false
        },
        Validators.required
      ],
      userStreet: ['', Validators.required],
      userNum: ['', Validators.required],
      userZip: ['', Validators.required],
      userCity: ['', Validators.required],
      remarks: ['']
    });
  }

  onSubmit() {
    if (this.devisFormGroup.invalid) {
      console.log('invalid form');
      return;
    }
    console.log(this.devisFormGroup.value);
    this.sendEmailService.sendDevis(this.devisFormGroup.value).subscribe(
      val => {
        console.log(`🚀`);
        console.log('Devis envoyé avec succès', val);
        this.sent = true;
      },
      response => {
        console.log('😰 POST Devis error', response);
        this.sent = false;
      }
    );
  }
}
