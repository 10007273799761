import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-service-peb-elec',
  templateUrl: './service-peb-elec.component.html',
  styleUrls: ['./service-peb-elec.component.scss']
})
export class ServicePebElecComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {}
  toOrdering() {
    this.router.navigate(['/']);
  }
}
