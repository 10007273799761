<h5 *ngIf="!succintStep1 && !step1TitleImmo" class="step-introduction">
  {{ 'step1.header-calculate-here' | translate }}
  <small *ngIf="step1SubTitle">
    {{
      ($hasImmoAccess | async) ? ('step1.header-immo' | translate) : ('step1.header-no-immo' + projectID | translate)
    }}
  </small>
</h5>

<h5 *ngIf="succintStep1 && !step1TitleImmo" class="step-introduction">
  {{ 'step1.header-summary' + projectID | translate }} {{ 'section-situation.region.' + currentRegionId | translate }}
</h5>

<h5 *ngIf="step1TitleImmo" class="step-introduction">
  {{ 'step1.header-mdn' | translate }}
</h5>

<div [class]="'step-content ' + (products.length > 0 ? 'step-money-visible' : '')">
  <section *ngIf="!succintStep1" class="section-situation" id="card-1">
    <h6>{{ 'section-client.title' | translate }}</h6>
    <app-big-select
      class="client-type"
      [selected]="$clientType | async"
      [items]="clientTypes"
      [isMobile]="$isMobile | async"
      (valueChange)="setClientType($event)"
      [translationString]="'section-client.client-type.'"
      [elementClass]="'clientType'"
    ></app-big-select>
  </section>
  <section class="section-type" id="card-2" *ngIf="($clientType | async) != -1">
    <h6>
      <span>{{ 'section-type.title' | translate }}</span>
      <app-tooltip
        [title]="'section-type.title-tooltip.title' | translate"
        [content]="'section-type.title-tooltip.content' | translate"
        [emphasis]="'section-type.title-tooltip.emphasis' | translate"
      ></app-tooltip>
    </h6>
    <app-big-select
      class="estate-selector"
      [selected]="$selectedEstateStyle | async"
      [items]="estateStyles"
      [isMobile]="$isMobile | async"
      (valueChange)="setEstateStyle($event)"
      [translationString]="'section-type.style.'"
      [elementClass]="'estate'"
    ></app-big-select>
    <ul class="sub-questions">
      <li *ngIf="($selectedEstateStyle | async) === 0" [class]="houseControl.hasError('isEmpty') ? '' : 'selected'">
        <label>{{ 'section-type.sub.house.label' | translate }} </label>
        <mat-form-field appearance="outline">
          <mat-select [formControl]="houseControl">
            <mat-option (click)="setEstateType(-1)" [value]="-1">--</mat-option>
            <mat-option
              *ngFor="let houseOption of houseOptions"
              (click)="setEstateType(houseOption.typeId)"
              [value]="houseOption.typeId"
            >
              {{ 'estateTpes.' + houseOption.typeId | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="houseControl.hasError('isEmpty')">{{ 'inputs.select-required' | translate }}</mat-error>
        </mat-form-field>
        <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
      </li>
      <li *ngIf="($selectedEstateStyle | async) === 1" [class]="appartControl.hasError('isEmpty') ? '' : 'selected'">
        <label>{{ 'section-type.sub.flat.label' | translate }} </label>
        <mat-form-field appearance="outline">
          <mat-select [formControl]="appartControl">
            <mat-option (click)="setEstateType(-1)" [value]="-1">--</mat-option>
            <mat-option
              *ngFor="let appartOption of appartOptions"
              (click)="setEstateType(appartOption.typeId)"
              [value]="appartOption.typeId"
            >
              {{ 'estateTpes.' + appartOption.typeId | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="appartControl.hasError('isEmpty')">{{ 'inputs.select-required' | translate }}</mat-error>
        </mat-form-field>
        <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
      </li>
      <li *ngIf="($selectedEstateStyle | async) === 2" [class]="buildingControl.hasError('isEmpty') ? '' : 'selected'">
        <label>{{ 'section-type.sub.building.label' | translate }}</label>
        <mat-form-field appearance="outline">
          <mat-select [formControl]="buildingControl">
            <mat-option (click)="setEstateType(-1)" [value]="-1">--</mat-option>
            <mat-option
              *ngFor="let immeubleOption of immeubleOptions"
              (click)="setNbOfAppartments(immeubleOption.id)"
              [value]="immeubleOption.id"
            >
              {{ immeubleOption.text }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="buildingControl.hasError('isEmpty')">{{ 'inputs.select-required' | translate }}</mat-error>
        </mat-form-field>
        <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
      </li>
    </ul>
  </section>
  <section
    *ngIf="(!succintStep1 && ($selectedEstateType | async) !== -1) || ($selectedEstateStyle | async) === 3"
    class="section-reason"
  >
    <h6>{{ 'section-reason.title' | translate }}</h6>
    <app-big-select
      class="reason-type"
      [selected]="$reason | async"
      [items]="reasons"
      [isMobile]="$isMobile | async"
      (valueChange)="setReasonType($event)"
      [translationString]="'section-reason.reason-type.'"
      [elementClass]="'reasonType'"
    ></app-big-select>
    <form [formGroup]="ventePubFG" *ngIf="($reason | async) !== 1">
      <ul class="sub-questions">
        <li>
          <label>Status de la vente</label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="venteStatut">
              <mat-option
                *ngFor="let item of ventePubChoices"                    
                [value]="item"
              >
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
        </li>
        <li>
          <label>Date de la vente</label>
          <mat-form-field appearance="outline" id="date-picker">
            <input
              matInput
              [min]="minDate"
              [matDatepicker]="picker"
              placeholder="DD/MM/YYYY"
              formControlName="venteDate"
            />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
        </li>
      </ul>
    </form>
  </section>
  <section class="section-services" id="card-3" *ngIf="($reason | async) !== -1">
    <header>
      <h6>{{ 'section-services.title' | translate }}</h6>
    </header>
    <!-- All pack here -->
    <div id="fake-pack-container">
      <div class="services-pack-element">
        <header class="pack-header subtitle">
          All in
          <span class="pack-pricing-big">650<small>€</small></span>
        </header>
        <div class="pack-details open">
          <span class="pack-details-title">Services certinergie</span>
          <ul>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Descriptif réalisé</span>
            </li>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Descriptif sommaire</span>
            </li>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Photos</span>
            </li>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Visite virtuelle</span>
            </li>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Plans 2D/3D</span>
            </li>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Affichettes</span>
            </li>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Plaquette virtuelle</span>
            </li>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Contrôle PEB</span>
            </li>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Contrôle électrique</span>
            </li>
          </ul>
        </div>
        <div class="pack-details open">
          <span class="pack-details-title">Services notaire</span>
          <ul>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Encodage IPL</span>
            </li>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Mise en ligne Immoweb</span>
            </li>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Mise en ligne notaire</span>
            </li>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Mise en ligne Immovlan</span>
            </li>
          </ul>
        </div>
        <button class="btn btn-primary btn-selection" (click)="setPackAllIn()">
          {{ 'section-services.choose-this-pack' | translate }}
        </button>
      </div>
      <div class="services-pack-element">
        <header class="pack-header subtitle">
          Presqu'in
          <span class="pack-pricing-big">450<small>€</small></span>
        </header>
        <div class="pack-details open">
          <span class="pack-details-title">Services certinergie</span>
          <ul>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Descriptif réalisé</span>
            </li>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Descriptif sommaire</span>
            </li>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Photos</span>
            </li>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Visite virtuelle</span>
            </li>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Plans 2D/3D</span>
            </li>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Affichettes</span>
            </li>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Plaquette virtuelle</span>
            </li>
          </ul>
        </div>
        <div class="pack-details open">
          <span class="pack-details-title">Services notaire</span>
          <ul>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Encodage IPL</span>
            </li>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Mise en ligne Immoweb</span>
            </li>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Mise en ligne notaire</span>
            </li>
            <li class="pack-details-element">
              <img src="../../assets/images/service-3d.svg" />
              <span>Mise en ligne Immovlan</span>
            </li>
          </ul>
        </div>
        <button class="btn btn-primary btn-selection" (click)="setImmoPack(immoPack.description)">
          {{ 'section-services.choose-this-pack' | translate }}
        </button>
      </div>
    </div>
    <div class="services-pack-container" *ngIf="($showPacks | async) && ($selectedEstateStyle | async) !== -1">
      <div
        class="services-pack-element"
        [ngClass]="{ selected: ($selectedImmoPack | async) == immoPack.description }"
        *ngFor="let immoPack of ($immoPacksWithPrices | async | sortby: 'description')"
      >
        <header class="pack-header subtitle">{{ 'section-services.packs.' + immoPack.description | translate }}</header>
        <div class="pack-pricing" *ngIf="($userType | async) === UserTypes.Particulier; else notPart">
          <span class="pack-pricing-promo">- {{ immoPack.servicesPrice - immoPack.totalWithReduc }}€</span>
          <span class="pack-pricing-big">{{ immoPack.totalWithReduc }}<small>€</small></span>
          <span class="pack-pricing-reduction">
            {{ 'section-services.insteadof' | translate }}
            <span> {{ immoPack.servicesPrice }} </span>€
          </span>
        </div>
        <ng-template #notPart>
          <div class="pack-pricing">
            <span class="pack-pricing-promo">- {{ immoPack.servicesPrice - immoPack.totalWithReduc }}€</span>
            <span class="pack-pricing-big">{{ immoPack.totalWithReduc }}<small>€</small></span>
            <span class="pack-pricing-reduction">
              {{ 'section-services.insteadof' | translate }}
              <span> {{ immoPack.servicesPrice }} </span>€
            </span>
          </div>
        </ng-template>
        <div class="pack-details" [ngClass]="{ open: immoPack.details }">
          <ul>
            <li class="pack-details-element" *ngFor="let serv of immoPack.servicesInfo">
              <img
                [src]="serviceImgUrl(serv.fileName)"
                matTooltip="{{ 'section-services.services.' + serv.id | translate }}"
                matTooltipPosition="below"
              />
              <span>{{ 'section-services.services.' + serv.id | translate }}</span>
            </li>
          </ul>
          <button class="btn btn-link btn-more" (click)="immoPack.details = true">
            ({{ 'section-services.details-show' | translate }})
          </button>
          <button class="btn btn-link btn-less" (click)="immoPack.details = false">
            ({{ 'section-services.details-hide' | translate }})
          </button>
        </div>
        <button class="btn btn-primary btn-selection" (click)="setImmoPack(immoPack.description)">
          {{ 'section-services.choose-this-pack' | translate }}
        </button>
        <span class="btn btn-accent btn-selected">
          {{ 'section-services.pack-selected' | translate }}
        </span>
      </div>
    </div>
    <!-- All carte here -->
    <div *ngIf="!($showPacks | async)" class="services-carte-container">
      <mat-accordion [displayMode]="'flat'" [multi]="true" class="services-carte-accordeon">
        <!-- Section controle -->
        <mat-expansion-panel
          *ngIf="($filteredServices | async).length > 0"
          class="services-carte-panel"
          [expanded]="true"
        >
          <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
            <mat-panel-title>
              <p class="subtitle">
                {{ 'section-services.alacarte.control' | translate }}
                <small>({{ howManyControlSelected }}/{{ controlLength }})</small>
              </p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="services-carte-panel-content options-container">
            <div
              *ngFor="let service of ($filteredServices | async)"
              [class]="'option ' + serviceClass(service.id)"
              (click)="setService(service.id)"
            >
              <picture>
                <img [src]="serviceImgUrl(service.fileName, service.id)" class="service-img" />
              </picture>
              <p class="service-txt">
                {{ 'section-services.services.' + service.id | translate }}
              </p>
              <app-tooltip
                *ngIf="
                  ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                  'section-services.services-tooltip.' + service.id + '.content'
                "
                [title]="
                  ('section-services.services-tooltip.' + service.id + '.title' | translate) !==
                  'section-services.services-tooltip.' + service.id + '.title'
                    ? ('section-services.services-tooltip.' + service.id + '.title' | translate)
                    : null
                "
                [content]="
                  ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                  'section-services.services-tooltip.' + service.id + '.content'
                    ? ('section-services.services-tooltip.' + service.id + '.content' | translate)
                    : null
                "
                [emphasis]="
                  ('section-services.services-tooltip.' + service.id + '.emphasis' | translate) !==
                  'section-services.services-tooltip.' + service.id + '.emphasis'
                    ? ('section-services.services-tooltip.' + service.id + '.emphasis' | translate)
                    : null
                "
              ></app-tooltip>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- <button id="show-all-services" class="btn btn-primary" (click)="toggleShowMoreServices(!showMoreServices)">
          {{
            showMoreServices
              ? ('homepage.section-services.show-less' | translate)
              : ('homepage.section-services.show-more' | translate)
          }}
        </button> -->
        <ul
          *ngIf="hasCiterne || hasElec || hasGaz || (hasPeb && ($selectedEstateStyle | async) === 1)"
          class="sub-questions"
        >
          <li *ngIf="hasPeb && ($selectedEstateStyle | async) === 1 && ($selectedRegion | async) === 0">
            <label>
              {{ 'section-services.peb-partiel.title' | translate }}
            </label>
            <mat-form-field appearance="outline">
              <mat-select [value]="pebPartiel">
                <mat-option (click)="setPebPartiel(false)" [value]="false">
                  {{ 'section-services.peb-partiel.no' | translate }}
                </mat-option>
                <mat-option (click)="setPebPartiel(true)" [value]="true">
                  {{ 'section-services.peb-partiel.yes' | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div id="peb-partiel" *ngIf="pebPartiel">
              <div>
                <label
                  >{{ 'section-services.peb-partiel.subtitle' | translate }}
                  <app-tooltip [content]="'section-services.peb-partiel.tooltip' | translate"></app-tooltip>
                </label>
                <mat-form-field class="peb-partiel" appearance="outline">
                  <input
                    matInput
                    #pebPartielNumber
                    (blur)="addPebNb(pebPartielNumber.value)"
                    [value]="pebNb"
                    placeholder="20190313001111"
                    name="peb-partiel"
                  />
                </mat-form-field>
              </div>
              <div>
                <span>{{ 'section-services.peb-partiel.option' | translate }}</span>
                <button class="btn btn-default" (click)="addPebPartiel()">
                  {{ 'section-services.peb-partiel.option-action' | translate }}
                </button>
              </div>
            </div>
          </li>
          <li *ngIf="hasElec">
            <label>{{ 'section-services.elec.subtitle' | translate }}</label>
            <mat-form-field appearance="outline">
              <mat-select [value]="$nbElec | async">
                <mat-option
                  *ngFor="let elecOption of elecOptions"
                  (click)="setElecSupp(elecOption)"
                  [value]="elecOption"
                >
                  {{ elecOption }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </li>
          <li *ngIf="hasGaz" class="sub-questions--gaz">
            <label>{{ 'section-services.gaz.subquestion' | translate }}</label>
            <mat-form-field appearance="outline">
              <mat-select [value]="$gazChoice | async">
                <mat-option (click)="setGazChoice(0)" [value]="0">
                  {{ 'section-services.gaz.choice-0' | translate }}
                </mat-option>
                <mat-option (click)="setGazChoice(1)" [value]="1">
                  {{ 'section-services.gaz.choice-1' | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </li>
          <li *ngIf="hasGaz">
            <label>{{ 'section-services.gaz.subtitle' | translate }}</label>
            <mat-form-field appearance="outline">
              <mat-select [value]="$nbGaz | async">
                <mat-option *ngFor="let gazOption of gazOptions" (click)="setGazSupp(gazOption)" [value]="gazOption">
                  {{ gazOption }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </li>
          <li *ngIf="hasGaz">
            <label>{{ 'section-services.gaz.iso' | translate }}</label>
            <mat-form-field appearance="outline">
              <mat-select [value]="$nbOfIso | async">
                <mat-option (click)="setPlanIso(0)" [value]="0">
                  {{ 'section-services.peb-partiel.no' | translate }}
                </mat-option>
                <mat-option *ngFor="let gazOption of gazOptions" (click)="setPlanIso(gazOption)" [value]="gazOption">
                  {{ gazOption }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </li>
          <li *ngIf="hasCiterne">
            <label>{{ 'section-services.citerne.subtitle' | translate }}</label>
            <mat-form-field appearance="outline">
              <mat-select [value]="citerneType">
                <mat-option (click)="setService(9)" [value]="9">
                  {{ 'section-services.citerne.under' | translate }}
                </mat-option>
                <mat-option (click)="setService(6)" [value]="6">
                  {{ 'section-services.citerne.aerial' | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </li>
        </ul>
        <!-- Section aide à la vente -->
        <mat-expansion-panel
          *ngIf="
            (($userType | async) === UserTypes.Notaire || hasImmoAccess || hasImmoAccessProject) &&
            ($selectedEstateStyle | async) !== 2 &&
            ($filteredImmoServices | async).length > 0
          "
          class="services-carte-panel"
          [expanded]="true"
        >
          <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
            <mat-panel-title>
              <p class="subtitle">
                {{ 'section-services.alacarte.immo' | translate }}
                <small>({{ howManyImmoSelected }}/{{ immoLength }})</small>
              </p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="services-carte-panel-content options-container">
            <div
              *ngFor="let service of ($filteredImmoServices | async)"
              [class]="'option ' + serviceClass(service.id) + (service.free ? ' free-service' : '')"
              (click)="setService(service.id)"
            >
              <picture>
                <img [src]="serviceImgUrl(service.fileName, service.id)" class="service-img" />
              </picture>
              <p class="service-txt">{{ 'section-services.services.' + service.id | translate }}</p>
              <app-tooltip
                *ngIf="
                  ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                  'section-services.services-tooltip.' + service.id + '.content'
                "
                [title]="
                  ('section-services.services-tooltip.' + service.id + '.title' | translate) !==
                  'section-services.services-tooltip.' + service.id + '.title'
                    ? ('section-services.services-tooltip.' + service.id + '.title' | translate)
                    : null
                "
                [content]="
                  ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                  'section-services.services-tooltip.' + service.id + '.content'
                    ? ('section-services.services-tooltip.' + service.id + '.content' | translate)
                    : null
                "
                [emphasis]="
                  ('section-services.services-tooltip.' + service.id + '.emphasis' | translate) !==
                  'section-services.services-tooltip.' + service.id + '.emphasis'
                    ? ('section-services.services-tooltip.' + service.id + '.emphasis' | translate)
                    : null
                "
              ></app-tooltip>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Section panneaux -->
        <mat-expansion-panel
          *ngIf="
            (($userType | async) === UserTypes.Notaire || hasImmoAccess || hasImmoAccessProject) &&
            ($selectedEstateStyle | async) !== 2 &&
            ($filteredPanneauxServices | async).length > 0
          "
          class="services-carte-panel"
          [expanded]="true"
        >
          <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
            <mat-panel-title>
              <p class="subtitle">
                {{ 'section-services.alacarte.panel' | translate }}
              </p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="services-carte-panel-content options-container">
            <div
              *ngFor="let service of ($filteredPanneauxServices | async)"
              [class]="'option ' + serviceClass(service.id) + (service.free ? ' free-service' : '')"
              (click)="setService(service.id)"
            >
              <picture>
                <img [src]="serviceImgUrl(service.fileName, service.id)" class="service-img" />
              </picture>
              <p class="service-txt">{{ 'section-services.panels.' + service.id | translate }}</p>
              <app-tooltip
                *ngIf="
                  ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                  'section-services.services-tooltip.' + service.id + '.content'
                "
                [title]="
                  ('section-services.services-tooltip.' + service.id + '.title' | translate) !==
                  'section-services.services-tooltip.' + service.id + '.title'
                    ? ('section-services.services-tooltip.' + service.id + '.title' | translate)
                    : null
                "
                [content]="
                  ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                  'section-services.services-tooltip.' + service.id + '.content'
                    ? ('section-services.services-tooltip.' + service.id + '.content' | translate)
                    : null
                "
                [emphasis]="
                  ('section-services.services-tooltip.' + service.id + '.emphasis' | translate) !==
                  'section-services.services-tooltip.' + service.id + '.emphasis'
                    ? ('section-services.services-tooltip.' + service.id + '.emphasis' | translate)
                    : null
                "
              ></app-tooltip>
            </div>
          </div>
        </mat-expansion-panel>
        <ul *ngIf="hasPanel || hasBidditPanel" class="sub-questions">
          <li *ngIf="hasPanel">
            <label>
              {{ 'section-services.panels.subtitle' | translate }}
            </label>
            <mat-form-field appearance="outline">
              <mat-select [value]="hasPanelSupport">
                <mat-option (click)="setServiceNoRemove(33)" [value]="true">
                  {{ 'section-services.panels.support' | translate }}
                </mat-option>
                <mat-option (click)="setServiceNoRemove(32)" [value]="false">
                  {{ 'section-services.panels.to-stick' | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </li>
          <li *ngIf="hasBidditPanel">
            <label>{{ 'section-services.panels.subtitle' | translate }}</label>
            <mat-form-field appearance="outline">
              <mat-select [value]="hasBidditPanelSupport">
                <mat-option (click)="setServiceNoRemove(30)" [value]="true">
                  {{ 'section-services.panels.support' | translate }}
                </mat-option>
                <mat-option (click)="setServiceNoRemove(29)" [value]="false">
                  {{ 'section-services.panels.to-stick' | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </li>
        </ul>
      </mat-accordion>
    </div>
  </section>
</div>
<div class="step-money" *ngIf="products.length > 0">
  <div class="step-money-details">
    <ul *ngIf="!succintStep1" class="selected-products">
      <li *ngFor="let product of ($products | async)">
        <div class="name">{{ 'section-money.reductions.' + product.id | translate }}</div>
        <div *ngIf="product.price != '0'; else free" class="price">{{ product.price }} €</div>
        <ng-template #free>
          <div class="price">Free</div>
        </ng-template>
      </li>
    </ul>
    <div
      class="products-reduction"
      [ngClass]="{ 'show-details': reducMoreDetails }"
      *ngIf="!succintStep1 && !($urgence | async) && ($reduction | async) !== 0"
    >
      <div class="reduction-total">
        <div class="name">
          {{ 'section-money.reduction' | translate }}
          <button *ngIf="($commission | async) === 0" class="btn btn-link btn-more" (click)="reducMoreDetails = true">
            ({{ 'section-services.details-show' | translate }})
          </button>
          <button *ngIf="($commission | async) === 0" class="btn btn-link btn-less" (click)="reducMoreDetails = false">
            ({{ 'section-services.details-hide' | translate }})
          </button>
        </div>
        <div class="price">- {{ reduction }} €</div>
      </div>
      <ul class="reduction-details" *ngIf="($commission | async) === 0">
        <li *ngFor="let reduction of ($reductions | async)">
          <div *ngIf="reduction.id !== 1002">
            {{ 'section-money.reductions.' + reduction.id | translate }}
          </div>
          <div *ngIf="reduction.id === 1002">
            {{ 'section-money.reductions.' + reduction.id | translate }} "{{ promoCode }}"
          </div>
          <div>- {{ reduction.price }} €</div>
        </li>
      </ul>
    </div>
    <div class="commission" *ngIf="!succintStep1 && ($hasCommission | async) && !($urgence | async)">
      <div class="commission-action">
        <div>{{ 'section-money.commission' | translate }}</div>
        <div class="slider-container">
          <span>0</span>
          <mat-slider
            style="width: 100%"
            #commission
            (change)="setCommission(commission.value)"
            [value]="$commission | async"
            [min]="0"
            [max]="$reductionWithoutComm | async"
            [tickInterval]="10"
            [thumbLabel]="true"
          >
          </mat-slider
          ><span>{{ $reductionWithoutComm | async }}</span>
        </div>
      </div>
      <div>{{ $commission | async }} €</div>
    </div>
    <div *ngIf="showFinalOptions" [class]="'final-options ' + (succintStep1 ? 'is-summary' : '')">
      <div *ngIf="!($urgence | async)" class="internet-payment">
        <mat-checkbox (change)="onlinePayment()" [checked]="$onlinePayment | async">
          <span class="checkbox-label">{{ 'section-money.online-payment' | translate }}</span>
        </mat-checkbox>
        <span *ngIf="($onlinePayment | async)">- 10 €</span>
      </div>
      <div *ngIf="!succintStep1" class="urgence-action">
        <mat-checkbox (change)="urgence()" [checked]="$urgence | async">
          {{ 'section-money.urgence.label' | translate }}
        </mat-checkbox>
        <app-tooltip
          [tooltipPosition]="'top'"
          [title]="'section-money.urgence.tooltip-title' | translate"
          [content]="'section-money.urgence.tooltip-content' | translate"
          [emphasis]="'section-money.urgence.tooltip-emphasis' | translate"
        ></app-tooltip>
      </div>
      <div
        *ngIf="!succintStep1"
        [ngClass]="{
          'promo-reduction': true,
          valid: promoCode !== '' && ($promocodeID | async) !== '',
          invalid: promoCode !== '' && ($promocodeID | async) === ''
        }"
      >
        <span class="title">
          <fa-icon icon="check" class="valid-icon"></fa-icon>
          {{ 'section-money.promo-code.label' | translate }}
          <app-tooltip
            [tooltipPosition]="'top'"
            [content]="'section-money.promo-code.tooltip' | translate"
          ></app-tooltip>
          <span class="valid-promo-code">
            {{ promoCode }}
          </span>
        </span>
        <div class="result">- {{ reductionPromoCode }} €</div>
        <div class="form-container">
          <mat-form-field class="promo-code-form" appearance="outline">
            <input
              matInput
              #pcode
              [value]="promoCode"
              placeholder="{{ 'section-money.promo-code.label' | translate }}"
            />
          </mat-form-field>
          <button class="btn btn-default" (click)="setPromoCode(pcode.value)">
            {{ 'section-money.promo-code.add' | translate }}
          </button>
          <span *ngIf="promoCode !== '' && ($promocodeID | async) === ''" class="error-msg caption">{{
            'section-money.promo-code.invalid' | translate
          }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="step-money-result">
    <div class="title">{{ 'section-money.total' | translate }}</div>
    <div class="result" *ngIf="!($urgence | async)">
      <span class="htva">
        <small>{{ 'section-money.htva' | translate }}</small>
        {{ priceWithoutReduction - reduction | htva }} €
      </span>
      <span>
        <small>{{ 'section-money.tvac' | translate }}</small>
        {{ priceWithoutReduction - reduction }} €
      </span>
    </div>
    <div class="result" *ngIf="($urgence | async)">
      <span class="htva">
        <small>{{ 'section-money.htva' | translate }}</small>
        {{ priceWithoutReduction | htva }} €
      </span>
      <span>
        <small>{{ 'section-money.tvac' | translate }}</small>
        {{ priceWithoutReduction }} €
      </span>
    </div>
  </div>
</div>
<div class="step-actions">
  <button *ngIf="succintStep1" class="btn btn-warning" (click)="succintStep1 = false">
    {{ 'navigation.show-options' | translate }}
  </button>
  <button matStepperNext class="btn btn-primary btn-next" (click)="nextStep()">
    {{ 'navigation.next' | translate }}
  </button>
</div>
<!-- {{ priceWithoutReduction - reduction }}
{{ reduction }}
<br /> {{ $products | async | json }}
<br /> {{ $reductions | async | json }}
 -->
