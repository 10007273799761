<mat-accordion [displayMode]="'flat'" class="login-accordeon">
  <mat-expansion-panel *ngIf="!($isLoggedIn | async)" class="panel-connect" [expanded]="!data.register" hideToggle>
    <mat-expansion-panel-header collapsedHeight="80px" expandedHeight="80px">
      <mat-panel-title>
        <h5>
          {{ 'login-modal.connection.title' | translate }}
        </h5>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="content">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'login-modal.connection.email' | translate }}</mat-label>
        <input type="email" matInput placeholder="Email@example.com" cdkFocusInitial #email />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'login-modal.connection.password' | translate }}</mat-label>
        <input matInput type="password" placeholder="Your Password" #password />
      </mat-form-field>
      <a
        (click)="forgotPassword(email.value)"
        class="caption"
        matTooltip="{{ 'login-modal.connection.password-reset-tooltip' | translate }}"
        matTooltipPosition="above"
        >{{ 'login-modal.connection.password-reset' | translate }}</a
      >
      <p *ngIf="(loginStatus | async) === 2" class="password-reset-error caption">
        {{ 'login-modal.connection.password-error' | translate }}
      </p>
      <p *ngIf="(loginStatus | async) === 3" class="password-reset-error caption">
        {{ 'login-modal.connection.email-error' | translate }}
      </p>
      <p
        *ngIf="!emailSent && ((emailAsked && email.value.length === 0) || emailNotInDB || emailError)"
        class="password-reset-error caption"
      >
        {{ 'login-modal.connection.password-reset-error' | translate }}
      </p>
      <p *ngIf="emailSent" class="password-reset-success caption">
        {{ 'login-modal.connection.password-reset-success' | translate }}
      </p>
      <button class="btn btn-primary" (click)="signIn(email.value, password.value)">
        {{ 'login-modal.connection.action' | translate }}
      </button>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel class="panel-register" [expanded]="data.register" hideToggle>
    <mat-expansion-panel-header collapsedHeight="80px" expandedHeight="80px">
      <mat-panel-title>
        <h5>{{ 'login-modal.register.title' | translate }}</h5>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div
      [id]="userTypeSelected === '' ? '' : userTypeSelected === 'other' ? 'user-other' : 'user-form'"
      class="content"
    >
      <p class="body">
        {{ 'login-modal.register.basic-content.intro' | translate }}
      </p>
      <div *ngIf="userTypeSelected === 'other'">
        <p class="body" id="user-other-explanation">
          {{ 'login-modal.register.user-other.content' | translate }}
        </p>
        <button class="btn btn-accent" (click)="closeDialog()">
          {{ 'login-modal.register.user-other.action' | translate }}
        </button>
      </div>
      <form
        *ngIf="userTypeSelected !== '' && userTypeSelected !== 'other'"
        [formGroup]="userFG"
        (ngSubmit)="askForProAccount()"
      >
        <div id="register-form">
          <mat-form-field appearance="outline" id="register-name">
            <mat-label>{{ 'section-user.disco.lastname' | translate }}</mat-label>
            <input matInput placeholder="Doe" formControlName="lastName" required />
          </mat-form-field>
          <mat-form-field appearance="outline" id="register-firstname">
            <mat-label>{{ 'section-user.disco.firstname' | translate }}</mat-label>
            <input matInput placeholder="Jane" formControlName="firstName" required />
          </mat-form-field>
          <mat-form-field appearance="outline" id="register-phone">
            <mat-label>{{ 'section-user.disco.phone' | translate }}</mat-label>
            <input matInput placeholder="0032123456789" formControlName="phone" required />
          </mat-form-field>
          <mat-form-field appearance="outline" id="register-email">
            <mat-label>{{ 'section-user.disco.email' | translate }}</mat-label>
            <input matInput placeholder="janedoe@mail.com" type="email" formControlName="email" required />
          </mat-form-field>
          <mat-form-field appearance="outline" id="register-company">
            <mat-label>{{ 'section-user.disco.societe-name' | translate }}</mat-label>
            <input matInput placeholder="Jane Doe Corporation" formControlName="company" required />
          </mat-form-field>
          <mat-form-field appearance="outline" id="register-tva">
            <mat-label>{{ 'section-user.disco.societe-tva' | translate }}</mat-label>
            <input matInput placeholder="BE 1234567890" formControlName="tva" required />
          </mat-form-field>
        </div>
        <button class="btn" (click)="closeDialog()">
          {{ 'login-modal.register.user-form.cancel' | translate }}
        </button>
        <button type="submit" class="btn btn-accent">
          {{ 'login-modal.register.user-form.confirm' | translate }}
        </button>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>
